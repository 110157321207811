import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './LeftMenu.jss';

const items = [
	{ name: 'logo' },
	{ name: 'persons', icon: 'group' },
	{ name: 'settings', icon: 'settings' },
	// {name: 'mapScrolling'},
];

class LeftMenu extends PureComponent {
	static propTypes = {
		active: PropTypes.string.isRequired,
		onClick: PropTypes.func,
	};

	handleOnClick = (el) => {
		const { onClick } = this.props;
		if (onClick) onClick(el);
	};

	renderItem(el) {
		const { classes, active } = this.props;
		return (
			<li
				key={el.name}
				className={classNames(classes.item, classes[el.name], {
					active: active === el.name,
				})}
				onClick={() => this.handleOnClick(el)}
			>
				{el?.icon && (
					<span className='menu-icon material-icons-outlined'>{el.icon}</span>
				)}
			</li>
		);
	}

	render() {
		const { classes } = this.props;
		return (
			<ul className={classes.list}>{items.map((el) => this.renderItem(el))}</ul>
		);
	}
}

export default withStyles(styles)(LeftMenu);
