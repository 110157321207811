import {createReducer, createActions} from 'reduxsauce';
import Immutable from 'seamless-immutable';

const {Types, Creators} = createActions({
    boundaryListRequest: ['bounds'],
    boundaryListSuccess: ['data', 'headers', 'status'],
    boundaryListFailure: ['headers', 'status'],
});

export const BoundaryTypes = Types;
export default Creators;

export const BOUNDARY_PENDING = {
    boundariesList: 'boundariesList',
};

export const INITIAL_STATE = Immutable({
    list: Immutable({
        error: false,
        data: [],
    }),
});

// list
export const boundaryListRequest = (state, action) => {
    const newState = state.merge({
        list: {
            error: false,
            data: [],
        }
    }, {deep: true});
    return newState;
};
export const boundaryListSuccess = (state, action) => {
    const {data} = action;
    const newState = state.merge({
        list: {
            error: false,  
            data,  
        }
    }, {deep: true});
    return newState;
};
export const boundaryListFailure = (state, action) => {
    const newState = state.merge({
        list: {error: true}
    }, {deep: true});
    return newState;
};

export const reducer = createReducer(INITIAL_STATE, {
    [Types.BOUNDARY_LIST_REQUEST]: boundaryListRequest,
    [Types.BOUNDARY_LIST_SUCCESS]: boundaryListSuccess,
    [Types.BOUNDARY_LIST_FAILURE]: boundaryListFailure,
});
