const styles = (theme) => ({
	container: {
		height: '100%',

		'@media screen and (max-width: 767px)': {
			backgroundColor: '#F8F9F9',
		},
	},
});

export default styles;
