import React, {useRef} from 'react';
import get from 'get-value';


import Config from '../../config';
import { Map } from './';

import './GeographicRisk.scss';

export const VARIANT = {
    PERSONAL: 0,
    COMPARE: 1,
};

const GeographicRisk = (props) => {
    const {riskLabel, location, variant} = props;

    const mapCenter = location;

    const elementToShare = useRef();

    // const mapPersonalStyle = variant === VARIANT.PERSONAL ? { width: '100%', height: 120 } : undefined;
    const blockMapStyle = variant === VARIANT.PERSONAL ? 'block-map-personal' : 'block-map';
    
    return (
        <div ref={elementToShare} className='geographic-risk-container'>
            <div className='box-graph'>
                <div className={blockMapStyle}>
                    <Map
                        accessToken={Config.mapbox.accessToken}
                        mapStyle={Config.mapbox.style}
                        center={mapCenter}
                        marker={mapCenter}
                        // style={mapPersonalStyle}
                        zoom = {riskLabel === 'National Ranking' ? 3 : 7}
                    />
                </div>
            </div>
        </div>
    );
};

GeographicRisk.defaultProps = {
    variant: VARIANT.PERSONAL,
};

export default GeographicRisk;
