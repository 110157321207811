
const styles = (theme) => ({
    container: {
        display: 'flex',
        flex: '1 1 auto',
        justifyContent: 'center',
        alignItems: 'flex-start',
        paddingTop: 45,
        paddingLeft: 10,
        alignSelf: 'flex-start',
    },
    content: {
        padding: 0,
    },
    panel: {
        zIndex: 100,
        minWidth: 698,
        width: 698,
        minHeight: 204,
        marginTop: -10,
        marginLeft: 10,
        position: 'relative',
    },
    header: {
        borderBottom: `1px solid ${theme.palette.primary.dark}`,
        padding: '13px 22px',
    },
    headerLine: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    message: {
        padding: 22,
        '& h6': {
            lineHeight: '24px',
        },
        '& .godoshboard': {
            margin: '20px 0',
        },
    },
});

export default styles;
