import {combineReducers} from 'redux';
import configureStore from './CreateStore';
import rootSaga from '../sagas';

import {reducer as networkReducer} from './NetworkRedux';
import {reducer as userReducer} from './UserRedux';
import {reducer as geocodingReducer} from './GeocodingRedux';
import {reducer as dictionaryReducer} from './DictionaryRedux';
import {reducer as boundaryReducer} from './BoundaryRedux';
import {reducer as personReducer} from './PersonRedux';
import {reducer as reportReducer} from './ReportRedux';
import {reducer as homeReducer} from './HomeRedux';

export const reducers = combineReducers({
    home: homeReducer,
    network: networkReducer,
    user: userReducer,
    geocoding: geocodingReducer,
    dictionary: dictionaryReducer,
    boundary: boundaryReducer,
    person: personReducer,
    report: reportReducer,
});

export default () => {
    let {
        // eslint-disable-next-line prefer-const
        store, sagasManager, sagaMiddleware,
    } = configureStore(reducers, rootSaga);

    if (module.hot) {
        module.hot.accept(() => {
            const nextRootReducer = require('./').reducers; // eslint-disable-line global-require, import/no-self-import
            store.replaceReducer(nextRootReducer);

            const newYieldedSagas = require('../sagas').default; // eslint-disable-line global-require
            sagasManager.cancel();
            sagasManager.done.then(() => {
                sagasManager = sagaMiddleware.run(newYieldedSagas);
            });
        });
    }

    return store;
};
