const styles = (theme) => ({
    riskScoreChart: {
        display: 'flex',
        alignItems: 'center',
        width: '100%',

        '& .ratingData': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth: 80,
            height: 80,
            background: '#97D101',
            borderRadius: 10,
            color: 'white',

            '& .ratingVal': {
                fontSize: 46,

                '& .ratingTotal': {
                    verticalAlign: 'baseline',
                    fontSize: 23,
                },
            },
        },

        '& .ratingTextInfo': {
            fontSize: 16,
            lineHeight: '140%',
            paddingLeft: 20,
            textAlign: 'left',

            '& .ratingName': {
                color: '#233544',
            },

            '& .ratingDescription': {
                color: '#7D8C97',
            },
        },
    },

    '@media screen and (max-width: 767px)': {
        riskScoreChart: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
    },
});

export default styles;
