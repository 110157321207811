import React, {PureComponent} from 'react';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {Typography} from '@material-ui/core';

import {Panel, DarkButton} from './index';
import styles from './Confirm.jss';

class Confirm extends PureComponent {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        message: PropTypes.string.isRequired,
        confirm: PropTypes.string, // text of the button
        onConfirm: PropTypes.func,
        cancel: PropTypes.string, // text of the button
        onCancel: PropTypes.func,
    };

    render() {
        const {
            classes, message, confirm = '', cancel = '',
            onConfirm, onCancel, children,
        } = this.props;
        return (
            <Panel
                className={classes.panel}
                light
            >
                <Typography variant="body1" color="primary">{message}</Typography>
                {children}
                <div className={classes.actions}>
                    {!!cancel &&
                        <DarkButton
                            small
                            fullWidth={false}
                            onClick={onCancel}
                        >{cancel}</DarkButton>
                    }
                    {!!confirm &&
                        <DarkButton
                            small
                            fullWidth={false}
                            onClick={onConfirm}
                        >{confirm}</DarkButton>
                    }
                </div>
            </Panel>
        );
    }
}

export default withStyles(styles)(Confirm);
