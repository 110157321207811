import {createReducer, createActions} from 'reduxsauce';

const {Types, Creators} = createActions({
    setMap: ['map'],
    setMapUserBehaviour: ['behaviour'],
    setMapMoved: ['value'],
    setMapDragged: ['value'],
    setMapZoomed: ['value'],
    setMapClick: ['data'],
    showMap: null,
    hideMap: null,

    searchPlacesRequest: ['query'],
    searchPlacesSuccess: ['data'],
    searchPlacesFailure: ['data'],

    geocodingLogout: null,
});

export const GeocodingTypes = Types;
export default Creators;

export const GEOCODING_PENDING = {
    searchPlaces: 'searchPlaces',
};

export const TYPE = {
    POI: 'poi',
    PLACE: 'place',
    PLACE_OUR: 'place_our',
    REGION: 'region',
    COUNTRY: 'country',
};

export const INITIAL_SEARCH_PLACES = {
    error: false,
    items: [],
};

export const INITIAL_STATE = {
    map: null,
    mapUserBehaviour: true,
    mapMoved: 0,
    mapDragged: 0,
    mapZoomed: 0,
    mapClick: {
        featureId: 0,
        featureName: '',
        lngLat: null, // {lng, lat}
    },
    searchPlaces: INITIAL_SEARCH_PLACES,
    showMap: true,
};

const setMap = (state, action) => {
    const {map} = action;
    return {
        ...state,
        map,
    };
};

const setMapUserBehaviour = (state, action) => {
    const {behaviour} = action;
    return {
        ...state,
        mapUserBehaviour: behaviour,
    };
};

const setMapMoved = (state, action) => {
    const {mapMoved} = state;
    const {value} = action;
    return ({
        ...state,
        mapMoved: (value !== undefined) ? value : mapMoved + 1,
    });
};

const setMapDragged = (state, action) => {
    const {mapDragged} = state;
    const {value} = action;
    return ({
        ...state,
        mapDragged: (value !== undefined) ? value : mapDragged + 1,
    });
};

const setMapZoomed = (state, action) => {
    const {mapZoomed} = state;
    const {value} = action;
    return ({
        ...state,
        mapZoomed: (value !== undefined) ? value : mapZoomed + 1,
    });
};

const setMapClick = (state, action) => {
    const {data: {featureId, featureName = '', lngLat}} = action;
    return ({
        ...state,
        mapClick: {
            featureId,
            featureName,
            lngLat,
        },
    });
};

const showMap = (state) => {
    return {
        ...state,
        showMap: true,
    };
};

const hideMap = (state) => {
    return {
        ...state,
        showMap: false,
    };
};

const reduceType = (list) => {
    return list.reduce((acc, t) => {
        if (!acc) return t;
        else if (t === TYPE.PLACE && acc === TYPE.POI) return acc;
        else if (t === TYPE.REGION && (acc === TYPE.POI || acc === TYPE.PLACE)) return acc;
        else if (t === TYPE.COUNTRY && (acc === TYPE.POI || acc === TYPE.PLACE || acc === TYPE.REGION)) return acc;
        else return t;
    })
};

export const adaptSearchPlacesResponse = (data) => {
    if (data && 'features' in data && data.features.length) {
        return data.features.map((el, idx) => ({
            id: el.id,
            name: el.place_name,
            longitude: el.center[0],
            latitude: el.center[1],
            type: reduceType(el.place_type),
        }));
    }
    return [];
};
export const searchPlacesRequest = (state, action) => {
    return {
        ...state,
        searchPlaces: {
            error: false,
            items: [],
        }
    };
};
export const searchPlacesSuccess = (state, action) => {
    const {data} = action;
    const items = adaptSearchPlacesResponse(data);
    return {
        ...state,
        searchPlaces: {
            error: false,
            items,
        }
    };
};
export const searchPlacesFailure = (state, action) => {
    return {
        ...state,
        searchPlaces: {error: true}
    };
};

export const logout = (state, action) => {
    return INITIAL_STATE;
};

export const reducer = createReducer(INITIAL_STATE, {
    [Types.SET_MAP]: setMap,
    [Types.SET_MAP_USER_BEHAVIOUR]: setMapUserBehaviour,
    [Types.SET_MAP_MOVED]: setMapMoved,
    [Types.SET_MAP_DRAGGED]: setMapDragged,
    [Types.SET_MAP_ZOOMED]: setMapZoomed,
    [Types.SET_MAP_CLICK]: setMapClick,
    [Types.SHOW_MAP]: showMap,
    [Types.HIDE_MAP]: hideMap,

    [Types.SEARCH_PLACES_REQUEST]: searchPlacesRequest,
    [Types.SEARCH_PLACES_SUCCESS]: searchPlacesSuccess,
    [Types.SEARCH_PLACES_FAILURE]: searchPlacesFailure,

    [Types.GEOCODING_LOGOUT]: logout,
});
