
const styles = (theme) => ({
    panel: {
        position: 'relative',
        zIndex: 100,
        minWidth: 298,
        minHeight: 204,
        marginTop: -10,
        marginLeft: 10,
        marginRight: 10,
    },
    container: {
        padding: 0,
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '13px 22px',
    },
    formPanel: {
        minWidth: 384,
        width: 384,
        minHeight: 504,
        position: 'relative',
        margin: '0 8px 8px',
        padding: 20,
        boxSizing: 'border-box',
    },
    row: {
        marginTop: 30,
    },
    error: {
        marginTop: 20,
        textAlign: 'center',
        color: theme.palette.error.main,
    },
    success: {
        marginTop: 20,
        textAlign: 'center',
    },
    fields: {
        marginTop: 20,
        marginRight: '-15px',
        maxHeight: 460,
        overflowY: 'auto',
        paddingRight: 5,
        '& h2': {
            fontSize: '1.42858rem',
            color: '#233544',
            marginTop: 24,
            marginBottom: -26,
        },
        '& i': {
            color: '#7D8C97',
            fontSize: '0.7857rem',
            marginTop: 5,
        },
    },
    '@media screen and (max-width: 767px)': {
        panel: {
            minWidth: 'auto',
            width: 'calc(100% - 60px)',
        },

        formPanel: {
            minWidth: 'auto',
            width: 'auto',
            marginRight: 8,
        },
    }
});

export default styles;
