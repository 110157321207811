import React, { useEffect } from 'react';

import { useOrttoActivityCreateReport } from '../../../../context/ortto/hooks';
import { CSSTransition } from 'react-transition-group';
import cn from '../../../../lib/classNames';

import styles from './TravelerInfo.module.scss';

export default function TravelerInfo(props) {
	const {
		reportId,
		reportTitle,
		home,
		destination,
		date,
		personName,
		personCompany,
		personAgeBucket,
		personGender,
		isPreloader,
	} = props;

	const userName = personCompany;

	const track = useOrttoActivityCreateReport();
	useEffect(() => {
		track(destination);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [destination]);

	return (
		<section className={styles['traveller-info']}>
			<div className={styles['row-1']}>
				<div className={styles['col-1']}>
					<CSSTransition
						key={reportTitle}
						appear
						in={!!reportTitle}
						timeout={100}
						classNames={{
							appear: styles['head-appear'],
							appearActive: styles['head-active-appear'],
							appearDone: styles['head-done-appear'],
							enter: styles['head-enter'],
							enterActive: styles['head-active-enter'],
							enterDone: styles['head-done-enter'],
							exit: styles['head-exit'],
							exitActive: styles['head-active-exit'],
							exitDone: styles['head-done-exit'],
						}}
					>
						<h3 className={cn(styles.title, 'title')}>{reportTitle}</h3>
					</CSSTransition>
					<div className={styles.locations}>
						{reportId === 'riskScoring' && (
							<div className={styles.location}>
								<span
									className={cn(
										styles['location-icon'],
										'material-icons-round'
									)}
								>
									home
								</span>
								<h4 className={cn(styles['location-name'], 'overflow-row-1')}>
									{home}
								</h4>
							</div>
						)}
						<div className='spacer' />
						<div className={styles.location}>
							<span className={cn(styles['location-icon'], 'material-icons')}>
								location_on
							</span>
							<h4
								className={cn(
									styles['location-name'],
									{
										[styles['risk-brief-location-name']]:
											reportId === 'riskBrief',
									},
									'overflow-row-1'
								)}
							>
								{destination}
							</h4>
						</div>
					</div>
					{/* TODO: need to add processing status? */}
					{isPreloader ? (
						<p className={cn(styles.description, styles.processing)}>
							Processing...
						</p>
					) : (
						<p className={styles.description}>
							{`Produced by Safe-xplore exclusively for ${userName} on ${date}`}
						</p>
					)}
				</div>
				<div className={styles['col-2']}>
					<div>
						<div className={styles['traveller-person-header']}>
							<span
								className={cn(
									styles['traveller-person-icon'],
									'material-icons'
								)}
							>
								person_outline
							</span>
							<h4 className={styles['traveller-person-title']}>
								Traveler information
							</h4>
						</div>
						<div className={styles['traveller-person-body']}>
							{personName && (
								<div className={styles['traveller-person-data-wrap']}>
									<p className={styles['traveller-label']}>Name</p>
									<p className={styles['traveller-data']}>{personName}</p>
								</div>
							)}
							<div className={styles['traveller-person-data-wrap']}>
								<p className={styles['traveller-label']}>Age Group</p>
								<p className={styles['traveller-data']}>
									{personAgeBucket && personAgeBucket + ' years old'}
								</p>
							</div>
							<div className={styles['traveller-person-data-wrap']}>
								<p className={styles['traveller-label']}>Male/Female</p>
								<p className={styles['traveller-data']}>{personGender}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}
