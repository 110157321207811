import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Redirect } from 'react-router-dom';
import copy from 'copy-to-clipboard';

import { Dialog } from '../../../components';
import {
	pendingReportRiskTravel,
	reportRiskTravelSelector,
	reportLastReportDataSelector,
	personGetById,
} from '../../../store/selectors';
import ReportActions from '../../../store/ReportRedux';
import GeocodingActions from '../../../store/GeocodingRedux';
import TravelReport from '../components/TravelReport';
import { createTravelReport } from '../components/ReportFactory';
import { api } from '../../../sagas';
// TODO: need for testing
// import { mockReportData } from './mockReportData';

import styles from './Report.jss';

class Report extends PureComponent {
	state = {
		leftMenu: 'report',
		share: {
			pending: false,
			showDialog: false,
			uuid: null,
			password: null,
		},
	};

	static propTypes = {
		reportRiskTravel: PropTypes.object,
		reportRiskTravelRequest: PropTypes.func,
		reportData: PropTypes.object,
		reportRiskTravelPending: PropTypes.number,
	};

	map = null;

	handleClickPdf = (props) => {
		const { reportRiskTravel } = this.props;
		createTravelReport({
			...props,
			reportRiskTravel,
		});
	};

	handleClickShare = () => {
		console.log('reportRiskTravel', this.props.reportRiskTravel);

		const {
			reportRiskTravel: {
				data: { id },
			},
		} = this.props;
		if (id) {
			this.setState({
				share: {
					...this.state.share,
					pending: true,
				},
			});
			api.reportShare(id).then((response) => {
				if (response.ok) {
					const {
						data: { uuid, password },
					} = response;
					this.setState({
						share: {
							showDialog: true,
							pending: false,
							uuid,
							password,
						},
					});
				} else {
					console.log('=== Report::handleClickShare', response);
				}
			});
		}
	};

	handleClickDashboard = () => {
		const { onClickDashboard } = this.props;
		if (onClickDashboard) onClickDashboard();
	};

	handleOnCloseShareDialog = () => {
		this.setState({
			share: {
				...this.state.share,
				pending: false,
				showDialog: false,
			},
		});
	};

	handleCopyUrl = (txt) => {
		copy(txt);
	};

	handleCopyPassword = (txt) => {
		copy(txt);
	};

	componentDidMount() {
		// const { hideMap } = this.props;
		// hideMap();
		this.loadData();
	}

	componentWillUnmount() {
		// const { showMap } = this.props;
		// showMap();
	}

	loadData() {
		const { reportData, reportRiskTravelRequest } = this.props;
		if (reportData.person.id) {
			reportRiskTravelRequest({
				userId: reportData.person.id,
				destination: {
					locationId: reportData.destination.locationId,
					locationName: reportData.destination.locationName,
					type: 'se',
					boundaryId: reportData.destination.boundaryId,
				},
			});
		}
	}

	renderLoading() {
		const { classes, reportRiskTravelPending } = this.props;
		const {
			share: { pending },
		} = this.state;
		if (reportRiskTravelPending || pending) {
			return (
				<div className={classes.overlay}>
					<LinearProgress color='primary' />
				</div>
			);
		}
		return null;
	}

	renderReport() {
		const { classes, reportRiskTravel } = this.props;
		const {
			share: { showDialog, uuid, password },
		} = this.state;
		const {
			location: { protocol, host },
		} = document;

		let shareUrl = '';
		if (showDialog && uuid) {
			shareUrl = `/report/${uuid}`;
			shareUrl = `${protocol}//${host}${shareUrl}`;
		}

		return (
			<div className={classes.content}>
				{this.renderLoading()}
				<TravelReport
					// TODO: need for testing
					data={reportRiskTravel.data}
					// data={mockReportData}
					onClickPdf={this.handleClickPdf}
					onClickShare={this.handleClickShare}
					onClickDashboard={this.handleClickDashboard}
				/>
				<Dialog
					title='Report has been shared'
					open={showDialog}
					onClose={this.handleOnCloseShareDialog}
				>
					{' '}
					<div className={classes.shareLinkPsw}>
						<p>
							<b>Public Link:</b> <span className='share-link'>{shareUrl}</span>
							<br />(
							<span
								className='btn-copy'
								onClick={() => this.handleCopyUrl(shareUrl)}
								target='_blank'
								rel='noopener noreferrer'
							>
								Copy Link
							</span>
							)
						</p>
						<p>
							<b>Password:</b> <span className='share-link'>{password}</span>
							<br />(
							<span
								className='btn-copy'
								onClick={() => this.handleCopyPassword(password)}
								target='_blank'
								rel='noopener noreferrer'
							>
								Copy Password
							</span>
							)
						</p>
						<p>
							Never share reports publicly. You can invite others to access this
							report, preferably by sending the unique URL and password
							separately.
						</p>
					</div>
				</Dialog>
			</div>
		);
	}

	renderNoData() {
		return <Redirect to='/' />;
	}

	render() {
		const { classes, reportData } = this.props;
		return (
			<div className={classes.container}>
				{reportData.person.id ? this.renderReport() : this.renderNoData()}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	reportRiskTravel: reportRiskTravelSelector(state),
	reportData: reportLastReportDataSelector(state),
	reportRiskTravelPending: pendingReportRiskTravel(state),
	getPersonById: (id) => personGetById(state, { id }),
});

const mapDispatchToProps = (dispatch) => ({
	reportRiskTravelRequest: (data) =>
		dispatch(ReportActions.reportRiskTravelRequest(data)),
	showMap: () => dispatch(GeocodingActions.showMap()),
	hideMap: () => dispatch(GeocodingActions.hideMap()),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(Report));
