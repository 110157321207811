
const styles = (theme) => ({
    container: {
        width: '100%',
        height: 5,
        borderRadius: 2.5,
        backgroundColor: theme.palette.primary.light,
        position: 'relative',
    },
    minMaxMargin: {
        margin: '7px 0',
    },
    valueLine: {
        display: 'inline-block',
        height: 5,
        borderRadius: 2.5,
        position: 'absolute',
        left: 0,
        top: 0,
    },
    minMax: {
        height: 20,
        position: 'absolute',
        top: -7,
        borderRadius: 10,
    }
});

export default styles;
