import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';
import {ROUTE} from '../App';

class Authenicated extends PureComponent {
    componentDidMount() {
        this.integrateLiveChat();
    }

    componentDidUpdate() {
        const {user} = this.props;
        if (!user.authed) {
            document.getElementById('chat-widget-container').remove();
            delete window.__lc_inited
            delete window.__lc;
        } else {
            this.integrateLiveChat();
        }
    }

    integrateLiveChat() {
        const {user} = this.props;
        if (user.authed) {
            window.lc = window.lc || {};
            window.__lc = window.__lc || {};
            window.__lc.license = 11895120;
            var lc = document.createElement('script');
            lc.type = 'text/javascript';
            lc.async = true;
            lc.src = ('https:' == document.location.protocol ? 'https://' : 'http://') + 'cdn.livechatinc.com/tracking.js';
            var s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(lc, s);
        }
    }

    render() {
        const {children, user, className = ''} = this.props;
        return (
            user.authed ? 
            <div className={className}>
                {children}
            </div>
            : <Redirect to={ROUTE.login} />
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
});

export default connect(mapStateToProps)(Authenicated);
