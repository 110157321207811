import React from 'react';
import ReactTooltip from 'react-tooltip';

import dataCity from '../../../../images/data_city.svg';
import dataCountry from '../../../../images/data_country.svg';
import dataRegion from '../../../../images/data_region.svg';

const DataLayer = ({ numData }) => {

	const toolTipsStaticText = 'Data Accuracy Level: <br>';

	const renderDataImg = () => {
		if (numData === 0) {
			return <img src={dataCountry} alt="" data-tip={`${toolTipsStaticText} Country`} />
		} else if (numData === 1) {
			return <img src={dataRegion} alt="" data-tip={`${toolTipsStaticText} State/Region`} />
		} else if (numData >= 2) {
			return <img src={dataCity} alt="" data-tip={`${toolTipsStaticText} City/County`} />
		} else {
			return null;
		}
	};

	return (
		<div className="data-layer">
			<ReactTooltip
				className='data-layer-tooltip'
				place='bottom'
				multiline={true}
				backgroundColor='#233544'
			/>
			{renderDataImg()}
		</div>
	)
};

export default DataLayer;