import {createSelector} from 'reselect';
import {userPropertiesSelector} from './index';

export const personSelector = (state) => state.person;
export const personListSelector = (state) => personSelector(state).list;
export const personListItemsSelector = (state) => personListSelector(state).items;

export const personAdaptListItems = createSelector([personListItemsSelector], (items) => {
    return items.map((el) => ({
        label: el.name,
        value: el.id.toString(),
    }));
});

export const personAdaptListItemsWithMe = createSelector([personListItemsSelector, userPropertiesSelector], (items, me) => {
    const persons = items.map((el) => ({
        label: el.name,
        locationName: el.locationName,
        value: el.id.toString(),
    }));
    return [
        {
            label: me.name,
            locationName: me.locationName,
            value: me.id.toString()
        },
        ...persons,
    ];
});

export const personGetById = createSelector([personListItemsSelector, userPropertiesSelector, (state, props) => (props)], (list, me, props) => {
    let {id} = props;
    id = parseInt(id, 10);
    const found = list.filter((el) => (el.id === id));
    if (found && found.length) return found[0];
    else if (me.id === id) return me;
    return null;
});
