import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';

import {
    Background, Dialog,
} from '../../../components';

import styles from './Privacy.jss';

class Privacy extends PureComponent {
    static propTypes = {
        classes: PropTypes.object.isRequired,
    };

    handleOnClose = () => {
        this.goBack();
    }

    goBack() {
        this.props.history.goBack();
    }

    render() {
        const {classes} = this.props;
        return (
            <Background>
                <Dialog open={true} onClose={this.handleOnClose}>
                    <Typography variant="h4">Privacy at Safe-esteem</Typography>
                    <Typography variant="body1">SAFE-ESTEEM INC. operates a service that allows you to measure and track your personal risks across three risk domains: crime, accident and health. The technology also communicates your overall level of safety (a user’s Safe-e Score) through a proprietary methodology.  Additional functionality includes allowing your risk scores and Safe-e Score to be shared with family and friends who are connected on our app.</Typography>
                    <Typography variant="body1">To deliver our services Safe-esteem will collect and use your location data and additional personal data. We strive to both anonymize and aggregate all data to ensure privacy.</Typography>
                    <Typography variant="body1">Safe-esteem may share anonymous data with 3rd parties to deliver the service but also for marketing activities. We may share your personal information with third parties outside the Company and its controlled subsidiaries and affiliates in some circumstances, including sharing your personal information, including your location data, with third parties for marketing purposes. We may share de-identified location data with third parties that analyze location and movement trends.</Typography>
                    <Typography variant="body1">If you have any questions, you may contact us at any time at privacy@safe-esteem.com.</Typography>
                    <Typography variant="h4">Safe-esteem Privacy Policy</Typography>
                    <Typography variant="body1">This Privacy Policy was last modified on May 30, 2019.</Typography>
                    <Typography variant="body1">Safe-esteem (“Safe-esteem,” “we,” or “us”) are committed to protecting your privacy. This Privacy Policy explains our practices regarding the collection, use, disclosure, and protection of information that is collected through our mobile app and our website, as well as your choices regarding the collection and use of information. Capitalized terms that are not defined in this Privacy Policy have the meaning given them in our Terms of Use.</Typography>
                    <Typography variant="body1"><strong>1. How We Collect and Use Information</strong></Typography>
                    <Typography variant="body1">We collect the following types of information about you:</Typography>
                    <Typography variant="body1"><strong>Registration and Profile Information.</strong> By creating an account, you are authorizing us to collect your personal information such as your username, first and last name, gender, date of birth, email address, your location, mobile phone number and a photo, if you chose to have a photo associated with your account. In an effort to further customize Safe-esteem for you, we may also request additional personal information from you. If you want to add a family member’s name to your account, we collect the family member’s name, email address, and mobile phone number. We may also collect billing and financial information when you sign up for Premium service.</Typography>
                    <Typography variant="body1"><strong>Data collected through use of the Service.</strong> After you set up your account, we may ask for other profile and demographic information, such as personal emergency instructions and information, and nickname.</Typography>
                    <Typography variant="body1"><strong>Geolocation.</strong> We collect your unique user identifier and your location through GPS, WiFi, or wireless network triangulation in order to obtain your location for the purposes of providing our Service. We collect the location of your designated “Places” to provide alerts when family members arrive and leave. We maintain location information only so long as we determine is reasonable to provide the Service. We may maintain location data for a longer period of time in order to analyze aggregate trends and metrics. We may also share location information with third parties for research and analytic purposes. Please see “Your Choices About Your Information” below to learn more.</Typography>
                    <Typography variant="body1"><strong>Event &amp; Activities Data.</strong> We collect, and may use a third party to collect sensory and motion data from your smartphone or mobile device, including information from the gyroscope, accelerometer, compass and Bluetooth, in order to calculate your scores and provide relevant notifications We maintain sensory and motion data as long as is reasonable to provide our Service. If you want to opt-out of the collection of your sensory and motion, please adjust your settings in your mobile device to limit the app’s access to your sensory and motion data.</Typography>
                    <Typography variant="body1"><strong>Messages and User Content. </strong>to operate, maintain, and provide to you the features and functionality of our Service and to communicate directly with you, such as to send you alerts on the movements of your Circle members, crime and safety alerts in your neighborhood, etc. You can control your receipt of alerts by visiting “Settings” and “Notifications” within the mobile app. We may also send you Service-related emails (e.g., account verification, parental consent notification, order confirmations, change or updates to features of the Service, technical and security notices). You may not opt-out of Service-related e-mails.</Typography>
                    <Typography variant="body1"><strong>Information we receive about you from others:</strong></Typography>
                    <Typography variant="body1"><strong>Social Media.</strong> When you interact with our site through various social media, such as when you Like us on Facebook or post a comment to our Facebook page, we may receive information from the social network such as your profile information, profile picture, gender, user name, user ID associated with your social media account, age range, language, country, friends list, and any other information you permit the social network to share with third parties. The data we receive is dependent upon your privacy settings with the social network. You should always review, and if necessary, adjust your privacy settings on third-party websites and services before linking or connecting them to Safe-esteem’s website or Service.</Typography>
                    <Typography variant="body1"><strong>Information we collect automatically through the use of technology. </strong>When you visit our website or use our mobile app, we and our business partners may collect certain information about your computer or device through existing location and marketing technology, for example through the use of cookies. By using our app you are agreeing to share the data such technologies may provide us about you. Some or all of this data may be combined with other personally identifying information we have acquired. When you access our Service by or through a mobile device, we may receive or collect and store a unique identification numbers associated with your device or our mobile application (including, for example, a UDID, Unique ID for Advertisers (“IDFA”), Google Ad ID, or Windows Advertising ID or other identifier), mobile carrier, device type, model and manufacturer, mobile device operating system brand and model, phone number, and, depending on your mobile device settings, your geographical location data, including GPS coordinates (e.g. latitude and/or longitude), WiFi location or similar information regarding the location of your mobile device. We use this information to:</Typography>
                    <ul className={classes.ul}>
                        <li><Typography variant="body1">Remember information so that you will not have to re-enter it during your visit or the next time you visit the site or mobile app</Typography></li>
                        <li><Typography variant="body1">Provide custom, personalized content and information, including advertising</Typography></li>
                        <li><Typography variant="body1">Track your location and movements</Typography>
                        </li><li><Typography variant="body1">Monitor the effectiveness of our Service and our marketing campaigns</Typography></li>
                        <li><Typography variant="body1">Monitor aggregate metrics such as total number of visitors, users, traffic, and demographic patterns</Typography></li>
                        <li><Typography variant="body1">Diagnose or fix technology problems reported by our Users or engineers that are associated with certain IP addresses or User IDs, and Automatically update our mobile application on your system and related devices.</Typography></li>
                    </ul>
                    <Typography variant="body1">We may also collect analytics data, or use third-party analytics tools, to help us measure traffic and usage trends for the Service. These tools collect information sent by your browser or mobile device, including the pages you visit, your use of third party applications, and other information that assists us in analyzing and improving the Service.</Typography>
                    <Typography variant="body1"><br /></Typography>
                    <Typography variant="body1"><strong>2. Sharing of Your Information</strong></Typography>
                    <Typography variant="body1"><strong>Personal Information: </strong>We may share your personal information with third parties outside the Company and its controlled subsidiaries and affiliates in some circumstances, including:</Typography>
                    <ul className={classes.ul}>
                        <li><Typography variant="body1">We share your personal information, including name, email, location and motion event data, with others within the Safe-esteem family and friends you have chosen to monitor.</Typography></li>
                        <li><Typography variant="body1">Any information or content that you voluntarily post to the website or to our Facebook page will automatically be available to the public. We may publicly post your reviews and comments online on our website or mobile app, or link your reviews to third party websites.</Typography></li>
                        <li><Typography variant="body1">We may aggregate or otherwise strip data of all personally identifying characteristics of our data and may share that anonymized and/or aggregated data with third parties. For example, we may share de-identified location data with third parties that analyze location and movement trends.</Typography></li>
                        <li><Typography variant="body1">We may at any time buy or sell/divest the company, or any combination of its products, services, assets and/or businesses. Personal information such as customer names and email addresses, and other user information related to the Service will likely be one of the items transferred in these types of transactions. We may also share, transfer or assign such information in the course of corporate divestitures, mergers, acquisitions, bankruptcies, dissolutions or similar transactions or proceedings.</Typography></li>
                        <li><Typography variant="body1">Except as described above, we will not disclose personal information to third parties (including law enforcement, other government entity, or civil litigant; excluding our subcontractors) unless required to do so by law or subpoena or if in our sole discretion, we determine it is necessary to (a) conform to the law, comply with legal process, or investigate, prevent, or take action regarding suspected or actual illegal activities; (b) to enforce our Terms of Use, take precautions against liability, to investigate and defend ourselves against any claims or allegations, or to protect the security or integrity of our site; and/or (c) to exercise or protect the rights, property, or personal safety of Safe-esteem, our Users or others.</Typography></li>
                    </ul>
                    <br />
                    <Typography variant="body1"><strong>3. How We Protect Your Information</strong></Typography>
                    <Typography variant="body1">Safe-esteem cares about the security of your information and uses commercially reasonable physical, administrative, and technological safeguards to preserve the integrity and security of all information we collect and that we share with our service providers. However, no security system is impenetrable and we cannot guarantee the security of our systems 100%. In the event that we learn that &nbsp;information under our control is compromised as a result of a breach of security, we will notify those individuals whose information may have been compromised and take other steps, in accordance with any applicable laws and regulations.</Typography>
                    <Typography variant="body1"><br /></Typography>
                    <Typography variant="body1"><strong>4. Your Choices About Your Information</strong></Typography>
                    <Typography variant="body1">You may, of course, decline to submit any personal information to the Service, in which case Safe-esteem may not be able to provide its Services to you.</Typography>
                    <Typography variant="body1"><br /><strong>Modifying Your Account Information and Settings:</strong> You may modify your account information, update or amend your personal information, or change your password at any time by logging in to your account. You can also stop receiving promotional email communications from us by clicking on the “unsubscribe link” provided in such communications. We make every effort to promptly process all unsubscribe requests. As noted above, you may not opt out of Service-related communications (e.g., account verification, parental consent notification, order confirmations, change or updates to features of the Service, technical and security notices). If you have any questions about reviewing or modifying your account information, you can contact us directly at support@safe-esteem.com.</Typography>
                    <Typography variant="body1"><strong>Delete Your Account and Personal Information: </strong>We will retain your information for as long as your account is active or as needed to provide you services. If you wish to cancel your account, please contact us at support@safe-esteem.com.</Typography>
                    <br />
                    <Typography variant="body1"><strong>5. Children’s Privacy</strong></Typography>
                    <Typography variant="body1">Our Service allows parents to keep track of their children’s safety on mobile devices that the Parent has added to their Circle. The Service is intended to be used by children under 13 only with significant parental involvement and approval. The child’s personal information, including name, user name, email, and location, will be shared with other members of the child’s Circle. Safe-esteem may collect, use, disclose, and store the child’s personal information as described in this Privacy Policy. However, we do not use or share personal information collected from the accounts of children under 13 for marketing or advertising purposes, or permit our third party partners to collect and use such information for advertising purposes. The Parent may contact us to review and/or delete any personal information we have collected from the child, or to withdraw permission for Safe-esteem to collect any additional information from the child. Please contact us at privacy@safe-esteem.com if you have questions about your child’s account. If you believe that we might have inadvertently collected information from a child under 13 without parental consent, please contact us at privacy@safe-esteem.com.<br /><br /></Typography>
                    <Typography variant="body1"><strong>6. Links to Other Web Sites and Services</strong></Typography>
                    <Typography variant="body1">We are not responsible for the practices employed by websites or services linked to or from the Service, nor the information or content contained therein. Please remember that when you use a link to go from the Service to another website, our Privacy Policy does not apply to third-party websites or services. Your browsing and interaction on any third-party website or service, including those that have a link on our website, are subject to that third party’s own rules and policies. Please read over those rules and policies before proceeding.<br /><br /></Typography>
                    <Typography variant="body1"><strong>7. Changes to Our Privacy Policy</strong></Typography>
                    <Typography variant="body1">Safe-esteem may, in its sole discretion, modify or update this Privacy Policy from time to time, and so you should review this page periodically. When we change the policy, we will update the ‘last modified’ date at the top of this page. If there are material changes to this Privacy Policy or in how Safe-esteem will use your personal information, we will notify you either by prominently posting a notice of such changes prior to implementing the change or by directly sending you a notification.<br /><br /></Typography>
                    <Typography variant="body1"><strong>8. Your California Privacy Rights</strong></Typography>
                    <Typography variant="body1">Safe-esteem collects various types of personal information about you both online and offline, as described in this privacy policy. Under California law, Safe-esteem customers who are residents of California may request certain information about our disclosure of personal information during the prior calendar year to third parties for their direct marketing purposes. To make such a request, please write to us at the following address:<br /><br /></Typography>
                    <Typography variant="body1">Safe-esteem<br />429 Lenox Avenue<br />Miami Beach, FL 33139</Typography>
                    <Typography variant="body1">Alternatively, you may send us an email at privacy@safe-esteem.com.</Typography>
                    <Typography variant="body1"><br /><strong>9. How to Contact Us</strong></Typography>
                    <Typography variant="body1">If you have any questions about this Privacy Policy or the Service, please contact us at privacy@safe-esteem.com</Typography>
                    <Typography variant="body1">Safe-esteem<br />429 Lenox Avenue<br />Miami Beach, FL 33139</Typography>
                    <Typography variant="body1">Last Updated: May 30, 2019</Typography>
                    <Typography variant="body1"><br /><br /></Typography>
                </Dialog>
            </Background>
        );
    }
}

export default withStyles(styles)(Privacy);