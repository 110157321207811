import {LngLatBounds} from 'mapbox-gl';
import {log} from '../../lib';
import {TYPE} from '../GeocodingRedux';

export const boundarySelector = state => (state.boundary);
export const boundaryListSelector = state => (boundarySelector(state).list);
export const boundaryListDataSelector = state => (boundaryListSelector(state).data);

function rs(score) {
    if (score) return Math.round(score * 10) / 10;
    return 0;
}

/**
 * Returns array in structure {name, value}
 * Value is rounded
 * @param {object} json 
 */
export const adaptScoringPercentages = (json) => {
    return (Object.keys(json).map((el) => ({
        name: el,
        value: rs(json[el]),
    })));
};

export const adaptScoring = (data) => {
    try {
        const {criminal, accident, health, maxCrimeLevel} = data;
        if (criminal && accident && health) {
            return ({
                criminal: {
                    percentages: adaptScoringPercentages(criminal.percentages),
                    score: rs(criminal.score),
                    minScore: rs(criminal.minScore),
                    maxScore: rs(criminal.maxScore),
                },
                accident: {
                    percentages: adaptScoringPercentages(accident.percentages),
                    score: rs(accident.score),
                    minScore: rs(accident.minScore),
                    maxScore: rs(accident.maxScore),
                },
                health: {
                    percentages: adaptScoringPercentages(health.percentages),
                    score: rs(health.score),
                    minScore: rs(health.minScore),
                    maxScore: rs(health.maxScore),
                },
                maxCrimeLevel,
            });
        }
        return null;
    } catch (e) {
        log('Error in selectors::boundary::adaptScoring.', e.message);
    }
};

/**
 * Calculates bounds zone of the polygones
 * @param {[{id, geometry: {type, coordinates: []}},...]} features 
 */
export const getFeaturesBounds = (features) => {
    if (features.length) {
        const first = features[0].geometry.coordinates[0][0];
        let bounds = new LngLatBounds(first[0], first[0]);
        features.forEach((feature) => {
            const coordinates = feature.geometry.coordinates[0][0];
            bounds = coordinates.reduce(function(bounds, coord) {
                return bounds.extend(coord);
            }, bounds);
        });
        return bounds;
    }
    return null;
};

export const getFeatureById = (features, id) => {
    if (features && features.length && id) {
        const filtered = features.filter((el) => (el.id === parseInt(id, 10)));
        if (filtered.length) return filtered[0];
        return null;
    }
    return null;
};

export const getFeatureNameById = (features, id) => {
    const feature = getFeatureById(features, id);
    if (feature) return feature.name;
    return null;
};

export const adaptBoundarySearchForAutoselect = (data) => {
    if (data && data.features) {
        return data.features.map((el) => ({
            label: el.name,
            value: {
                id: el.id,
                name: el.name,
                longitude: el.geometry.coordinates[0],
                latitude: el.geometry.coordinates[1],
                type: TYPE.PLACE,
            },
        }));
    }
    return [];
};
