import React, {PureComponent} from 'react';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {Typography} from '@material-ui/core';
import {connect} from 'react-redux';
import Icon from 'react-svg-icon';

import {Panel} from '../../../components';
import {userPropertiesSelector, userBillingSelector} from '../../../store/selectors';

import CardForm from './CardForm';

import styles from './BillingDetails.jss';

class BillingDetails extends PureComponent {
    static propTypes = {
        classes: PropTypes.object,
        user: PropTypes.object.isRequired,
        billing: PropTypes.object.isRequired,
        onClickEditAccount: PropTypes.func,
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            subscription: null,
        };    
    }

    getContent() {
        const {classes, onClickEditAccount} = this.props;
        return (
            <div className={classes.container}>
                <div className={classes.header}>
                    <Typography variant="h4" color="textSecondary">Billing Details</Typography>
                    <div onClick={onClickEditAccount}>
                        <Icon name="svg-i-edit" className="edit-action" />
                    </div>
                </div>
                <div className={classes.content}>
                    {this.renderBillingInfo()}
                    <div className="card">
                        <Typography variant="body1">A credit card is required to create account. You will be able to choose your preferred payment method later. (Pro plan is credit card only)</Typography>
                        <CardForm onLoading={this.hanldeCardFormLoading} />
                    </div>
                </div>
            </div>
        );
    }

    hanldeCardFormLoading = (loading) => {
        this.setState({loading});
    };

    renderBillingInfo() {
        const {billing} = this.props;
        const na = 'N/A';
        return (
            <div className="billing-info">
                {billing.businessName ? billing.businessName : `${billing.firstName} ${billing.lastName}`}<br/>
                {billing.line1 || na}<br/>
                {billing.city || na}, {billing.postalCode || na}, {billing.country || na}
            </div>
        );
    }

    render() {
        const {classes} = this.props;
        const {loading} = this.state;
        return (
            <Panel className={classes.panel} loading={loading}>
                {this.getContent()}
            </Panel>
        );
    }
}

const mapStateToProps = (state) => ({
    user: userPropertiesSelector(state),
    billing: userBillingSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(BillingDetails));
