import React from 'react';
import cn from '../../../lib/classNames';

import styles from './TabButton.module.scss';

export default function TabButton(props) {
	const {
		className,
		iconBefore,
		iconAfter,
		children,
		onClick,
		disabled = false,
		active,
	} = props;

	const renderIconBefore = (
		<span className={cn(styles['btn-icon'], 'btn-icon', 'btn-icon-before')}>
			{iconBefore}
		</span>
	);

	const renderIconAfter = (
		<span className={cn(styles['btn-icon'], 'btn-icon', 'btn-icon-after')}>
			{iconAfter}
		</span>
	);

	return (
		<button
			className={cn(
				styles.button,
				{
					[styles.disabled]: disabled,
					[styles.active]: active,
				},
				className
			)}
			onClick={onClick}
			disabled={disabled}
		>
			{iconBefore && renderIconBefore}
			<span className={cn(styles['btn-text'], 'btn-text')}>{children}</span>
			{iconAfter && renderIconAfter}
		</button>
	);
}
