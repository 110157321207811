
const styles = (theme) => ({
    content: {
        padding: 0,
    },
    panel: {
        zIndex: 100,
        minWidth: 400,
        width: 400,
        minHeight: 204,
        marginTop: -10,
        marginLeft: 10,
        position: 'relative',
    },
    header: {
        padding: '13px 22px',
    },
    parts: {
        margin: '0 8px 8px',
        backgroundColor: 'white',
        borderRadius: 12,
        padding: '30px 20px 20px 20px',
        minHeight: 300,
        '& .header-line': {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            '& h5': {
                fontSize: '1.428571428571429rem',
                fontWeight: 'normal',
                lineHeight: '23px',
                color: theme.palette.primary.main,
                padding: 0,
                margin: 0,
            },
            '& .edit-action': {
                width: 30,
                height: 30,
                cursor: 'pointer',
                stroke: theme.palette.primary.light,
            },
        },
        '& p': {
            marginTop: 8,
        },
        '& .billing-info': {
            color: theme.palette.primary.light,
            lineHeight: '21px',
            marginTop: 5,
            marginBottom: 30,
        },
        '& .card-form': {
            marginTop: 20,
        },
    },
});

export default styles;
