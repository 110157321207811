import React, {PureComponent} from 'react';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Joi from 'joi-browser';

import {
    Background, Panel,
    TextInput, LightButton,
} from '../../../components';
import {ROUTE} from '../../../App';
import {getErrorObject} from '../../../lib/form';
import userActions from '../../../store/UserRedux';
import {userForgotPasswordSelector} from '../../../store/selectors';

import styles from './ForgotPassword.jss';

class ForgotPassword extends PureComponent {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        forgotPassword: PropTypes.object.isRequired,
        userForgotPassword: PropTypes.func.isRequired,
    };

    state = {
        form: {
            email: null,
            emailTouched: false,
        },
        formErrors: [],
        showAgreements: false,
    };

    handleChangeEmail = (event) => {
        const {form} = this.state;
        this.setState({
            form: {
                ...form,
                email: event.target.value,
                emailTouched: true,
            }
        }, () => {
            this.validate();
        });
    }

    handleSubmit = () => {
        this.setTouched().then(() => {
            if (this.validate() === null) {
                const {form} = this.state;
                const {userForgotPassword} = this.props;
                userForgotPassword(form.email);
            }
        });
    }

    validate() {
        const {form} = this.state;

        const keys = {};
        if (form.emailTouched) keys.email = Joi.string().email().required();
        const schema = Joi.object(keys);

        const result = Joi.validate(form, schema, {abortEarly: false, allowUnknown: true}).error;

        let formErrors = [];
        if (result) {
            formErrors = result.details.map((item: Object): {} => ({
                path: item.path,
                type: item.type,
            }));
        }
        this.setState({formErrors});

        return result;
    }

    setTouched() {
        return new Promise((resolve, reject) => {
            const {form} = this.state;
            this.setState({
                form: {
                    ...form,
                    emailTouched: true,
                    passwordTouched: true,
                    agreementsTouched: true,
                }
            }, () => { resolve(); });
        });
    }

    render() {
        const {formErrors} = this.state;
        const {classes, forgotPassword} = this.props;
        return (
            <Background>
                <Panel className={classes.panel}>
                    <div className={classes.container}>
                        <Typography variant="h4" color="secondary">Forgot Password?</Typography>
                        <Typography variant="body2" color="textPrimary">Enter the email address you used when you joined 
and we’ll send you instructions to reset your password.</Typography>
                        <div className={classes.row}>
                            <TextInput
                                placeholder="Email"
                                type="email"
                                onChange={this.handleChangeEmail}
                                {...getErrorObject(formErrors, 'email', {
                                    "default": "Please enter Email",
                                    "string.email": "Please enter valid Email",
                                })}
                            />
                        </div>
                        {forgotPassword.error && (
                            <Typography variant="body1" color="error">{forgotPassword.errorMessage}</Typography>
                        )}
                        {!forgotPassword.error && forgotPassword.success  && (
                            <Typography variant="body1" color="textSecondary">Please find following instuction in the mailbox you have just entered</Typography>
                        )}
                        <div className={classes.row}>
                            <LightButton onClick={this.handleSubmit} disabled={!forgotPassword.error && forgotPassword.success}>SEND</LightButton>
                        </div>
                        <div className={classes.back}>
                            <Link to={ROUTE.login}>back to sign in</Link>
                        </div>
                    </div>
                </Panel>
            </Background>
        );
    }
}

const mapStateToProps = (state) => ({
    forgotPassword: userForgotPasswordSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    userForgotPassword: (email) => dispatch(userActions.userForgotPasswordRequest(email)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ForgotPassword));
