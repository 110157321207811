import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import get from 'get-value';
import moment from 'moment';
import Icon from 'react-svg-icon';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import copy from 'copy-to-clipboard';

import { Panel, TextInput, Dialog } from '../../../components';
import { api } from '../../../sagas';

import { createTravelReport } from '../../Report/components/ReportFactory';
import SubscriptionInfo from './SubscriptionInfo';
import ReportsTable from './ReportsTable';
import TravelReport from '../../Report/components/TravelReport';
// TODO: need for testing
// import { mockReportData } from '../../Report/containers/mockReportData';
import styles from './Reports.jss';

const EmptyComponent = (props) => {
	const { classes } = props;
	return (
		<div className={classes.empty}>
			<div className='long' />
			<div className='short' />
		</div>
	);
};

const Empty = withStyles(styles)(EmptyComponent);

class Reports extends PureComponent {
	static propTypes = {
		classes: PropTypes.object,
	};

	state = {
		loading: false,
		total: 0,
		last: {
			content: [],
		},
		search: {
			focused: false,
			text: '',
			content: [],
			total: 0,
			page: 0,
			size: 16,
			pages: 0,
			last: false,
		},
		share: {
			pending: false,
			showDialog: false,
			uuid: null,
			password: null,
		},
		showReport: false,
		reportData: null,
	};

	debouncedSearch = AwesomeDebouncePromise(() => {
		this.loadReports();
	}, 700);

	componentDidMount() {
		this.loadReportCount();
		this.loadReportsLast();
		this.debouncedSearch();
	}

	loadReportCount() {
		api.getReportCount().then((response) => {
			if (response.ok) {
				this.setState({
					total: get(response, 'data', { default: 0 }),
				});
			}
		});
	}

	loadReportsLast() {
		this.setState({ loading: true });
		api.getReports().then((response) => {
			if (response.ok) {
				this.setState({
					last: {
						content: this.adaptList(
							get(response, 'data.content', { default: [] })
						),
					},
				});
			}
			this.setState({ loading: false });
		});
	}

	loadReports() {
		const {
			loading,
			search,
			search: { content, page, size, pages },
		} = this.state;
		if (!loading && (pages === 0 || page < pages)) {
			this.setState({ loading: true });
			api.getReports(search.text, { page, size }).then((response) => {
				if (response.ok) {
					const newTotal = get(response, 'data.totalElements', { default: 0 });
					const newPages = get(response, 'data.totalPages', { default: 0 });
					const last = get(response, 'data.last', { default: false });
					this.setState({
						search: {
							...search,
							total: newTotal,
							page: page + 1,
							pages: newPages,
							last,
							content: content.concat(
								this.adaptList(get(response, 'data.content', { default: [] }))
							),
						},
					});
				}
				this.setState({ loading: false });
			});
		}
	}

	adaptList(list) {
		if (list && list.length) {
			return list.map((el) => ({
				id: el.id,
				travelerName: el.travelerName,
				createdAt: moment(el.createdAt).format('MMM DD YYYY'),
				destination: get(el, 'input.destination.locationName', { default: '' }),
			}));
		}
		return list;
	}

	getLastReports() {
		const {
			last: { content },
		} = this.state;
		if (content && content.length) {
			return content.slice(0, 3);
		}
		return [];
	}

	getReports() {
		const {
			search: { content },
		} = this.state;
		if (content && content.length) {
			return content;
		}
		return [];
	}

	handleOnClickView = (report) => {
		const { id } = report;
		this.setState({ loading: true });
		api.getReport(id).then((response) => {
			if (response.ok) {
				this.setState({
					reportData: { ...response.data, id },
					showReport: true,
				});
			}
			this.setState({ loading: false });
		});
	};

	handleOnScrollReports = (e) => {
		const height = e.target.offsetHeight;
		const scrollHeight = e.target.scrollHeight;
		const top = e.target.scrollTop;
		if (scrollHeight - top < height + 80) {
			this.debouncedSearch();
		}
	};

	handleOnSearchChange = (e) => {
		const {
			target: { value },
		} = e;
		this.changeSearchText(value);
	};

	handleOnSearchClear = () => {
		this.changeSearchFocus(false, true);
	};

	handleOnSearchFocus = () => {
		this.changeSearchFocus(true);
	};

	handleClickDashboard = () => {
		this.setState({ showReport: false, reportData: null });
	};

	handleClickPdf = (props) => {
		const { reportData } = this.state;
		createTravelReport({
			...props,
			reportRiskTravel: { data: reportData },
		});
	};

	changeSearchText(value) {
		const { search } = this.state;
		this.setState(
			{
				search: {
					...search,
					text: value,
					content: [],
					page: 0,
					last: false,
				},
			},
			() => {
				this.debouncedSearch();
			}
		);
	}

	changeSearchFocus(value, clearText = false) {
		const { search } = this.state;
		this.setState({
			search: {
				...search,
				text: clearText ? '' : search.text,
				content: clearText ? [] : search.content,
				focused: value,
			},
		});
	}

	renderAvailableInfo() {
		const { classes } = this.props;
		const { total } = this.state;
		return (
			<div className={classes.availableInfo}>
				<div className='total'>
					<Typography variant='body2'>
						Available reports: {total || 'N/A'}
					</Typography>
				</div>
			</div>
		);
	}

	renderSearchPanel() {
		const { classes } = this.props;
		const { search } = this.state;
		return (
			<div className={classes.searchPanel}>
				{!search.focused && <div className='title'>Last 3 reports</div>}
				<div className='search'>
					<TextInput
						placeholder='Find Report'
						startAdornment={
							<Icon name='svg-i-search' className={classes.searchIcon} />
						}
						value={search.text}
						onChange={this.handleOnSearchChange}
						onClear={this.handleOnSearchClear}
						onFocus={this.handleOnSearchFocus}
						clearable
					/>
				</div>
			</div>
		);
	}

	handleClickShare = () => {
		const {
			reportData: { id },
		} = this.state;
		if (id) {
			this.setState({
				share: {
					...this.state.share,
					pending: true,
				},
			});
			api.reportShare(id).then((response) => {
				if (response.ok) {
					const {
						data: { uuid, password },
					} = response;
					this.setState({
						share: {
							showDialog: true,
							pending: false,
							uuid,
							password,
						},
					});
				} else {
					console.log('=== Report::handleClickShare', response);
				}
			});
		}
	};

	handleOnCloseShareDialog = () => {
		this.setState({
			share: {
				...this.state.share,
				pending: false,
				showDialog: false,
			},
		});
	};

	handleCopyUrl = (txt) => {
		copy(txt);
	};

	handleCopyPassword = (txt) => {
		copy(txt);
	};

	renderReport() {
		const { classes } = this.props;
		const {
			showReport,
			reportData,
			share: { showDialog, uuid, password },
		} = this.state;
		const {
			location: { protocol, host },
		} = document;

		let shareUrl = '';
		if (showDialog && uuid) {
			shareUrl = `/report/${uuid}`;
			shareUrl = `${protocol}//${host}${shareUrl}`;
		}

		if (showReport && reportData) {
			return (
				<React.Fragment key={reportData.id}>
					<div key='report' className={classes.overlay}>
						<div className={classes.reportContainer}>
							<div className={classes.reportContent}>
								<TravelReport
									data={reportData}
									// TODO: need for testing
									// data={mockReportData}
									onClickShare={this.handleClickShare}
									clickDashboardLabel='BACK'
									hideShare={false}
									onClickPdf={this.handleClickPdf}
									onClickDashboard={this.handleClickDashboard}
								/>
							</div>
						</div>
					</div>
					<Dialog
						title='Report has been shared'
						open={showDialog}
						onClose={this.handleOnCloseShareDialog}
					>
						{' '}
						<div className={classes.shareLinkPsw}>
							<p>
								<b>Public Link:</b>{' '}
								<span className='share-link'>{shareUrl}</span>
								<br />(
								<span
									className='btn-copy'
									onClick={() => this.handleCopyUrl(shareUrl)}
									target='_blank'
									rel='noopener noreferrer'
								>
									Copy Link
								</span>
								)
							</p>
							<p>
								<b>Password:</b> <span className='share-link'>{password}</span>
								<br />(
								<span
									className='btn-copy'
									onClick={() => this.handleCopyPassword(password)}
									target='_blank'
									rel='noopener noreferrer'
								>
									Copy Password
								</span>
								)
							</p>
							<p>
								Never share reports publicly. You can invite others to access
								this report, preferably by sending the unique URL and password
								separately.
							</p>
						</div>
					</Dialog>
				</React.Fragment>
			);
		}

		return null;
	}

	render() {
		const { classes } = this.props;
		const { loading, search } = this.state;
		return [
			<Panel key='reports' className={classes.panel} loading={loading}>
				<SubscriptionInfo />
				{this.renderAvailableInfo()}
				{this.renderSearchPanel()}
				{search.focused ? (
					<div className={classes.table}>
						<ReportsTable
							list={this.getReports()}
							onClickView={this.handleOnClickView}
							onScroll={this.handleOnScrollReports}
							bottom={
								!search.last && this.getReports().length !== 0
									? [<Empty key={0} />, <Empty key={1} />]
									: null
							}
						/>
					</div>
				) : (
					<div className={classes.table}>
						<ReportsTable
							list={this.getLastReports()}
							onClickView={this.handleOnClickView}
						/>
					</div>
				)}
			</Panel>,
			this.renderReport(),
		];
	}
}

export default withStyles(styles)(Reports);
