export const networkSelector = (state) => state.network;
export const networkPendingSelector = (state) => networkSelector(state).pending;

export const pendingSearchPlaces = (state) => networkPendingSelector(state).searchPlaces;
export const pendingGetPersons = (state) => networkPendingSelector(state).getPersons;
export const pendingUserLogin = (state) => networkPendingSelector(state).userLogin;
export const pendingUserDetails = (state) => networkPendingSelector(state).userDetails;
export const pendingUserMapboxHodeData = (state) => networkPendingSelector(state).userMapboxHodeData;
export const pendingGetRaces = (state) => networkPendingSelector(state).getRaces;
export const pendingGetGenders = (state) => networkPendingSelector(state).getGenders;
export const pendingGetModifiers = (state) => networkPendingSelector(state).getModifiers;
export const pendingBoundariesList = (state) => networkPendingSelector(state).boundariesList;
export const pendingReportRiskTravel = (state) => networkPendingSelector(state).reportRiskTravel;
