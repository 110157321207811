import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { Color } from '../../theme';

function arrowGenerator(color) {
    return {
        '&[x-placement*="bottom"] $arrow': {
            top: 0,
            left: 0,
            marginTop: '-0.95em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '0 1em 1em 1em',
                borderColor: `transparent transparent ${color} transparent`,
            },
        },
        '&[x-placement*="top"] $arrow': {
            bottom: 0,
            left: 0,
            marginBottom: '-0.95em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '1em 1em 0 1em',
                borderColor: `${color} transparent transparent transparent`,
            },
        },
        '&[x-placement*="right"] $arrow': {
            left: 0,
            marginLeft: '-0.95em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 1em 1em 0',
                borderColor: `transparent ${color} transparent transparent`,
            },
        },
        '&[x-placement*="left"] $arrow': {
            right: 0,
            marginRight: '-0.95em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 0 1em 1em',
                borderColor: `transparent transparent transparent ${color}`,
            },
        },
    };
}

const styles = (theme) => ({
    tooltip: {
        backgroundColor: 'rgba(35, 53, 68, .9)',
        color: Color.white,
        maxWidth: 220,
        fontSize: 11,
        borderRadius: 3,
        padding: 10,

        '& .tooltip-btn-default': {
            display: 'flex',
            justifyContent: 'space-between',
        },

        '& .tooltip-link': {
            display: 'flex',
            fontSize: '.786rem',
            color: Color.cyanLight,
            fontWeight: 400,
            margin: '10px 0 0',
            cursor: 'pointer',

            '& svg': {
                width: 15,
                height: 15,
                marginLeft: 0,
            },
        },
    },

    arrowPopper: {
        ...arrowGenerator('rgba(35, 53, 68, .9)'),
        zIndex: 100,
    },
    arrow: {
        position: 'absolute',
        fontSize: 6,
        width: '3em',
        height: '3em',
        '&::before': {
            content: '""',
            margin: 'auto',
            display: 'block',
            width: 0,
            height: 0,
            borderStyle: 'solid',
        },
    },

    htmlPopper: arrowGenerator('rgba(35, 53, 68, .9)'),
});

class Tooltips extends React.Component {
    state = {
        arrowRef: null,
    };

    handleArrowRef = (node) => {
        this.setState({
            arrowRef: node,
        });
    };

    render() {
        const { classes, children, title, placement, open, id } = this.props;

        return (
            <div>
                <Tooltip
                    title={
                        <React.Fragment>
                            {title}
                            <span
                                className={classes.arrow}
                                ref={this.handleArrowRef}
                            />
                        </React.Fragment>
                    }
                    id={id}
                    open={open}
                    placement={placement}
                    interactive
                    classes={{
                        popper: classes.arrowPopper,
                        tooltip: classes.tooltip,
                    }}
                    PopperProps={{
                        popperOptions: {
                            modifiers: {
                                arrow: {
                                    enabled: Boolean(this.state.arrowRef),
                                    element: this.state.arrowRef,
                                },
                            },
                        },
                    }}
                >
                    {children}
                </Tooltip>
            </div>
        );
    }
}

Tooltips.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Tooltips);
