import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Form, Field } from "react-final-form";
import Joi from 'joi-browser';

import { api } from '../../../sagas';
import { getFinalFormErrors } from '../../../lib/form';
import { Dialog, TextInput, DarkButton } from '../../../components';
import TravelReport from '../components/TravelReport';
import { createTravelReport } from '../components/ReportFactory';

import styles from './ReportShared.jss';

const errorMessages = {
    password: { 'default': 'Password is required' },
};

class ReportShared extends PureComponent {
    static propTypes = {
        classes: PropTypes.object,
        match: PropTypes.object,
    };

    constructor(props) {
        super(props);

        const { match: { params: { uuid } } } = props;
        this.state = {
            showPasswordDialog: true,
            reportRiskTravel: null,
            uuid: uuid,
            password: null,
            submitting: false,
            getSharedReportError: false,
            getSharedReportErrorMessage: null,
        };
    }

    handleClickPdf = (props) => {
        const { reportRiskTravel } = this.state;
        createTravelReport({
            ...props,
            reportRiskTravel: {data: reportRiskTravel},
        });
    }

    handleOnClosePasswordDialog = () => {
        this.setState({ showPasswordDialog: false });
    }

    handleOnSubmit = (values) => {
        this.setState({
            password: values.password
        }, () => {
            this.loadData();
        });
    }

    handleValidate = (values) => {
        let errors = {};

        const schema = Joi.object({
            password: Joi.string().required(),
        });
        const result = Joi.validate(values, schema, { abortEarly: false, allowUnknown: true }).error;
        if (result) errors = getFinalFormErrors(result.details, errorMessages);

        return errors;
    }

    loadData() {
        const { uuid, password } = this.state;
        if (uuid && password) {
            this.setState({ submitting: true });
            api.getSharedReport(uuid, password)
                .then((response) => {
                    if (response.ok) {
                        this.setState({
                            reportRiskTravel: response.data,
                            getSharedReportError: false,
                            getSharedReportErrorMessage: null,
                            showPasswordDialog: false,
                        });
                    } else {
                        this.setState({
                            getSharedReportError: true,
                            getSharedReportErrorMessage: response.data.message,
                        });
                    }
                    this.setState({ submitting: false });
                });
        }
    }

    renderLoading() {
        const { classes } = this.props;
        const { submitting } = this.state;
        if (submitting) {
            return (
                <div className={classes.overlay}>
                    <LinearProgress color="primary" />
                </div>
            );
        }
        return null;
    }

    renderReport() {
        const { classes } = this.props;
        const { reportRiskTravel } = this.state;
        if (reportRiskTravel) {
            return (
                <div className={classes.content}>
                    {this.renderLoading()}
                    <TravelReport
                        data={reportRiskTravel}
                        onClickPdf={this.handleClickPdf}
                        hideDashboard={true}
                        hideShare={true}
                    />
                </div>
            );
        }
        return null;
    }

    renderForm = ({
        handleSubmit,
        submitFailed,
        submitSucceeded,
    }) => {
        const { classes } = this.props;
        const { getSharedReportError, getSharedReportErrorMessage } = this.state;
        return (
            <form onSubmit={handleSubmit} className="light">
                <Field name="password">
                    {({ input, meta }) => (
                        <div className={classes.row}>
                            <TextInput
                                placeholder="Password"
                                type="text"
                                dark
                                {...input}
                                error={(meta.error || meta.submitError) && meta.touched}
                                errorMessage={meta.error}
                            />
                        </div>
                    )}
                </Field>
                {getSharedReportError && <div className={classes.error}>{getSharedReportErrorMessage}</div>}
                <div className={classes.row}>
                    <DarkButton onClick={handleSubmit}>GET REPORT<input type="submit" style={{ display: 'none' }} /></DarkButton>
                </div>
            </form>
        );
    }

    renderPasswordDialog() {
        const { classes } = this.props;
        const { showPasswordDialog } = this.state;
        return (
            <Dialog
                title="Enter password to receive the report"
                open={showPasswordDialog}
            >
                <div className={classes.form}>
                    <Form
                        onSubmit={this.handleOnSubmit}
                        validate={this.handleValidate}
                        render={this.renderForm}
                    />
                </div>
                {this.renderLoading()}
            </Dialog>
        );
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.container}>
                {this.renderPasswordDialog()}
                {this.renderReport()}
            </div>
        );
    }
}

export default withStyles(styles)(ReportShared);
