import React, { useMemo, useRef } from 'react';

import { adaptDataFromHomeCovidTrendHistoryApi } from '../../api/adapter';
import { GraphColor, RiskTrends } from './';

import './GeographicRiskTrends.scss';

const GeographicRiskTrends = (props) => {
    const { casesTrend, deathTrend, covidTrendHistory, location, locationIcon } = props;

    const mmCasesTrend = useMemo(() => Math.round(casesTrend), [casesTrend]);
    const mmDeathTrend = useMemo(() => Math.round(deathTrend), [deathTrend]);

    const elementToShare = useRef();

    return (
        <div ref={elementToShare} className='geographic-risk-trends-container mob-pd-h-15'>
            <div className='header'>
                <img src={locationIcon} alt=""/>
                <div className='block-text'>
                    <h3>COVID-19 Geographic Risk Trends</h3>
                    <span>{location}</span>
                </div>
            </div>
            <div className='box-graph'>
                <div className='block-graph-left'>
                    <RiskTrends
                        data={adaptDataFromHomeCovidTrendHistoryApi(
                            covidTrendHistory
                        )}
                    />
                </div>
                <div className='block-graph-right'>
                    <div className='graph-color'>
                        <header className='graph-color-header'>
                            Last 7 days
                        </header>
                        <div className='graph-color-item graph-color-item-01'>
                            <div className='graph-color-text'>
                                <div className='data-arrow'>
                                    <GraphColor className='graph-color-health' />
                                    <h2>{mmCasesTrend > 0 ? '+' + mmCasesTrend : mmCasesTrend} %</h2>
                                    {/* {mmCasesTrend > 0 ? (
                                        <ArrowUpwardRoundedIcon className='arrow-up' />
                                    ) : (
                                        <ArrowDownwardRoundedIcon className='arrow-down' />
                                    )} */}
                                </div>
                                <span>New cases</span>
                            </div>
                        </div>
                        <div className='graph-color-item graph-color-item-02'>
                            <div className='graph-color-text'>
                                <div className='data-arrow'>
                                    <GraphColor className='graph-color-violet' />
                                    <h2>{mmDeathTrend > 0 ? '+' + mmDeathTrend :  mmDeathTrend} %</h2>
                                    {/* {mmDeathTrend > 0 ? (
                                        <ArrowUpwardRoundedIcon className='arrow-up' />
                                    ) : (
                                        <ArrowDownwardRoundedIcon className='arrow-down' />
                                    )} */}
                                </div>
                                <span>New deaths</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

GeographicRiskTrends.defaultProps = {
    casesTrend: 0,
    deathTrend: 0,
    covidTrendHistory: [],
};

export default GeographicRiskTrends;
