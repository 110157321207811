
export const fromToDetailsToString = (fromToDetails) => {
    let destinationParts = [
        fromToDetails.destinationLevel3, fromToDetails.destinationLevel2,
        fromToDetails.destinationLevel1, fromToDetails.destinationLevel0
    ];
    destinationParts = destinationParts.filter((el) => (!!el));
    const destinationLabel = destinationParts.join(', ');
    return destinationLabel;
};
