import React, { useState } from 'react';

import './Switch.scss';

const Switch = (props) => {
    const { values, initialIndex = 0, onChange } = props;
    const [index, setIndex] = useState(initialIndex);
    const handleOnClick = (idx) => {
        setIndex(idx);
        if (onChange) onChange(idx);
    };
    const selectorStyle = {
        // width: `calc(${100 / values.length}% - 4px)`,
        // transform: `translateX(calc(${100 * index}% + ${4 * index}px))`,
        width: `calc(${100 / values.length}%)`,
        left: index < 1 ? '1px' : `calc(${100 * index / values.length}%  - 1px)`,
    };
    return (
        <div className='switch'>
            <div className='switch-selector' style={selectorStyle} />
            {values.map((el, idx) => (
                <label
                    key={idx}
                    className={index === idx ? 'active' : ''}
                    onClick={() => {
                        handleOnClick(idx);
                    }}
                    dangerouslySetInnerHTML={{ __html: el }}
                />
            ))}
        </div>
    );
};

export default Switch;
