import React from 'react';
import Icon from 'react-svg-icon';
import InputAdornment from '@material-ui/core/InputAdornment';
import {withStyles} from '@material-ui/core/styles';
import {TextInput} from '../../../components';

import styles from './Search.jss';

const Search = (props) => {
    const {classes, onChange} = props;
    const handleChange = (e) => {
        if (onChange) onChange(e);
    };
    return (
        <TextInput
            placeholder="Search"
            type="text"
            onChange={handleChange}
            startAdornment={
                <InputAdornment position="start">
                    <Icon name="svg-i-search" className={classes.icon} />
                </InputAdornment>
            }
        />
    );
};

export default withStyles(styles)(Search);
