import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import {
    Background, Dialog,
} from '../../../components';

class Eula extends PureComponent {
    static propTypes = {
        classes: PropTypes.object.isRequired,
    };

    handleOnClose = () => {
        this.goBack();
    }

    goBack() {
        this.props.history.goBack();
    }

    render() {
        // const {classes} = this.props;
        return (
            <Background>
                <Dialog open={true} onClose={this.handleOnClose}>
                    <Typography variant="h4">USER LICENSE AGREEMENT</Typography>
                    <Typography variant="body1">BY DOWNLOADING AND/OR USING ANY SAFE ESTEEM SOLUTION APPLICATION, YOU ARE ENTERING INTO AND AGREEING TO BE BOUND BY THE TERMS OF THIS END USER LICENSE AGREEMENT. </Typography>
                    <Typography variant="body1">IF YOU DO NOT AGREE TO THE TERMS OF THIS LICENSE, DO NOT DOWNLOAD OR USE THE APPLICATION.</Typography>
                    <Typography variant="body1"><strong>1. General.</strong> The Application is licensed, not sold, to You by Safe-esteem, Inc. (“SAFE ESTEEM”) for use strictly in accordance with the terms and conditions of this License and any "usage rules" established by any other third party usage rules or terms of use, such as Apple Inc. and such other vendors (“Usage Rules”), which are incorporated herein by this reference. The term "Application" shall refer to and consist of the following: (i) the mobile and Web-based software application accompanying this License, including, without limitation, any software code, scripts, interfaces, graphics, displays, text, documentation and other components; (ii) any updates, modifications or enhancements to the items listed in subsection (i); and (iii) any specific website the Application directs you to via any browser located on an iPad or such other mobile device (“Mobile Device”).</Typography>
                    <Typography variant="body1"><strong>2. License Grant and Restrictions on Use.</strong> </Typography>
                    <Typography variant="body1"><strong>2.1 License Grant.</strong> SAFE ESTEEM grants You a revocable, non-exclusive, non-transferable, limited right to install and use the Application on a single Mobile Device owned and controlled by You, and to access and use the Application on such Mobile Device strictly in accordance with the terms and conditions of this License, the Usage Rules and any service agreement associated with your Mobile Device (collectively "Related Agreements"). </Typography>
                    <Typography variant="body1"><strong>2.2 Restrictions on Use.</strong> You shall use the Application strictly in accordance with the terms of the Related Agreements and shall not: (a) decompile, reverse engineer, disassemble, attempt to derive the source code of, or decrypt the Application; (b) make any modification, adaptation, improvement, enhancement, translation or derivative work from the Application; (c) violate any applicable laws, rules or regulations in connection with Your access or use of the Application; (d) remove, alter or obscure any proprietary notice (including any notice of copyright or trademark) of SAFE ESTEEM or its affiliates, partners, suppliers or the licensors of the Application; (e) use the Application for any revenue generating endeavor, commercial enterprise, or other purpose for which it is not designed or intended; (f) use the Application for creating a product, service or software that is, directly or indirectly, competitive with or in any way a substitute for any services, product or software offered by SAFE ESTEEM; (g) use the Application to send automated queries to any website or to send any unsolicited commercial e-mail; or (h) use any proprietary information or interfaces of SAFE ESTEEM or other intellectual property of SAFE ESTEEM in the design, development, manufacture, licensing or distribution of any applications, accessories or devices for use with the Application. </Typography>
                    <Typography variant="body1"><strong>2.3 User Name, Password, and Security.</strong></Typography>
                    <Typography variant="body1">You may be required to register with Safe-esteem in order to access and use certain features of the Site or Services. If you choose to register for the Service, you agree to provide and maintain true, accurate, current and complete information about yourself as prompted by the Services’ registration form. Registration data and certain other information about you are governed by our Privacy Policy. If you are under 13 years of age, you are not authorized to use the Services, with or without registering. In addition, if you are under 18 years old, you may use the Services, with or without registering, only with the approval of your parent or guardian.  You are responsible for maintaining the confidentiality of your user name and password, and you are solely responsible for all activities that occur under your user name and password. You agree to immediately notify Safe-esteem of any unauthorized use of your user name and password or any other breach of security related to the Site. Safe-esteem WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE ARISING FROM YOUR FAILURE TO ADEQUATELY SAFEGUARD YOUR USERNAME AND/OR PASSWORD, OR TO OTHERWISE COMPLY WITH THIS SECTION.</Typography>
                    <Typography variant="body1"><strong>3. Intellectual Property Rights.</strong> </Typography>
                    <Typography variant="body1"><strong>3.1 Rights to Application.</strong> You acknowledge and agree that the Application and all copyrights, patents, trademarks, trade secrets and other intellectual property rights associated therewith are, and shall remain, the property of SAFE ESTEEM. Furthermore, You acknowledge and agree that the source and object code of the Applications and the format, directories, queries, algorithms, structure and organization of the Application are the intellectual property and proprietary and confidential information of SAFE ESTEEM and its affiliates, licensors and suppliers. Except as expressly stated in this License, You are not granted any intellectual property rights in or to the Application by implication, estoppel or other legal theory, and all rights in and to the Application not expressly granted in this License are hereby reserved and retained by SAFE ESTEEM. </Typography>
                    <Typography variant="body1"><strong>3.2 Third Party Software.</strong> The Application may utilize or include third party software that is subject to open source and/or third party license terms ("Third Party Software"). You acknowledge and agree that Your right to use such Third Party Software as part of the Application is subject to and governed by the terms and conditions of the open source or third party license applicable to such Third Party Software, including, without limitation, any applicable acknowledgements, license terms and disclaimers contained therein. In the event of a conflict between the terms of this License and the terms of such open source or third party licenses, the terms of the open source or third party licenses shall control with regard to Your use of the relevant Third Party Software. In no event, shall the Application or components thereof be deemed to be "open source" or "publically available" software. </Typography>
                    <Typography variant="body1"><strong>3.3 SAFE ESTEEM’s Marks.</strong> You are not authorized to use the SAFE ESTEEM trademarks in any advertising, publicity or in any other commercial manner without the prior written consent of SAFE ESTEEM, which may be withheld for any or no reason. </Typography>
                    <Typography variant="body1"><strong>3.4 Infringement Acknowledgement.</strong> You and SAFE ESTEEM acknowledge and agree that, in the event of a third party claim that the Application or Your possession or use of the Application infringes any third party's intellectual property rights, You (and not SAFE ESTEEM) will be responsible for the investigation, defense, settlement and discharge of any such claim of intellectual property infringement. You will, however, promptly notify SAFE ESTEEM in writing of such a claim. </Typography>
                    <Typography variant="body1"><strong>4. Restriction on Transfer.</strong> You may not rent, lease, lend, sublicense or transfer the Application, this License or any of the rights granted hereunder. Any attempted transfer in contravention of this provision shall be null and void and of no force or effect. </Typography>
                    <Typography variant="body1"><strong>5. Use of Information.</strong> </Typography>
                    <Typography variant="body1"><strong>5.1 Consent to Use Information.</strong> You hereby authorize and consent to the collection, storage and use, by SAFE ESTEEM and its affiliates, partners and agents, of any information and data related to or derived from Your use of the Application, and any information or data that You or consumers provide to SAFE ESTEEM and its affiliates, partners and licensors ("Information"). Without limiting the generality of the foregoing, the Information shall include, but not be limited to, all data identified in SAFE ESTEEM’s privacy policy which may change from time to time at the sole discretion of SAFE ESTEEM, and the following types of information and data, in an aggregate (not user level) form: search requests, search results, patterns, data and suggestions based on user actions, location, and other information we may gather from your use of the Application. Notwithstanding the foregoing, You shall not provide or disclose and the Information shall not include any information or data that is personally identifiable to You. The Information will be treated as being non-confidential and nonproprietary, and SAFE ESTEEM assumes no obligation to protect confidential or proprietary information (other than personally identifiable information) from disclosure and will be free to reproduce, use, and distribute the Information to others without restriction. We will also be free to use any ideas, concepts, know-how or techniques contained in the Information for any purpose whatsoever including, without limitation, developing, manufacturing and marketing products and services incorporating such Information. </Typography>
                    <Typography variant="body1"><strong>5.2 Privacy Policy.</strong> You represent that You shall comply with the terms and conditions of the SAFE ESTEEM Privacy Policy located at http://safe-esteem.com/privacy, which sets forth and describes the practices of SAFE ESTEEM with respect to the collection, use and disclosure of Information in connection with Your use of the Application. SAFE ESTEEM reserves the right to change the provisions of its Privacy Policy at any time and from time to time at its sole discretion. SAFE ESTEEM will post any changes to its Privacy Policy at the web address set forth in the preamble to this License. Your use of the Application following the posting of such changes to the Privacy Policy will constitute Your acceptance of any such changes. </Typography>
                    <Typography variant="body1"><strong>6. Third Party Content and Services.</strong> </Typography>
                    <Typography variant="body1"><strong>6.1 General.</strong> You acknowledge that the Application permits access to products, services, web-sites, advertisements, promotions, recommendations, advice, information, and materials created and provided by advertisers, publishers, content partners, marketing agents, vendors and other third parties ("Third Party Content and Services"). </Typography>
                    <Typography variant="body1"><strong>6.2 Disclaimer.</strong> You acknowledge that SAFE ESTEEM does not investigate, monitor, represent or endorse the Third Party Content and Services (including any third party websites available through the Application). Furthermore, Your access to and use of the Third Party Content and Services is at Your sole discretion and risk, and SAFE ESTEEM and its affiliates, partners, suppliers and licensors shall have no liability to You arising out of or in connection with Your access to and use of the Third Party Content and Services. SAFE ESTEEM hereby disclaims any representation, warranty or guaranty regarding the Third Party Content and Services, whether express, implied or statutory, including, without limitation, the implied warranties of merchantability or fitness for a particular purpose, and any representation, warranty or guaranty regarding the availability, quality, reliability, features, appropriates, accuracy, completeness, or legality of the Third Party Content and Services. </Typography>
                    <Typography variant="body1"><strong>6.3 Third Party Terms of Service.</strong> You acknowledge and agree that Your access to and use of the Third Party Content and Services and any correspondence or business dealings between You and any third party using the Application are governed by and require Your acceptance of the terms of service of such third party, including, without limitation, any terms, privacy policies, conditions, representations, warranties or disclaimers contained therein. Furthermore, You acknowledge and agree that the Third Party Content and Services and any related third party terms of service are subject to change by the applicable third party at its sole discretion and without any notice. You assume all risks arising out of or resulting from your transaction of business over the Internet and with any third party, and you agree that SAFE ESTEEM and its affiliates, partners, suppliers and licensors are not responsible or liable for any loss or result of the presence of information about or links to such advertisers or service providers. Furthermore, You acknowledge and agree that You are not being granted a license to (i) the Third Party Content and Services; (ii) any products, services, processes or technology described in or offered by the Third Party Content and Services; or (iii) any copyright, trademark, patent or other intellectual property right in the Third Party Content or Services or any products, services, processes or technology described or offered therein. </Typography>
                    <Typography variant="body1"><strong>6.4 Endorsements.</strong> You acknowledge and agree that the provision of access to any Third Party Content and Service shall not constitute or imply any endorsement by SAFE ESTEEM or its affiliates of such Third Party Content and Services. SAFE ESTEEM reserves the right to restrict or deny access to any Third Party Content and Services otherwise accessible through the Application, although SAFE ESTEEM has no obligation to restrict or deny access even if requested by You. </Typography>
                    <Typography variant="body1"><strong>6.5 Inappropriate Materials.</strong> You understand that by accessing and using the Third Party Content and Services, You may encounter information, materials and subject matter (i) that You or others may deem offensive, indecent, or objectionable; (ii) which may or may not be identified as having explicit language, and (iii) that automatically and unintentionally appears in search results, as a link or reference to objectionable material. Notwithstanding the foregoing, You agree to use the Third Party Content and Services at Your sole risk and that SAFE ESTEEM and its affiliates, partners, suppliers and licensors shall have no liability to You for information, material or subject matter that is found to be offensive, indecent, or objectionable. </Typography>
                    <Typography variant="body1"><strong>6.6 Use of Third Party Content and Services.</strong> You agree that the Third Party Content and Services contain proprietary information and material that is owned by SAFE ESTEEM and its affiliates, partners, suppliers and licensors and is protected by applicable intellectual property and other laws, including, without limitation, pursuant to copyright, and that You will not use such proprietary information or materials in any way whatsoever except for permitted use of the Third Party Content and Services. No portion of the Third Party Content and Services may be reproduced in any form or by any means. You agree not to modify, rent, lease, loan, sell, distribute, or create derivative works based on the Third Party Content and Services, in any manner, and You shall not exploit the Third Party Content and Services in any unauthorized way whatsoever, including, without limitation, by trespass or burdening network capacity. You agree that You will not use any Third Party Content and Services in a manner that would infringe or violate the rights of any other party, and that SAFE ESTEEM is not in any way responsible for any such use by You. </Typography>
                    <Typography variant="body1"><strong>6.7 Users under the age of 18. &nbsp;</strong>Users between the age of seventeen (17) and thirteen (13) are prohibited from using this Application without parental consent and Users are expressly prohibited from using the Application under any circumstances if they are under the age of thirteen (13).</Typography>
                    <Typography variant="body1"><strong>6.8 Mobile Services.</strong></Typography>
                    <Typography variant="body1">The Services includes certain services that are available via a mobile device, including (i) the ability to upload content to the Services via a mobile device, (ii) the ability to browse the Services and the Site from a mobile device and (iii) the ability to access certain features through an application downloaded and installed on a mobile device (collectively, the “Mobile Services”). To the extent you access the Services through a mobile device, your wireless service carrier’s standard charges, data rates and other fees may apply. In addition, downloading, installing, or using certain Mobile Services may be prohibited or restricted by your carrier, and not all Mobile Services may work with all carriers or devices. By using the Mobile Services, you agree that we may communicate with you regarding Safe-esteem and other entities by SMS, MMS, text message or other electronic means to your mobile device and that certain information about your usage of the Mobile Services may be communicated to us.  In the event you change or deactivate your mobile telephone number, you agree to promptly update your Safe-esteem account information to ensure that your messages are not sent to the person that acquires your old number.</Typography><Typography variant="body1"><strong>7. Term and Termination.</strong> </Typography><Typography variant="body1"><strong>7.1 Term.</strong> This License shall be effective until terminated. </Typography>
                    <Typography variant="body1"><strong>7.2 Termination.</strong> SAFE ESTEEM may, in its sole and absolute discretion, at any time and for any or no reason, suspend or terminate this License and the rights afforded to You hereunder with 30 days written notice. Furthermore, if You fail to comply with any terms and conditions of this License, then this License and any rights afforded to You hereunder shall terminate automatically, without any notice or other action by SAFE ESTEEM. Upon the termination of this License, You shall cease all use of the Application and uninstall the Application. </Typography>
                    <Typography variant="body1"><strong>8. Disclaimer of Warranties.</strong> THIS APPLICATION CANNOT AND DOES NOT GUARANTEE YOUR SAFETY. It is provided for information purposes only.</Typography>
                    <Typography variant="body1">YOU ACKNOWLEDGE AND AGREE THAT THE APPLICATION IS PROVIDED ON AN "AS IS' AND "AS AVAILABLE" BASIS, AND THAT YOUR USE OF OR RELIANCE UPON THE APPLICATION AND ANY THIRD PARTY CONTENT AND SERVICES ACCESSED THEREBY IS AT YOUR SOLE RISK AND DISCRETION. SAFE ESTEEM AND ITS AFFILIATES, PARTNERS, SUPPLIERS AND LICENSORS HEREBY DISCLAIM ANY AND ALL REPRESENTATIONS, WARRANTIES AND GUARANTEES REGARDING THE APPLICATION AND THIRD PARTY CONTENT AND SERVICES, WHETHER EXPRESS, IMPLIED OR STATUTORY, AND INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. FURTHERMORE, SAFE ESTEEM AND ITS AFFILIATES, PARTNERS, SUPPLIERS AND LICENSORS MAKE NO WARRANTY THAT (I) THE APPLICATION OR THIRD PARTY CONTENT AND SERVICES WILL MEET YOUR REQUIREMENTS; (II) THE APPLICATION OR THIRD PARTY CONTENT AND SERVICES WILL BE UNINTERRUPTED, ACCURATE, RELIABLE, TIMELY, SECURE OR ERROR-FREE; (III) THE QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION OR OTHER MATERIAL ACCESSED OR OBTAINED BY YOU THROUGH THE APPLICATION WILL BE AS REPRESENTED OR MEET YOUR EXPECTATIONS; OR (IV) ANY ERRORS IN THE APPLICATION OR THIRD PARTY CONTENT AND SERVICES WILL BE CORRECTED. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM SAFE ESTEEM OR FROM THE APPLICATION SHALL CREATE ANY REPRESENTATION, WARRANTY OR GUARANTY. FURTHERMORE, YOU ACKNOWLEDGE THAT SAFE ESTEEM HAVE NO OBLIGATION TO CORRECT ANY ERRORS OR OTHERWISE SUPPORT OR MAINTAIN THE APPLICATION IN ANY SET TIMEFRAME.</Typography>
                    <Typography variant="body1"><strong>8.1 Assumption of Risk.</strong></Typography>
                    <Typography variant="body1">YOU AGREE THAT YOU USE THE SITE AND/OR ANY THIRD PARTY LINKED SITE AT YOUR OWN RISK. YOU FURTHER UNDERSTAND AND AGREE THAT SAFE-ESTEEM DOES NOT ASSUME ANY RESPONSIBILITY FOR YOUR ILLEGAL, UNAUTHORIZED, OR IMPROPER USE OF INFORMATION TRANSMITTED, MONITORED, STORED OR RECEIVED USING THE SITE OR THE SERVICES. </Typography>
                    <Typography variant="body1"><strong>9. Limitation of Liability.</strong> UNDER NO CIRCUMSTANCES SHALL SAFE ESTEEM OR ITS AFFILIATES, PARTNERS, SUPPLIERS OR LICENSORS BE LIABLE FOR ANY HARM YOU INCUR WHILE USING THE APPLICATION, OR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL OR EXEMPLARY DAMAGES ARISING OUT OF OR IN CONNECTION WITH YOUR ACCESS OR USE OF OR INABILITY TO ACCESS OR USE THE APPLICATION AND ANY THIRD PARTY CONTENT AND SERVICES, WHETHER OR NOT THE DAMAGES WERE FORESEEABLE AND WHETHER OR NOT SAFE ESTEEM WAS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. UNDER NO CIRCUMSTANCES SHALL SAFE ESTEEM OR ITS AFFILIATES, PARTNERS, SUPPLIERS OR LICENSORS BE LIABLE FOR ANY HARM YOU INCUR IF YOU MISREPRESENT INFORMATION IN THE REGISTRATION OR USE OF THE APPLICATION. WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, SAFE ESTEEM’S AGGREGATE LIABILITY TO YOU (WHETHER UNDER CONTRACT, TORT, STATUTE OR OTHERWISE) SHALL NOT EXCEED THE LICENSE FEE YOU PAID SAFE ESTEEM TO DOWNLOAD AND USE THE APPLICATION. THE FOREGOING LIMITATIONS WILL APPLY EVEN IF THE ABOVE STATED REMEDY FAILS IN ITS ESSENTIAL PURPOSE. </Typography>
                    <Typography variant="body1"><strong>10. Indemnification.</strong> You shall indemnify, defend and hold harmless SAFE ESTEEM and its affiliates, partners, suppliers and licensors, and each of their respective officers, directors, agents and employees (the "Indemnified Parties") from and against any claim, proceeding, loss, damage, fine, penalty, interest and expense (including, without limitation, fees for attorneys and other professional advisors) arising out of or in connection with the following: (i) Your access to or use of the Application or Third Party Content and Services; (ii) Your breach of this License; (iii) Your violation of law; (iv) Your negligence or willful misconduct; or (v) Your violation of the rights of a third party, including the infringement by You of any intellectual property or misappropriation of data or proprietary right or trade secret of any person or entity. These obligations will survive any termination of the License. </Typography>
                    <Typography variant="body1"><strong>11. Compatibility.</strong> SAFE ESTEEM does not warrant that the Application will be compatible or interoperable with Your Mobile Device or any other piece of hardware, software, equipment or device installed on or used in connection with your Mobile Device unless deemed compatible by SAFE ESTEEM. Furthermore, You acknowledge that compatibility and interoperability problems can cause the performance of your Mobile Device to diminish or fail completely, and may result in permanent the damage to Your Mobile Device, loss of the data located on Your Mobile Device, and corruption of the software and files located on Your Mobile Device. You acknowledge and agree that SAFE ESTEEM and its affiliates, partners, suppliers and licensors shall have no liability to You for any losses suffered resulting from or arising in connection with compatibility or interoperability problems. </Typography>
                    <Typography variant="body1"><strong>12. Product Claims.</strong> You acknowledge that You (not SAFE ESTEEM) are responsible for addressing any third party claims relating to Your use or possession of the Application, and agree to notify SAFE ESTEEM of any third party claims relating to the Application of which You become aware. Furthermore, You hereby release SAFE ESTEEM from any liability resulting from Your use or possession of the Application, including, without limitation, the following: (i) any product liability claims; (ii) any claim that the Application fails to conform to any applicable legal or regulatory requirement; and (iii) any claim arising under consumer protection or similar legislation. </Typography>
                    <Typography variant="body1"><strong>13. Miscellaneous.</strong> </Typography>
                    <Typography variant="body1"><strong>13.1 Governing Law.</strong> This License shall be deemed to take place in the State of Florida and shall be governed by and construed in accordance with the laws of the State of Florida, excluding its conflicts of law principles. Any disputes arising from this License shall be adjudicated in the courts of the County of Miami-Dade. This License shall not be governed by the United Nations Convention on Contracts for the International Sale of Goods, the application of which is expressly excluded. </Typography>
                    <Typography variant="body1"><strong>13.2 Severability.</strong> If any provision of this License is held to be invalid or unenforceable with respect to a party, the remainder of this License, or the application of such provision to persons other than those to whom it is held invalid or unenforceable shall not be affected and each remaining provision of this License shall be valid and enforceable to the fullest extent permitted by law. </Typography>
                    <Typography variant="body1"><strong>13.3 Waiver.</strong> Except as provided herein, the failure to exercise a right or require performance of an obligation under this License shall not effect a party's ability to exercise such right or require such performance at any time thereafter nor shall the waiver of a breach constitute waiver of any subsequent breach. </Typography>
                    <Typography variant="body1"><strong>13.4 Export Control.</strong> You may not use or otherwise export or re-export the Application except as authorized by United States law and the laws of the jurisdiction(s) in which the Application was obtained. You represent and warrant that You are not (a) located in any country that is subject to a U.S. Government embargo, or that has been designated by the U.S. Government as a "terrorist supporting" country, or (b) listed on any U.S. U.S. Government list of prohibited or restricted parties including the Treasury Department's list of Specially Designated Nationals or the U.S. Department of Commerce Denied Person's List or Entity List. You also agree that You will not use the Application for any purposes prohibited by United States law. </Typography>
                    <Typography variant="body1"><strong>13.5 Modification or Amendment.</strong> SAFE ESTEEM may modify or amend the terms of this License by posting a copy of the modified or amended License on the SAFE ESTEEM Application EULA website. You will be deemed to have agreed to any such modification or amendment by Your decision to continue using the Application following the date in which the modified or amended License is posted on the SAFE ESTEEM Application EULA website. </Typography>
                    <Typography variant="body1"><strong>13.6 Survival.</strong> The following sections of this License and any other provisions of this License which by their express language or by their context are intended to survive the termination of this License shall survive such termination: 1, 2.2, 3, 4, 5, 7, 8, 9, 10, 12 and 13. </Typography>
                    <Typography variant="body1"><strong>13.7 Assignment.</strong> Except as permitted in Section 4, You shall not assign this License or any rights or obligations herein without the prior written consent of SAFE ESTEEM and any attempted assignment in contravention of this provision shall be null and void and of no force or effect. </Typography>
                    <Typography variant="body1"><strong>13.8 Entire Agreement.</strong> This End User License including the documents incorporated herein by reference constitute the entire agreement with respect to the use of the Application licensed hereunder and supersedes all prior or contemporaneous understandings regarding such subject matter.</Typography>
                    <Typography variant="body1"><strong>14. Questions.</strong> If you have any questions, complaints or claims they should be directed to Safe-esteem as follows:<br/><br/>
                    privacy@safe-esteem.com<br/>
                    Safe-esteem, Inc<br/>
                    429 Lenox Ave.<br/>
                    Miami Beach, FL 33139</Typography>
                </Dialog>
            </Background>
        );
    }
}

export default Eula;
