import React from 'react';

import { roundNumber } from '../../lib';
import { GraphColor, RelativeRiskChart } from './';

import './PersonalRiskDrivers.scss';

const GeographicRisk = (props) => {
    const {
        gender,
        ageGroup,
        centerIcon,
        location,
        data: { health, crime, accidents },
    } = props;

    const formatValue = (value) => {
        return value >= 2 ? roundNumber(value, 1) : '<2';
    };

    return (
        <div className='personal-risk-drivers-container'>
            <div className='box-graph'>
                <div className='graph-col graph-circle'>
                    <RelativeRiskChart
                        data={{
                            health: health.value,
                            crime: crime.value,
                            accidents: accidents.value,
                        }}
                        centerIcon={centerIcon}
                    />
                    <div className='block-text'>
                        <span>{location}</span>
                    </div>
                </div>
                <div className='graph-col graph-info'>
                    <div className='graph-color-item graph-color-item-01'>
                        <div className='graph-color-text'>
                            <div className='graph-color-data'>
                                <GraphColor className='graph-color-crime' />
                                <h2>
                                    {formatValue(crime.value)}
                                    <span> % Crime</span>
                                    <span className='tag'>{crime.label}</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className='graph-color-item graph-color-item-01'>
                        <div className='graph-color-text'>
                            <div className='graph-color-data'>
                                <GraphColor className='graph-color-safety' />
                                <h2>
                                    {formatValue(accidents.value)}
                                    <span> % Accidents</span>
                                    <span className='tag'>
                                        {accidents.label}
                                    </span>
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className='graph-color-item graph-color-item-01'>
                        <div className='graph-color-text'>
                            <div className='graph-color-data'>
                                <GraphColor className='graph-color-health' />
                                <h2>
                                    {formatValue(health.value)}
                                    <span> % Health</span>
                                    <span className='tag'>
                                        {health.label}
                                    </span>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

GeographicRisk.defaultProps = {
    data: {
        health: { label: '', value: 58 },
        crime: { label: '', value: 16 },
        accidents: { label: '', value: 26 },
    },
};

export default GeographicRisk;
