import React, {
	useCallback,
	createContext,
	useContext,
	useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import config from '../../config';
import { api } from '../../sagas';

const DESCRIPTION = 'PRO';
const isTrackingOff = !!Number(process.env.REACT_APP_ORTTO_TRACKING_OFF);

export const OrttoContext = createContext();

export default function OrttoProvider({ children }) {
	const userDataFromState = useSelector((state) => {
		return state.user.properties;
	});

	const email = userDataFromState?.email;

	const getUserData = (user) => {
		return {
			userFirstName: user?.firstName || user?.billing?.firstName || '',
			userLastName: user?.lastName || user?.billing?.lastName || '',
			userEmail: user?.email,
			userHome:
				user?.city || user?.billing?.city || user?.billing?.country || '',
			userAgeGroup: user?.ageGroup || '',
			userGender: user?.gender || '',
			userCompany: user?.companyName || '',
		};
	};

	const init = useCallback(() => {
		const APIkeySE = config?.ortto?.generalKey;

		if (!APIkeySE) return;

		window.ap3c = window.ap3c || {};
		const { ap3c } = window;
		ap3c.cmd = ap3c.cmd || [];
		ap3c.cmd.push(function () {
			ap3c.init(APIkeySE, 'https://capture-api.autopilotapp.com/', {
				cookieDomains: [
					'safe-esteem.com',
					'safe-xplore.com',
					'localhost:3000',
					'localhost:5000',
				],
				link: 'g',
			});
			ap3c.track({ v: 0, email });
		});
		ap3c.activity = function (act) {
			ap3c.act = ap3c.act || [];
			ap3c.act.push(act);
		};
		let s;
		let t;
		s = document.createElement('script');
		s.type = 'text/javascript';
		s.src = 'https://cdn2l.ink/app.js';
		t = document.getElementsByTagName('script')[0];
		t.parentNode.insertBefore(s, t);
	}, [email]);

	useEffect(() => {
		if (!isTrackingOff) {
			init();
		}
	}, [email, init]);

	const track = (activity, userDestination = '') => {
		const { id: activityId, key: activityKey } = activity;

		if (!activityId || isTrackingOff || !userDestination) return;

		const userData = getUserData(userDataFromState);

		const {
			userFirstName,
			userLastName,
			userEmail,
			userHome,
			userAgeGroup,
			userGender,
			userCompany,
		} = userData;

		const data = {
			activities: [
				{
					activity_id: activityId,
					attributes: {
						'str:cm:report-destination': userDestination,
						'str:cm:description': DESCRIPTION,
					},
					fields: {
						'str::email': userEmail,
						'str::first': userFirstName,
						'str::last': userLastName,
						'str:cm:home': userHome,
						'str:cm:organization': userCompany,
						'str:cm:gender': userGender,
						'str:cm:age-group': userAgeGroup,
					},
				},
			],
			merge_by: ['str::email'],
		};

		api
			.customOrttoActivity(data, activityKey)
			.then((response) => {
				if (response.ok) {
					console.log('### Ortto', activityId, data);
				}
			})
			.catch((error) => {
				console.error('### Ortto error', error.message);
			});
	};

	return (
		<OrttoContext.Provider value={{ track }}>{children}</OrttoContext.Provider>
	);
}

export const useOrtto = () => useContext(OrttoContext);
