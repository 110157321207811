import React from 'react';

import cn from '../../../lib/classNames';

import Info from './Info';

import styles from './HeaderMarkdown.module.scss';

export default function HeaderMarkdown(props) {
	const { className, descriptionMain, info, image } = props;

	return (
		<>
			<section className={cn(styles.bluf, className)}>
				<div className={styles.header}>
					<div className={styles.description}>{descriptionMain}</div>
				</div>
			</section>
			<section className={cn(styles.body, className)}>
				<Info description={info} image={image} isAccordion={false} />
			</section>
		</>
	);
}
