export const geocodingSelector = state => (state.geocoding);
export const mapSelector = state => (geocodingSelector(state).map);
export const mapUserBehaviourSelector = state => (geocodingSelector(state).mapUserBehaviour);
export const mapMovedSelector = state => (geocodingSelector(state).mapMoved);
export const mapDraggedSelector = state => (geocodingSelector(state).mapDragged);
export const mapZoomedSelector = state => (geocodingSelector(state).mapZoomed);
export const mapClickSelector = state => (geocodingSelector(state).mapClick);
export const mapIsShownSelector = state => (geocodingSelector(state).showMap);

export const geocodingAdaptSourceData = (data) => {
    if (data && 'features' in data && data.features.length) {
        const newData = data;
        newData.features = data.features.map((feature) => {
            feature.properties = {
                ...feature.properties,
            };
            feature.properties.id = feature.id;
            feature.properties.gid = feature.gid;
            feature.properties.name = feature.name;
            feature.properties.level = feature.level;
            feature.properties.administrative = feature.administrative;
            feature.properties.geographicArea = Math.round(feature.geographicArea * 100) / 100;
            feature.properties.boundaryType = feature.boundaryType;
            return feature;
        });
        return newData;
    }
    return data;
};

export const geocodingGetUsualData = (data) => {
    if (data && 'features' in data && data.features.length) {
        const newData = {};
        newData.type = data.type;
        newData.features = data.features.filter((el) => !el.containsCrimeSeData);
        return newData;
    }
    return data;
};

export const geocodingGetCrimeData = (data) => {
    if (data && 'features' in data && data.features.length) {
        const newData = {};
        newData.type = data.type;
        newData.features = data.features.filter((el) => el.containsCrimeSeData);
        return newData;
    }
    return data;
};