import React from 'react';

import {
	Bar,
	BarChart,
	LabelList,
	ResponsiveContainer,
	XAxis,
	YAxis,
} from 'recharts';

import { StatisticsTotalNumber } from '../StatisticsTotalNumber';

import BarLabel from './BarLabel';
import ComparisonInfo from './ComparisonInfo';

import styles from './DistributionBarChart.module.scss';

export default function DistributionBarChart(props) {
	const {
		data,
		destinationValue,
		type,
		showComparison = false,
		percentComparison = 0,
		destinationMin = 0,
		destinationMax = 0,
		totalStatistics,
		title = '',
		description = '',
		home,
		destination,
		captionX = '',
		captionY = '',
		minTick = 0,
	} = props;

	const scaledDataX = data.filter(
		(_item, index) => (index + 1) % minTick === 0
	);

	return (
		<div className={styles['bar-chart-container']}>
			<div className={styles['header-container']}>
				<div className={styles.header}>
					<div className={styles.title}>
						<h5>{title}</h5>
						<p>{description}</p>
					</div>
					{totalStatistics && (
						<div className={styles.statistics}>
							<StatisticsTotalNumber
								indexed={totalStatistics.indexed}
								conflict={totalStatistics.conflict}
								poorData={totalStatistics.poorData}
							/>
						</div>
					)}
				</div>
			</div>
			<div className={styles['bar-chart']}>
				<ResponsiveContainer>
					<BarChart
						data={data}
						barSize={100}
						margin={{
							top: 30,
							right: 0,
							bottom: 10,
							left: captionY ? -20 : -30,
						}}
					>
						<XAxis
							tickLine={false}
							dataKey={minTick ? 'max' : 'name'}
							fontSize={10}
							ticks={scaledDataX.map((item) => item.max)}
							label={{
								value: captionX,
								position: 'insideBottom',
								offset: 0,
								fontSize: 11,
							}}
						/>
						<YAxis
							tickLine={false}
							fontSize={10}
							label={{
								value: captionY,
								angle: -90,
								position: 'insideCenter1',
								offset: 20,
								fontSize: 10,
							}}
						/>
						<Bar type='monotone' dataKey='value'>
							<LabelList
								dataKey='value'
								position='top'
								content={
									<BarLabel {...props} home={home} destination={destination} />
								}
							/>
						</Bar>
					</BarChart>
				</ResponsiveContainer>
				{showComparison && (
					<ComparisonInfo
						type={type}
						percent={percentComparison}
						min={destinationMin}
						max={destinationMax}
						destinationValue={destinationValue}
					/>
				)}
			</div>
		</div>
	);
}
