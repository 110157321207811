import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {Typography} from '@material-ui/core';
import get from 'get-value';

import {Panel, DarkButton, Background, Confirm, TextInput} from '../../../components';
import {pendingGetPersons, userErrorSelector} from '../../../store/selectors';
import {api} from '../../../sagas';
import UserActions from '../../../store/UserRedux';

import styles from './NoSubscription.jss';

class NoSubscription extends PureComponent {
    state = {
        loading: false,
        showConfirm: false,
        subscriptions: [],
        selectedPlan: null,
        promoCode: '',
    };

    static propTypes = {
        onSelectPlan: PropTypes.func,
        pending: PropTypes.number,
        userError: PropTypes.object,
        classes: PropTypes.object,
        userSetSubscribeDetails: PropTypes.func,
    };

    componentDidMount() {
        this.loadSubscriptions();
    }

    authed() {
        const {userError: {errorType}} = this.props;
        return !errorType;
    }

    loadSubscriptions() {
        this.setState({loading: true});
        api.getSubscriptions().then((response) => {
            this.setState({loading: false});
            if (response.ok) {
                this.setState({subscriptions: response.data});
            }
        });
    }

    onClickPlan(plan) {
        if (plan) {
            this.setState({
                selectedPlan: plan,
                showConfirm: true,
            });
        }
    }

    handleOnCancelConfirm = () => {
        this.setState({
            selectedPlan: null,
            showConfirm: false,
        });
    };

    // handleOnConfirm_OLD = () => {
    //     const {selectedPlan: {id}, promoCode} = this.state;
    //     const {onSelectPlan} = this.props;
    //     api.subscribe({
    //         planId: id,
    //         promoCode,
    //     }).then((response) => {
    //         if (response.ok) {
    //             this.setState({
    //                 selectedPlan: null,
    //                 showConfirm: false,
    //             });
    //             if (onSelectPlan) onSelectPlan({id, promoCode});
    //             else document.location = '/';
    //         }
    //     });
    // };

    handleOnConfirm = () => {
        const {selectedPlan: {id}, promoCode} = this.state;
        const {onSelectPlan, userSetSubscribeDetails} = this.props;
        if (onSelectPlan) {
            userSetSubscribeDetails({
                planId: id,
                promoCode,
            });
            onSelectPlan({planId: id, promoCode});
        } else {
            api.subscribe({
                planId: id,
                promoCode,
            }).then((response) => {
                if (response.ok) {
                    this.setState({
                        selectedPlan: null,
                        showConfirm: false,
                    });
                    if (onSelectPlan) onSelectPlan({id, promoCode});
                    else document.location = '/';
                }
            });
        }
    };

    handleChangePromocode = (e) => {
        const promoCode = get(e, 'target.value', {default: ''});
        this.setState({promoCode});
    };

    getContent() {
        const {classes} = this.props;
        const {subscriptions} = this.state;
        return (
            <div className={classes.content}>
                <div className={classes.header}>
                    <div className={classes.headerLine}>
                        <Typography variant="h4" color="textSecondary">Please select a plan</Typography>
                    </div>
                </div>
                <div className={classes.list}>
                    {subscriptions.map((el, index) => this.renderItem(el, index))}
                </div>
            </div>
        );
    }

    renderItem(item, index) {
        const {name = '', description = '', additionalDescription = '', plans, enabled = false} = item;
        const parts = additionalDescription ? additionalDescription.split(';') : [];
        return (
            <div key={index} className="item">
                <Typography variant="h5" color="textPrimary">{name}</Typography>
                <p>{description}</p>
                {parts && parts.length
                    ? (
                        <ul>
                            {parts.map((el, index) => <li key={index}>{el}</li>)}
                        </ul>
                    )
                    : null
                }
                {plans && plans.length
                    ? (
                        <div className="button" style={{visibility: 'hidden'}}>
                            {enabled
                                ? <DarkButton onClick={() => { this.onClickPlan(plans[0]); }}>GET STARTED</DarkButton>
                                : <DarkButton disabled={true}>COMING SOON</DarkButton>
                            }
                        </div>
                    )
                    : null
                }
            </div>
        );
    }

    render() {
        const {classes, pending} = this.props;
        const {loading, showConfirm, selectedPlan} = this.state;
        return (
            <Background>
                <div className={classes.container}>
                    <Panel className={classes.panel} loading={loading || pending > 0}>
                        {this.getContent()}
                    </Panel>
                </div>
                {showConfirm && 
                    <div className={classes.overlay}>
                        <Confirm
                            message="Are you sure you want to activate this subscription?"
                            cancel="No"
                            onCancel={this.handleOnCancelConfirm}
                            confirm="Yes"
                            onConfirm={this.handleOnConfirm}
                        >
                            <p className={classes.confirmPlan}>
                                <b>{selectedPlan.description}</b>
                            </p>
                            <div className={classes.promoCodeContainer}>
                                <TextInput
                                    placeholder="Promocode"
                                    dark
                                    onChange={this.handleChangePromocode}
                                />
                            </div>
                        </Confirm>
                    </div>
                }
            </Background>
        );
    }
}

const mapStateToProps = (state) => ({
    pending: pendingGetPersons(state),
    userError: userErrorSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    userSetSubscribeDetails: (data) => dispatch(UserActions.userSetSubscribeDetails(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NoSubscription));
