import logo from '../../images/navigator/logo-inactive.svg';
import logoActive from '../../images/navigator/logo-active.svg';
import report from '../../images/navigator/report-inactive.svg';
import reportActive from '../../images/navigator/report-active.svg';
import settings from '../../images/navigator/settings-inactive.svg';
import settingsActive from '../../images/navigator/settings-active.svg';
import personas from '../../images/navigator/personas-inactive.svg';
import personasActive from '../../images/navigator/personas-active.svg';
import mapScrolling from '../../images/navigator/map-scrolling.svg';
import mapScrollingActive from '../../images/navigator/map-scrolling-active.svg';

const width = 88;
const height = 88;

const item = {
	overflow: 'hidden',
	transition: '0.2s',
	backgroundRepeat: 'no-repeat',
	backgroundPosition: 'center center',
	'&:hover': {
		transform: 'scale(1.12)',
	},
	'&.active:hover': {
		transform: 'none',
	},
};

const styles = (theme) => ({
	list: {
		zIndex: 100,
		marginTop: -7,
	},
	item: {
		width: width,
		height: height,
		cursor: 'pointer',
		display: 'block',
		marginTop: -17,

		'& .menu-icon': {
			display: 'none',
		},
	},
	logo: {
		...item,
		backgroundImage: `url(${logo})`,
		'&.active, &.active:hover': {
			backgroundImage: `url(${logoActive})`,
			backgroundPosition: 'center 7px',
		},
	},
	report: {
		...item,
		backgroundImage: `url(${report})`,
		'&.active, &.active:hover': {
			backgroundImage: `url(${reportActive})`,
			backgroundPosition: 'center 7px',
		},
	},
	persons: {
		...item,
		backgroundImage: `url(${personas})`,

		'&.active, &.active:hover': {
			backgroundImage: `url(${personasActive})`,
			backgroundPosition: 'center 7px',
		},
	},
	settings: {
		...item,
		backgroundImage: `url(${settings})`,
		'&.active, &.active:hover': {
			backgroundImage: `url(${settingsActive})`,
			backgroundPosition: 'center 7px',
		},
	},

	mapScrolling: {
		...item,
		backgroundImage: `url(${mapScrolling})`,
		'&:hover, &.active, &.active:hover': {
			backgroundImage: `url(${mapScrollingActive})`,
			backgroundPosition: 'center',
		},
	},

	'@media screen and (max-width: 767px)': {
		list: {
			position: 'fixed',
			top: 0,
			zIndex: 110,
			backgroundColor: '#fff',
			width: '100%',
			display: 'flex',
			justifyContent: 'start',
			alignItems: 'center',
			gap: '7px',
			margin: 0,
			padding: '12px 30px',
			boxSizing: 'border-box',
		},

		// item: {
		// 	marginTop: 0,
		// },

		item: {
			width: 40,
			height: 40,
			backgroundSize: 'cover',
			marginTop: 0,

			'&:not(:first-of-type)': {
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				background: '#DEE2E5',
				borderRadius: '50%',
				color: '#7D8C97',
			},

			'& .menu-icon': {
				display: 'block',
			},
		},

		logo: {
			backgroundPosition: 'center !important',
			position: 'relative',
			marginRight: 8,
			overflow: 'initial',

			'&:after': {
				content: "''",
				position: 'absolute',
				top: 0,
				right: -9,
				width: 1,
				height: '100%',
				backgroundColor: '#DEE2E5',
			},
		},
	},
});

export default styles;
