// @flow
import React, { Component } from 'react';
import * as d3 from 'd3';

type PropsType = {
    width: number,
    height: number,
    radius: number,
    lineWidth: number,
    color: {},
    value: number,
    label: string,
    onComplete: Function,
};

class RiskScoreDecrease extends Component<PropsType> {
    shape = null;
    shapeInited = false;
    svg = null;

    initShape() {
        if (this.shape && !this.shapeInited) {
            this.shapeInited = true;
            const { width, height, onComplete } = this.props;

            const svg = d3.select(this.shape);
            svg.attr('width', width).attr('height', height);
            this.svg = svg;

            this.addBorder();
            this.addArrow();
            this.addLabel();

            if (onComplete)
                onComplete({
                    svg: svg.html(),
                    width,
                    height,
                });
        }
    }

    addBorder() {
        const svg = this.svg;
        if (svg) {
            const { width, height, lineWidth, radius, color } = this.props;
            const hl = lineWidth / 2;
            svg.append('rect')
                .attr('x', hl)
                .attr('y', hl)
                .attr('width', width - lineWidth)
                .attr('height', height - lineWidth)
                .attr('rx', radius)
                .attr('stroke-width', lineWidth)
                .attr('stroke-linecap', 'round')
                .attr('fill', 'none')
                .attr('stroke', color.line);
        }
    }

    getArrowPath(ax1, ah) {
        return `M${ax1},0 v${ah} M0,32 L20,${ah} L42,32`;
    }

    getArrowPath2() {
        return `M8.79174 0.875H6.2084V18.6634L2.09329 14.5483L0.266602 16.375L7.57337 23.6818L9.40006 21.8551L14.8801 16.375L13.0535 14.5483L8.79174 18.81V0.875Z`;
    }

    addArrow() {
        const svg = this.svg;
        if (svg) {
            const { width, height, color, label } = this.props;

            const alh = 2;
            const aw = 15;
            const ah = 23;
            const ax1 = (aw - alh) / 2;

            const tx1 = width / 2 - aw / 2;
            const ty1 = height * 0.323;

            svg.append('g')
                // .attr('transform', `translate(${tx1}, ${ty1})`)
                .attr('transform', `translate(${tx1}, 4)`)
                .append('path')
                .attr('d', this.getArrowPath2())
                .attr('fill', color.line);
            // .attr('stroke-width', alh)
            // .attr('stroke-linecap', 'round')
            // .attr('stroke', color.line);

            svg.append('text')
                .attr('x', width / 2)
                .attr('y', 47)
                .text(`${label}`)
                .attr('font-size', '11px')
                .style('text-anchor', 'middle')
                .attr('fill', color.label);
        }
    }

    addLabel() {
        const svg = this.svg;
        if (svg) {
            const { color, value, width, height, bottomText } = this.props;

            const textValue = () => {
                if (value === 0) {
                    return '<1%';
                } else {
                    return value > 0 ? `+ ${value}%` : `${value}%`;
                }
            };

            svg.append('text')
                .attr('x', width / 2)
                .attr('y', height - 30)
                .text(textValue)
                .attr('font-size', '50px')
                .style('text-anchor', 'middle')
                .attr('fill', color.text);

            svg.append('text')
                .attr('x', width / 2)
                .attr('y', height - 3)
                .text(bottomText)
                .attr('font-size', '18px')
                .style('text-anchor', 'middle')
                .attr('fill', color.text);
        }
    }

    render(): React.ReactNode {
        return (
            <svg
                className='risk-score'
                ref={(ref) => {
                    this.shape = ref;
                    this.initShape();
                }}
            />
        );
    }
}

RiskScoreDecrease.defaultProps = {
    width: 210,
    height: 150,
    radius: 16,
    lineWidth: 2,
    color: {
        line: '#29C503',
        text: '#233544',
        label: '#7D8C97',
    },
};

export default RiskScoreDecrease;
