import React, {PureComponent} from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

import {debounce, log} from '../../lib';
import {userIsAdmin} from '../../store/selectors';

import './index.scss';

const COLOR = {
    USUAL: '#777',
    CRIME: '#ff8080',
    USUAL_BORDER: '#000',
    CRIME_BORDER: '#000',
};

class Map extends PureComponent {
    constructor(props) {
        super(props);

        this.onMoveEndDebounced = debounce(this.handleOnMoveEnd, 1000);
    }

    static propTypes = {
        accessToken: PropTypes.string.isRequired,
        mapStyle: PropTypes.string.isRequired,
        onInit: PropTypes.func,
        onMoveEnd: PropTypes.func,
        onDragEnd: PropTypes.func,
        onZoomEnd: PropTypes.func,
        onClick: PropTypes.func,
    };

    state = {
        hoveredStateId: null,
        popup2Opened: false,
    };

    popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false,
    });

    popup2 = new mapboxgl.Popup({
        closeButton: true,
        closeOnClick: true,
    });

    componentDidMount() {
        this.init();
    }

    componentWillUnmount() {
        this.map.remove();
    }

    init() {
        const {accessToken, mapStyle} = this.props;
        mapboxgl.accessToken = accessToken;
        this.map = new mapboxgl.Map({
            container: this.mapContainer,
            style: mapStyle,
            center: [-87.676352, 41.839661],
            zoom: 7
        });
        const geocoder = new MapboxGeocoder({
            accessToken: mapboxgl.accessToken,
            placeholder: 'Enter search',
            mapboxgl: mapboxgl,
        });
        this.map.addControl(geocoder);
        this.map.on('load', this.handleOnLoad);
    }

    handleMouseMove = (e) => {
        const {hoveredStateId, popup2Opened} = this.state;
        if (e.features.length > 0) {
            const coordinates = e.lngLat;
            const feature = e.features[0];
            const id = feature.id;
            if (hoveredStateId && hoveredStateId !== id) {
                this.map.setFeatureState({source: 'world', id: hoveredStateId}, {hover: false});
                this.map.setFeatureState({source: 'world-crime', id: hoveredStateId}, {hover: false});
            }
            this.setState({
                hoveredStateId: id,
            });
            this.map.setFeatureState({source: 'world', id: id}, {hover: true});
            this.map.setFeatureState({source: 'world-crime', id: id}, {hover: true});

            if (!popup2Opened) {
                try {
                    const name = feature.properties.name;
                    if (name) {
                        this.popup.setLngLat(coordinates)
                            .setHTML(name)
                            .addTo(this.map);
                    }
                } catch (e) {
                    log(e);
                }
            }
        }
    };

    handleMouseLeave = () => {
        const {hoveredStateId} = this.state;
        if (hoveredStateId) {
            this.map.setFeatureState({source: 'world', id: hoveredStateId}, {hover: false});
            this.map.setFeatureState({source: 'world-crime', id: hoveredStateId}, {hover: false});
        }
        this.setState({
            hoveredStateId: null,
        });
        this.popup.remove();
    };

    handleClick = (e) => {
        const {onClick} = this.props;
        if (onClick) onClick({
            map: this.map,
            featureId: e.features[0].id,
            featureName: e.features[0].properties.name,
            lngLat: e.lngLat,
        });
    };

    handleContextMenu = (e) => {
        if (e.features.length > 0) {
            const feature = e.features[0];
            const {
                id, gid, name, level, administrative, geographicArea, boundaryType
            } = feature.properties;
            if (id) {
                const coordinates = e.lngLat;
                this.popup.remove();
                this.popup2.setLngLat(coordinates)
                    .setHTML(`<h3>Properties</h3><div><b>Name:</b> ${name}</div><div><b>ID:</b> ${id}</div><div><b>GID:</b> ${gid}</div><div><b>Level:</b> ${level}</div><div><b>Administrative:</b> ${administrative}</div><div><b>Area:</b> ${geographicArea} sq mi</div><div><b>Boundary type:</b> ${boundaryType}</div>`)
                    .addTo(this.map);
            }
        }
    };

    handleOnLoad = () => {
        const {onInit} = this.props;
        this.addSources();
        this.addLayers();

        this.map.on("mousemove", "world-fills", (e) => {
            this.handleMouseMove(e);
        });
        this.map.on("mouseleave", "world-fills", (e) => {
            this.handleMouseLeave(e);
        });
        this.map.on('click', 'world-fills', (e) => {
            this.handleClick(e);
        });
        this.map.on("contextmenu", "world-fills", (e) => {
            this.handleContextMenu(e);
        });

        this.map.on("mousemove", "world-crime-fills", (e) => {
            this.handleMouseMove(e);
        });
        this.map.on("mouseleave", "world-crime-fills", (e) => {
            this.handleMouseLeave(e);
        });
        this.map.on('click', 'world-crime-fills', (e) => {
            this.handleClick(e);
        });
        this.map.on("contextmenu", "world-crime-fills", (e) => {
            this.handleContextMenu(e);
        });

        this.map.on('moveend', (e) => {
            this.onMoveEndDebounced();
        });

        this.map.on('dragend', (e) => {
            this.handleOnDragEnd();
        });

        this.map.on('zoomend', (e) => {
            this.handleOnZoomEnd();
        });

        this.popup2.on('open', (e) => {
            this.setState({popup2Opened: true});
        });
        this.popup2.on('close', (e) => {
            this.setState({popup2Opened: false});
        });

        if (onInit) onInit(this.map);
    }
    
    handleOnMoveEnd = () => {
        const {onMoveEnd} = this.props;
        if (onMoveEnd) onMoveEnd(this.map);
    }
    
    handleOnDragEnd = () => {
        const {onDragEnd} = this.props;
        if (onDragEnd) onDragEnd(this.map);
    }
    
    handleOnZoomEnd = () => {
        const {onZoomEnd} = this.props;
        if (onZoomEnd) onZoomEnd(this.map);
    }

    addSources() {
        this.map.addSource('single-point', {
            "type": "geojson",
            "data": {
                "type": "FeatureCollection",
                "features": []
            }
        });

        this.map.addSource("world", {
            "type": "geojson",
            "data": {
                "type": "FeatureCollection",
                "features": []
            }
        });

        this.map.addSource("world-crime", {
            "type": "geojson",
            "data": {
                "type": "FeatureCollection",
                "features": []
            }
        });
    }

    addLayers() {
        const {isAdmin} = this.props;

        this.map.addLayer({
            "id": "point",
            "source": "single-point",
            "type": "circle",
            "paint": {
                "circle-radius": 10,
                "circle-color": "#007cbf"
            }
        });

        this.map.addLayer({
            "id": "world-fills",
            "type": "fill",
            "source": "world",
            "layout": {},
            "paint": {
                "fill-color": COLOR.USUAL,
                "fill-opacity": ["case", ["boolean", ["feature-state", "hover"], false], 0.7, 0.2]
            }
        });

        this.map.addLayer({
            "id": "world-borders",
            "type": "line",
            "source": "world",
            "layout": {},
            "paint": {
                "line-color": COLOR.USUAL_BORDER,
                "line-width": 2
            }
        });

        this.map.addLayer({
            "id": "world-crime-fills",
            "type": "fill",
            "source": "world-crime",
            "layout": {},
            "paint": {
                "fill-color": isAdmin ? COLOR.CRIME : COLOR.USUAL,
                "fill-opacity": ["case", ["boolean", ["feature-state", "hover"], false], 0.7, 0.2]
            }
        });

        this.map.addLayer({
            "id": "world-crime-borders",
            "type": "line",
            "source": "world-crime",
            "layout": {},
            "paint": {
                "line-color": isAdmin ? COLOR.CRIME_BORDER : COLOR.USUAL_BORDER,
                "line-width": 2
            }
        });
    }

    render() {
        return (
            <div className="map" ref={el => this.mapContainer = el} />
        );
    }
}

const mapStateToProps = (state) => ({
    isAdmin: userIsAdmin(state),
});

export default connect(mapStateToProps)(Map);
