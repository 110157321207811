import Color from 'color';

const styles = (theme) => ({
    root: {
        maxWidth: "calc(100% - 36px)",
        height: 50,
        borderRadius: 25,
        boxShadow: theme.shadows[24],
        margin: 16,
    },
    rootSmall: {
        height: 30,
        minHeight: 30,
        borderRadius: 15,
        boxShadow: 'none',
        lineHeight: 'unset',
    },
    rootTiny: {
        height: 26,
        minHeight: 26,
        borderRadius: 13,
        boxShadow: 'none',
        lineHeight: 'unset',
    },
    label: {
        fontSize: '1rem',
        fontWeight: 'bold',
        color: theme.palette.primary.contrastText,
    },
    labelSmall: {
        fontSize: '0.8571rem',
        fontWeight: 500,
        color: theme.palette.primary.contrastText,
        textTransform: 'none',
    },
    labelTiny: {
        fontSize: '0.8571rem',
        fontWeight: 500,
        color: theme.palette.primary.contrastText,
        textTransform: 'none',
    },
    secondary: {
        backgroundColor: theme.palette.primary.dark,
        '&:hover': {
            backgroundColor: Color(theme.palette.primary.dark).darken(0.2).string(),
        }
    },

});

export default styles;
