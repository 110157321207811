
const styles = (theme) => ({
    root: {
    },
    underline: {
        borderBottom: '2px solid #7D8C97',
        transform: 'none',
        '&:after': {
            display: 'none',
            transform: 'none',
        },
        '&:before': {
            display: 'none',
            transform: 'none',
        },
        '&:focus': {
            background: 'none',
            transform: 'none',
        }
    },
    underlineError: {
        borderBottom: `2px solid red`,
        transform: 'none',
        '&:after': {
            display: 'none',
            transform: 'none',
        },
        '&:before': {
            display: 'none',
            transform: 'none',
        },
        '&:focus': {
            background: 'none',
            transform: 'none',
        }
    },
    filled: {
        color: 'red',
    },
    icon: {
        color: theme.palette.text.primary,
    },
    selectMenu: {
        fontWeight: 'bold',
    }
});

export default styles;
