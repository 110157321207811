import React from 'react';
import ReactDOM from 'react-dom';
import "core-js/stable";  // IE11 fix
import "core-js/modules/es.object.assign";  // IE11 fix
import "regenerator-runtime/runtime";  // IE11 fix
import 'core-js/features/string';
import '@stripe/stripe-js';

import App from './app/App';

ReactDOM.render(<App />, document.getElementById('root'));
