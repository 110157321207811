import React, { useState, useMemo, useRef } from 'react';

import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import { roundNumber } from '../../lib';
import { Switch, GraphColor, Tooltips, PersonalRiskStaticInfo } from '.';
import { Color } from '../../theme';

import './PersonalRisk.scss';

const titles = ['Communicable Diseases', 'All Diseases', 'All Causes'];

const PersonalRisk = (props) => {
  const {
    riskChange,
    riskChangeHealth,
    riskChangeCommunicableHealth,
    location,
    tipDefaultOpen,
    locationIcon,
    listCausesIdx,
  } = props;
  const listCauses = [
    riskChangeCommunicableHealth,
    riskChangeHealth,
    riskChange,
  ];
  const [causes, setCauses] = useState(listCauses[listCausesIdx]);
  const [title, setTitle] = useState(titles[listCausesIdx]);

  const mmRiskChange = useMemo(() => roundNumber(causes.value, 0), [causes]);
  const mmRiskChangeLower = useMemo(() => roundNumber(causes.lower, 0), [
    causes,
  ]);
  const mmRiskChangeUpper = useMemo(() => roundNumber(causes.upper, 0), [
    causes,
  ]);

  const all200 = 276;
  const riskChangeHeight = () => {
    return all200 / (1 + 1 / (causes.value / 100));
  };

  const riskChangeStyle = {
    height: `${riskChangeHeight()}px`,
  };

  const allCausesStyle = {
    height: `${all200 - riskChangeHeight()}px`,
  };

  return (
    <div className='personal-risk-container mob-pd-h-15'>
      <div className='box-graph'>
        <div className='graph-top'>
          <div className={`graph-text ${riskChangeHeight() > 98 ? 'va' : ''}`}>
            <div className='block-info'>
              <span>COVID19 Impact</span>
              <h2>
                <div className='block-arrow'>
                  {mmRiskChange > 0 ? (
                    <ArrowUpwardRoundedIcon className='graph-text-arrow arrow-up' />
                  ) : (
                    <ArrowDownwardIcon className='graph-text-arrow arrow-down' />
                  )}
                </div>
                {mmRiskChange}
                <span>%</span>
              </h2>
              <span>
                from {mmRiskChangeLower}% - {mmRiskChangeUpper}%
              </span>
            </div>
          </div>
          <div
            className='graph-item graph-item-01'
            style={riskChangeStyle}
          ></div>
        </div>

        <div className='graph-item graph-item-02' style={allCausesStyle}></div>
        <div className='graph-title'>{title}</div>

      </div>
    </div>
  );
};

export default PersonalRisk;
