import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import bg from '../../images/bg.jpg';
import bgVideo from '../../images/bg_video.mp4'

import styles from './Background.jss';

class Background extends PureComponent {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        children: PropTypes.node,
    };

    render() {
        const { classes, children } = this.props;
        return (
            <div className={classes.bg}>
                <video autoPlay muted loop  id='bg-video'>
                    <source src={bgVideo} type='video/mp4' />
                </video>
                {children}
            </div>
        );
    }
}

export default withStyles(styles)(Background);
