import React, {useState} from 'react';
import {withStyles} from '@material-ui/core/styles';

import {ROUTE_LEFT_MENU} from '../../../App';
import {LeftMenu} from '../../../components';

import AddCard from '../components/AddCard';
import EditAccount from '../../Settings/components/EditAccount';

import styles from './AddCardContainer.jss';

const AddCardContainer = (props) => {
    const [editBillingDetails, setSditBillingDetails] = useState(false);

    const handleOnClickLeftMenu = (el) => {
        props.history.push(ROUTE_LEFT_MENU[el.name]);
    };

    const onClickEditBillingDetails = () => {
        setSditBillingDetails(!editBillingDetails);
    };

    const handleOnCloseEditAccount = () => {
        setSditBillingDetails(!editBillingDetails);
    };

    const {classes} = props;
    return (
        <div className={classes.container}>
            <LeftMenu active={'logo'} onClick={handleOnClickLeftMenu} />
            {editBillingDetails
                ? <EditAccount onClose={handleOnCloseEditAccount} />
                : <AddCard onClickEditBillingDetails={onClickEditBillingDetails} />
            }
        </div>
    );
}

export default withStyles(styles)(AddCardContainer);
