import React, { useRef } from 'react';

import warImg from '../../../images/war_img.svg';

import './ViolentCrimeRiskConflict.scss';

const ViolentCrimeRiskConflict = (props) => {

	const { header = '', description = '', destinationLocation = '' } = props;

	const elementToShare = useRef();

	return (
		<div ref={elementToShare} className='violent-crimer-risk-conflict-container'>
			{
				header
					?
					(
						<div className='header'>
							<div className='block-text'>
								<h3>{header}</h3>
							</div>
						</div>
					)
					:
					''
			}

			<div className='body'>
				<img className='war-img' src={warImg} alt="" />
				<div className="war-destination-location overflow-row-1" title={destinationLocation}>
					{destinationLocation}
				</div>
				{/* <h2 className='title'>Active Conflict Area</h2> */}
				<p className='war-description'>{description}</p>
			</div>
		</div >
	);
};

export default ViolentCrimeRiskConflict;
