
const styles = (theme) => ({
    root: {
        minWidth: 556,
        minHeight: 700,
        backgroundColor: '#fff',
        boxShadow: theme.shadows[23],
        zIndex: 100,
    }
});

export default styles;
