import React, {PureComponent} from 'react';
import Select, {components} from 'react-select';
import AsyncSelect from 'react-select/async';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Icon from '@material-ui/core/Icon';
import PropTypes from 'prop-types';

const styles1 = {
    control: (provided, state) => ({
        ...provided,
        backgroundColor: 'none',
        border: 'none',
        borderRadius: 0,
        borderBottom: '2px solid #7D8C97',
        boxShadow: 'none',
        '&:hover': { borderColor: '#7D8C97', }
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        padding: 0,
        margin: 0,
    }),
    placeholder: (provided, state) => ({
        ...provided,
        color: '#7D8C97',
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: '#fff',
    }),
    indicatorSeparator: (provided, state) => ({
        ...provided,
        display: 'none',
    }),
    input: (provided, state) => ({
        ...provided,
        color: '#fff',
    }),
};

const styles2 = {
    ...styles1,
    control: (provided, state) => ({
        ...provided,
        backgroundColor: 'none',
        border: 'none',
        borderRadius: 0,
        borderBottom: 'none',
        boxShadow: 'none',
        fontSize: '1.1429rem',
        '&:hover': { borderColor: '#7D8C97', }
    }),
};

const styles1Dark = {
    control: (provided, state) => ({
        ...provided,
        backgroundColor: 'none',
        border: 'none',
        borderRadius: 0,
        borderBottom: '2px solid #7D8C97',
        boxShadow: 'none',
        '&:hover': { borderColor: '#7D8C97', }
    }),
    valueContainer: (provided, state) => ({
        ...provided,
        padding: 0,
        margin: 0,
    }),
    placeholder: (provided, state) => ({
        ...provided,
        color: '#7D8C97',
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: '#233544',
    }),
    indicatorSeparator: (provided, state) => ({
        ...provided,
        display: 'none',
    }),
    input: (provided, state) => ({
        ...provided,
        color: '#233544',
    }),
};

const styles2Dark = {
    ...styles1Dark,
    control: (provided, state) => ({
        ...provided,
        backgroundColor: 'none',
        border: 'none',
        borderRadius: 0,
        borderBottom: 'none',
        boxShadow: 'none',
        fontSize: '1.1429rem',
        '&:hover': { borderColor: '#7D8C97', }
    }),
};

const DropdownIndicator = props => {
    const {selectProps: {menuIsOpen}} = props;
    const style = {
        color: menuIsOpen ? '#fff' : '#7D8C97',
    };
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <Icon style={style}>{menuIsOpen ? "keyboard_arrow_up" : "keyboard_arrow_down"}</Icon>
            </components.DropdownIndicator>
        )
    );
};

class Autocomplete extends PureComponent {
    static propTypes = {
        label: PropTypes.string,
        placeholder: PropTypes.string,
        value: PropTypes.object,
        isClearable: PropTypes.bool,
        items: PropTypes.array, // [{label, value}]
        error: PropTypes.bool,
        errorMessage: PropTypes.string,
        async: PropTypes.bool,
        fullWidth: PropTypes.bool,
        large: PropTypes.bool,
        onChange: PropTypes.func,
    };

    handleChange = (event) => {
        const {onChange} = this.props;
        if (onChange) onChange(event.target.name, event.target.value);
    }

    render() {
        const {
            placeholder, value, isClearable = false, items = [],
            error = false, errorMessage = '', async = false,
            fullWidth = true, large = false, dark = false, ...rest
        } = this.props;

        let styles = (large) ? styles2 : styles1;
        if (dark) {
            styles = (large) ? styles2Dark : styles1Dark;
        }

        return (
            <FormControl
                fullWidth={fullWidth}
                error={!!error}
            >
                {async ?
                    <AsyncSelect
                    onChange={this.handleChange}
                    placeholder={placeholder}
                    isClearable={isClearable}
                    components={{DropdownIndicator}}
                    styles={styles}
                    value={value}
                    {...rest}
                />
                : <Select
                    options={items}
                    value={value}
                    onChange={this.handleChange}
                    placeholder={placeholder}
                    isClearable={isClearable}
                    components={{DropdownIndicator}}
                    styles={styles}
                    {...rest}
                />
                }
                {error && <FormHelperText>{errorMessage}</FormHelperText>}    
            </FormControl>
        );
    }
};

export default Autocomplete;
