
const styles = (theme) => ({
    root: {
        backgroundColor: theme.palette.primary.main,
        boxShadow: 'none',
        borderBottom: `1px solid ${theme.palette.primary.dark}`,
        '&:before': {
            backgroundColor: theme.palette.primary.dark,
        },
        '&:first-child': {
            borderRadius: 0,
        },
        '&:last-child': {
            border: 'none',
        },
    },
    rootDark: {
        backgroundColor: theme.palette.primary.dark,
        boxShadow: 'none',
        borderBottom: `1px solid ${theme.palette.primary.dark}`,
        '&:before': {
            backgroundColor: theme.palette.primary.dark,
        },
        '&:first-child': {
            borderRadius: 0,
            
        },
        '&:last-child': {
            border: 'none',
        },
    },
    expanded: {
        borderBottom: `1px solid ${theme.palette.primary.dark}`,
        marginTop: 0,
        '&:before': {
            opacity: 1,
        }
    },
    icon: {
        color: theme.palette.primary.light,
    },
    detailsRoot: {
        flexDirection: 'column',
        flexWrap: 'wrap',
    },
});

export default styles;
