import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {Typography} from '@material-ui/core';
import Icon from 'react-svg-icon';

import {userBillingSelector} from '../../../store/selectors';
import {Panel} from '../../../components';
import CardForm, {THEME} from '../../Settings/components/CardForm';

import styles from './AddCard.jss';

class AddCard extends PureComponent {
    state = {
        leftMenu: 'logo',
        loading: false,
        editBillingDetails: false,
    };

    static propTypes = {
        onCardConfirm: PropTypes.func,
        onClickEditBillingDetails: PropTypes.func,
        billing: PropTypes.object.isRequired,
    };

    hanldeCardFormLoading = (loading) => {
        this.setState({loading});
    };

    handleCardConfirm = () => {
        const {onCardConfirm} = this.props;
        if (onCardConfirm) onCardConfirm();
        else document.location = '/';
    };

    getContent() {
        const {classes, onClickEditBillingDetails, billing} = this.props;
        const na = 'N/A';
        return (
            <div className={classes.content}>
                <div className={classes.header}>
                    <Typography variant="h4" color="textSecondary">Add a Credit Card</Typography>
                </div>
                <div className={classes.parts}>
                    <div>
                        <div className="header-line">
                            <h5>Your billing details</h5>
                            <div onClick={onClickEditBillingDetails}>
                                <Icon name="svg-i-edit" className="edit-action" />
                            </div>
                        </div>
                        <div className="billing-info">
                            {billing.businessName ? billing.businessName : `${billing.firstName} ${billing.lastName}`}<br/>
                            {billing.line1 || na}<br/>
                            {billing.city || na}, {billing.postalCode || na}, {billing.country || na}
                        </div>
                        <div className="header-line">
                            <h5>Credit card details</h5>
                        </div>
                        <Typography variant="body1">A credit card is required to create account. You will be able to choose your preferred payment method later. (Pro plan is credit card only)</Typography>
                        <div className="card-form">
                            <CardForm
                                theme={THEME.LIGHT}
                                onLoading={this.hanldeCardFormLoading}
                                buttonLarge={true}
                                onCardConfirm={this.handleCardConfirm}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const {classes, pending} = this.props;
        const {loading} = this.state;
        return (
            <Panel className={classes.panel} loading={loading || pending > 0}>
                {this.getContent()}
            </Panel>
        );
    }
}

const mapStateToProps = (state) => ({
    billing: userBillingSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddCard));
