import React, { useState, useRef, useEffect } from 'react';

import './MenuMobile.scss';

export default function MenuMobile(props) {
	const { className = '', icon = '', children = '', currentReport } = props;

	const [open, setOpen] = useState(false);
	const [reportId, setReportId] = useState(currentReport);

	const mobileMenu = useRef(null);

	useEffect(() => {
		if (reportId !== currentReport) {
			setOpen(false);
			setReportId(currentReport);
		}

		const handleClickOutside = (e) => {
			if (
				mobileMenu.current &&
				open &&
				!mobileMenu.current.contains(e.target)
			) {
				setOpen(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, [currentReport, open, reportId]);

	return (
		<div
			ref={mobileMenu}
			className={`menu-mobile ${!!className && className} ${
				open ? 'menu-open' : ''
			}`}
		>
			<button
				onClick={() => {
					setOpen(!open);
				}}
				className='menu-icon'
			>
				<span className='material-icons-round'>{!open ? icon : 'close'}</span>
			</button>
			<div className='menu-content'>{children}</div>
		</div>
	);
}
