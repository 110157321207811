
const styles = (theme) => ({
    panel: {
        zIndex: 100,
        minWidth: 298,
        width: 298,
        minHeight: 204,
        marginTop: 20,
        marginLeft: 10,
        marginBottom: 30,
        position: 'relative',
    },
    container: {
        padding: 0,
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.primary.dark}`,
        padding: '13px 22px',
        '& .edit-action': {
            width: 30,
            height: 30,
            cursor: 'pointer',
            stroke: theme.palette.primary.light,
        },
    },
    content: {
        '& .subscription': {
            marginTop: 20,
        },
        '& .billing-info': {
            color: 'white',
            lineHeight: '21px',
            padding: '14px 20px',
            borderBottom: `1px solid ${theme.palette.primary.dark}`,
        },
        '& .card': {
            padding: '14px 20px 20px',
            '& p': {
                margin: '0 0 14px',
            }
        },
    },

    '@media screen and (max-width: 767px)': {
		panel: {
			marginLeft: 0,
            width: 'calc(100% - 60px)',
            minWidth: 'auto',
		},
	},
});

export default styles;
