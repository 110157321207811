import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import {
    Background, Dialog,
} from '../../../components';

class Terms extends PureComponent {
    static propTypes = {
        classes: PropTypes.object.isRequired,
    };

    handleOnClose = () => {
        this.goBack();
    }

    goBack() {
        this.props.history.goBack();
    }

    render() {
        // const {classes} = this.props;
        return (
            <Background>
                <Dialog open={true} onClose={this.handleOnClose}>
                    <Typography variant="h4">Terms of Website Use</Typography>
                    <Typography variant="body1">Safe-esteem, Inc . (“SAFE ESTEEM”) is a Delaware corporation and the owner of this site. By accessing or using this Website, or by registering as a User of at site you agree to adhere to and be bound by these Terms of Use (“Terms”). Please read these Terms carefully before accessing or using this site. If you cannot accept these Terms, you may not access or use this Website.</Typography>
                    <Typography variant="body1">The materials and information contained throughout this Website, including its home page and deep link pages, are intended to provide information, not advice, to its users.</Typography>
                    <Typography variant="body1">Transmitting materials through this Website or receiving materials from SAFE ESTEEM via this Website, blog or application does not create with you a client – service provider. The act of sending an email to a member of our staff will not create with you a contractual relationship or obligation for us to provide services. Your electronic communications with us will not be privileged and may be disclosed to other persons.</Typography>
                    <Typography variant="body1">Everything on this Website or submitted through this Website is considered SAFE ESTEEM data. Under these Terms, SAFE ESTEEM, SAFE ESTEEM data and information, all services related to it, including all text, images, photographs, user interface, "look and feel," data and other content (including the selection, coordination and arrangement of such content) are referred to as the "Website."</Typography>
                    <Typography variant="body1">SAFE ESTEEM reserves the right to change the Terms and such modifications shall be effective immediately. Users should visit this page periodically to review the Terms because they legally determine the conditions under which they are allowed to use the Website. A User’s continued access of this Website shall be deemed acceptance of the modified agreement.</Typography>
                    <Typography variant="subtitle1">I. SERVICES AND INFORMATION GENERALLY.</Typography>
                    <Typography variant="body1">A. Description. SAFE ESTEEM provides through this Website an online offering where individuals or entities (collectively, "Users") can access information about our business.</Typography>
                    <Typography variant="body1">B. License. Users should access this Website for information purposes only. SAFE ESTEEM retains the copyrights and all associated rights to the original symbols, concepts, methodologies and “know how” provided through this Website. The license allows Users to use the Website for information purposes only.  Any use of the information for legal advice, material gain, or conversion of data for the use or benefit of the User is strictly prohibited. All information and code on this Website is and shall remain the property of SAFE ESTEEM. Under this license, Users cannot sell, assign, or transfer rights to any information.</Typography>
                    <Typography variant="body1">C. Permitted Uses. Users may access and use the Service only according to this Agreement and any posted policies and procedures that appear on the Website. Any use of the Website is at the Users own risk and responsibility. Users may display the Website on an internet access device, and, occasionally, and only in circumstances that constitute "fair use" under U.S. copyright law, print copies of insubstantial portions of the Website. Users agree not to modify the Website, or any portion of it, except by the express written consent of SAFE ESTEEM. Users may not frame, or use any framing techniques to enclose any portion of the Website without the written consent of SAFE ESTEEM. Users may not use Meta tags or any other "hidden text" that utilize the SAFE ESTEEM trademark or company name without the written consent of SAFE ESTEEM.</Typography>
                    <Typography variant="body1">D. Prohibited Uses. Users may not broadcast, circulate, distribute, download, perform, publish, rent, reproduce, sell, store, transmit or create derivative works from the Website. Users must comply with all applicable laws in connection with using the Website. Further limitations may be set forth in any written or on-screen notice from SAFE ESTEEM.</Typography>
                    <Typography variant="body1">E. Printed or Electronically Produced Products. Users understand that SAFE ESTEEM shall retain the right to all information on printed materials when any portion of this Website is printed by the User.</Typography>
                    <Typography variant="body1">1. &nbsp;Users agree not to transmit, distribute, post, communicate or store information on, to, or from the Website that:</Typography>
                    <Typography variant="body1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (i) is copyrighted by SAFE ESTEEM;</Typography>
                    <Typography variant="body1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (ii) reveals trade secrets of SAFE ESTEEM.</Typography>
                    <Typography variant="body1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (iii) infringes on any intellectual property, privacy, or publicity rights of SAFE ESTEEM;</Typography>
                    <Typography variant="body1">2. &nbsp;Users further agree not to:</Typography>
                    <Typography variant="body1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (i) use any incomplete, false, or inaccurate information for purposes of registering as a User;</Typography>
                    <Typography variant="body1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (ii) delete or revise any information of another User or of SAFE ESTEEM;</Typography>
                    <Typography variant="body1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (iii) take any action that imposes an unreasonably large load on the Website's infrastructure;</Typography>
                    <Typography variant="body1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (iv) attempt to interfere with the proper working of this Website;</Typography>
                    <Typography variant="body1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (v) attempt to use any software, tool, or other device (including browsers, spiders, robots, avatars, agents, or the like) to navigate or search this Website other than with the search engines that may be available from SAFE ESTEEM and other commercially available third-party web browsers (e.g., Google Chrome and Microsoft Internet Explorer); or</Typography>
                    <Typography variant="body1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (vi) attempt to decipher, decompile, disassemble, or reverse engineer any of the software underlying the Website.</Typography>
                    <Typography variant="body1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (vii) attempt to delete or modify links or keywords in the meta-data of this Website.</Typography>
                    <Typography variant="body1">3. &nbsp;Users are prohibited from attempting to violate the security of the Website, including:</Typography>
                    <Typography variant="body1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (i) accessing data not intended for Users or logging into a server or account that Users are not authorized to access;</Typography>
                    <Typography variant="body1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (ii) attempting to probe, scan, or test the vulnerability of the Website or to breach security or authentication measures;</Typography>
                    <Typography variant="body1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (iii) attempting to interfere with the service of any user, host or network, including submitting a virus to, overloading, "flooding", "spamming", "mail bombing," or "crashing" the Website;</Typography>
                    <Typography variant="body1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (iv) sending unsolicited e-mail, including promotions and/or advertising of products or services; or</Typography>
                    <Typography variant="body1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (v) forging any TCP/IP packet header or any part of the header information in any e-mail or posting.</Typography>
                    <Typography variant="body1">F. Violations. SAFE ESTEEM will investigate any violation and will cooperate with law enforcement authorities in prosecuting Users involved in such violations.</Typography>
                    <Typography variant="body1">G. Operation of Website. SAFE ESTEEM reserves the right to terminate Users ability to communicate with, or access the Website at any time. SAFE ESTEEM will take reasonable efforts not to disclose any information Users send SAFE ESTEEM via the Website. SAFE ESTEEM may disclose any information to the extent necessary to satisfy any applicable law, regulation, legal process or governmental request. SAFE ESTEEM may edit, refuse to post, or to remove any information or materials from the Website or Server provided by the User.</Typography>
                    <Typography variant="body1">H. Accessibility. SAFE ESTEEM shall not be responsible for any delays or interruptions of, errors, or omissions contained in the Website. Although SAFE ESTEEM will use reasonable efforts to make the Website and log in accessible, SAFE ESTEEM makes no representation, warranty or covenant that the Website will be available at any specific time since various circumstances may prevent or delay availability. SAFE ESTEEM may discontinue or modify the Website, may change or eliminate any transmission method, and may change transmission speeds or other signal characteristics. SAFE ESTEEM shall not be responsible for any loss, cost, damage or liability that may result in such events.</Typography>
                    <Typography variant="subtitle1">II. RESPONSIBILITY FOR CONTENT.</Typography>
                    <Typography variant="body1">1. &nbsp;Website. Safety condition are ever-changing. It is for those reasons that SAFE ESTEEM makes no representations, warranties or guarantees regarding the truthfulness, accuracy, or reliability of any information that is communicated to and from this Website. SAFE ESTEEM shall not be responsible for any use that is or is not made of the Website.</Typography>
                    <Typography variant="body1">2. &nbsp;Downloads. SAFE ESTEEM does not guarantee that data made available on the Website will be free of infection or viruses, worms, Trojan horses, or other code that manifests contaminating or destructive properties. It is Users responsibility for implementing sufficient safeguards and procedures to ensure that any such data is free from such problems.</Typography>

                    <Typography variant="subtitle1">III. RIGHTS TO USE.</Typography>
                    <Typography variant="body1">A. User Information. For any information or media provided by User in connection with use of the Service (collectively, the "User Information"), SAFE ESTEEM has all rights necessary to use the User Information to contact the User or the entity the User represents.</Typography>
                    <Typography variant="body1">B. License. Upon submission of User Information to the Website, User grants SAFE ESTEEM a right to use the information of User to contact User for marketing purposes, providing such contact does not violate the rules of the Florida Bar.</Typography>
                    <Typography variant="body1">1. &nbsp;Intellectual Property. SAFE ESTEEM owns all right, title and interest in and to all copyright, trademarks, service mark, patent, trade secret or other intellectual property and proprietary rights in and to the Website to the fullest extent provided under U.S. and international law. Users shall not remove, conceal or alter any copyright notice, information, disclaimer, restriction, or other notice on the Website. Users shall not use or permit any third party to use the name, trademarks, trade names, or trade dress of SAFE ESTEEM without the written consent of SAFE ESTEEM.<br/></Typography>
                    <Typography variant="body1">2. &nbsp;Trademarks.&nbsp; User acknowledges that no trade or service marks are being conveyed under this Agreement. <br/></Typography>
                    <Typography variant="body1">3. &nbsp;Third Party Rights. If Users believe any content appearing on the Website infringes another party’s rights, please notify us of this infringement.</Typography>
                    <Typography variant="subtitle1">IV. REGISTRATION, PASSWORD, USER IDENTITY.</Typography>
                    <Typography variant="body1">A. User Identity. When registering, Users will create an identity ("User Identity"). The User Identity will include certain information, including the industry in which Users operate. The information provided may not be treated as confidential.</Typography>
                    <Typography variant="body1">B. Passwords and Access. Users shall keep confidential the User Identity, registration identification, and password(s). Users shall immediately notify SAFE ESTEEM if Users learn of or suspect: (i) any loss or theft of a User Identity, registration identification or password, or (ii) any unauthorized use of Users registration identification or password or of the Website. In such an event, SAFE ESTEEM may impose additional security obligations on Users.</Typography>
                    <Typography variant="body1">C. Security Breaches. If any unauthorized person obtains access to the Website as a result of any act or omission by a User, that User shall use his best efforts to ascertain the source and manner of acquisition and shall promptly notify SAFE ESTEEM. Users shall cooperate and assist in any investigation relating to any such unauthorized access.</Typography>
                    <Typography variant="body1">D. Inactivity. SAFE ESTEEM will maintain and grant Users access for a period of time subject to its discretion.</Typography>
                    <Typography variant="subtitle1">V. PRIVACY.</Typography>
                    <Typography variant="body1">There are two categories of information we receive from you at our Website—personal information you may affirmatively provide us (eg. signing up for our marketing information) and information our Website collects on an aggregate basis as you browse our site. Information that you intentionally provide us is given the same treatment as if you contacted us by phone or mail. As for information we collect on an aggregate basis during your browsing, such information is tracked via standard commercially available means, such as “cookies” and web server logs and it is our policy not to disclose or contact you based upon the personal identifying information that may emerge from such monitoring of our site. Such privacy policy may be changed from time to time at SAFE ESTEEM's sole discretion. SAFE ESTEEM does not assume any responsibility or liability for any information gained from our Website via illegal means such as hacking or by any other malicious act.</Typography>
                    <Typography variant="subtitle1">VI. DISCLAIMERS; LIMITATIONS OF LIABILITY.</Typography>
                    <Typography variant="body1">A. Release from Claims. User releases SAFE ESTEEM from claims, demands, and damages of every kind and nature arising out of or in any way connected with information provided at this Website.</Typography>
                    <Typography variant="body1">B. Disclaimer of Warranties. SAFE ESTEEM provides this Website "AS IS." SAFE ESTEEM MAKES NO REPRESENTATION OR WARRANTY OF ANY KIND RELATING IN ANY WAY TO THE WEBSITE, INCLUDING ANY USER IDENTITY OR CONTENT. SAFE ESTEEM DISCLAIMS TO THE MAXIMUM EXTENT PERMITTED BY LAW, ANY AND ALL SUCH REPRESENTATIONS AND WARRANTIES. SAFE ESTEEM SPECIFICALLY DISCLAIMS TO THE MAXIMUM EXTENT PERMITTED BY LAW ANY AND ALL (i) WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, (ii) WARRANTIES AGAINST INFRINGEMENT OF ANY THIRD PARTY INTELLECTUAL PROPERTY OR PROPRIETARY RIGHTS, (iii) WARRANTIES RELATING TO DELAYS, INTERRUPTIONS, ERRORS, OR OMISSIONS IN THE WEBSITE, OR ANY PART THEREOF, (iv) WARRANTIES RELATING TO THE TRANSMISSION OR DELIVERY OF THE WEBSITE, AND (v) WARRANTIES OTHERWISE RELATING TO DATA, INFORMATION, PERFORMANCE, NONPERFORMANCE, OR OTHER ACTS OR OMISSIONS BY SAFE ESTEEM OR ANY THIRD PARTY. FURTHER, SAFE ESTEEM DOES NOT WARRANT THAT THE WEBSITE OR THE TECHNOLOGY OR INTELLECTUAL PROPERTY DESCRIBED IN THE WEBSITE WILL MEET USERS NEEDS OR REQUIREMENTS OR THE NEEDS OR REQUIREMENTS OF ANY OTHER PERSON OR ENTITY.</Typography>
                    <Typography variant="body1">C. Limitation of Remedy. NEITHER SAFE ESTEEM NOR ANY THIRD PARTY SHALL BE LIABLE TO USERS OR ANY OTHER PERSON FOR ANY REMEDY, LIABILITY, DAMAGES, COSTS, OR LOSSES RELATING TO THE WEBSITE OR ANY CONTENT, WHETHER ARISING UNDER THESE TERMS OR ANY APPLICABLE LAW.</Typography>
                    <Typography variant="body1">D. Exclusion of Consequential Damages. IN NO EVENT SHALL SAFE ESTEEM OR ANY THIRD PARTY BE LIABLE TO USERS OR ANY OTHER PERSON FOR CONSEQUENTIAL, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE, OR INDIRECT DAMAGES (INCLUDING LOST PROFITS, LOSS OF BUSINESS OR DATA, BUSINESS INTERRUPTION, TRADING LOSSES, AND DAMAGES THAT RESULT FROM INACCURACY OF THE INFORMATION OR INCONVENIENCE, DELAY, OR LOSS OF THE USE OF THE SERVICE) ARISING UNDER OR IN ANY WAY RELATED TO THE WEBSITE OR ANY OTHER CONTENT, EVEN IF SAFE ESTEEM OR ANY THIRD PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR LOSSES.</Typography>
                    <Typography variant="subtitle1">VII. INDEMNIFICATION.</Typography>
                    <Typography variant="body1">Users agree to defend, indemnify and hold harmless SAFE ESTEEM and its subsidiaries, affiliates, officers, agents, partners and employees from all liabilities or claims of any third party arising out of Users violation of this Agreement.</Typography>
                    <Typography variant="subtitle1">VIII. LINKED CONTENT.</Typography>
                    <Typography variant="body1">This Website may contain links to third party Websites or other services (the "Linked Content"). The Linked Content is not under the control of SAFE ESTEEM and SAFE ESTEEM therefore is not responsible for the Linked Content. SAFE ESTEEM provides Linked Content to Users only as a convenience and the inclusion of such Linked Content is not an endorsement by SAFE ESTEEM. If Users decide to access any Linked Content, Users do so at their own risk.</Typography>
                    <Typography variant="subtitle1">IX. E-MAIL COMMUNICATIONS.</Typography>
                    <Typography variant="body1">SAFE ESTEEM will generally use email to communicate with User. By using the Website, Users agree to receive communications from SAFE ESTEEM and its agents.</Typography>
                    <Typography variant="subtitle1">X. MISCELLANEOUS</Typography>
                    <Typography variant="body1">If any provision of this Agreement is held by a court of competent jurisdiction to be invalid, void, or unenforceable, then such provision shall be deemed null and void, but the remaining provisions shall continue in full force without being impaired or invalidated in any way. The language of this Agreement shall be deemed to be the language mutually chosen by the parties and no rule of strict construction shall be applied against or in favor of either party hereto. The section headings and other headings contained in this Agreement were selected for convenience and shall not affect the meaning or interpretation of this Agreement. This Agreement shall be governed by the substantive law of the State of Florida, without reference to any choice of law rules that would result in the application of the substantive law of any other jurisdiction. The parties agree that any disputes arising out of or relating to this Agreement shall be submitted to mediation and then to the federal courts having jurisdiction in Hillsborough County, Florida, or state courts having jurisdiction in the area in which such federal courts have jurisdiction, and the parties consent to the personal jurisdiction of such courts in respect of such disputes. Users may not assign or otherwise transfer (by operation of law or otherwise) any of Users rights or duties hereunder unless SAFE ESTEEM agrees in writing after receiving prior written notice. Any attempted assignment or other transfer without the requisite consent shall be null and void ab initio. SAFE ESTEEM may assign or otherwise transfer any of its rights or the performance of any of its duties. The waiver by SAFE ESTEEM of a breach or a violation of any provision of these Terms shall not operate as or be construed to be a waiver of any subsequent breach or violation of any provision of these Terms.</Typography>
                    <Typography variant="body1">Any rights not expressly granted herein are reserved by SAFE ESTEEM. Safe-esteem © 2019.</Typography>
                </Dialog>
            </Background>
        );
    }
}

export default Terms;
