import React, {Component} from 'react';
import * as d3 from 'd3';

import {homeIconSvg, destinationIconPath} from '../../features/Report/components/SvgFactory';

class RelativeRiskChart extends Component {
    chart = null;
    chartInited = false;
    svg = null;

    initChart() {
        if (this.chart && !this.chartInited) {
            this.chartInited = true;
            const {width, height} = this.props;

            const svg = d3.select(this.chart);
            svg
            // .attr("width", width)
            // .attr("height", height);
            .attr("viewBox", "0 0 140 140");
            this.svg = svg;

            this.initDefs();
            this.initPie();
        }
    }

    initDefs() {
        const svg = this.svg;
        if (svg) {
            const defs = svg.append("defs");
            const maskDestination = defs.append('mask')
                .attr('id', 'mask-destination')
            maskDestination.append('rect')
                    .attr('x', -50)
                    .attr('y', -50)
                    .attr('width', 100)
                    .attr('height', 200)
                    .attr('fill', 'white')
            maskDestination.append('circle')
                    .attr('cx', 9.5)
                    .attr('cy', 9)
                    .attr('r', 4)
                    .attr('fill', 'black');
        }
    }

    initPie() {
        const svg = this.svg;
        if (svg) {
            const {data, pieOuterRadius, pieInnerRadius, color, height} = this.props;
            const path = d3.arc()
                .outerRadius(pieOuterRadius)
                .innerRadius(pieInnerRadius);
            const pie = svg.append('g')
                .attr('class', 'relative-risk-pie')
                .attr('transform', `translate(${pieOuterRadius},${(height - pieOuterRadius * 2) / 2 + pieOuterRadius})`);
            const dataAdopted = [data.crime, data.accidents, data.health];

            const pieAdapter = d3.pie()
                .value((d) => (d))
                .sort(null)
                .endAngle(Math.PI * 2);
            const colors = [color.crime, color.accidents, color.health];

            pie.append('circle')
                .attr('cx', 0)
                .attr('cy', 0)
                .attr('r', pieOuterRadius * 0.8)
                .attr('fill', color.cover);

            pie.selectAll('path')
                .data(pieAdapter(dataAdopted))
                .enter()
                .append('path')
                    .attr('d', path)
                    .attr("fill", (d, i) => (colors[i]));

            this.addCenterPoint(pie);
        }
    }

    addCenterPoint(pie) {
        if (pie) {
            const {color, centerIcon} = this.props;
            pie.append('g')
                    .attr('transform', `translate(-10, -12) scale(1.3125)`)
                .append(() => (
                    centerIcon({fill: color.centerIcon})
                ));
        }
    }
    render() {
        return (
            <svg className="pie-chart" ref={(ref) => { this.chart = ref; this.initChart(); }} />
        );
    }
}

RelativeRiskChart.defaultProps = {
    width: 140,
    height: 140,
    pieOuterRadius: 70,
    pieInnerRadius: 35,
    color: {
        health: 'rgb(255, 203, 0)',
        crime: 'rgb(255, 0, 0)',
        accidents: 'rgb(126, 242, 243)',
        cover: '#FFFFFF',
        centerIcon: '#233544',
    },
    data: {
        health: 53,
        crime: 24,
        accidents: 23,
    },
    centerIcon: destinationIconPath
};

export default RelativeRiskChart;
