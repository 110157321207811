import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import AwesomeDebouncePromise from 'awesome-debounce-promise';

import { api } from '../../../sagas';

import TravelReport from '../components/TravelReport';

import styles from './ReportsPDF.jss';

class ReportsPDF extends PureComponent {
	static propTypes = {
		classes: PropTypes.object,
	};

	state = {
		loading: false,
		total: 0,
		last: {
			content: [],
		},
		search: {
			focused: false,
			text: '',
			content: [],
			total: 0,
			page: 0,
			size: 16,
			pages: 0,
			last: false,
		},
		share: {
			pending: false,
			showDialog: false,
			uuid: null,
			password: null,
		},
		showReport: false,
		reportData: null,
	};

	debouncedSearch = AwesomeDebouncePromise(() => {
		this.loadReports();
	}, 700);

	componentDidMount() {
		this.loadReports();
	}

	loadReports() {
		const { uuid } = this.props.match.params;
		const {
			loading,
			search: { content, page, size, pages },
		} = this.state;
		if (!loading && (pages === 0 || page < pages)) {
			this.setState({ loading: true });
			api.getTestReport(uuid).then((response) => {
				if (response.ok) {
					this.setState({
						reportData: { ...response.data },
						showReport: true,
					});
				}
				this.setState({ loading: false });
			});
		}
	}

	renderReport() {
		const { classes } = this.props;
		const { showReport, reportData } = this.state;
		const { uuid } = this.props.match.params;

		if (showReport && reportData) {
			return (
				<React.Fragment key={uuid}>
					<div key='report' className={classes.overlay}>
						<div className={classes.reportContainer}>
							<div className={classes.reportContent}>
								<TravelReport
									data={reportData}
									onClickShare={this.handleClickShare}
									clickDashboardLabel='BACK'
									hideShare={false}
									onClickPdf={this.handleClickPdf}
									onClickDashboard={this.handleClickDashboard}
								/>
							</div>
						</div>
					</div>
				</React.Fragment>
			);
		}

		return null;
	}

	render() {
		return [this.renderReport()];
	}
}

export default withStyles(styles)(withRouter(ReportsPDF));
