const styles = (theme) => ({
	panel: {
		zIndex: 100,
		minWidth: 298,
		width: 298,
		minHeight: 204,
		marginTop: -10,
		marginLeft: 10,
		position: 'relative',
	},
	container: {
		padding: 0,
	},
	header: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		borderBottom: `1px solid ${theme.palette.primary.dark}`,
		padding: '13px 22px',
	},
	enabledRequestOnDragMap: {
		width: '100%',
		padding: '13px 22px',
		boxSizing: 'border-box',
	},
	form: {
		borderBottom: `1px solid ${theme.palette.primary.dark}`,
	},
	actionRow: {
		padding: '13px 45px 13px 22px',
		position: 'relative',
		'&:hover': {
			backgroundColor: theme.palette.primary.dark,
		},
		'& > svg': {
			display: 'none',
		},
		'&:hover > svg': {
			display: 'inline-block',
		},
	},
	editAction: {
		position: 'absolute',
		right: 22,
		top: 11,
		width: 23,
		height: 23,
		cursor: 'pointer',
		stroke: theme.palette.primary.light,
		'&:hover': {
			stroke: theme.palette.secondary.main,
		},
	},
	footer: {
		padding: '13px 22px',
	},
	link: {
		display: 'block',
		marginBottom: 5,
		cursor: 'pointer',
	},
	formPanel: {
		minWidth: 282,
		width: 282,
		minHeight: 204,
		position: 'relative',
		margin: 8,
	},

	'@media screen and (max-width: 767px)': {
		panel: {
			marginLeft: 0,
			width: 'calc(100% - 60px)',
			minWidth: 'auto',
		},
	},
});

export default styles;
