import {
    takeEvery, takeLatest, all, put,
} from 'redux-saga/effects';

import Api from '../api';

import {GeocodingTypes} from '../store/GeocodingRedux';
import {searchPlaces} from './GeocodingSagas';

import {UserTypes} from '../store/UserRedux';
import {userLogin, userLogout, userDetails, userForgotPassword} from './UserSagas';

import {DictionaryTypes} from '../store/DictionaryRedux';
import {getCountries, getRaces, getGenders, getModifiers} from './DictionarySagas';

import {BoundaryTypes} from '../store/BoundaryRedux';
import {boundariesList} from './BoundarySagas';

import {PersonTypes} from '../store/PersonRedux';
import {getPersons} from './PersonSagas';

import {ReportTypes} from '../store/ReportRedux';
import {reportRiskTravel} from './ReportSagas';

export const api = Api.create();

function* watchdog(action) {
    const {status} = action;
    if (status === 401) {
        yield put(UserTypes.USER_LOGOUT);
    }
}

export default function* root() {
    yield all([
        takeEvery('*', watchdog),
        takeLatest(GeocodingTypes.SEARCH_PLACES_REQUEST, searchPlaces, api),
        takeLatest(UserTypes.USER_LOGIN_REQUEST, userLogin, api),
        takeLatest(UserTypes.USER_LOGOUT, userLogout, api),
        takeLatest(UserTypes.USER_DETAILS_REQUEST, userDetails, api),
        takeLatest(UserTypes.USER_FORGOT_PASSWORD_REQUEST, userForgotPassword, api),

        takeLatest(DictionaryTypes.GET_COUNTRIES_REQUEST, getCountries, api),
        takeLatest(DictionaryTypes.GET_RACES_REQUEST, getRaces, api),
        takeLatest(DictionaryTypes.GET_GENDERS_REQUEST, getGenders, api),
        takeLatest(DictionaryTypes.GET_MODIFIERS_REQUEST, getModifiers, api),

        takeLatest(PersonTypes.GET_PERSONS_REQUEST, getPersons, api),

        takeLatest(BoundaryTypes.BOUNDARY_LIST_REQUEST, boundariesList, api),

        takeLatest(ReportTypes.REPORT_RISK_TRAVEL_REQUEST, reportRiskTravel, api),
    ]);
}
