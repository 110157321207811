import React, {PureComponent} from 'react';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {Typography} from '@material-ui/core';
import {Elements, ElementsConsumer, CardElement} from '@stripe/react-stripe-js';
import get from 'get-value';
import {connect} from 'react-redux';

import {api} from '../../../sagas';
import {stripePromise} from '../../../App';
import {adaptCardFromApi} from '../../../store/UserRedux';
import {DarkButton, LightButton} from '../../../components';
import {userPropertiesSelector} from '../../../store/selectors';

import styles from './CardForm.jss';

export const THEME = {
    DARK: 1,
    LIGHT: 2,
};

const CARD_ELEMENT_OPTIONS_DARK = {
    hidePostalCode: true,
    style: {
      base: {
        color: "#fff",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
};

const CARD_ELEMENT_OPTIONS_LIGHT = {
    hidePostalCode: true,
    style: {
      base: {
        color: "#233544",
        fontSize: "16px",
        "::placeholder": {
          color: "#7D8C97",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
};

class CardForm extends PureComponent {
    static propTypes = {
        classes: PropTypes.object,
        theme: PropTypes.number,
        user: PropTypes.object,
        onLoading: PropTypes.func,
        buttonLarge: PropTypes.bool,
        onCardConfirm: PropTypes.func,
    };

    static defaultProps = {
        theme: THEME.DARK,
        buttonLarge: false,
    };

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            bankCard: null,
            stripeReady: false,
            stripeError: null,
            stripeResult: null,
            initBankCard: null,
        };    
    }

    componentDidMount() {
        this.loadBankCard();
    }

    get stripeErrorMessage() {
        const {stripeError} = this.state;
        return get(stripeError, 'message', {default: null});
    }

    get bankCardExist() {
        const {bankCard} = this.state;
        return bankCard && get(bankCard, 'last4', {default: ''}) !== '';
    }

    beginCall() {
        const {onLoading} = this.props;
        if (onLoading) onLoading(true);
        this.setState({loading: true});
    }

    endCall() {
        const {onLoading} = this.props;
        if (onLoading) onLoading(false);
        this.setState({loading: false});
    }

    loadBankCard() {
        const {user} = this.props;
        if (user.billable) {
            this.beginCall();
            api.getBankCard().then((response) => {
                if (response.ok) {
                    if (response.data) {
                        this.setState({
                            bankCard: adaptCardFromApi(get(response, 'data', {default: {}})),
                        });
                    } else {
                        this.setState({bankCard: null});
                    }
                }
                this.endCall();
            });
        }
    }

    handleCardSubmit = (event, stripe, elements) => {
        const {user, onCardConfirm} = this.props;
        event.preventDefault();
        if (stripe && elements) {
            this.beginCall();
            api.initBankCard().then((response) => {
                if (response.ok) {
                    const id = get(response, 'data.id');
                    const clientSecret = get(response, 'data.clientSecret');
                    if (id && clientSecret) {
                        this.setState({initBankCard: true});
                        this.beginCall();
                        stripe.confirmCardSetup(clientSecret, {
                            payment_method: {
                                card: elements.getElement(CardElement),
                                billing_details: {
                                    name: user.name,
                                },
                            }
                        }).then((stripeResponse) => {
                            if (stripeResponse.error) {
                                this.setState({stripeError: get(stripeResponse, 'error')});
                            } else {
                                this.setState({
                                    stripeError: null,
                                    stripeResult: get(stripeResponse, 'setupIntent'),
                                });
                                if (onCardConfirm) onCardConfirm(stripeResponse);
                            }
                            this.loadBankCard();
                        });
                    } else {
                        this.setState({initBankCard: false});
                        this.endCall();
                    }
                }
                else this.endCall();
            });
        }
    };

    handleCardDelete = (event) => {
        const {bankCard} = this.state;
        const paymentMethodId = get(bankCard, 'paymentMethodId');
        if (paymentMethodId) {
            this.beginCall();
            api.deleteBankCard(paymentMethodId)
                .then((response) => {
                    if (response.ok) {
                        this.setState({
                            bankCard: null
                        });
                    }
                    this.endCall();
                });
        }
    };

    renderCard() {
        if (this.bankCardExist) {
            const {theme, buttonLarge} = this.props;
            const {bankCard: {brand, expMonth, expYear, last4}} = this.state;
            return [
                <div key="0" className="bank-card">
                    <span>{brand ? brand.toUpperCase() : ''}</span>
                    <span>{`**** **** **** ${last4}`}</span>
                    <span>{`${expMonth}/${expYear}`}</span>
                </div>,
                <div key="1" className="submit">
                    {theme === THEME.LIGHT
                        ? (
                            <DarkButton
                                small={!buttonLarge}
                                onClick={this.handleCardDelete}
                            >
                                DELETE CARD
                            </DarkButton>
                        )
                        : (
                            <LightButton
                                small={!buttonLarge}
                                onClick={this.handleCardDelete}
                            >
                                DELETE CARD
                            </LightButton>
                        )
                    }
                </div>
            ];
        }
        return null;
    }

    renderCardForm() {
        const {user, theme, buttonLarge} = this.props;
        const {stripeReady, initBankCard, bankCard} = this.state;
        if (user.billable && !bankCard && !this.bankCardExist) {
            const cardElementOptions = (theme === THEME.LIGHT) ? CARD_ELEMENT_OPTIONS_LIGHT : CARD_ELEMENT_OPTIONS_DARK;
            return (
                <Elements stripe={stripePromise}>
                    <ElementsConsumer>
                        {({stripe, elements}) => {
                            return (
                                <form onSubmit={this.handleCardSubmit}>
                                    <CardElement
                                        options={cardElementOptions}
                                        onReady={() => { this.setState({stripeReady: true}); }}
                                    />
                                    {this.stripeErrorMessage
                                        && (
                                            <div className="stripe-error">
                                                <Typography color="error">
                                                    {this.stripeErrorMessage}
                                                </Typography>
                                            </div>
                                        )
                                    }
                                    {initBankCard === false
                                        && (
                                            <div className="stripe-error">
                                                <Typography color="error">
                                                    Data has not been received from server.
                                                </Typography>
                                            </div>
                                        )
                                    }
                                    <div className="submit">
                                        {theme === THEME.LIGHT
                                            ? (
                                                <DarkButton
                                                    small={!buttonLarge}
                                                    onClick={(event) => this.handleCardSubmit(event, stripe, elements)}
                                                    disabled={!stripeReady}
                                                >
                                                    ADD CARD
                                                </DarkButton>
                                            )
                                            : (
                                                <LightButton
                                                    small={!buttonLarge}
                                                    onClick={(event) => this.handleCardSubmit(event, stripe, elements)}
                                                    disabled={!stripeReady}
                                                >
                                                    ADD CARD
                                                </LightButton>
                                            )
                                        }
                                    </div>
                                </form>
                            );
                        }}
                    </ElementsConsumer>
                </Elements>
            );
        } else if (!user.billable) {
            return <Typography color="textSecondary">This account is not billable</Typography>;
        }
        return null;
    }

    render() {
        const {classes, theme} = this.props;
        const containerClass = (theme === THEME.LIGHT) ? classes.containerLight : classes.container;
        return (
            <div className={containerClass}>
                {this.renderCard()}
                {this.renderCardForm()}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: userPropertiesSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CardForm));
