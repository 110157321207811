import {put, call} from 'redux-saga/effects';
import PersonActions, {PERSON_PENDING} from '../store/PersonRedux';
import NetworkActions from '../store/NetworkRedux';

export function* getPersons(api, action) {
    const pending = PERSON_PENDING.getPersons;

    yield put(NetworkActions.networkAddPending(pending));
    const response = yield call(() => api.getPersons());

    if (response.ok) {
        yield put(PersonActions.getPersonsSuccess(response.data, response.headers));
        yield put(NetworkActions.networkDelPending(pending));
    } else {
        yield put(PersonActions.getPersonsFailure(response));
        yield put(NetworkActions.networkDelPending(pending));
    }

    return response;
}
