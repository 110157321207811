import React from 'react';

import { useState } from 'react';
import { AccordionData } from './AccordionData';
import cn from '../../../../lib/classNames';

import AccordionCustom from '../../../../components/AccordionCustom';

import styles from './Disclaimer.module.scss';

export default function Disclaimer(props) {
	const [expanded, setExpanded] = useState('panel-1');

	const { className, classNameTerms } = props;

	const renderAccordions = () => {
		return AccordionData.map((e, idx) => {
			const num = idx + 1;
			const panel = `panel-${num}`;
			return (
				<div className={styles['accordion-item']} key={panel}>
					<AccordionCustom
						panel={panel}
						panelContent={`panel-content-${num}`}
						expanded={expanded}
						headerText={e.headerContent}
						setExpanded={setExpanded}
					>
						{e.bodyContent}
					</AccordionCustom>
				</div>
			);
		});
	};

	return (
		<>
			<section className={cn(styles['description'], classNameTerms)}>
				<p>
					The AI-generated Travel Risk Brief provided by Safe-xplore is designed
					to offer users a synthesis of travel risk information, advisories, and
					general guidance to assist in planning for safer travel experiences.
					This information includes, but is not limited to, government-sourced
					travel risk data, local emergency contact numbers, recommendations for
					medical facilities, and neighborhood risk assessments for cities
					globally.
				</p>
			</section>
			<section className={cn(styles['disclaimer-wrap-inner'], className)}>
				{renderAccordions()}
			</section>
			<section className={cn(styles['terms'], classNameTerms)}>
				<strong>
					By using the Safe-xplore app and its AI-generated Travel Risk Brief
					feature, users acknowledge and agree to these limitations and assume
					full responsibility for the use of the information provided. Further,
					the use of the Safe-xplore app is subject to the Safe-esteem terms and
					conditions outlined in our{' '}
					<a href='https://www.safe-esteem.com/terms-of-use' target='_blank'>
						Terms of Use
					</a>{' '}
					and{' '}
					<a href='https://www.safe-esteem.com/eula' target='_blank'>
						End User License Agreement (EULA)
					</a>
					. Users are encouraged to review these documents in detail as they
					contain important information regarding your rights and obligations in
					relation to the use of Safe-xplore and any Safe-esteem application.
				</strong>
			</section>
		</>
	);
}
