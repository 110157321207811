import html2canvas from 'html2canvas';

import LoaderIcon from '../../images/loader_1.svg';

const converter = function (element, options = {}) {
	const context = {};
	const defaultOptions = {
		logging: true,
		allowTaint: false,
		scrollX: 0,
		scrollY: 0,
	};
	context.element = element;
	context.options = {
		...defaultOptions,
		...options,
	};

	function adjustSvgs(el) {
		const svgArr = el.querySelectorAll('svg');
		const arr = [];

		svgArr.forEach((e) => {
			if (!e.getAttribute('width')) {
				const elWidth = e.clientWidth;
				const elHeight = e.clientHeight;

				e.setAttribute('width', elWidth);
				e.setAttribute('height', elHeight);

				arr.push(e);
			}
		});
		return arr;
	}

	function clone(el) {
		// get size the container
		const containerWidth = el.offsetWidth;
		const containerHeight = el.offsetHeight;

		// creating cloneBox
		const cloneBox = document.createElement('div');
		cloneBox.setAttribute('class', 'cloneBox');
		cloneBox.setAttribute('width', containerWidth);
		cloneBox.setAttribute('height', containerHeight);
		cloneBox.style.width = `${containerWidth}px`;
		cloneBox.style.height = `${containerHeight}px`;
		cloneBox.style.position = 'fixed';
		cloneBox.style.top = 0;
		cloneBox.style.left = 0;
		cloneBox.style.margin = 0;
		cloneBox.style.padding = 0;
		// cloneBox.style.zIndex = -1;

		// clone the container
		const cloneElement = el.cloneNode(true);

		// set size the cloneElement
		cloneElement.setAttribute('width', containerWidth);
		cloneElement.setAttribute('height', containerHeight);
		cloneElement.style.width = `${containerWidth}px`;
		cloneElement.style.height = `${containerHeight}px`;
		cloneElement.style.marginTop = 0;

		// get a props the canvas

		const mapCanvasArr = el.querySelectorAll('.mapboxgl-canvas');

		const cloneCanvasToImg = (mapCanvas, idx) => {
			const mapCanvasWidth = mapCanvas.clientWidth;
			const mapCanvasHeight = mapCanvas.clientHeight;

			const cloneCanvasContainer = cloneElement.getElementsByClassName(
				'mapboxgl-canvas-container'
			)[idx];

			const cloneMapCanvas =
				cloneCanvasContainer.getElementsByClassName('mapboxgl-canvas')[0];

			const img = new Image();
			img.setAttribute('width', mapCanvasWidth);
			img.setAttribute('height', mapCanvasHeight);
			img.style.width = `${mapCanvasWidth}px`;
			img.style.height = `${mapCanvasHeight}px`;
			img.src = mapCanvas.toDataURL();

			cloneMapCanvas.remove();
			cloneCanvasContainer.appendChild(img);
		};

		// replace canvas to img

		if (mapCanvasArr.length > 0) {
			mapCanvasArr.forEach((e, idx) => {
				cloneCanvasToImg(e, idx);
			});
		}

		// insert the cloneElement to the cloneBox
		cloneBox.append(cloneElement);

		// adding the cloneBox to the page
		document.body.appendChild(cloneBox);

		return cloneBox;
	}

	// create a loader image

	const loaderImg = document.createElement('img');
	loaderImg.setAttribute('src', LoaderIcon);
	loaderImg.style.cssText =
		'animation: rotating 1.4s linear infinite; width: 137px; height: 137px;';

	// create a loader container

	const loader = document.createElement('div');
	loader.setAttribute('class', 'downloadLoader');
	loader.style.cssText =
		'position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: rgba(0, 0, 0, .9); z-index: 10; display: flex; justify-content: center; align-items: center;';

	// insert the loader image to the loader container

	loader.appendChild(loaderImg);

	return new Promise((resolve, reject) => {
		document.body.appendChild(loader);
		const cloned = clone(context.element);
		adjustSvgs(cloned);
		html2canvas(cloned, context.options).then((canvas) => {
			resolve(canvas);

			cloned.remove();

			loader.remove();
		});
	});
};

export default converter;
