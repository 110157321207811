const styles = (theme) => ({
	container: {
		minHeight: '100vh',
		display: 'flex',
		flex: '1 1 auto',
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		paddingLeft: 10,
		backgroundColor: '#F2F2F2',

		'@media screen and (max-width: 1079px)': {
			paddingLeft: 20,
			paddingRight: 20,
		},
	},
	panel: {
		minWidth: 298,
		width: 298,
		zIndex: 100,
		marginTop: -10,
		marginLeft: 10,
		position: 'relative',
	},
	panelReport: {
		minWidth: 800,
		minHeight: 600,
		width: 800,
		zIndex: 100,
		marginTop: -10,
		marginLeft: 10,
		position: 'relative',
		padding: 10,
	},
	header: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: '13px 22px',
	},
	formPanel: {
		minWidth: 282,
		width: 282,
		minHeight: 344,
		position: 'relative',
		margin: 8,
		padding: 20,
		boxSizing: 'border-box',
	},
	overlay: {
		position: 'absolute',
		left: 0,
		top: 0,
		bottom: 0,
		right: 0,
		width: '100%',
		backgroundColor: 'rgba(0,0,0,0.5)',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		'& > *': {
			width: '50%',
		},
		zIndex: 999,
	},
	content: {
		maxWidth: 1020,
		width: '100%',
		marginLeft: 'auto',
		marginRight: 'auto',
	},

	shareLinkPsw: {
		'& .share-link': {
			color: theme.palette.common.grey,
		},

		'& .btn-copy': {
			cursor: 'pointer',
		},
	},

	'@media screen and (max-width: 767px)': {
		// formPanel: {
		//     width: '100%',
		// },
		container: {
			paddingLeft: 10,
			paddingRight: 10,
		},
	},
});

export default styles;
