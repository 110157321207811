import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Icon from 'react-svg-icon';

import { ROUTE } from '../../../App';
import {
    Panel, LightButton, Checkbox,
} from '../../../components';
import {
    userPropertiesSelector,
    userIsAdmin,
} from '../../../store/selectors';
import UserActions from '../../../store/UserRedux';
import { api } from '../../../sagas';

import EditPassword from './EditPassword';
import EditEmail from './EditEmail';
import EditProperties from './EditProperties';
import styles from './MyAccount.jss';

class MyAccount extends PureComponent {
    static propTypes = {
        user: PropTypes.object.isRequired,
        userLogout: PropTypes.func,
        getDetails: PropTypes.func,
    };

    state = {
        editEmail: false,
        editPassword: false,
        editProperties: false,
    };

    handleOnClickLogOut = () => {
        this.props.userLogout();
    }

    handleToggleEditEmail = () => {
        const { editEmail } = this.state;
        this.setState({ editEmail: !editEmail });
    }

    handleToggleEditPassword = () => {
        const { editPassword } = this.state;
        this.setState({ editPassword: !editPassword });
    }

    handleToggleEditProperties = () => {
        const { editProperties } = this.state;
        this.setState({ editProperties: !editProperties });
    }

    handleOnSave = (person) => {
        const { getDetails } = this.props;
        getDetails();
    }

    getContent() {
        const {
            classes, user, userEnableRequestOnDragMap, isAdmin
        } = this.props;

        return (
            <div className={classes.container}>
                <div className={classes.header}>
                    <Typography variant="h4" color="textSecondary">My Account</Typography>
                    <LightButton
                        small
                        fullWidth={false}
                        onClick={this.handleOnClickLogOut}
                    >
                        Log Out
                    </LightButton>
                </div>
                <div className={classes.form}>
                    <div className={classes.actionRow} onClick={this.handleToggleEditEmail}>
                        <Typography variant="button" color="textSecondary">{user.email}</Typography>
                        <Icon name="svg-i-edit" className={classes.editAction} />
                    </div>
                    <div className={classes.actionRow} onClick={this.handleToggleEditPassword}>
                        <Typography variant="button" color="textSecondary">• • • • • • • • • • •</Typography>
                        <Icon name="svg-i-edit" className={classes.editAction} />
                    </div>
                    {isAdmin ? (
                        <div className={classes.enabledRequestOnDragMap}>
                            <Checkbox
                                label='Enable map scrolling'
                                onChange={() => {
                                    userEnableRequestOnDragMap(!user.enabledRequestOnDragMap);
                                }
                                }
                                checked={user.enabledRequestOnDragMap}
                                light
                            />
                        </div>
                    ) : ''}
                </div>
                <div className={classes.footer}>
                    <Link to={ROUTE.terms} className={classes.link}>
                        <Typography variant="body2" color="textPrimary">Terms & Conditions</Typography>
                    </Link>
                    <Link to={ROUTE.eula} className={classes.link}>
                        <Typography variant="body2" color="textPrimary">User License Agreement</Typography>
                    </Link>
                    <Link to={ROUTE.privacy} className={classes.link}>
                        <Typography variant="body2" color="textPrimary">Privacy Policy</Typography>
                    </Link>
                </div>
            </div>
        );
    }

    render() {
        const { classes, user } = this.props;
        const { editPassword, editEmail, editProperties } = this.state;
        return (
            <Panel className={classes.panel}>
                {!editEmail && !editPassword && !editProperties && this.getContent()}
                {editPassword &&
                    <EditPassword
                        onClose={this.handleToggleEditPassword}
                    />
                }
                {editEmail &&
                    <EditEmail
                        onClose={this.handleToggleEditEmail}
                    />
                }
                {editProperties &&
                    <EditProperties
                        user={user}
                        onClose={this.handleToggleEditProperties}
                        onSave={this.handleOnSave}
                        addEndpoint={(id, data) => api.changeProperties(data)}
                        updateEndpoint={(id, data) => api.changeProperties(data)}
                    />
                }
            </Panel>
        );
    }
}

const mapStateToProps = (state) => ({
    user: userPropertiesSelector(state),
    isAdmin: userIsAdmin(state),
});

const mapDispatchToProps = (dispatch) => ({
    userLogout: () => dispatch(UserActions.userLogout()),
    getDetails: () => dispatch(UserActions.userDetailsRequest()),
    userEnableRequestOnDragMap: (data) => dispatch(UserActions.userEnableRequestOnDragMap(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MyAccount));
