import React from 'react';
import { Link } from 'react-router-dom';

import cn from '../../../lib/classNames';

import styles from './LinkBox.module.scss';

// type LinkTargetType = '_blank' | '_self' | '_parent' | '_top' | 'framename';

// type LinkType = {
// 	elementType?: 'navigate' | 'link';
// 	iconBefore?: string;
// 	children: ReactNode;
// 	iconAfter?: string;
// 	className?: string;
// 	onClick?: () => void;
// 	variant?: 'filled' | 'text';
// 	color?: 'primary' | 'secondary';
// 	sizeWidth?: 'content' | 'full';
// 	href: string;
// 	target?: LinkTargetType;
// };

export default function LinkBox(props) {
	const {
		elementType = 'link',
		iconBefore,
		children,
		iconAfter,
		className,
		onClick,
		variant = 'text',
		color = 'primary',
		sizeWidth = 'content',
		href,
		target = '_blank',
	} = props;

	const renderIconBefore = !!iconBefore && (
		<span
			className={cn(
				styles['btn-icon'],
				'btn-icon',
				'btn-icon-before',
				'material-icons'
			)}
		>
			{iconBefore}
		</span>
	);

	const renderIconAfter = !!iconAfter && (
		<span
			className={cn(
				styles['btn-icon'],
				'btn-icon',
				'btn-icon-after',
				'material-icons'
			)}
		>
			{iconAfter}
		</span>
	);

	const renderContent = (
		<>
			{renderIconBefore}
			<span className='link-text'>{children}</span>
			{renderIconAfter}
		</>
	);

	return (
		<div
			onClick={onClick}
			className={cn(
				styles['link-box'],
				styles[`variant-${variant}`],
				styles[`color-${color}`],
				{ [styles['size-width-full']]: sizeWidth === 'full' },
				className
			)}
		>
			{elementType === 'link' ? (
				<a
					href={href}
					target={target}
					rel='noopener noreferrer'
					className={styles.link}
				>
					{renderContent}
				</a>
			) : (
				<Link to={href} className={styles.link}>
					{renderContent}
				</Link>
			)}
		</div>
	);
}
