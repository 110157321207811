import {createReducer, createActions} from 'reduxsauce';
import Immutable from 'seamless-immutable';

const {Types, Creators} = createActions({
    getPersonsRequest: null,
    getPersonsSuccess: ['data'],
    getPersonsFailure: ['data'],
});

export const PersonTypes = Types;
export default Creators;

export const PERSON_PENDING = {
    getPersons: 'getPersons',
};

export const INITIAL_STATE = Immutable({
    list: {
        error: false,
        items: [],    
    },
});

// persons
export const adaptPersonsResponse = (data) => {
    if (data && data.length) {
        return data.map((d, idx) => ({
            id: d.id,
            email: d.email,
            name: d.name,
            group: d.group,
            enabled: d.enabled,
            createdAt: d.createdAt,
            updatedAt: d.updatedAt,
            age: d.age,
            gender: d.gender, // M, F
            city: null,
            locationName: d.locationName,
            longitude: d.locationX,
            latitude: d.locationY,
            locationMapboxId: d.locationMapboxId,
        }));
    }
    return [];
};
export const getPersonsRequest = (state, action) => {
    return {
        ...state,
        list: {
            error: true,
            items: [],
        }
    };
};
export const getPersonsSuccess = (state, action) => {
    const {data} = action;
    const items = adaptPersonsResponse(data);
    return {
        ...state,
        list: {
            error: false,
            items,
        }
    };
};
export const getPersonsFailure = (state, action) => {
    return {
        ...state,
        list: {error: true}
    };
};

export const reducer = createReducer(INITIAL_STATE, {
    [Types.GET_PERSONS_REQUEST]: getPersonsRequest,
    [Types.GET_PERSONS_SUCCESS]: getPersonsSuccess,
    [Types.GET_PERSONS_FAILURE]: getPersonsFailure,
});
