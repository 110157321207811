
const styles = theme => ({
    select: {
        backgroundColor: "transparent",
        paddingLeft: 20,

        "&:focus": {
            backgroundColor: "transparent",
        }
    },

    selectContainerOpened: {
        letterSpacing: 0.436364,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",

        "& div": {
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: 200,
        }
    },

    selectContainer: {
        display: "flex",
        alignItems: "center",
    },

    formControl: {
        "& > div:hover:before, & > div:before, & > div:hover:after, & > div:after": {
            border: 0 + "!important",
        }
    },

    selectTitle: {
        fontSize: "1.142857rem",
        color: theme.palette.primary.dark,
        lineHeight: "20px",
    },

    selectSubTitlePrefix: {
        fontSize: "0.857142rem",
        letterSpacing: 0.436364,
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: 194,
    },

    iconArrow: {
        width: "1em",
        height: "1em",
        fontFamily: "'Material Icons'",
        fontSize: 24,
        color: "rgb(125, 140, 151)",
        lineHeight: 1,
        position: "absolute",
        top: "50%",
        right: 0,
        transform: "translateY(-50%)",
        "z-index": -1,
    },
});

export default styles;
