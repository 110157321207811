import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

import styles from './IconButton.jss';

const CIconButton = (props) => {
    const {classes, children, ...rest} = props;
    return (
        <IconButton
            color="secondary"
            className={classes.button}
            {...rest}
            classes={{
                root: classes.root,
            }}
        >
            {children}
        </IconButton>
    );
};

export default withStyles(styles)(CIconButton);
