import React from 'react';

import logotype from '../../images/logotype_dark.svg';
import logotypeSmall from '../../images/logotype_small.svg';

const LogotypeDark = (props) => {
	const { small = false } = props;

	return (
		<img
			width='auto'
			height={48}
			src={small ? logotypeSmall : logotype}
			alt='SE Logotype'
		/>
	);
};

export default LogotypeDark;
