import RiskScoreDecrease from './RiskScoreDecrease';

class RiskScoreNoChange extends RiskScoreDecrease {
    getArrowPath2() {
        return 'M0 6.21 L19.33 6.21 M6.165 1.21 L13.165 1.21 M5.165 11.21 L14.165 11.21Z';
    }

    addArrow() {
        const svg = this.svg;
        if (svg) {
            const { width, color, label } = this.props;

            const alh = 2;
            const aw = 15;
            const tx1 = width / 2 - aw / 2;

            svg.append('g')
                .attr('transform', `translate(${tx1}, 4)`)
                .append('path')
                .attr('d', this.getArrowPath2())
                .attr('fill', 'none')
                .attr('stroke-width', alh)
                .attr('stroke-linecap', 'round')
                .attr('stroke', color.line);

            svg.append('text')
                .attr('x', width / 2)
                .attr('y', 47)
                .text(`${label}`)
                .attr('font-size', '11px')
                .style('text-anchor', 'middle')
                .attr('fill', color.label);
        }
    }
}

RiskScoreNoChange.defaultProps = {
    width: 210,
    height: 150,
    radius: 16,
    lineWidth: 2,
    color: {
        line: '#7D8C97',
        text: '#233544',
    },
};

export default RiskScoreNoChange;
