const styles = (theme) => ({
	panel: {
		zIndex: 100,
		minWidth: 714,
		width: 715,
		minHeight: 204,
		marginTop: -10,
		marginLeft: 20,
		position: 'relative',
	},
	container: {
		padding: 0,
	},
	availableInfo: {
		height: 26,
		backgroundColor: '#7D8C97',
		'& .total': {
			backgroundColor: '#fff',
			width: 219,
			height: 26,
			paddingLeft: 20,
			'& p': {
				color: theme.palette.primary.dark,
				lineHeight: '26px',
			},
		},
	},
	searchPanel: {
		margin: '20px 20px 0 20px',
		display: 'flex',
		flex: 0,
		flexDirection: 'row',
		alignItems: 'flex-end',
		'& .title': {
			width: 264,
			fontSize: 26,
			color: '#fff',
			marginBottom: -4,
		},
		'& .search': {
			flex: 1,
			alignSelf: 'stretch',
			'& input': {
				flex: 1,
			},
			'& .total': {
				width: 120,
				textAlign: 'right',
			},
		},
	},
	searchIcon: {
		width: 15,
		height: 15,
		stroke: '#fff',
		marginRight: 8,
	},

	overlay: {
		position: 'absolute',
		left: 0,
		top: 0,
		bottom: 0,
		right: 0,
		backgroundColor: 'rgba(0,0,0,0.5)',
		zIndex: 999,
	},

	reportContainer: {
		minHeight: '100vh',
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
		alignItems: 'flex-start',
		justifyContent: 'flex-start',
		backgroundColor: '#F2F2F2',
	},

	reportContent: {
		maxWidth: 1020,
		width: '100%',
		marginLeft: 'auto',
		marginRight: 'auto',
		backgroundColor: '#F2F2F2',

		'@media screen and (max-width: 1079px)': {
			maxWidth: '100vw',
			padding: '0 20px',
			overflow: 'hidden',
		},
	},
	empty: {
		padding: '10px 20px',
		'& .long': {
			width: '30%',
			height: 13,
			backgroundColor: 'rgba(200, 200, 200, 0.25)',
			marginBottom: 5,
		},
		'& .short': {
			width: '20%',
			height: 13,
			backgroundColor: 'rgba(200, 200, 200, 0.2)',
		},
	},

	'@media screen and (max-width: 767px)': {
		panel: {
			width: 'calc(100% - 60px)',
			minWidth: 'auto',
			margin: '0 0 35px',
		},

		searchPanel: {
			flexDirection: 'column-reverse',
			alignItems: 'flex-start',

			'& .title': {
				marginTop: 10,
			},
		},
	},
});

export default styles;
