
const styles = (theme) => ({
    root: {
        paddingTop: 0,
    },

    item: {
        marginBottom: 10,

        "& label": {
            display: "flex",
            alignItems: "flex-start",
        },
    },
});

export default styles;
