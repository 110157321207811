const icon = {
    display: "inline-block",
    width: 28,
    height: 28,
    cursor: "pointer"
};

const styles = theme => ({
    panel: {
        zIndex: 100,
        minWidth: 338,
        width: 338,
        minHeight: 154,
        marginTop: -10,
        marginLeft: 10,
        position: "relative"
    },
    panelFull: {
        zIndex: 100,
        minWidth: 676,
        width: 676,
        minHeight: 154,
        marginTop: -10,
        marginLeft: 10,
        position: "relative"
    },
    container: {
        padding: "0 0 20px 0",
        display: "flex",
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start"
    },
    container1: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "stretch",
        alignSelf: "stretch",
        margin: 20,
    },
    containerOutlinedButton: {
        marginTop: "auto",

        '& .btn-cancel-mobile': {
            display: 'none',
            marginTop: 20,
        },
    },
    container2: {
        display: "flex",
        flex: 1,
        flexDirection: "column",
        justifyContent: "flex-start",
        alignSelf: "stretch",
        alignItems: "stretch",
        margin: 20,
    },
    container2Header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
    },
    actions: {
        position: "absolute",
        right: 0,
        top: -32
    },
    collapse: {
        ...icon
    },
    expand: {
        ...icon
    },
    close: {
        ...icon
    },
    person: {
        display: "flex",
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
        marginBottom: 20,
        backgroundColor: theme.palette.common.white,
        borderRadius: 13,
        paddingRight: 20,
        position: "relative",
        minHeight: 72,
    },
    personRowShadows: {
        position: "relative"
    },
    personShadow1: {
        position: "absolute",
        left: "12%",
        width: "76%",
        height: 70,
        backgroundColor: "#B5BEC5",
        borderRadius: 13,
        bottom: 4,
        opacity: 0.5
    },
    personShadow2: {
        position: "absolute",
        left: "9%",
        width: "82%",
        height: 70,
        backgroundColor: "#B5BEC5",
        borderRadius: 13,
        bottom: 10
    },

    personSelect: {
        display: "flex",
        alignItems: "center",

        "& svg": {
            ...icon,
            width: 22,
            height: 21,
            marginRight: 10,
        }
    },

    personIcon: {
        ...icon,
        width: 22,
        height: 21,
        marginRight: 10,
    },
    pin: {
        ...icon,
        width: 18,
        height: 25,
        marginRight: 10
    },
    reportIcon: {
        ...icon,
        width: 22,
        height: 26,
        marginRight: 10
    },
    scaleCompactContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: 24
    },
    scaleContainer: {
        width: "100%"
    },
    scaleCompact: {
        width: 80
    },
    modifiers: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        flex: 0,
        alignSelf: "stretch",
        position: "relative",
        overflow: "hidden",
        maxHeight: 270,

        '&:before': {
            display: "block",
            content: '""',
            position: "absolute",
            width: "100%",
            height: "100%",
            background: "rgba(72, 92, 107, 0.8)",
            paddingTop: 15,
            top: 0,
            "z-index": 10,
        }
    },
    modifierGroup: {
        "&:not(:first-child)": {
            marginTop: 15
        }
    },
    modifierGroupTitle: {
        marginTop: 0
    },
    percentage: {
        display: "flex",
        width: "100%",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        "& p": {
            marginTop: 0
        },
        "& > p": {
            marginRight: 20
        },
        "&:not(:first-child)": {
            marginTop: 10
        }
    },
    scrollable: {
        paddingLeft: 20,
        paddingRight: 20,
        marginBottom: 20,
        borderBottomLeftRadius: theme.shape.borderRadius
    },
    autocomplete: {
        display: "flex",
        flex: 1,
        position: "relative",
        "z-index": 100,
    },
    actionsLeft: {
        position: "absolute",
        left: 0,
        top: -32
    },
    withPerson: {
        height: 28,
        backgroundColor: "rgba(51,75,93,0.65)",
        borderRadius: 14,
        fontSize: "0.95rem",
        listStyle: "none",
        lineHeight: "2rem",
        padding: "0px 10px 0px 35px",
        "&:after": {}
    },
    withPersonIcon: {
        ...icon,
        width: 18,
        height: 17,
        position: "absolute",
        left: 15,
        top: 8
    },

    titleH2: {
        fontSize: 16,
        color: theme.palette.common.white,
        marginBottom: 5,
    },

    modifiersTitleH2: {
        marginTop: 10,
    },

    location: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: 10,
        borderRadius: 13,
        padding: "6px 20px",
        position: "relative",

        "& input": {
            color: theme.palette.common.grey + "!important",
        },
    },
    locationEmpty: {
        backgroundColor: theme.palette.common.white,
    },
    locationEntered: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,

        "& div": {
            backgroundColor: theme.palette.primary.dark
        },
    },
    '@media screen and (max-width: 767px)': {
        panel: {
            marginLeft: 0,
            marginBottom: 25,
            width: 'calc(100% - 60px)',
            minWidth: 'auto',
        },

        panelFull: {
            width: 'calc(100% - 60px)',
            minWidth: 'auto',
            margin: 0,
        },

        container: {
            flexDirection: 'column',
        },

        container1: {
            marginBottom: 25,
        },

        scrollable: {
            marginBottom: 0,
            padding: 0,
        },

        modifiers: {
            marginBottom: 25,
        },

        containerOutlinedButton: {
            '& .btn-cancel-mobile': {
                display: 'block',
            },

            '& .btn-cancel-desktop': {
                display: 'none',
            },
        },
    },
});

export default styles;
