import React, { PureComponent } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import { Authenicated, Map } from '../../../components';
import Config from '../../../config';
import { ROUTE } from '../../../App';
import pubsub, { EVENT } from '../../../lib/pubsub';

import { Home } from '../../Home';
import { Settings } from '../../Settings';
import { Persons } from '../../Persons';
import { AddCard } from '../../AddCard';
import NoSubscription from './NoSubscription';
import TextMessageException from './TextMessageException';

import personActions from '../../../store/PersonRedux';
import geocodingActions from '../../../store/GeocodingRedux';
import dictionaryActions from '../../../store/DictionaryRedux';
import { ERROR_TYPE } from '../../../store/UserRedux';

import {
	networkPendingSelector,
	mapIsShownSelector,
	userErrorSelector,
} from '../../../store/selectors';

import styles from './Layout.jss';

class Layout extends PureComponent {
	state = {
		textMessageException: false,
		noSubscriptionException: false,
		noBankCardException: false,
		classes: [],
		isMobile: false,
		isTablet: false,
		isDesktop: false,
	};

	events = [];

	componentDidMount() {
		const { getCountries, getRaces, getGenders, getPersons } = this.props;
		getCountries();
		getRaces();
		getGenders();
		getPersons();
		this.checkUserError();
		this.subscribe();
		window.addEventListener('resize', this.handleWindowSizeChange);
		this.handleWindowSizeChange();
	}

	componentDidUpdate() {
		this.checkUserError();
	}

	componentWillUnmount() {
		this.unsubscribe();
		window.removeEventListener('resize', this.handleWindowSizeChange);
	}

	handleWindowSizeChange = () => {
		const windowWidth = window.innerWidth;
		const isMobile = windowWidth < 768;
		const isTablet = windowWidth >= 768 && windowWidth < 1024;
		const isDesktop = windowWidth >= 1024;

		this.setState({
			isMobile,
			isTablet,
			isDesktop,
		});
	};

	subscribe() {
		this.events.push(
			pubsub.subscribe(EVENT.LAYOUT_ADD_CLASS, this.handleAddClassname)
		);
		this.events.push(
			pubsub.subscribe(EVENT.LAYOUT_DEL_CLASS, this.handleDelClassname)
		);
	}

	unsubscribe() {
		this.events.forEach((el) => {
			pubsub.unsubscribe(el);
		});
	}

	handleAddClassname = (className) => {
		const { classes } = this.state;
		this.setState({ classes: [...classes, className] });
	};

	handleDelClassname = (className) => {
		const { classes } = this.state;
		const found = classes.find((el) => el === className);
		if (found) {
			this.setState({ classes: classes.filter((el) => el !== className) });
		}
	};

	checkUserError() {
		const {
			userError: { errorType },
		} = this.props;
		if (errorType === ERROR_TYPE.NoSubscriptionException) {
			this.setState({
				noSubscriptionException: true,
				textMessageException: false,
				noBankCardException: false,
			});
		} else if (errorType === ERROR_TYPE.NoBankCardException) {
			this.setState({
				noBankCardException: true,
				noSubscriptionException: false,
				textMessageException: false,
			});
		} else if (errorType) {
			this.setState({
				textMessageException: true,
				noSubscriptionException: false,
				noBankCardException: false,
			});
		}
	}

	handleOnInitMap = (map) => {
		const { setMap } = this.props;
		setMap(map);
	};

	handleOnMoveEnd = () => {
		this.props.setMapMoved();
	};

	handleOnDragEnd = () => {
		this.props.setMapDragged();
	};

	handleOnZoomEnd = () => {
		this.props.setMapZoomed();
	};

	handleOnClickMap = ({ map, featureId, featureName, lngLat }) => {
		this.props.setMapClick({ map, featureId, featureName, lngLat });
	};

	render() {
		const {
			mapIsShown,
			location: { pathname },
			classes,
		} = this.props;
		const {
			noSubscriptionException,
			textMessageException,
			noBankCardException,
			classes: additionClasses,
			isMobile,
		} = this.state;
		return (
			<Authenicated
				className={`${classes.container} ${additionClasses.join(' ')}`}
			>
				{noSubscriptionException &&
					pathname !== ROUTE.noSubscriptionException && (
						<Redirect to={ROUTE.noSubscriptionException} />
					)}
				{noBankCardException && pathname !== ROUTE.addCreditCard && (
					<Redirect to={ROUTE.addCreditCard} />
				)}
				{textMessageException && pathname !== ROUTE.textMessageException && (
					<Redirect to={ROUTE.textMessageException} />
				)}
				{!isMobile &&
					!noSubscriptionException &&
					!textMessageException &&
					mapIsShown && (
						<Map
							accessToken={Config.mapbox.accessToken}
							mapStyle={Config.mapbox.style}
							onInit={this.handleOnInitMap}
							onMoveEnd={this.handleOnMoveEnd}
							onDragEnd={this.handleOnDragEnd}
							onZoomEnd={this.handleOnZoomEnd}
							onClick={this.handleOnClickMap}
						/>
					)}
				<Switch>
					<Route path={ROUTE.default} exact component={Home} />
					<Route path={ROUTE.persons} exact component={Persons} />
					<Route path={ROUTE.settings} exact component={Settings} />
					<Route path={ROUTE.addCreditCard} exact component={AddCard} />
					<Route
						path={ROUTE.noSubscriptionException}
						exact
						component={NoSubscription}
					/>
					<Route
						path={ROUTE.textMessageException}
						exact
						component={TextMessageException}
					/>
					<Route component={() => <div>404</div>} />
				</Switch>
			</Authenicated>
		);
	}
}

const mapStateToProps = (state) => ({
	networkPending: networkPendingSelector(state),
	mapIsShown: mapIsShownSelector(state),
	userError: userErrorSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
	setMap: (map) => dispatch(geocodingActions.setMap(map)),
	setMapMoved: () => dispatch(geocodingActions.setMapMoved()),
	setMapDragged: () => dispatch(geocodingActions.setMapDragged()),
	setMapZoomed: () => dispatch(geocodingActions.setMapZoomed()),
	setMapClick: (data) => dispatch(geocodingActions.setMapClick(data)),
	getCountries: () => dispatch(dictionaryActions.getCountriesRequest()),
	getRaces: () => dispatch(dictionaryActions.getRacesRequest()),
	getGenders: () => dispatch(dictionaryActions.getGendersRequest()),
	getPersons: () => dispatch(personActions.getPersonsRequest()),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(Layout));
