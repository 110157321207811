import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import {withStyles} from '@material-ui/core/styles';

import styles from './Checkbox.jss';

const CCheckbox = (props) => {
    const {classes, placeholder, checked, value, error = false, label, light, errorMessage = '', ...rest} = props;

    const lightClass = light ? classes.light : '';

    return (
        <FormControl
            fullWidth
            error={!!error}
        >
            <FormControlLabel
                control={
                    <Checkbox
                        checked={checked}
                        value={value}
                        color="secondary"
                        {...rest}

                        classes={{
                            root: classes.root,
                        }}
                    />
                }
                label={label}
                classes={{
                    label: `${classes.label} ${lightClass} `,
                }}
            />
            {error && <FormHelperText classes={{root: classes.errorRoot}}>{errorMessage}</FormHelperText>}
        </FormControl>
    );
};

export default withStyles(styles)(CCheckbox);
