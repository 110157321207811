import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {Typography} from '@material-ui/core';

import {ROUTE_LEFT_MENU} from '../../../App';
import {
    LeftMenu, LightButton, Panel, Confirm,
} from '../../../components';
import {
    personListItemsSelector, pendingGetPersons,
} from '../../../store/selectors';
import PersonsActions from '../../../store/PersonRedux';
import {api} from '../../../sagas';

import Search from '../components/Search';
import ListItem from '../components/ListItem';
import EditProperties from '../../Settings/components/EditProperties';

import styles from './Persons.jss';

class Persons extends PureComponent {
    state = {
        leftMenu: 'persons',
        search: '',
        editProperties: false,
        deleteConfirm: false,
        user: {},
        loading: true,
    };

    static propTypes = {
        pending: PropTypes.number.isRequired,
        persons: PropTypes.array.isRequired,
        getPersons: PropTypes.func.isRequired,
    };

    // static getDerivedStateFromProps(props, state) {
    //     if (state.loading && props.persons.length) {
    //         return {loading: false};
    //     } else if (!state.loading && !props.persons.length) {
    //         return {loading: true};
    //     }
    //     return null;
    // }

    componentDidMount() {
        // this.props.getPersons();
        setTimeout(() => {
            this.setState({loading: false});
        }, 500);
    }

    handleOnClickLeftMenu = (el) => {
        this.props.history.push(ROUTE_LEFT_MENU[el.name]);
    }

    handleOnClickAddNew = () => {
        this.setState({
            editProperties: true,
            user: {},
        });
    }

    handleOnSearch = (e) => {
        this.setState({search: e.target.value});
    }

    handleToggleEditProperties = () => {
        const {editProperties} = this.state;
        this.setState({editProperties: !editProperties});
    }

    handleToggleDeleteConfirm = () => {
        const {deleteConfirm} = this.state;
        this.setState({deleteConfirm: !deleteConfirm});
    }

    handleOnDelete = (person) => {
        this.setState({
            user: person,
        });
        this.handleToggleDeleteConfirm();
    }

    handleOnDeleteConfirm = () => {
        const {user} = this.state;
        if (user && user.id) {
            this.handleToggleDeleteConfirm();
            this.setState({loading: true});
            api.deletePerson(user.id)
                .then((response) => {
                    if (response.ok) {
                        this.props.getPersons();
                    }
                    this.setState({loading: false});
                })
                .catch(() => {
                    this.setState({loading: false});
                });
        } else {
            // TODO, show error
        }
    }

    handleOnEdit = (person) => {
        this.setState({
            editProperties: true,
            user: person,
        });
    }

    handleOnSave = (person) => {
        this.props.getPersons();
    }

    renderList() {
        const {persons} = this.props;
        const {search} = this.state;
        if (search) {
            return (
                persons
                    .filter((p) => p.name.toLowerCase().indexOf(search.toLowerCase()) >= 0)
                    .map((p, idx) => (
                        <ListItem
                            key={idx}
                            data={p}
                            onEdit={this.handleOnEdit}
                            onDelete={this.handleToggleDeleteConfirm}
                        />
                    ))
            );
        }
        return (
            persons.map((p, idx) => (
                <ListItem
                    key={idx}
                    data={p}
                    onEdit={this.handleOnEdit}
                    onDelete={this.handleOnDelete}
                />
            ))
        );
    }

    getContent() {
        const {classes} = this.props;
        return (
            <div className={classes.content}>
                <div className={classes.header}>
                    <div className={classes.headerLine}>
                        <Typography variant="h4" color="textSecondary">All Profiles</Typography>
                        <LightButton
                            small
                            fullWidth={false}
                            onClick={this.handleOnClickAddNew}
                            className={classes.item}
                        >
                            + Add
                        </LightButton>
                    </div>
                    <Search onChange={this.handleOnSearch} />
                </div>
                <ul className={`${classes.list} scrollbar1`}>
                    {this.renderList()}
                </ul>
            </div>
        );
    }

    render() {
        const {
            classes, pending,
        } = this.props;
        const {
            leftMenu, editProperties, user, loading, deleteConfirm,
        } = this.state;
        return (
            <div className={classes.container}>
                <LeftMenu
                    active={leftMenu}
                    onClick={this.handleOnClickLeftMenu}
                />
                <Panel className={classes.panel} loading={loading || pending > 0}>
                    {!editProperties && this.getContent()}
                    {editProperties &&
                        <EditProperties
                            user={user}
                            onClose={this.handleToggleEditProperties}
                            onSave={this.handleOnSave}
                            addEndpoint={(id, data) => api.addPerson(data)}
                            updateEndpoint={(id, data) => api.updatePerson(id, data)}
                        />
                    }
                    {deleteConfirm &&
                        <div className={classes.overlay}>
                            <Confirm
                                message="Are you sure you want to delete this person?"
                                cancel="Cancel"
                                onCancel={this.handleToggleDeleteConfirm}
                                confirm="Delete"
                                onConfirm={this.handleOnDeleteConfirm}
                            />
                        </div>
                    }
                </Panel>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    pending: pendingGetPersons(state),
    persons: personListItemsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    getPersons: () => dispatch(PersonsActions.getPersonsRequest()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Persons));
