import {createStore, applyMiddleware, compose} from 'redux';
import createSagaMiddleware from 'redux-saga';
import logger from 'redux-logger';

import Config from '../config';

export default (rootReducer, rootSaga) => {
    const middleware = [];
    const enhancers = [];

    const sagaMiddleware = createSagaMiddleware({});
    middleware.push(sagaMiddleware);
    if (Config.debug) middleware.push(logger);

    enhancers.push(applyMiddleware(...middleware));
    const store = createStore(rootReducer, compose(...enhancers));

    // kick off root saga
    const sagasManager = sagaMiddleware.run(rootSaga);

    return {
        store,
        sagasManager,
        sagaMiddleware,
    };
};
