import blobStream from 'blob-stream';
import moment from 'moment';
import SVGtoPDF from 'svg-to-pdfkit';
import get from 'get-value';

import { fromToDetailsToString } from '../../../helpers/report';
import { PDFDocumentWithTables as PDFDocument } from '../../../lib/pdf';
import { createSvg } from './SvgFactory';
import {RED, GREEN, YELLOW} from './TravelReport.jss';
import {DATE_FORMAT} from './TravelReport';

import logo from '../../../../images/logotype-pdf.png';
// import logo from '../../../../images/logotype_dark.png';

import {TAG, keyConceptsAndTerminology} from '../../../lib/constants';

const COLOR = {
    text: '#233544',
};

const FONT = {
    bold: 'Helvetica-Bold',
    normal: 'Helvetica',
};

const ratioSize = 595.28 / 1024;
const pageMargin = 100 * ratioSize;
const pagePadding = 20;

const checkPage = (props) => {
    const {doc, height} = props;
    if (doc.y + height + pagePadding * 2 >  doc.page.height) {
        doc.addPage({
            size: 'A4',
            margin: pageMargin,
        });    
    }
};

const renderHealthNotices = (props) => {
    const {doc} = props;
    const level1 = get(props, 'reportRiskTravel.data.cdcNotices.LEVEL_1', {default: []});
    const level2 = get(props, 'reportRiskTravel.data.cdcNotices.LEVEL_2', {default: []});
    const level3 = get(props, 'reportRiskTravel.data.cdcNotices.LEVEL_3', {default: []});

    const widthPage = doc.page.width;

    let y = doc.y;

    doc
        .fontSize(28 * ratioSize)
        .fillColor('#000')
        .text('Travel Health Notices');
    doc.moveDown(0.5);

    const padding = 10;
    const radius = 8;
    const contentWidth = widthPage - pageMargin * 2;

    doc.x += padding;

    y = doc.y - 8;
    let startY = y;

    if (level3.length) {
        doc.lineCap('round').roundedRect(pageMargin, y, contentWidth, 22, radius).fillAndStroke(RED, RED);
        doc.rect(pageMargin, y + radius, contentWidth, 22 - radius).fillAndStroke(RED, RED);

        doc
            .fontSize(16 * ratioSize)
            .fillColor('#fff')
            .text('Warning Level 3, Avoid Nonessential Travel');
        doc.moveDown(0.5);
        doc.y += 10;

        level3.forEach((el, idx) => {
            const title = get(el, 'title');
            const description = get(el, 'description');
            const url = get(el, 'sourceUrl');
            let date = get(el, 'datePublished');
            if (date) date = moment(date).format(DATE_FORMAT);
            const svg = get(props, `svgs.cdcNotices.level3.qr.${idx}`);

            if (idx > 0) {
                doc.lineWidth(1).moveTo(doc.x, doc.y).lineTo(contentWidth + pageMargin - padding, doc.y).stroke('#DEE2E5')
                doc.y += 10;
            }

            if (svg) {
                const svgSize = 41 * 1.3;
                SVGtoPDF(doc, svg, contentWidth + pageMargin - padding - svgSize, doc.y, {
                    width: svgSize,
                    height: svgSize,
                    preserveAspectRatio: 'slice',
                });
            }

            doc.fontSize(16 * ratioSize).fillColor('#000').text(title);
            doc.moveDown(0.3);

            doc.fontSize(12 * ratioSize).fillColor('#7D8C97').text(date);
            doc.moveDown(0.5);

            doc.fontSize(16 * ratioSize).fillColor('#000').text(description, {width: contentWidth - 100});
            doc.moveDown(0.3);

            doc.fontSize(16 * ratioSize).fillColor('#7D8C97').text(url);
            doc.moveDown(1.5);
        });

        const endY = doc.y;

        doc.lineCap('round').roundedRect(pageMargin, startY, contentWidth, endY - startY, 8).stroke(RED);

        doc.y += 20;
    }

    y = doc.y;
    startY = y;
    doc.y += 8;

    if (level2.length) {
        doc.lineCap('round').roundedRect(pageMargin, y, contentWidth, 22, radius).fillAndStroke(YELLOW, YELLOW);
        doc.rect(pageMargin, y + radius, contentWidth, 22 - radius).fillAndStroke(YELLOW, YELLOW);

        doc
            .fontSize(16 * ratioSize)
            .fillColor('#fff')
            .text('Alert Level 2, Practice Enhanced Precautions');
        doc.moveDown(0.5);
        doc.y += 10;

        level2.forEach((el, idx) => {
            const title = get(el, 'title');
            const description = get(el, 'description');
            const url = get(el, 'sourceUrl');
            let date = get(el, 'datePublished');
            if (date) date = moment(date).format(DATE_FORMAT);
            const svg = get(props, `svgs.cdcNotices.level2.qr.${idx}`);

            if (idx > 0) {
                doc.lineWidth(1).moveTo(doc.x, doc.y).lineTo(contentWidth + pageMargin - padding, doc.y).stroke('#DEE2E5')
                doc.y += 10;
            }

            if (svg) {
                const svgSize = 41 * 1.3;
                SVGtoPDF(doc, svg, contentWidth + pageMargin - padding - svgSize, doc.y, {
                    width: svgSize,
                    height: svgSize,
                    preserveAspectRatio: 'slice',
                });
            }

            doc.fontSize(16 * ratioSize).fillColor('#000').text(title);
            doc.moveDown(0.3);

            doc.fontSize(12 * ratioSize).fillColor('#7D8C97').text(date);
            doc.moveDown(0.5);

            doc.fontSize(16 * ratioSize).fillColor('#000').text(description, {width: contentWidth - 100});
            doc.moveDown(0.3);

            doc.fontSize(16 * ratioSize).fillColor('#7D8C97').text(url);
            doc.moveDown(1.5);
        });

        const endY = doc.y;

        doc.lineCap('round').roundedRect(pageMargin, startY, contentWidth, endY - startY, 8).stroke(YELLOW);

        doc.y += 20;
    }

    y = doc.y;
    startY = y;
    doc.y += 8;

    if (level1.length) {
        doc.lineCap('round').roundedRect(pageMargin, y, contentWidth, 22, radius).fillAndStroke(GREEN, GREEN);
        doc.rect(pageMargin, y + radius, contentWidth, 22 - radius).fillAndStroke(GREEN, GREEN);

        doc
            .fontSize(16 * ratioSize)
            .fillColor('#fff')
            .text('Watch Level 1, Practice Enhanced Precautions');
        doc.moveDown(0.5);
        doc.y += 10;

        level1.forEach((el, idx) => {
            const title = get(el, 'title');
            const description = get(el, 'description');
            const url = get(el, 'sourceUrl');
            let date = get(el, 'datePublished');
            if (date) date = moment(date).format(DATE_FORMAT);
            const svg = get(props, `svgs.cdcNotices.level1.qr.${idx}`);

            if (idx > 0) {
                doc.lineWidth(1).moveTo(doc.x, doc.y).lineTo(contentWidth + pageMargin - padding, doc.y).stroke('#DEE2E5')
                doc.y += 10;
            }

            if (svg) {
                const svgSize = 41 * 1.3;
                SVGtoPDF(doc, svg, contentWidth + pageMargin - padding - svgSize, doc.y, {
                    width: svgSize,
                    height: svgSize,
                    preserveAspectRatio: 'slice',
                });
            }

            doc.fontSize(16 * ratioSize).fillColor('#000').text(title);
            doc.moveDown(0.3);

            doc.fontSize(12 * ratioSize).fillColor('#7D8C97').text(date);
            doc.moveDown(0.5);

            doc.fontSize(16 * ratioSize).fillColor('#000').text(description, {width: contentWidth - 100});
            doc.moveDown(0.3);

            doc.fontSize(16 * ratioSize).fillColor('#7D8C97').text(url);
            doc.moveDown(1.5);
        });

        const endY = doc.y;

        doc.lineCap('round').roundedRect(pageMargin, startY, contentWidth, endY - startY, 8).stroke(GREEN);

        doc.y += 20;
    }
};

export const createTravelReport = (props) => {
    const { reportRiskTravel } = props;
    const {
        guidance, additionalRisks, fromToDetails,
    } = reportRiskTravel.data;
    const destinationLabel = fromToDetailsToString(fromToDetails);

    const doc = new PDFDocument({
        autoFirstPage: false,
        size: 'A4',
    });

    const stream = doc.pipe(blobStream());

    const date = moment().format('MMM DD YYYY');

    doc.addPage({
        size: 'A4',
        margin: pageMargin,
    });

    const widthPage = doc.page.width;

    doc.image(logo, doc.x, doc.y, { width: 156 * ratioSize });

    doc.moveDown();

    const personCompany = fromToDetails.personCompany

    doc.fontSize(26 * ratioSize).fillColor('#000000')
        .text('Personal Risk Scoring and Effects of Travel Report', doc.x, doc.y, {
            align: 'left'
        })
        .fontSize(16 * ratioSize).fillColor(COLOR.text)
        .text('Produced exclusively for ', {
            continued: true
        })
        .font(FONT.bold).text(personCompany, {continued: true})
        .font(FONT.normal)
        .text(' on ' + date);
    doc.moveDown();

    const nameVal_1 = fromToDetails.personName,
        countryVal_2 = fromToDetails.homeLevel0,
        countryVal_3 = fromToDetails.destinationLevel0,

        ageGroupVal_1 = fromToDetails.personAgeBucket,
        stateRegionVal_2 = fromToDetails.homeLevel1,
        stateRegionVal_3 = fromToDetails.destinationLevel1,

        maleVal_1 = fromToDetails.personGender,
        countyVal_2 = fromToDetails.homeLevel2,
        countyVal_3 = fromToDetails.destinationLevel2,

        cityVal_2 = fromToDetails.homeLevel3,
        cityVal_3 = fromToDetails.destinationLevel3;


    const tablePosStartX = doc.x;
    const tablePosStartY = doc.y;

    const iconTravellerData = document.getElementById('svg-i-traveller').innerHTML;
    const iconResidenceData = document.getElementById('svg-i-residence').innerHTML;
    const iconDestinationData = document.getElementById('svg-i-destination').innerHTML;

    let tableIconPositionX = tablePosStartX + pagePadding / 2;
    let tableIconPositionY = tablePosStartY + 19;

    const columnSpacing = 15;
    const tableWidth = widthPage - pageMargin * 2 - pagePadding / 2;

    SVGtoPDF(doc, createSvg({ svg: iconTravellerData, width: 19, height: 18 }), tableIconPositionX, tableIconPositionY, {
        width: 19 * ratioSize,
        height: 18 * ratioSize,
        preserveAspectRatio: 'slice',
    });

    tableIconPositionX += tableWidth / 3;

    SVGtoPDF(doc, createSvg({ svg: iconResidenceData, width: 21, height: 21 }), tableIconPositionX, tableIconPositionY, {
        width: 18 * ratioSize,
        height: 18 * ratioSize,
        preserveAspectRatio: 'slice',
    });

    tableIconPositionX += tableWidth / 3;
    tableIconPositionY -= 2;

    SVGtoPDF(doc, createSvg({ svg: iconDestinationData, width: 20, height: 25 }), tableIconPositionX, tableIconPositionY, {
        width: 20 * ratioSize,
        height: 25 * ratioSize,
        preserveAspectRatio: 'slice',
    });

    const table0 = {
        headers: ['     Traveler', '     Home', '     Destination'],
        rows: []
    };
    doc.table(table0, pageMargin + pagePadding / 2, doc.y + pagePadding, {
        width: tableWidth,
        prepareHeader: () => doc.font(FONT.normal).fontSize(12),
        columnSpacing,
    });

    const table1 = {
        headers: [' ', ' ', ' ', ' ', ' ', ' '],
        rows: [
            ['Name', nameVal_1, 'Country', countryVal_2, 'Country', countryVal_3],
            ['Age Group', ageGroupVal_1, 'State/Region', stateRegionVal_2, 'State/Region', stateRegionVal_3],
        ],
    };
    const row2 = ['Male/Female', maleVal_1];

    if (countyVal_2) {
        if (!cityVal_2) row2.push('County', countyVal_2);
        else row2.push('County/City', countyVal_2);
    } else row2.push('', '');

    if (countyVal_3) {
        if (!cityVal_3) row2.push('County', countyVal_3);
        else row2.push('County/City', countyVal_3);
    } else row2.push('', '');

    const row3 = ['', ''];

    if (cityVal_2) row3.push('City', cityVal_2);
    else row3.push('', '');

    if (cityVal_3) row3.push('City', cityVal_3);
    else row3.push('', '');

    table1.rows.push(row2, row3);

    doc.table(table1, pageMargin + pagePadding / 2, doc.y, {
        width: tableWidth,
        prepareRow: (row, i) => doc.font(FONT.normal).fontSize(9),
        columnSpacing,
    });

    const tablePosEndY = doc.y;
    const tablePaddingBtm = 5;
    const verticalLine = (widthPage - pageMargin * 2) / 3;

    //Table border
    doc.lineCap('round')
        .roundedRect(tablePosStartX, tablePosStartY, widthPage - pageMargin * 2, (tablePosEndY - tablePosStartY) + tablePaddingBtm, 12)
        .strokeColor('#E3E6ED')
        .stroke();

    //Column vertical border
    doc.lineCap('butt')
        .moveTo((tablePosStartX + verticalLine - columnSpacing / 2) + 3, tablePosStartY)
        .lineTo((tablePosStartX + verticalLine - columnSpacing / 2) + 3, tablePosEndY + tablePaddingBtm)
        .stroke();

    //Column vertical border
    doc.lineCap('butt')
        .moveTo((tablePosStartX + verticalLine * 2 - columnSpacing / 2) + 3, tablePosStartY)
        .lineTo((tablePosStartX + verticalLine * 2 - columnSpacing / 2) + 3, tablePosEndY + tablePaddingBtm)
        .stroke();

    doc
        .fontSize(28 * ratioSize)
        .fillColor('#000000')
        .text('Risk Scores Estimates', pageMargin, doc.y + 20, {
            align: 'left'
        })
        .fontSize(16 * ratioSize)
        .text('Percentage increase/decrease of underlying risk rates');
    doc.moveDown();

    //Crime Risk
    doc.fontSize(20 * ratioSize).fillColor('#7D8C97').text('Crime Risk');

    let x = pageMargin;
    let y = doc.y + 5;
    let lineY = y;
    
    doc.x = x;
    doc.y = y;
    SVGtoPDF(doc, createSvg(props.svgs.riskScore.crime.scoreChange), doc.x, doc.y, {
        width: props.svgs.riskScore.crime.scoreChange.width * ratioSize,
        height: props.svgs.riskScore.crime.scoreChange.height * ratioSize,
        preserveAspectRatio: 'slice',
    });

    doc.x += (props.svgs.riskScore.crime.scoreChange.width + 43) * ratioSize;
    doc.y += 20;
    SVGtoPDF(doc, createSvg(props.svgs.riskScore.crime.chartHome), doc.x, doc.y, {
        width: props.svgs.riskScore.crime.chartHome.width * ratioSize,
        height: props.svgs.riskScore.crime.chartHome.height * ratioSize,
        preserveAspectRatio: 'slice',
    });

    doc.y += (props.svgs.riskScore.crime.chartHome.height + 30) * ratioSize;
    SVGtoPDF(doc, createSvg(props.svgs.riskScore.crime.chartDestination), doc.x, doc.y, {
        width: props.svgs.riskScore.crime.chartDestination.width * ratioSize,
        height: props.svgs.riskScore.crime.chartDestination.height * ratioSize,
        preserveAspectRatio: 'slice',
    });

    doc.x += (props.svgs.riskScore.crime.chartDestination.width + 50) * ratioSize;
    doc.fontSize(16 * ratioSize).fillColor(COLOR.text).text('Crime Risk Drivers', doc.x, lineY);

    let spacerY = 20;
    props.svgs.riskScore.crime.contributors.forEach((el) => {
        SVGtoPDF(doc, createSvg(el), doc.x, lineY + spacerY, {
            width: el.width * ratioSize,
            height: el.height * ratioSize,
            preserveAspectRatio: 'slice',
        });
        spacerY += el.height * ratioSize + 17;
    });
    doc.x = pageMargin;
    doc.y = lineY + props.svgs.riskScore.crime.scoreChange.height * ratioSize;

    //Accidents Risk
    doc.y += 10;
    doc.fontSize(20 * ratioSize).fillColor('#7D8C97').text('Accidents Risk', doc.x, doc.y);

    doc.y += 5;
    lineY = doc.y;
    SVGtoPDF(doc, createSvg(props.svgs.riskScore.accidents.scoreChange), doc.x, doc.y, {
        width: props.svgs.riskScore.accidents.scoreChange.width * ratioSize,
        height: props.svgs.riskScore.accidents.scoreChange.height * ratioSize,
        preserveAspectRatio: 'slice',
    });

    doc.x += (props.svgs.riskScore.accidents.scoreChange.width + 43) * ratioSize;
    doc.y += 20;

    SVGtoPDF(doc, createSvg(props.svgs.riskScore.accidents.chartHome), doc.x, doc.y, {
        width: props.svgs.riskScore.accidents.chartHome.width * ratioSize,
        height: props.svgs.riskScore.accidents.chartHome.height * ratioSize,
        preserveAspectRatio: 'slice',
    });

    doc.y += (props.svgs.riskScore.accidents.chartHome.height + 30) * ratioSize;

    SVGtoPDF(doc, createSvg(props.svgs.riskScore.accidents.chartDestination), doc.x, doc.y, {
        width: props.svgs.riskScore.accidents.chartDestination.width * ratioSize,
        height: props.svgs.riskScore.accidents.chartDestination.height * ratioSize,
        preserveAspectRatio: 'slice',
    });

    doc.x += (props.svgs.riskScore.accidents.chartDestination.width + 50) * ratioSize;

    doc.fontSize(16 * ratioSize).fillColor(COLOR.text).text('Accidents Risk Drivers', doc.x, lineY);

    spacerY = 20;
    props.svgs.riskScore.accidents.contributors.forEach((el) => {
        SVGtoPDF(doc, createSvg(el), doc.x, lineY + spacerY, {
            width: el.width * ratioSize,
            height: el.height * ratioSize,
            preserveAspectRatio: 'slice',
        });
        spacerY += el.height * ratioSize + 17;
    });
    doc.x = pageMargin;
    doc.y = 10 + lineY + props.svgs.riskScore.accidents.scoreChange.height * ratioSize;

    //Health Risk
    doc.fontSize(20 * ratioSize).fillColor('#7D8C97').text('Health Risk', doc.x, doc.y);

    doc.y += 5;

    SVGtoPDF(doc, createSvg(props.svgs.riskScore.health.scoreChange), doc.x, doc.y, {
        width: props.svgs.riskScore.health.scoreChange.width * ratioSize,
        height: props.svgs.riskScore.health.scoreChange.height * ratioSize,
        preserveAspectRatio: 'slice',
    });

    doc.x += (props.svgs.riskScore.health.scoreChange.width + 50) * ratioSize;
    let column1X = doc.x;

    SVGtoPDF(doc, createSvg(props.svgs.riskScore.health.chartHome), doc.x, doc.y, {
        width: props.svgs.riskScore.health.chartHome.width * ratioSize,
        height: props.svgs.riskScore.health.chartHome.height * ratioSize,
        preserveAspectRatio: 'slice',
    });

    doc.x += (props.svgs.riskScore.health.chartHome.width + 70) * ratioSize;
    let column2X = doc.x;

    SVGtoPDF(doc, createSvg(props.svgs.riskScore.health.chartDestination), doc.x, doc.y, {
        width: props.svgs.riskScore.health.chartDestination.width * ratioSize,
        height: props.svgs.riskScore.health.chartDestination.height * ratioSize,
        preserveAspectRatio: 'slice',
    });

    doc.y += props.svgs.riskScore.health.chartDestination.height * ratioSize + 20;
    lineY = doc.y;

    doc.fontSize(16 * ratioSize).fillColor(COLOR.text).text('Non-communicable Drivers', column1X, lineY);
    doc.moveDown();

    spacerY = 0;
    props.svgs.riskScore.health.nonCommunicableContributors.forEach((el) => {
        SVGtoPDF(doc, createSvg(el), column1X, doc.y + spacerY, {
            width: el.width * ratioSize,
            height: el.height * ratioSize,
            preserveAspectRatio: 'slice',
        });
        spacerY += el.height * ratioSize + 10;
    });

    doc.fontSize(16 * ratioSize).fillColor(COLOR.text).text('Communicable Drivers', column2X, lineY);
    doc.moveDown();

    spacerY = 0;
    props.svgs.riskScore.health.contributors.forEach((el) => {
        SVGtoPDF(doc, createSvg(el), column2X, doc.y + spacerY, {
            width: el.width * ratioSize,
            height: el.height * ratioSize,
            preserveAspectRatio: 'slice',
        });
        spacerY += el.height * ratioSize + 10;
    });

    doc.x = pageMargin;
    doc.y += spacerY + 20;
    doc.moveDown();

    //New Page
    doc.addPage({
        size: 'A4',
        margin: pageMargin,
    });

    //Travel Health Notices
    renderHealthNotices({...props, doc});

    //Relative Risk Weighting
    doc.x = pageMargin;
    doc.fontSize(28 * ratioSize).fillColor('#000000').text('Relative Risk Weighting');
    doc.moveDown(0.3);
    doc.fontSize(16 * ratioSize).text('By risk domain');
    doc.moveDown(0.5);

    lineY = doc.y;
    let spacerX = (widthPage - (pageMargin * 2)) - props.svgs.riskWeighting.home.width * ratioSize - props.svgs.riskWeighting.destination.width * ratioSize;

    SVGtoPDF(doc, createSvg({ svg: iconResidenceData, width: 21, height: 21 }), doc.x, lineY, {
        width: 18 * ratioSize,
        height: 18 * ratioSize,
        preserveAspectRatio: 'slice',
    });

    doc.fontSize(20 * ratioSize).fillColor('#7D8C97').text('Home', doc.x + 15, lineY + 2);
    doc.x = pageMargin;

    SVGtoPDF(doc, createSvg(props.svgs.riskWeighting.home), doc.x, lineY + 22, {
        width: props.svgs.riskWeighting.home.width * ratioSize,
        height: props.svgs.riskWeighting.home.height * ratioSize,
        preserveAspectRatio: 'slice',
    });

    column1X = doc.x + props.svgs.riskWeighting.home.width * ratioSize + spacerX;

    SVGtoPDF(doc, createSvg({ svg: iconDestinationData, width: 20, height: 25 }), column1X, lineY, {
        width: 20 * ratioSize,
        height: 25 * ratioSize,
        preserveAspectRatio: 'slice',
    });

    doc.x += 15;
    doc.text('Travel Destination', column1X + 15, lineY + 2);
    doc.moveDown();
    doc.y += 10;

    SVGtoPDF(doc, createSvg(props.svgs.riskWeighting.destination), column1X, lineY + 22, {
        width: props.svgs.riskWeighting.destination.width * ratioSize,
        height: props.svgs.riskWeighting.destination.height * ratioSize,
        preserveAspectRatio: 'slice',
    });

    doc.y = lineY + props.svgs.riskWeighting.destination.height * ratioSize;

    //Crime Risk Score Reference
    doc.x = pageMargin;
    doc.y += props.svgs.riskWeighting.destination.height * ratioSize + 20;

    doc.fontSize(28 * ratioSize).fillColor('#000000').text('Crime Risk Score Reference');
    doc.moveDown(0.2);
    doc.fontSize(16 * ratioSize).text('Underlying incident/victimization rates per 100,000');
    doc.moveDown(0.5);

    checkPage({doc, height: props.svgs.riskChart.height * ratioSize});

    SVGtoPDF(doc, createSvg(props.svgs.riskChart), doc.x, doc.y, {
        width: props.svgs.riskChart.width * ratioSize,
        height: props.svgs.riskChart.height * ratioSize,
        preserveAspectRatio: 'slice',
        warningCallback: (e) => { console.log('==== riskChart', e); }
    });

    doc.y += props.svgs.riskChart.height * ratioSize;

    //Additional Risk Highlights & Indicators
    x = doc.x = pageMargin;
    doc.y += 20;

    checkPage({doc, height: 500});

    doc.fontSize(28 * ratioSize).fillColor('#000000').text('Additional Risk Highlights & Indicators for ');
    doc.moveDown(0.3);
    doc.font(FONT.bold).text(destinationLabel);
    doc.moveDown();

    y = doc.y;

    // const riskHighlightsIndicatorSpacer = 30 * ratioSize;
    // const riskHighlightsContainerWidth = (widthPage - pageMargin * 2 - (riskHighlightsIndicatorSpacer * 2)) / 3;
    // const riskHighlightsContainerHeight = 150 * ratioSize;
    // const riskHighlightsContainerPadding = 16 * ratioSize;
    // const riskHighlightsTextWidth = riskHighlightsContainerWidth - riskHighlightsContainerPadding * 2;
    // const indicatorCalcWidthToPos = (riskHighlightsContainerWidth - props.svgs.riskHighlightsIndicator[0].width * ratioSize) / 2;
    // const indicatorCalcHeightToPos = (riskHighlightsContainerHeight - props.svgs.riskHighlightsIndicator[0].height * ratioSize) / 2;

    // let riskHighlightsTextTop;
    // let riskHighlightsTextBtm;
    // let riskHighlightsTextPosX = x + riskHighlightsContainerPadding;
    // let riskHighlightsTextTopPosY = y + riskHighlightsContainerPadding;
    // let riskHighlightsTextBtmPosY = y + riskHighlightsContainerHeight - riskHighlightsContainerPadding;

    // let riskHighlightsIndicatorPosX = x + indicatorCalcWidthToPos;
    // let riskHighlightsIndicatorPosY = y + indicatorCalcHeightToPos;

    spacerY = 0;
    spacerX = 0;

    // props.svgs.riskHighlightsIndicator.forEach((el, index) => {

    //     riskHighlightsTextTop = get(additionalRisks[index], 'name');
    //     riskHighlightsTextBtm = get(additionalRisks[index], 'ratingDescription');

    //     if ((index % 3) === 0) {
    //         spacerX = 0;
    //         if (index > 0) {
    //             spacerY += riskHighlightsContainerHeight + riskHighlightsIndicatorSpacer;
    //         }
    //     } else {
    //         spacerX += riskHighlightsContainerWidth + riskHighlightsIndicatorSpacer;
    //     }

    //     doc.lineCap('round')
    //         .roundedRect(x + spacerX, y + spacerY, riskHighlightsContainerWidth, riskHighlightsContainerHeight, 12)
    //         .fillOpacity(0.4)
    //         .fill('#DEE2E5')
    //         .stroke();

    //     doc
    //         .font(FONT.normal)
    //         .fontSize(16 * ratioSize)
    //         .fillColor(COLOR.text)
    //         .fillOpacity(1)
    //         .text(riskHighlightsTextTop, riskHighlightsTextPosX + spacerX, riskHighlightsTextTopPosY + spacerY, {
    //             width: riskHighlightsTextWidth,
    //             align: 'center',
    //         })

    //     SVGtoPDF(doc, createSvg(el), riskHighlightsIndicatorPosX + spacerX, riskHighlightsIndicatorPosY + spacerY, {
    //         width: el.width * ratioSize,
    //         height: el.height * ratioSize,
    //         preserveAspectRatio: 'slice',
    //     });

    //     doc
    //         .font(FONT.bold)
    //         .fontSize(14 * ratioSize)
    //         .fillColor(COLOR.text)
    //         .text(riskHighlightsTextBtm, riskHighlightsTextPosX + spacerX, riskHighlightsTextBtmPosY + spacerY, {
    //             width: riskHighlightsTextWidth,
    //             align: 'center',
    //             baseline: 'bottom',
    //         })
    // });

    doc.moveDown();

    x = pageMargin;
    y = doc.y + pagePadding;

    doc
        .font(FONT.normal)
        .fontSize(26 * ratioSize)
        .fillColor('#000000')
        .text('How to use this report', x, y)

    doc.moveDown();

    let guidanceName;
    let guidanceDescription;
    let guidanceGuidance;


    guidance.forEach((el) => {
        guidanceName = el.name;
        guidanceDescription = el.description;
        guidanceGuidance = el.guidance;
        const keys = Object.keys(guidanceGuidance);

        doc
            .font(FONT.bold)
            .fontSize(20 * ratioSize)
            .fillColor(COLOR.text)
            .text(guidanceName)

        y = doc.y;

        doc
            .font(FONT.normal)
            .fontSize(16 * ratioSize)
            .fillColor(COLOR.text)
            .text(guidanceDescription, x, y + 5)

            let guidance;
            let colors = ['#387A2C', '#6AAD5A', '#F9CE38', '#E99597', '#EB3223'];

            keys.map((el) => {
                guidance = el + '. ' + guidanceGuidance[el];

                y = doc.y;

                let color = colors[+el-1];

                doc
                .lineCap('round')
                .roundedRect(x, y + 5, 10, 3, 5)
                .fillAndStroke(color)    
                .stroke()
                .font(FONT.normal)
                .fontSize(16 * ratioSize)
                .fillColor(COLOR.text)
                .text(guidance, x, y + 12)
             });            
        

        doc.moveDown();

        y = doc.y;

    });

    doc.addPage({
        size: 'A4',
        margin: pageMargin,
    });

    const keyConceptsAndTerminologyAdapted = [];
    keyConceptsAndTerminology.forEach((el) => {
        el.forEach((item) => {
            keyConceptsAndTerminologyAdapted.push(item); 
        });
    });

    keyConceptsAndTerminologyAdapted.forEach((el) => {
        if (el.t === TAG.h1) {
            doc.font(FONT.bold).fontSize(22 * ratioSize).fillColor(COLOR.text).text(el.v, {lineGap: 3, align: 'center'});
            doc.moveDown(0.6);
        } else if (el.t === TAG.b) {
            doc.font(FONT.bold).fontSize(16 * ratioSize).fillColor(COLOR.text).text(el.v, {lineGap: 3});
            doc.moveDown(0.6);
        } else if (el.t === TAG.li) {
            doc.font(FONT.normal).fontSize(15 * ratioSize).fillColor(COLOR.text).text(` - ${el.v}`, {lineGap: 3});
            doc.moveDown(0.6);
        } else {
            doc.font(FONT.normal).fontSize(15 * ratioSize).fillColor(COLOR.text).text(el.v, {lineGap: 3});
            doc.moveDown(0.6);
        }
    });

    doc.end();
    stream.on('finish', function () {
        const url = stream.toBlobURL('application/pdf');
        window.open(url);
    });
};
