import verticalDotted from '../../../../images/vertical_dotted_line.png';
import { Color } from '../../../components/Scale';

export const RED = '#FF6401';
export const YELLOW = '#FFB601';
export const GREEN = '#B5CF01';

const styles = (theme) => ({
	clear: {
		display: 'table',
		clear: 'both',
	},
	container: {
		overflow: 'auto',
		paddingBottom: 15,
		overflowX: 'hidden',

		'& h4': {
			color: theme.palette.common.black,
			fontSize: '1.857rem',
			lineHeight: 1.15,
			marginTop: 25,
			marginBottom: 8,
		},

		// '& h4.first-title': {
		//     marginTop: 106,
		// },

		'& h5': {
			color: theme.palette.primary.dark,
			fontSize: '1.143rem',
			lineHeight: 1.25,
		},

		'& .titleSection': {
			marginTop: 60,
			marginBottom: 25,

			'& h4': {
				fontSize: '1.45rem',
				color: theme.palette.primary.dark,
				lineHeight: 1.14,
				marginBottom: 3,
			},

			'& h5': {
				fontSize: '.93rem',
				color: theme.palette.common.grey,
				lineHeight: 1.12,
			},

			'&.titleViolentCrimeRiskIndex': {
				'& h5': {
					maxWidth: 470,
					lineHeight: 1.4,
				},
			},
		},

		'& .additionalRiskHighlights': {
			marginBottom: 5,

			'& .helpReportLink': {
				fontSize: '1.429rem',
				color: theme.palette.primary.light,
				lineHeight: 1.15,
				borderBottom: '1px dashed ',
				borderColor: theme.palette.primary.light,
				cursor: 'pointer',
			},
		},

		'& .subTitle': {
			fontSize: '1.429rem',
			color: theme.palette.primary.light,
			lineHeight: 1.15,
			margin: '25px 0 8px',

			'& span': {
				float: 'right',
				fontSize: '0.8571428571428571rem',
				paddingTop: 5,
			},
		},

		'@media print': {
			'& .share-container': {
				display: 'none !important',
			},
		},

		'& table': {
			color: theme.palette.primary.dark,
			width: '100%',
			borderRadius: 16,

			'& td': {
				padding: '0 20px 10px',
				fontSize: '0.857rem',
				color: theme.palette.primary.light,

				'& span': {
					display: 'inline-block',
					minWidth: 90,
				},

				'& b': {
					color: theme.palette.primary.dark,
					fontSize: '0.857rem',
					fontWeight: 'normal',
				},
			},
		},
	},
	header: {
		background: '#F2F2F2',
		width: '100%',
		padding: '20px 0 20px',
		position: 'fixed',
		left: 0,
		display: 'flex',
		justifyContent: 'center',
		zIndex: 1000,
		transition: 'box-shadow 0.25s',

		'& .header-content': {
			maxWidth: 1020,
			width: '100%',
			display: 'flex',
			justifyContent: 'space-between',

			'@media screen and (max-width: 1079px)': {
				paddingLeft: 20,
				paddingRight: 20,
			},
		},

		'& .logo': {
			display: 'flex',
		},

		'& .logo img': {
			height: 40,
			paddingRight: 14.7,
			borderRight: '1px solid rgba(35, 53, 68, 0.1)',

			'@media screen and (max-width: 767px)': {
				paddingRight: 10,
			},
		},

		'& .share-container': {
			display: 'flex',
		},

		'& button': {
			background: 'rgba(222, 226, 229, 0.65)',
			border: 0,
			margin: 4,
			cursor: 'pointer',
		},

		'& .btn-wrap': {
			display: 'flex',
			gap: '12px',
			margin: '0 16px',

			'@media screen and (max-width: 767px)': {
				margin: 0,
				gap: '0',
			},

			'& .btn': {
				height: 40,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				background: '#DEE2E5',
				borderRadius: 22.629,
				opacity: 0.65,
				padding: '4px 16px 4px 12px',
				fontSize: 14,
				color: theme.palette.primary.light,
				margin: 0,

				'@media screen and (max-width: 767px)': {
					height: 40,
					width: 40,
					borderRadius: '50%',
					justifyContent: 'center',
					margin: '0 0 0 7px',
				},
			},

			'& .btn-text': {
				fontSize: 14,
				fontWeight: 700,
				color: theme.palette.primary.dark,

				'@media screen and (max-width: 767px)': {
					display: 'none',
				},
			},
		},

		'& .btn-back': {
			'& .material-icons': {
				fontSize: 20,

				'@media screen and (max-width: 1079px)': {
					marginLeft: 12,
				},
			},
		},

		'& .btn-share': {
			gap: '4px',
		},

		'& .btn-reports-wrap': {
			display: 'flex',
			alignItems: 'center',
			gap: '20px',

			'@media screen and (max-width: 767px)': {
				order: 2,
			},
		},

		'& .btn-report': {
			display: 'flex',
			alignItems: 'center',
			background: 'transparent',
			border: 'none',
			cursor: 'pointer',
			transition: '0.25s',
			margin: 0,
			padding: '0 0 5px 0',
			fontSize: 14,
			color: 'var(--text-color-secondary)',
			fontWeight: 700,
			position: 'relative',

			'&:after': {
				content: '""',
				position: 'absolute',
				left: 0,
				right: 0,
				bottom: -2,
				height: 3,
				borderRadius: 2,
				background: 'transparent',
				transform: 'scale(0)',
				transition: '0.25s',
			},

			'&:not(.active):hover': {
				'&:after': {
					background: 'var(--violet)',
					transform: 'scale(0.6)',
				},
			},

			'@media screen and (max-width: 767px)': {
				height: 40,
				padding: '5px 10px',
			},

			'&.active': {
				color: 'var(--text-color-primary)',

				'&:after': {
					background: 'var(--violet)',
					transform: 'scale(1)',
				},
			},

			'& .btn-text': {
				textWrap: 'nowrap',
			},

			'& .btn-icon': {
				position: 'absolute',
				top: -5,
				right: -10,
				fontSize: 11,
				color: 'var(--violet)',
			},
		},

		'& .menu-mobile-wrap': {
			position: 'relative',

			'& .btn-report': {
				width: '100%',
				zIndex: 10,
				display: 'flex',
				alignItems: 'center',
				padding: '10px 0',
				margin: '5px 0',

				'&:after': {
					top: 0,
					bottom: 0,
					left: -20,
					right: -20,
					height: 'auto',
				},

				'&.active': {
					'&:after': {
						background: 'rgba(125, 140, 151, .5)',
						zIndex: -1,
					},
				},

				'& .btn-icon': {
					position: 'relative',
					right: -5,
				},
			},
		},

		'& .btnPrint': {
			width: 40,
			height: 40,
			borderRadius: '50%',
			margin: 0,
			display: 'none',
		},
	},

	headerShadow: {
		boxShadow: '0 6px 4px -4px rgba(0, 0, 0, .2)',
	},

	infoContainer: {
		marginTop: 112,
		marginBottom: 31,

		'& .date': {
			marginTop: 16,
			color: theme.palette.primary.light,
			fontSize: '0.857rem',

			'& b': {
				fontSize: '1rem',
				color: theme.palette.primary.dark,
			},
		},

		'& .info': {
			marginTop: 20,
		},

		'& .general': {
			float: 'left',

			'& div': {
				color: theme.palette.primary.light,
				fontSize: '0.857rem',
				display: 'flex',
				alignItems: 'flex-end',
				clear: 'both',
				marginBottom: 30,

				'& span': {
					display: 'inline-block',
					minWidth: 84,
				},

				'& b': {
					marginLeft: 24,
					fontSize: '1.857rem',
					color: theme.palette.primary.dark,
				},
			},
		},

		'& .home-destination-content': {
			display: 'flex',
			width: '100%',
			background: 'white',
			boxShadow: '0px 5px 10px rgba(0, 24, 32, 0.07)',
			borderRadius: 10,
			fontSize: '0.857rem',
			color: theme.palette.primary.light,

			'& table': {
				border: 'none',

				'& td': {
					paddingLeft: 0,
					paddingRight: 0,
				},
			},

			'& .traveller': {
				background: '#DEE2E5',
				minWidth: 209,
				maxWidth: 209,
				width: '100%',
				borderRadius: '10px 0 0 10px',
				padding: '27px 20px 24px 20px',
				boxSizing: 'border-box',

				'& .traveller-header': {
					display: 'flex',
					alignItems: 'center',
					fontSize: 20,
					color: '#233544',
					marginBottom: 12,

					'& .icon': {
						marginRight: 14,
					},
				},

				'& .data': {
					color: '#233544',
				},
			},

			'& .title-section': {
				padding: '24px 26px',
				flexBasis: '100%',

				'& .head': {
					fontSize: 26,
					color: '#233544',
					lineHeight: '33.8px',
				},

				'& .sub-head': {
					fontSize: 12,
					lineHeight: '20px',
					margin: '10px 0',
				},
			},

			'& .location': {
				display: 'flex',
				padding: '20px 0',
				maxWidth: 240,
				width: '100%',

				'& .content': {
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'space-between',
					padding: '22px 20px 14px 25px',
					borderLeft: '1px solid rgba(35, 53, 68, .1)',
					whiteSpace: 'nowrap',
					overflow: 'hidden',
					textOverflow: 'ellipsis',

					'& > div': {
						display: 'flex',
						alignItems: 'center',
					},

					'& .location-name': {
						fontSize: 16,
						color: '#233544',
						paddingLeft: 7,
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
					},

					'& .spacer': {
						height: 50,
						background: `url(${verticalDotted}) repeat-y`,
						marginLeft: 7,
					},

					'& .destination': {
						marginLeft: -3,
					},

					'& .icon': {
						width: 24,
						color: '#9356F8',
					},
				},
			},
		},
	},

	riskScoringContainer: {
		opacity: 0,
		transition: '0.5s',

		// '&.risk-scoring-enter, &.risk-scoring-active-enter': {
		// 	opacity: 0,
		// },

		' &.risk-scoring-done-enter': {
			opacity: 1,
		},
	},

	homeDestinationsLocation: {
		display: 'flex',
		justifyContent: 'space-between',

		'& *': {
			boxSizing: 'border-box',
		},

		'& .location-name': {
			fontSize: 16,
			color: '#233544',
			marginTop: 6,
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
		},

		'& .block-location': {
			maxWidth: 495,
			width: '100%',
			background: 'white',
			borderRadius: 10,
			boxShadow: '0px 5px 10px rgba(0, 24, 32, 0.07)',
			padding: 10,
		},

		'& .location-info': {
			display: 'flex',
			justifyContent: 'space-between',
			padding: '0 11px',
			minHeight: 128,

			'& .left, & .right': {
				flexBasis: '50%',
				whiteSpace: 'nowrap',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
			},

			'& .left': {
				padding: '21px 20px 0 0',
				width: 150,
			},

			'& .right': {
				padding: '14px 0 11px 11px',
			},

			'& .title': {
				fontSize: 13,
				color: '#7D8C97',
				marginTop: 3,
			},

			'& table': {
				'& .title, & .data': {
					fontSize: 12,
					verticalAlign: 'top',
					paddingRight: 0,
					paddingLeft: 5,
				},

				'& .data': {
					color: '#233544',
				},
			},
		},
	},

	alertSection: {
		display: 'flex',
		justifyContent: 'center',
	},

	riskScoresEstimates: {
		display: 'flex',
		justifyContent: 'space-between',

		'& .increase-decrease, & .increase-decrease-full': {
			'& svg rect': {
				strokeWidth: 0,
			},
		},
	},

	dataLayerContainer: {
		position: 'absolute',
		top: 5,
		right: 10,

		'& .data-layer-tooltip': {
			padding: '3px 10px 6px',
		},

		'& .multi-line': {
			textAlign: 'left !important',
			fontSize: 11,
			lineHeight: 1.3,
		},
	},

	riskScoresContainer: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		maxWidth: 320,
		width: '100%',
		paddingTop: 20,
		background: 'white',
		borderRadius: 10,
		boxSizing: 'border-box',
		boxShadow: '0px 5px 10px rgba(0, 24, 32, 0.07)',
		position: 'relative',

		'&:before': {
			content: '""',
			position: 'absolute',
			top: 0,
			height: 5,
			width: '100%',
			background: '#7D8C97',
			borderRadius: '10px 10px 0px 0px',
		},

		'&.decrease:before': {
			background: '#6AD400',
		},

		'&.increase:before': {
			background: '#FF6401',
		},

		'& .drivers': {
			width: '100%',
			height: '100%',
		},

		'& .riskDriversTitle': {
			fontSize: '1rem',
			color: theme.palette.primary.light,
			fontWeight: 'normal',
			marginBottom: 20,
		},

		'& .riskDriversData': {
			marginBottom: 20,
		},

		'& .riskDriversDataNarrow': {
			marginBottom: 20,
		},

		'& .bg-white': {
			width: '100%',
			textAlign: 'center',
			padding: '0 20px',
			boxSizing: 'border-box',
		},

		'& .bg-gray': {
			width: '100%',
			background: 'rgba(222, 226, 229, .2)',
			padding: 20,
			boxSizing: 'border-box',
		},

		'& .communicable-drivers': {
			display: 'flex',
			justifyContent: 'space-between',

			'& .score-line-contributors': {
				width: '50%',
			},
		},

		'& .increase-decrease': {
			width: '100%',
			height: 180,
		},

		'& .increase-decrease-full': {
			width: '100%',
			height: 180,
		},

		'& .risk-score-lines': {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-around',
			padding: '13px 0',
		},

		'& .risk-score-line': {
			marginBottom: 14,
		},

		'& .score-line-contributors': {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'space-around',
			padding: '13px 0',
		},
	},

	relativeRiskContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: '16px',
		marginBottom: 30,

		'& .relative-risk': {
			maxWidth: 495,
			width: '100%',
			background: 'white',
			boxShadow: '0px 5px 10px rgba(0, 24, 32, 0.07)',
			borderRadius: 10,
			padding: '24px 20px 24px',
			boxSizing: 'border-box',
			position: 'relative',
		},
	},

	violentCrimeRiskIndex: {
		borderRadius: 10,
		background: 'white',
		boxSizing: 'border-box',
		boxShadow: '0px 5px 10px rgba(0, 24, 32, 0.07)',
		marginBottom: 50,
		padding: '24px 20px 32px',
	},

	relativeRisk: {
		width: '49%',
		float: 'left',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',

		'& .labelContainer': {
			display: 'flex',
			alignItems: 'center',
			marginBottom: 14,
		},

		'& .label': {
			display: 'inline-block',
			margin: '0 20px 0 10px',
		},
	},

	crimeRiskLineContainer: {
		borderRadius: 10,
		background: 'white',
		boxSizing: 'border-box',
		boxShadow: '0px 5px 10px rgba(0, 24, 32, 0.07)',
		marginBottom: 50,
		padding: '24px 20px',
		overflow: 'hidden',

		'& .titleSection': {
			marginTop: 0,

			'& h4': {
				fontSize: '1.285rem',
				marginTop: 0,
				marginBottom: 5,
			},
		},

		'& .crime-risk-graphic-container': {
			overflowX: 'auto',
		},
	},

	travellerIcon: {
		width: 30,
		height: 30,
	},

	printerIcon: {
		width: 19,
		height: 19,
	},

	uploadIcon: {
		width: 16,
		height: 19,
	},

	riskHighlightsIndicatorContainer: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-between',

		'& .risk-highlights-indicator': {
			background: 'white',
			maxWidth: 320,
			width: '100%',
			height: 121,
			boxSizing: 'border-box',
			padding: 20,
			borderRadius: 10,
			marginTop: 20,
			display: 'flex',
			alignItems: 'center',
			position: 'relative',
			textAlign: 'center',
			cursor: 'pointer',
			transition: '.3s',
			boxShadow: '0px 5px 10px rgba(0, 24, 32, 0.07)',

			'&:hover': {
				background: 'rgba(196, 198, 200, 0.9)',
			},

			'& p': {
				maxWidth: '100%',
				margin: 0,
				fontSize: '1.143rem',
				lineHeight: 1.25,
				color: theme.palette.primary.dark,

				'&:last-child': {
					marginTop: 'auto',
					fontSize: '1rem',
					fontWeight: 'bold',
				},
			},
		},
	},

	travelHealthNotices: {
		'& .titleSection': {
			marginTop: 41,
		},
	},

	travelAdviceContainer: {
		'& .titleSection': {
			display: 'flex',

			'& h4': {
				marginTop: 0,
			},
		},

		'& .btn-wrap': {
			borderRadius: 10,
			overflow: 'hidden',
			background: 'white',
			boxShadow: '0px 5px 10px rgba(0, 24, 32, 0.07)',
			padding: 20,
			paddingBottom: 10,
		},
	},

	healthNotice: {
		borderRadius: 10,
		overflow: 'hidden',
		background: 'white',
		boxShadow: '0px 5px 10px rgba(0, 24, 32, 0.07)',
		marginBottom: 30,

		'& *': {
			boxSizing: 'border-box',
		},

		'& .content': {
			paddingTop: 15,
		},

		'& .notice-header': {
			display: 'flex',
			alignItems: 'center',
			minHeight: 40,
			fontSize: '1rem',
			color: '#fff',
			padding: '0 15px',

			'& span': {
				paddingLeft: 5,
			},
		},

		'& .notice-item': {
			color: theme.palette.primary.dark,

			'& .top-block': {
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				padding: '0 20px',
			},

			'& .bottom-block': {
				minHeight: 40,
				display: 'flex',
				alignItems: 'center',
				padding: '7.5px 20px',
				background: 'rgba(222, 226, 229, .2)',
			},

			'& .left': {
				marginRight: 20,
			},

			'&:not(:first-child)': {
				marginTop: 15,
			},
			'&:last-child': {
				border: 'none',
			},
		},

		'& .title': {
			fontSize: '1.2857rem',
		},

		'& .date': {
			color: '#7D8C97',
			fontSize: '.928rem',
			lineHeight: 1.3,
		},

		'& .description': {
			marginTop: 10,
			fontSize: '.928rem',
			lineHeight: 1.428,
		},

		'& .url': {
			color: '#7D8C97',
			fontSize: '0.857rem',

			'& a': {
				display: 'flex',
				textDecoration: 'underline',
			},

			'& img': {
				marginRight: 5,
			},
		},
	},

	healthNoticeLevel3: {
		// border: `1px solid ${RED}`,
		marginBottom: 20,
		'& .notice-header': {
			backgroundColor: RED,
		},
	},

	healthNoticeLevel2: {
		// border: `1px solid ${YELLOW}`,
		// marginBottom: 20,
		'& .notice-header': {
			backgroundColor: YELLOW,
		},
	},

	healthNoticeLevel1: {
		// border: `1px solid ${GREEN}`,
		marginBottom: 20,
		'& .notice-header': {
			backgroundColor: GREEN,
		},
	},

	// TODO: need to remove styles for the covid content
	covidGeographicRisk: {
		display: 'flex',
		justifyContent: 'space-between',

		'& *': {
			boxSizing: 'border-box',
		},

		'& .box-graph': {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			maxWidth: 495,
			width: '100%',
			background: 'white',
			boxShadow: '0px 5px 10px rgba(0, 24, 32, 0.07)',
			borderRadius: 10,
			padding: '24px 20px 17px',
			position: 'relative',

			'& h4': {
				fontSize: '1.2857rem',
				alignSelf: 'flex-start',
				marginTop: 0,
				marginBottom: 30,
			},

			'& .graph-top': {
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				maxWidth: 220,
				width: '100%',
			},

			'& .block-text-location': {
				maxWidth: 140,
				fontSize: '1.1428rem',
				color: theme.palette.primary.dark,
				marginTop: -2,
				whiteSpace: 'nowrap',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
			},

			'& .block-text-wrap': {
				width: '100%',
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',

				'& span': {
					fontSize: 9,
					whiteSpace: 'nowrap',
				},

				'& .block-text-location': {
					width: '55%',
					marginTop: 0,
					textAlign: 'center',
				},
			},

			'& .block-icon-location': {
				marginTop: 15,
			},
		},
	},

	covidGeographicRiskTrends: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: 30,

		'& *': {
			boxSizing: 'border-box',
		},

		'& .col': {
			maxWidth: 495,
			width: '100%',
			background: 'white',
			boxShadow: '0px 5px 10px rgba(0, 24, 32, 0.07)',
			borderRadius: 10,
			padding: '26px 20px 17px',
			position: 'relative',
		},
	},

	covidPersonalRiskImpact: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: 30,
		background: 'white',
		boxShadow: '0px 5px 10px rgba(0, 24, 32, 0.07)',
		borderRadius: 10,

		'& *': {
			boxSizing: 'border-box',
		},

		'& .block-left': {
			width: '100%',
			padding: '26px 20px 20px',
			position: 'relative',
		},

		'& .header': {
			display: 'flex',
			alignItems: 'flex-start',

			'& .block-text': {
				marginLeft: 10,

				'& h3': {
					fontSize: '1.2857rem',
					color: theme.palette.primary.dark,
					fontWeight: 400,
					margin: 0,
				},

				'& span': {
					display: 'block',
					fontSize: '0.928rem',
					color: theme.palette.primary.light,
					marginTop: 3,

					'&.sub-line': {
						marginTop: 10,
					},
				},
			},
		},

		'& .graph-wrp': {
			display: 'flex',
			justifyContent: 'space-between',
			width: '100%',

			'& .graph-col': {
				width: 'calc(100% / 3)',
			},
		},

		'& .graph-color': {
			display: 'flex',
			justifyContent: 'center',
			marginTop: 40,
			color: '#001a21',
			lineHeight: 1,

			'& .graph-color-item': {
				display: 'flex',
				alignItems: 'center',
			},
		},

		'& .block-right': {
			maxWidth: 262,
			width: '100%',
			background: 'rgba(222, 226, 229, .2)',
			borderRadius: '0 10px 10px 0',
			color: theme.palette.primary.light,
			textAlign: 'center',
			padding: '26px 25px 20px',

			'& .wrp': {
				height: '100%',
				display: 'flex',
				flexDirection: 'column',

				'& .cont': {
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
				},

				'& h4': {
					fontSize: 16,
					color: theme.palette.primary.dark,
					fontWeight: 400,
					lineHeight: 1.25,
					letterSpacing: 0.6,
				},

				'& .per': {
					fontSize: 13,
					lineHeight: 1.4,
				},

				'& .data': {
					fontSize: 60,
					lineHeight: 1.3,
					color: theme.palette.primary.dark,
					marginTop: 10,
				},

				'& .ci': {
					fontSize: 13,
					lineHeight: 1.4,
				},
			},

			'& .footer': {
				fontSize: 12,
				lineHeight: 1.4,
				paddingTop: 20,
				marginTop: 20,
				borderTop: '1px solid rgba(35, 53, 68, .1)',
			},
		},
	},

	covidComparisonPersonalRiskImpact: {
		display: 'flex',
		justifyContent: 'space-between',
		marginTop: 30,
		background: 'white',
		boxShadow: '0px 5px 10px rgba(0, 24, 32, 0.07)',
		borderRadius: 10,
		position: 'relative',

		'& *': {
			boxSizing: 'border-box',
		},

		'& .block-left, & .block-right': {
			padding: '26px 20px 20px',
		},

		'& .header': {
			display: 'flex',
			alignItems: 'flex-start',

			'& .block-text': {
				marginLeft: 10,

				'& h3': {
					fontSize: '1.2857rem',
					color: theme.palette.primary.dark,
					fontWeight: 400,
					margin: 0,
				},

				'& span': {
					display: 'block',
					fontSize: '0.928rem',
					color: theme.palette.primary.light,
					marginTop: 3,

					'&.sub-line': {
						marginTop: 10,
					},
				},
			},
		},

		'& .graph-wrp': {
			width: '100%',
			textAlign: 'center',

			'& .arrow': {
				display: 'inline-block',
				width: 0,
				height: 0,
				borderTop: '6px solid #DEE2E5',
				borderLeft: '6px solid transparent',
				borderRight: '6px solid transparent',
			},

			'& .arrow-up': {
				transform: 'rotate(180deg)',
			},

			'& .title-location': {
				fontSize: 14,
				color: theme.palette.primary.dark,
				lineHeight: 1.4,
			},
		},

		'& .graph-color': {
			display: 'flex',
			justifyContent: 'space-between',
			marginTop: 40,
			color: '#001a21',
			lineHeight: 1,

			'& .graph-color-item': {
				display: 'flex',
				width: 'calc(50% - 10px)',

				'& .graph-color-text': {
					flexBasis: 'calc(100% - 20px)',
				},

				'& .title': {
					fontSize: 14,
					color: theme.palette.primary.dark,
					lineHeight: 1.4,
				},

				'& .description': {
					fontSize: 12,
					color: theme.palette.primary.light,
					lineHeight: 1.4,
					marginTop: 3,
				},
			},
		},

		'& .block-right': {
			background: 'rgba(222, 226, 229, .2)',
			borderRadius: '0 10px 10px 0',
			color: theme.palette.primary.light,
			padding: '26px 25px 20px',

			'& .wrp': {
				height: '100%',
				display: 'flex',
				flexDirection: 'column',

				'& .cont': {
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
					justifyContent: 'center',
				},

				'& .graph-info-item': {
					lineHeight: 1.4,
					padding: '10px 0',
					position: 'relative',

					'& .graph-color-violet-dark': {
						background: '#7B3CE3',
					},

					'& .graph-color-gradient-violet-yellow': {
						background:
							'linear-gradient(90deg, rgba(147,86,248,1) 0%, rgba(147,86,248,1) 50%, rgba(255,203,0,1) 50%)',
					},

					'& .graph-color-yellow-dark': {
						background: '#FFB601',
					},

					'&:not(:first-of-type)': {
						borderTop: '1px solid rgba(35, 53, 68, .1)',
					},

					'& .box-data': {
						display: 'flex',
						alignItems: 'center',

						'& .data': {
							fontSize: 22,
							lineHeight: 1.3,
							color: theme.palette.primary.dark,

							'& span': {
								fontSize: 14,
								lineHeight: 1.4,
							},
						},
					},

					'& .ci': {
						marginTop: 7,
					},

					'& .text': {
						fontSize: 12,
						marginTop: 5,
					},
				},
			},
		},
	},

	covidHeatmap: {
		marginTop: 30,
	},

	keyConcepts: {
		marginTop: 60,

		'& h4': {
			fontSize: '1.428rem',
			color: theme.palette.primary.dark,
			textAlign: 'left',
		},

		'& h5': {
			fontSize: '1.28rem',
			color: theme.palette.primary.dark,
			textAlign: 'left',
		},

		'& .item:not(:first-of-type)': {
			background: 'white',
			boxShadow: '0px 5px 10px rgba(0, 24, 32, 0.07)',
			borderRadius: 10,
			padding: '24px 36px 26px 16px',
			marginTop: 20,
		},

		'& p, & div': {
			fontSize: 13,
			color: theme.palette.primary.light,
			lineHeight: '18.2px',
			marginTop: 12,

			'&:last-of-type': {
				marginBottom: 0,
			},
		},

		'& .list-item:first-of-type': {
			marginTop: 18,
		},

		'& .list-item': {
			position: 'relative',
			paddingLeft: 16,
			marginTop: 0,

			'&:before': {
				content: '"•"',
				position: 'absolute',
				top: 0,
				left: 0,
				fontSize: 13,
				color: theme.palette.primary.dark,
			},
		},
	},

	'@media screen and (max-width: 1079px)': {
		container: {
			'& .styles-mob-1024': {
				flexDirection: 'column',
				justifyContent: 'flex-start',
				alignItems: 'center',
				gap: '30px',
			},
		},
	},

	'@media screen and (min-width: 1024px)': {
		crimeRiskLineContainer: {
			'& .crime-risk-graphic-container': {
				padding: '0 75px',
			},
		},
	},

	'@media screen and (min-width: 768px)': {
		container: {
			'& .block-right': {
				marginLeft: 30,
			},
		},

		covidComparisonPersonalRiskImpact: {
			'& .block-left, .block-right': {
				width: '50%',
			},

			'& .block-right': {
				marginLeft: 0,
			},
		},

		covidPersonalRiskImpact: {
			'& .block-right': {
				marginLeft: 0,
			},
		},
	},

	'@media screen and (max-width: 1023px)': {
		container: {
			'& .styles-mob-1024': {
				flexDirection: 'column',
				justifyContent: 'flex-start',
				alignItems: 'center',
			},
		},

		riskScoresContainer: {
			'&:first-of-type': {
				marginTop: 20,
			},
		},

		relativeRiskContainer: {
			'& .box-graph': {
				paddingLeft: 0,
			},
		},

		crimeRiskLineContainer: {
			'& .risk-chart': {
				height: '545px !important',
			},

			'& .home-point': {
				transform: 'translate(153px, 444px) !important',

				'& rect': {
					width: '270px !important',
				},
			},

			'& .home-point-0': {
				transform: 'translate(111px, 507px) !important',
			},
		},
	},

	'@media screen and (max-width: 767px)': {
		container: {
			maxWidth: 495,
			margin: '0 auto',

			'& .titleSection': {
				'& h4': {
					fontSize: 20,
				},
			},

			'& .header-content': {
				maxWidth: 495,
				width: '100%',
				gap: '8px',
				margin: '0 auto',
				padding: '0 10px',
			},

			'& .styles-mob-767': {
				flexDirection: 'column',
				justifyContent: 'flex-start',
				alignItems: 'center',
			},

			'& .block-left': {
				marginBottom: 20,
			},

			'& .titleSection': {
				marginTop: 40,
			},

			'& .location-name': {
				fontSize: '14px !important',
			},

			'& .home-destination-content': {
				maxWidth: 495,
				margin: '0 auto',

				'& *': {
					boxSizing: 'border-box',
				},

				'& .traveller': {
					maxWidth: '100%',
					width: '100%',
					borderRadius: '10px 10px 0 0',
					paddingLeft: 15,
					paddingRight: 15,

					'& .text': {
						fontSize: 16,
					},

					'& .title': {
						width: 80,
					},
				},

				'& .title-section': {
					width: '100%',
					padding: '30px 15px 10px',

					'& .head': {
						fontSize: 20,
					},
				},

				'& .location': {
					maxWidth: '100%',
					width: '100%',
					padding: '10px 15px',

					'& .content': {
						width: '100%',
						border: 'none',
						borderTop: '1px solid rgba(35, 53, 68, .1)',
						padding: '30px 0',
					},

					'& .destination': {
						marginLeft: 1.4,
					},
				},
			},

			'& .label.subTitle': {
				fontSize: 16,
			},

			'& .box-graph h4': {
				fontSize: 16,
			},

			'& .logo': {
				marginTop: 0,
				marginLeft: 0,
			},
		},

		riskScoresContainer: {
			maxWidth: 495,
		},

		infoContainer: {
			marginBottom: 20,
		},

		relativeRiskContainer: {
			marginBottom: 20,
		},

		covidPersonalRiskImpact: {
			'& .block-right': {
				maxWidth: 'none',
				borderRadius: '0 0 10px 10px',
			},
		},

		covidComparisonPersonalRiskImpact: {
			'& .block-right': {
				borderRadius: '0 0 10px 10px',
			},
		},

		crimeRiskLineContainer: {
			'& h4': {
				fontSize: '16px !important',
			},

			'& h5': {
				fontSize: '12px !important',
			},
		},

		riskHighlightsIndicatorContainer: {
			maxWidth: 334,
			width: '100%',
			marginLeft: 'auto',
			marginRight: 'auto',

			'& .risk-highlights-indicator:nth-child(odd)': {
				marginRight: 20,
			},

			'& .risk-highlights-indicator': {
				alignItems: 'flex-start',
				maxWidth: 157,
				height: 173,
				padding: '14px 15px 15px',

				'& .ratingData': {
					minWidth: 'auto',
					width: 70,
					height: 70,

					'& .ratingVal': {
						fontSize: 40,
					},

					'& .ratingTotal': {
						fontSize: 18,
					},
				},

				'& .ratingTextInfo': {
					fontSize: 14,
					lineHeight: '16.8px',
					padding: '10px 0 0',

					'& .ratingDescription': {
						marginTop: 3,
					},
				},
			},
		},

		keyConcepts: {
			'& h5': {
				fontSize: 16,
			},
		},
		healthNotice: {
			'& .top-block': {
				flexDirection: 'column-reverse',
			},

			'& .qrcode': {
				margin: '5px 0 20px',
			},
		},
	},

	'@media screen and (max-width: 479px)': {
		covidPersonalRiskImpact: {
			'& .graph-wrp': {
				flexDirection: 'column',
				alignItems: 'center',

				'& .graph-col': {
					width: '100%',
				},
			},
		},
	},
});

export default styles;
