
const styles = (theme) => ({
    root: {
        color: theme.palette.text.primary,
    },
    label: {
        color: theme.palette.text.primary,
    },
    errorRoot: {
        marginTop: -5,
    },
    light: {
        color: theme.palette.common.white,
    }
});

export default styles;
