
const localStoreFactory = () => {
    const keys = {
        token: 'token',
        refreshToken: 'refresh_token',
    };

    const setToken = (token) => {
        window.localStorage.setItem(keys.token, token);
    };

    const getToken = () => {
        return window.localStorage.getItem(keys.token);
    };

    const setRefreshToken = (token) => {
        window.localStorage.setItem(keys.refreshToken, token);
    };

    const getRefreshToken = () => {
        return window.localStorage.getItem(keys.refreshToken);
    };

    return {
        setToken,
        getToken,
        setRefreshToken,
        getRefreshToken,
    };
};

export default localStoreFactory();
