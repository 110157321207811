
const styles = (theme) => ({
    panel: {
        minWidth: 310,
        width: 310,
        minHeight: 137,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        padding: 26,
        boxSizing: 'border-box',
        zIndex: 999,
    },
    actions: {
        display: 'flex',
        flex: 0,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 20,
    },
});

export default styles;
