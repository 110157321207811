import React, {PureComponent} from 'react';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import {Scale} from './index';
import styles from './ScaleWithLabels.jss';

class ScaleWithLabels extends PureComponent {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        start: PropTypes.number.isRequired,
        end: PropTypes.number.isRequired,
        min: PropTypes.number.isRequired,
        max: PropTypes.number.isRequired,
        color: PropTypes.object.isRequired, // see Scale::Color
        compact: PropTypes.bool,
    };

    static defaultProps = {
        compact: false,
    };

    state = {
        valuesAndPoints: null,
    };

    getContent() {
        const {compact} = this.props;
        if (compact) return this.getCompactContent();
        return this.getNormalContent();
    }

    getCompactContent() {
        const {
            classes, color, start, end, min, max, title,
        } = this.props;
        const {valuesAndPoints} = this.state;
        return (
            <div className={classes.containerCompact}>
                {valuesAndPoints
                    && <div className={classes.valueCompact}>
                        <Typography variant="body1" color="textSecondary" className={classes.titleCompact}>{title}</Typography>
                        <Typography variant="h6" color="textPrimary">{`${valuesAndPoints.values.min} - ${valuesAndPoints.values.max}`}</Typography>
                    </div>
                }
                <Scale
                    start={start}
                    end={end}
                    min={min}
                    max={max}
                    color={color}
                    showMinMax={true}
                    onRender={this.handleOnRenderScale}
                />
            </div>
        );
    }

    formatValue(value) {
        return parseFloat(value).toFixed(1);
    }

    handleOnRenderScale = (valuesAndPoints) => {
        setTimeout(() => {
            this.setState({valuesAndPoints});
        }, 50);
    }

    getNormalContent() {
        const {
            classes, color, start, end, min, max, title,
        } = this.props;
        const {valuesAndPoints} = this.state;
        return (
            <div className={classes.container}>
                {valuesAndPoints
                    && <div className={classes.value}>
                        <Typography variant="body1" color="textSecondary" className={classes.title}>{title}</Typography>
                        <Typography variant="h3" color="textPrimary">{`${this.formatValue(valuesAndPoints.values.min)} - ${this.formatValue(valuesAndPoints.values.max)}`}</Typography>
                    </div>
                }
                <Scale
                    start={start}
                    end={end}
                    min={min}
                    max={max}
                    color={color}
                    showMinMax={true}
                    onRender={this.handleOnRenderScale}
                />
            </div>
        );
    }

    render() {
        return this.getContent();
    }
}

export default withStyles(styles)(ScaleWithLabels);
