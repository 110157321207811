const styles = (theme) => ({
	container: {
		marginTop: 20,
		marginBottom: 20,
		maxHeight: 400,
		overflowY: 'auto',
	},
	// table: {
	// 	width: '100%',
	// 	'& tr': {
	// 		'& td': {
	// 			minHeight: 48,
	// 			padding: '10px 20px 10px 20px',
	// 			fontSize: 14,
	// 			color: '#7D8C97',
	// 			'& button': {
	// 				visibility: 'hidden',
	// 			},
	// 			'& .name': {
	// 				color: '#fff',
	// 				marginBottom: 6,
	// 			},
	// 		},
	// 		'& .column-name': {
	// 			width: 324,
	// 		},
	// 		'& .no-data': {
	// 			color: '#fff',
	// 			textAlign: 'center',
	// 		},
	// 		'&:hover': {
	// 			backgroundColor: '#DEE2E5',
	// 			'& td button': {
	// 				visibility: 'visible',
	// 			},
	// 			'& td .name': {
	// 				color: '#233544',
	// 			},
	// 		},
	// 	},
	// },

	historyReportWrap: {
		'& .history-report-row': {
			display: 'flex',
			alignItems: 'center',

			'& .col-wrap': {
				display: 'flex',
				flex: 3,
			},

			'& .col': {
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				minHeight: 48,
				padding: '10px 20px 10px 20px',
				fontSize: 14,
				color: '#7D8C97',

				'&.column-name': {
					flexBasis: 364,
					boxSizing: 'border-box',
				},

				'& button': {
					visibility: 'hidden',
				},

				'& .name': {
					color: '#fff',
					marginBottom: 6,
				},
			},

			'&:hover': {
				backgroundColor: '#DEE2E5',

				'& .col': {
					'& button': {
						visibility: 'visible',
					},

					'& .name': {
						color: '#233544',
					},
				},
			},
		},

		'& .no-data': {
			color: '#fff',
			textAlign: 'center',
		},
	},

	'@media screen and (max-width: 767px)': {
		historyReportWrap: {
			'& .history-report-row': {
				flexDirection: 'column',
				alignItems: 'flex-start',

				'& .col-wrap': {
					width: '100%',
					justifyContent: 'flex-start',
					alignItems: 'flex-start',
				},

				'& .column-date-create': {
					minWidth: 105,
					paddingLeft: 0,
					boxSizing: 'border-box',
				},

				'& .col': {
					minHeight: 'auto',
					'&.column-btn': {
						paddingTop: 0,
					},

					'& button': {
						visibility: 'visible',
					},
				},
			},
		},
	},
});

export default styles;
