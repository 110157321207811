import React, {PureComponent} from 'react';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {Typography} from '@material-ui/core';
import {connect} from 'react-redux';
import {Form, Field} from "react-final-form";
import Joi from 'joi-browser';

import {
    Panel, TextInput, DarkButton,
} from '../../../components';
import {
    userPropertiesSelector,
} from '../../../store/selectors';
import {getFinalFormErrors} from '../../../lib/form';
import {api} from '../../../sagas';

import styles from './EditEmail.jss';

const errorMessages = {
    email: {
        'default': 'Email is required',
        'string.email': 'Wrong email format',
    },
};

class EditEmail extends PureComponent {
    static propTypes = {
        user: PropTypes.object.isRequired,
        onClose: PropTypes.func,
    };

    state = {
        submitting: false,
    };

    handleOnClose = () => {
        const {onClose} = this.props;
        if (onClose) onClose();
    }

    handleOnSubmit = (values) => {
        const errorMessage = 'Change email error';
        return new Promise((resolve, reject) => {
            this.setState({submitting: true});
            api.changeEmail(values.email).then((response) => {
                this.setState({submitting: false});
                if (response.ok) resolve();
                else throw new Error(errorMessage);
            }).catch((e) => {
                this.setState({submitting: false});
                resolve(errorMessage);
            });
        });
    }

    handleValidate = (values) => {
        let errors = {};

        const schema = Joi.object({
            email: Joi.string().email().required(),
        });
        const result = Joi.validate(values, schema, {abortEarly: false, allowUnknown: true}).error;
        if (result) errors = getFinalFormErrors(result.details, errorMessages);

        return errors;
    }

    renderForm = ({
        handleSubmit,
        submitFailed,
        submitSucceeded,
    }) => {
        const {classes} = this.props;
        return (
            <form onSubmit={handleSubmit} className="light">
                <Field name="email">
                    {({input, meta}) => (
                        <div className={classes.row}>
                            <TextInput
                                placeholder="Email"
                                type="email"
                                dark
                                {...input}
                                error={(meta.error || meta.submitError) && meta.touched}
                                errorMessage={meta.error}
                            />
                        </div>
                    )}
                </Field>
                {submitFailed && <div className={classes.error}>Failed to save</div>}
                {submitSucceeded && <div className={`success ${classes.success}`}>Data has been saved</div>}
                <div className={classes.row}>
                    <DarkButton onClick={handleSubmit}>SAVE<input type="submit" style={{display:'none'}}/></DarkButton>
                </div>
            </form>
        );
    }

    render() {
        const {
            classes, user,
        } = this.props;
        const {submitting} = this.state;
        return (
            <div className={classes.container}>
                <div className={classes.header}>
                    <Typography variant="h4" color="textSecondary">Edit Email</Typography>
                </div>
                <Panel
                    className={classes.formPanel}
                    light
                    onClose={this.handleOnClose}
                    loading={submitting}
                >
                    <Form
                        onSubmit={this.handleOnSubmit}
                        validate={this.handleValidate}
                        render={this.renderForm}
                        initialValues={{email: user.email}}
                    />
                </Panel>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: userPropertiesSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    // userLogout: () => dispatch(UserActions.userLogout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EditEmail));
