import React from 'react';

import alertIcon from '../../../../images/alerts_icon_alert.svg';
import { withStyles } from '@material-ui/core/styles';

import styles from './Alert.jss';

const Alert = ({ classes }) => {
	return (
		<section className={classes.alert}>
			<div className='icon'>
				<img src={alertIcon} alt="" />
			</div>
			<div className='text'>
				<h3>ATTENTION:</h3>
				<p>Conflict deaths and injuries are not categorized and/or included in the Safe-esteem Violent Crimes scores and estimates (which owe their accuracy to the very specificity of the risk being measured). Active conflict areas are likely to carry considerably higher (and additional) risks of violence beyond homicide, aggravated assault, robbery, rape (represented below).</p>
			</div>
		</section>
	)
}

export default withStyles(styles)(Alert);