import React from 'react';

import Accordion from './Accordion';

import styles from './Info.module.scss';

export default function Info(props) {
	const { title, description } = props;

	return (
		<div className={styles['info-wrap']}>
			<Accordion title={title} description={description} />
		</div>
	);
}
