import React, {PureComponent} from 'react';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {Typography} from '@material-ui/core';
import {Form, Field} from "react-final-form";
import Joi from '@hapi/joi';
import {connect} from 'react-redux';

import {Panel, TextInput, DarkButton, Autocomplete} from '../../../components';
import {getFinalFormErrors} from '../../../lib/form';
import {api} from '../../../sagas';
import {userPropertiesSelector, userBillingSelector, dictionaryAdaptCountriesList} from '../../../store/selectors';
import UserActions, {adaptBillingForApi} from '../../../store/UserRedux';

import styles from './EditAccount.jss';

const errorMessages = {
    firstName: {'default': 'First Name is required'},
    lastName: {'default': 'Last Name is required'},
    businessName: {'default': 'Business Name is required'},
    country: {'default': 'Country is required'},
    line1: {'default': 'Line 1 is required'},
    postalCode: {'default': 'Postal Code is required'},
};

class EditAccount extends PureComponent {
    static propTypes = {
        user: PropTypes.object,
        billing: PropTypes.object,
        updateUserDetails: PropTypes.func,
        countries: PropTypes.arrayOf(PropTypes.object),
    };

    constructor(props) {
        super(props);

        const {billing} = props;
        this.state = {
            initialValues: {
                firstName: billing.firstName,
                lastName: billing.lastName,
                businessName: billing.businessName,
                line1: billing.line1,
                line2: billing.line2,
                city: billing.city,
                country: billing.country,
                postalCode: billing.postalCode,
                phone: billing.phone,
                state: billing.state,
            },
        };
    }

    handleOnClose = () => {
        const {onClose} = this.props;
        if (onClose) onClose();
    }

    handleOnSubmit = (values) => {
        const {updateUserDetails} = this.props;
        const errorMessage = 'Change account information error';
        return new Promise((resolve, reject) => {
            this.setState({submitting: true});
            const data = adaptBillingForApi(values);
            api.changeBilling(data).then((response) => {
                this.setState({submitting: false});
                if (response.ok) {
                    resolve();
                    updateUserDetails(response.data);
                }
                else throw new Error(errorMessage);
            }).catch((e) => {
                this.setState({submitting: false});
                resolve(errorMessage);
            });
        });
    }

    handleValidate = (values) => {
        let errors = {};

        const adapted = {
            firstName: '',
            lastName: '',
            businessName: '',
            country: '',
            line1: '',
            postalCode: '',
            ...values,
        };

        const schema = Joi.object({
            firstName: Joi.any(),
            lastName: Joi.any(),
            businessName: Joi.any(),
            country: Joi.string().required(),
            line1: Joi.string().required(),
            postalCode: Joi.string().required(),
        });
        const result = schema.validate(adapted, {abortEarly: false, allowUnknown: true}).error;
        const details = result ? result.details : [];
        console.log('===', adapted);
        if (!adapted.businessName && (!adapted.firstName || !adapted.lastName)) {
            details.push({type: 'string.empty', path: ['businessName'], message: 'Required'});
        }
        if (!adapted.businessName && !adapted.firstName) {
            details.push({type: 'string.empty', path: ['firstName'], message: 'Required'});
        }
        if (!adapted.businessName && !adapted.lastName) {
            details.push({type: 'string.empty', path: ['lastName'], message: 'Required'});
        }
        if (details.length) errors = getFinalFormErrors(details, errorMessages);

        return errors;
    }

    renderForm = ({
        handleSubmit,
        submitFailed,
        submitSucceeded,
    }) => {
        const {classes, countries} = this.props;
        return (
            <form onSubmit={handleSubmit} className="light">
                <div className={`${classes.fields} scrollbar1`}>
                    <Field name="firstName">
                        {({input, meta}) => (
                            <div className={classes.row}>
                                <TextInput
                                    placeholder="First Name"
                                    type="text"
                                    dark
                                    inputProps={{maxLength: 100}}                                
                                    {...input}
                                    error={(meta.error || meta.submitError) && meta.touched}
                                    errorMessage={meta.error}
                                />
                            </div>
                        )}
                    </Field>
                    <Field name="lastName">
                        {({input, meta}) => (
                            <div className={classes.row}>
                                <TextInput
                                    placeholder="Last Name"
                                    type="text"
                                    dark
                                    inputProps={{maxLength: 100}}                                
                                    {...input}
                                    error={(meta.error || meta.submitError) && meta.touched}
                                    errorMessage={meta.error}
                                />
                            </div>
                        )}
                    </Field>
                    <Typography variant="h2">Billing Details</Typography>
                    <Field name="businessName">
                        {({input, meta}) => (
                            <div className={classes.row}>
                                <TextInput
                                    placeholder="Business Name"
                                    type="text"
                                    dark
                                    inputProps={{maxLength: 100}}                                
                                    {...input}
                                    error={(meta.error || meta.submitError) && meta.touched}
                                    errorMessage={meta.error}
                                />
                                <i>Will appear in the report</i>
                            </div>
                        )}
                    </Field>
                    <Field
                        name="country"
                        parse={(value) => {
                            return value.value;
                        }}
                    >
                        {({input, meta}) => {
                            const {value, ...rest} = input;
                            return (
                                <div className={classes.row}>
                                    <Autocomplete
                                        dark
                                        placeholder="Choose Country"
                                        items={countries}
                                        value={countries.filter((el) => (el.value === value))[0]}
                                        {...rest}
                                        error={(meta.error || meta.submitError) && meta.touched}
                                        errorMessage={meta.error}
                                    />
                                </div>
                            );
                        }}
                    </Field>
                    <Field name="line1">
                        {({input, meta}) => (
                            <div className={classes.row}>
                                <TextInput
                                    placeholder="Line 1"
                                    type="text"
                                    dark
                                    inputProps={{maxLength: 100}}
                                    {...input}
                                    error={(meta.error || meta.submitError) && meta.touched}
                                    errorMessage={meta.error}
                                />
                            </div>
                        )}
                    </Field>
                    <Field name="line2">
                        {({input, meta}) => (
                            <div className={classes.row}>
                                <TextInput
                                    placeholder="Line 2"
                                    type="text"
                                    dark
                                    inputProps={{maxLength: 100}}
                                    {...input}
                                    error={(meta.error || meta.submitError) && meta.touched}
                                    errorMessage={meta.error}
                                />
                            </div>
                        )}
                    </Field>
                    <Field name="city">
                        {({input, meta}) => (
                            <div className={classes.row}>
                                <TextInput
                                    placeholder="City"
                                    type="text"
                                    dark
                                    inputProps={{maxLength: 25}}
                                    {...input}
                                    error={(meta.error || meta.submitError) && meta.touched}
                                    errorMessage={meta.error}
                                />
                            </div>
                        )}
                    </Field>
                    <Field name="state">
                        {({input, meta}) => (
                            <div className={classes.row}>
                                <TextInput
                                    placeholder="State"
                                    type="text"
                                    dark
                                    inputProps={{maxLength: 25}}
                                    {...input}
                                    error={(meta.error || meta.submitError) && meta.touched}
                                    errorMessage={meta.error}
                                />
                            </div>
                        )}
                    </Field>
                    <Field name="postalCode">
                        {({input, meta}) => (
                            <div className={classes.row}>
                                <TextInput
                                    placeholder="Postal Code"
                                    type="number"
                                    dark
                                    {...input}
                                    error={(meta.error || meta.submitError) && meta.touched}
                                    errorMessage={meta.error}
                                />
                            </div>
                        )}
                    </Field>
                    <Field name="phone">
                        {({input, meta}) => (
                            <div className={classes.row}>
                                <TextInput
                                    placeholder="Phone"
                                    type="number"
                                    dark
                                    {...input}
                                    error={(meta.error || meta.submitError) && meta.touched}
                                    errorMessage={meta.error}
                                />
                            </div>
                        )}
                    </Field>
                </div>
                {submitFailed && <div className={classes.error}>Failed to save</div>}
                {submitSucceeded && <div className={`success ${classes.success}`}>Data has been saved</div>}
                <div className={classes.row}>
                    <DarkButton onClick={handleSubmit}>SAVE<input type="submit" style={{display:'none'}}/></DarkButton>
                </div>
            </form>
        );
    }

    render() {
        const {classes} = this.props;
        const {submitting, initialValues} = this.state;
        return (
            <Panel className={classes.panel}>
                <div className={classes.container}>
                    <div className={classes.header}>
                        <Typography variant="h4" color="textSecondary">Edit Your Account</Typography>
                    </div>
                    <Panel
                        className={classes.formPanel}
                        light
                        onClose={this.handleOnClose}
                        loading={submitting}
                    >
                        <Form
                            onSubmit={this.handleOnSubmit}
                            validate={this.handleValidate}
                            render={this.renderForm}
                            initialValues={initialValues}
                        />
                    </Panel>
                </div>
            </Panel>
        );
    }
}

const mapStateToProps = (state) => ({
    user: userPropertiesSelector(state),
    billing: userBillingSelector(state),
    countries: dictionaryAdaptCountriesList(state),
});

const mapDispatchToProps = (dispatch) => ({
    updateUserDetails: (data) => dispatch(UserActions.userDetailsSuccess(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EditAccount));
