
const styles = (theme) => ({
    container: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        paddingTop: 45,
        paddingLeft: 10,
    },
});

export default styles;
