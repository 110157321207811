import {createSelector} from 'reselect';
import {NAME} from '../HomeRedux';
export const homeSelector = (state) => state.home;
export const homeListSelector = (state) => homeSelector(state).list;

/**
 * props: {name} see NAME enum
 */
export const homeListItem = createSelector([homeListSelector, (state, props) => (props)], (list, props) => {
    const {name} = props;
    return list.getIn([name]);
});

export const homeListMe = (state) => homeListItem(state, {name: NAME.ME});
export const homeListOther = (state) => homeListItem(state, {name: NAME.OTHER});
