import {createReducer, createActions} from 'reduxsauce';
import Immutable from 'seamless-immutable';

const {Types, Creators} = createActions({
    setPersonPanel: ['data'],
});

export const HomeTypes = Types;
export default Creators;

export const NAME = {
    ME: 'me',
    OTHER: 'other',
};

export const INITIAL_STATE = Immutable({
    list: {},
});

export const setPersonPanel = (state, action) => {
    const {data} = action;

    return state.merge({
        list: {
            [data.name]: data,
        }
    }, {deep: true});
};

export const reducer = createReducer(INITIAL_STATE, {
    [Types.SET_PERSON_PANEL]: setPersonPanel,
});
