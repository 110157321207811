import React from 'react';
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';

import styles from './LightButton.jss';

const LightButton = (props) => {
    const {classes, children, onClick, small = false, tiny = false, ...rest} = props;

    const getRootStyle = () => {
        if (small) return classes.rootSmall;
        else if (tiny) return classes.rootTiny;
        return classes.root;
    };

    const getLabelStyle = () => {
        if (small) return classes.labelSmall;
        else if (tiny) return classes.labelTiny;
        return classes.label;
    };

    const color = 'secondary';

    return (
        <Button
            variant="contained"
            color={color}
            classes={{
                root: getRootStyle(),
                label: getLabelStyle(),
            }}
            fullWidth
            onClick={onClick}
            {...rest}
        >
            {children}
        </Button>
    );
};

export default withStyles(styles)(LightButton);
