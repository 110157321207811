import React, {PureComponent} from 'react';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import styles from './Accordion.jss';

class Accordion extends PureComponent {
    static propTypes = {
        heading: PropTypes.node.isRequired,
        children: PropTypes.node.isRequired,
        dark: PropTypes.bool,
        onChange: PropTypes.func, // Params: event: object, expanded: boolean
    };

    static defaultProps = {
        dark: false,
    };

    render() {
        const {classes, heading, children, dark, onChange} = this.props;
        return (
            <ExpansionPanel
                classes={{
                    root: dark ? classes.rootDark : classes.root,
                    expanded: classes.expanded,
                }}
                onChange={onChange}
            >
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon className={classes.icon} />}>
                    {heading}
                </ExpansionPanelSummary>
                <ExpansionPanelDetails classes={{root: classes.detailsRoot}}>
                    {children}
                </ExpansionPanelDetails>
            </ExpansionPanel>
        );
    }
}

export default withStyles(styles)(Accordion);
