import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Icon from 'react-svg-icon';

import styles from './ListItem.jss';

const ListItem = (props) => {
    const {
        classes, data, onEdit = () => {}, onDelete = () => {},
    } = props;
    
    const handleOnEdit = () => {
        onEdit(data);
    };
    
    const handleOnDelete = () => {
        onDelete(data);
    };

    return (
        <li className={classes.row}>
            <span>{data.name}</span>
            <IconButton className={classes.editAction} onClick={handleOnEdit}>
                <Icon name="svg-i-edit" className={classes.icon} />
            </IconButton>
            <IconButton className={classes.deleteAction} onClick={handleOnDelete}>
                <Icon name="svg-i-delete" className={classes.icon} />
            </IconButton>
        </li>
    );
};

export default withStyles(styles)(ListItem);
