import React, { useState, useRef, useEffect } from 'react';
import cn from '../../../lib/classNames';

import LinkBox from './LinkBox';

import styles from './Accordion.module.scss';

export default function Accordion(props) {
	const {
		className,
		title,
		description,
		footerClassName,
		footer,
		heightClosed = 175,
		btnText = {
			toOpen: 'Read more',
			toClose: 'Minimize',
		},
	} = props;

	const [isAccordionBtn, setIsAccordionBtn] = useState(false);
	const [isOpen, setIsOpen] = useState(false);

	const infoTextRef = useRef(null);

	const toggleAccordion = () => {
		setIsOpen(!isOpen);
	};

	useEffect(() => {
		setIsAccordionBtn(infoTextRef.current.scrollHeight > heightClosed);
	}, [heightClosed]);

	const getHeight = isOpen ? infoTextRef.current.scrollHeight : heightClosed;

	return (
		<div className={cn(styles.accordion, className)}>
			{title && <h4 className={styles.title}>{title}</h4>}
			<div
				ref={infoTextRef}
				className={styles.description}
				style={{ maxHeight: getHeight + 'px' }}
			>
				{description}
				{footer && (
					<footer className={cn(styles.footer, footerClassName)}>
						{footer}
					</footer>
				)}
			</div>
			{isAccordionBtn && (
				<LinkBox
					className={styles['link-read-more']}
					variant='text'
					iconBefore={isOpen ? 'remove_circle_outline' : 'add_circle_outline'}
					onClick={toggleAccordion}
				>
					{isOpen ? btnText.toClose : btnText.toOpen}
				</LinkBox>
			)}
		</div>
	);
}
