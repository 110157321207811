import React, {PureComponent} from 'react';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import {Checkbox} from './index';
import styles from './Modifiers.jss';

class Modifiers extends PureComponent {
    static propTypes = {
        name: PropTypes.string.isRequired,
        classes: PropTypes.object.isRequired,
        className: PropTypes.string,
        items: PropTypes.array.isRequired, // [{label, value}]
        multivalues: PropTypes.bool,
        preselect: PropTypes.array,
        onChange: PropTypes.func, // params {name, list: Array}
    };

    static defaultProps = {
        name: '',
        multivalues: false,
        preselect: [],
    };

    constructor(props) {
        super(props);

        this.state = {
            active: new Set(props.preselect),
        };
    }

    handleOnClick = (el) => {
        this.setState({
            active: this.toggle(el),
        }, () => {
            const {onChange, name} = this.props;
            if (onChange) onChange({
                name,
                list: Array.from(this.state.active)
            });
        });
    }

    toggle(el) {
        const {active} = this.state;
        const {multivalues} = this.props;
        let newList = new Set();
        if (multivalues) {
            newList = new Set(active);
            if (newList.has(el.value)) newList.delete(el.value);
            else newList.add(el.value);
        } else {
            newList.add(el.value);
        }
        return newList;
    }

    renderItem(el, idx) {
        const {active} = this.state;
        const {classes} = this.props;
        return (
            <div key={`${idx}`} className={classes.item}>
                <Checkbox
                    label={el.label}
                    value={el.value}
                    classes={{root: classes.root}}
                    onChange={() => this.handleOnClick(el)}
                    checked={active.has(el.value)}
                    light

                />
            </div>
        );
    }

    render() {
        const {className = '', items} = this.props;
        return (
            <div className={className}>
                {items.map((el, idx) => (
                    this.renderItem(el, idx)
                ))}
            </div>
        );
    }
}

export default withStyles(styles)(Modifiers);
