import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {Typography} from '@material-ui/core';
import {Redirect} from "react-router-dom";

import {Panel, Background, LightButton} from '../../../components';
import {userErrorSelector} from '../../../store/selectors';
import {ROUTE} from '../../../App';

import styles from './TextMessageException.jss';

class TextMessageException extends PureComponent {
    static propTypes = {
        userError: PropTypes.object,
        classes: PropTypes.object,
    };

    authed() {
        const {userError: {errorType}} = this.props;
        return !errorType;
    }

    getContent() {
        const {classes, userError: {errorMessage}} = this.props;
        return (
            <div className={classes.content}>
                <div className={classes.header}>
                    <div className={classes.headerLine}>
                        <Typography variant="h4" color="textSecondary">Message</Typography>
                    </div>
                </div>
                <div className={classes.message}>
                    <Typography variant="h6" color="textSecondary">{errorMessage}</Typography>
                    <div className="godoshboard">
                        <LightButton
                            small
                            fullWidth={false}
                            onClick={() => { document.location = '/'; }}
                        >
                            Go to Dashboard
                        </LightButton>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const {classes} = this.props;
        if (this.authed()) {
            return <Redirect to={ROUTE.default} />;
        } else {
            return (
                <Background>
                    <div className={classes.container}>
                        <Panel className={classes.panel}>
                            {this.getContent()}
                        </Panel>
                    </div>
                </Background>
            );
        }
    }
}

const mapStateToProps = (state) => ({
    userError: userErrorSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TextMessageException));
