import {createReducer, createActions} from 'reduxsauce';

const {Types, Creators} = createActions({
    getCountriesRequest: null,
    getCountriesSuccess: ['data'],
    getCountriesFailure: ['data'],

    getRacesRequest: null,
    getRacesSuccess: ['data'],
    getRacesFailure: ['data'],

    getGendersRequest: null,
    getGendersSuccess: ['data'],
    getGendersFailure: ['data'],

    getModifiersRequest: ['data'],
    getModifiersSuccess: ['data'],
    getModifiersFailure: ['data'],
});

export const DictionaryTypes = Types;
export default Creators;

export const DICTIONARY_PENDING = {
    getRaces: 'getRaces',
    getGenders: 'getGenders',
    getModifiers: 'getModifiers',
    getCountries: 'getCountries',
};

export const INITIAL_COUNTRIES = {
    error: false,
    items: [],
};

export const INITIAL_RACES = {
    error: false,
    items: [],
};

export const INITIAL_GENDERS = {
    error: false,
    items: [],
};

export const INITIAL_MODIFIER_GROUPS = {
    error: false,
    items: [],
};

export const INITIAL_STATE = {
    countries: INITIAL_COUNTRIES,
    races: INITIAL_RACES,
    genders: INITIAL_GENDERS,
    modifiers: INITIAL_MODIFIER_GROUPS,
};

// countries
export const adaptCountriesResponse = (data) => {
    if (data && data.length) {
        return data.map((el, idx) => ({
            id: el.code2,
            name: el.country,
        }));
    }
    return [];
};
export const getCountriesRequest = (state, action) => {
    return {
        ...state,
        countries: {
            error: true,
            items: [],
        }
    };
};
export const getCountriesSuccess = (state, action) => {
    const {data} = action;
    const items = adaptCountriesResponse(data);
    return {
        ...state,
        countries: {
            error: false,
            items,
        }
    };
};
export const getCountriesFailure = (state, action) => {
    return {
        ...state,
        countries: {error: true}
    };
};

// races
export const adaptRacesResponse = (data) => {
    if (data && data.length) {
        return data.map((el, idx) => ({
            id: el.id,
            name: el.name,
            description: el.description,
            showOrder: el.showOrder,
        }));
    }
    return [];
};
export const getRacesRequest = (state, action) => {
    return {
        ...state,
        races: {
            error: true,
            items: [],
        }
    };
};
export const getRacesSuccess = (state, action) => {
    const {data} = action;
    const items = adaptRacesResponse(data);
    return {
        ...state,
        races: {
            error: false,
            items,
        }
    };
};
export const getRacesFailure = (state, action) => {
    return {
        ...state,
        races: {error: true}
    };
};

// genders ['M', 'F']
export const adaptGendersResponse = (data) => {
    if (data && data.length) {
        return data.map((el, idx) => ({
            id: el,
            name: el,
        }));
    }
    return [];
};
export const getGendersRequest = (state, action) => {
    return {
        ...state,
        genders: {
            error: true,
            items: [],
        }
    };
};
export const getGendersSuccess = (state, action) => {
    const {data} = action;
    const items = adaptGendersResponse(data);
    return {
        ...state,
        genders: {
            error: false,
            items,
        }
    };
};
export const getGendersFailure = (state, action) => {
    return {
        ...state,
        genders: {error: true}
    };
};

// modifiers
export const adaptModifiersResponse = (data) => {
    if (data && data.length) {
        return data.map((el, idx) => {
            const group = el;
            return {
                id: group.id,
                code: group.code,
                name: group.name,
                description: group.description,
                uniqueness: group.uniqueness,
                required: group.required,
                type: group.type,
                showOrder: group.showOrder,
                enabled: group.enabled,
                modifiers: group.modifiers.map((m) => ({
                    id: m.id,
                    code: m.code,
                    name: m.name,
                    description: m.description,
                    showOrder: m.showOrder,
                    enabled: m.enabled,
                    setAsDefault: m.setAsDefault,
                })),
            };
        });
    }
    return [];
};
export const getModifiersRequest = (state, action) => {
    return {
        ...state,
        modifiers: {
            error: true,
            items: [],
        }
    };
};
export const getModifiersSuccess = (state, action) => {
    const {data} = action;
    const items = adaptModifiersResponse(data);
    return {
        ...state,
        modifiers: {
            error: false,
            items,
        }
    };
};
export const getModifiersFailure = (state, action) => {
    return {
        ...state,
        modifiers: {error: true}
    };
};

export const reducer = createReducer(INITIAL_STATE, {
    [Types.GET_COUNTRIES_REQUEST]: getCountriesRequest,
    [Types.GET_COUNTRIES_SUCCESS]: getCountriesSuccess,
    [Types.GET_COUNTRIES_FAILURE]: getCountriesFailure,

    [Types.GET_RACES_REQUEST]: getRacesRequest,
    [Types.GET_RACES_SUCCESS]: getRacesSuccess,
    [Types.GET_RACES_FAILURE]: getRacesFailure,

    [Types.GET_GENDERS_REQUEST]: getGendersRequest,
    [Types.GET_GENDERS_SUCCESS]: getGendersSuccess,
    [Types.GET_GENDERS_FAILURE]: getGendersFailure,

    [Types.GET_MODIFIERS_REQUEST]: getModifiersRequest,
    [Types.GET_MODIFIERS_SUCCESS]: getModifiersSuccess,
    [Types.GET_MODIFIERS_FAILURE]: getModifiersFailure,
});
