import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import get from 'get-value';

import { DarkButton } from '../../../components';

import styles from './ReportsTable.jss';

class ReportsTable extends PureComponent {
	static propTypes = {
		classes: PropTypes.object,
		list: PropTypes.arrayOf(PropTypes.object),
		onClickView: PropTypes.func,
		onScroll: PropTypes.func,
	};

	onClickView(report) {
		const { onClickView } = this.props;
		if (onClickView) onClickView(report);
	}

	handleScroll = (e) => {
		const { onScroll } = this.props;
		if (onScroll) onScroll(e);
	};

	renderRow(report, index) {
		const destination = get(report, 'destination', { default: '' });
		return (
			<div className='history-report-row' key={index}>
				<div className='col-wrap'>
					<div className='col column-name'>
						<div className='name'>{report.travelerName}</div>
						<div>trip to {destination}</div>
					</div>
					<div className='col column-date-create'>{report.createdAt}</div>
				</div>
				<div className='col column-btn'>
					<DarkButton
						small
						fullWidth={false}
						onClick={() => {
							this.onClickView(report);
						}}
					>
						View
					</DarkButton>
				</div>
			</div>
		);
	}

	renderNoData() {
		return <div className='no-data'>No data yet</div>;
	}

	render() {
		const { classes, list, bottom } = this.props;
		return (
			<div
				className={`${classes.container} scrollbar1`}
				onScroll={this.handleScroll}
			>
				<div className={classes.historyReportWrap}>
					{list.map((el, index) => this.renderRow(el, index))}
					{list.length === 0 && this.renderNoData()}
				</div>
				{bottom}
			</div>
		);
	}
}

export default withStyles(styles)(ReportsTable);
