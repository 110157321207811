const styles = (theme) => ({
	alert: {
		width: 'calc(100% - 3px)',
		boxSizing: 'border-box',
		backgroundColor: '#fdd596',
		padding: '15px 15px 15px 44px',
		marginBottom: 20,
		borderRadius: 10,
		boxShadow: '0 0 2px 1px rgba(0, 0, 0, 0.1), 0 2px 3px rgba(0, 0, 0, 0.16)',
		color: theme.palette.primary.dark,
		position: 'relative',

		'& .icon': {
			position: 'absolute',
			top: 14,
			left: 15,
		},

		'& h3': {
			margin: 0,
		},

		'& p': {
			margin: '7px 0 0',
		},
	}
});

export default styles;