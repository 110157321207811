import React, {PureComponent} from 'react';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './Scale.jss';

// import scaleRed from '../../images/scale-red.png';
// import scaleBlue from '../../images/scale-blue.png';
// import scaleYellow from '../../images/scale-yellow.png';

export const Color = {
    red: {
        color: '#ff0000',
        bg: '#ff0000',
        // bg: scaleRed,
    },
    blue: {
        color: '#7EF2F3',
        bg: '#7EF2F3',
        // bg: scaleBlue,
    },
    yellow: {
        color: '#FFCB00',
        bg: '#FFCB00',
        // bg: scaleYellow,
    },
};

class Scale extends PureComponent {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        start: PropTypes.number.isRequired,
        end: PropTypes.number.isRequired,
        min: PropTypes.number.isRequired,
        max: PropTypes.number.isRequired,
        color: PropTypes.object.isRequired, // see Color
        showMinMax: PropTypes.bool,
        onRender: PropTypes.func, // {values, points}
    };

    static defaultProps = {
        showMinMax: true,
        color: Color.red,
    };

    state = {
        containerRect: null,
    };

    handleContainerRef = (el) => {
        if (el) {
            this.setState({
                containerRect: el.getBoundingClientRect(), // {width, height, etc..}
            });
        }
    };

    getContent() {
        const {containerRect} = this.state;
        if (containerRect) {
            const {
                classes, color, start, end, min, max, showMinMax, onRender,
            } = this.props;
            if (Number.isFinite(start)
                && Number.isFinite(end)
                && Number.isFinite(min)
                && Number.isFinite(max)
            ) {
                const w = containerRect.width;
                const rate = w / (end - start);
                const points = {
                    min: (min - start) * rate,
                    max: (max - start) * rate,
                    width: (max - min) * rate,
                };

                const elements = [<div key="0" className={classes.valueLine}></div>];
                if (showMinMax) {
                    const minMaxStyle = {
                        left: points.min,
                        width: points.width,
                        backgroundColor: color.bg,
                    };
                    elements.push(<div key="1" className={classes.minMax} style={minMaxStyle}></div>);
                }
                if (onRender) onRender({
                    values: {min, max},
                    points: points,
                });
                return elements;
            }
        }
        return null;
    }

    render() {
        const {classes, showMinMax} = this.props;
        return (
            <div
                className={classNames(classes.container, {[classes.minMaxMargin]: showMinMax})}
                ref={this.handleContainerRef}
            >
                {this.getContent()}
            </div>
        );
    }
}

export default withStyles(styles)(Scale);
