import React from 'react';

import { Color } from '../../theme';

const RiskRatingChart = (props) => {
	const {
		value,
		valueFontSize = 35,
		valueVerticalPosition = '85',
		text = 'Counties Ranking',
		colors = '',
	} = props;

	const valueNum = value > 0 ? value : 'N.A.';

	const colorsArr = [
		Color.heatmap1,
		Color.heatmap2,
		Color.heatmap3,
		Color.heatmap4,
		Color.heatmap5,
		Color.heatmap6,
		Color.heatmap7,
		Color.heatmap8,
		Color.heatmap9,
		Color.heatmap10,
		Color.heatmap11,
	];

	const colorsBlueArr = [
		Color.blueHeatmap1,
		Color.blueHeatmap2,
		Color.blueHeatmap3,
		Color.blueHeatmap4,
		Color.blueHeatmap5,
		Color.blueHeatmap6,
		Color.blueHeatmap7,
		Color.blueHeatmap8,
		Color.blueHeatmap9,
		Color.blueHeatmap10,
	];

	const colorsPaletteArr = colors === 'blue' ? { ...colorsBlueArr } : { ...colorsArr };

	const valueColor = colorsPaletteArr[value - 1];

	const ratio = 204.545 / 100;

	const padding = (v) => -18 * (0.07 * v - 1);

	const dasharray = (v) => {
		const l = Math.round((232 * v) / 11) - padding(v);
		const ll = 493 - l;
		return `${l * ratio} ${ll * ratio}`;
	};

	return (
		<svg viewBox='0 0 450 231' fill='none' xmlns='http://www.w3.org/2000/svg'>

			<path d="M379.834 209.601C379.834 141.993 309.356 66.3115 222.417 66.3115C135.478 66.3115 65 141.993 65 209.601" stroke="#DEE2E5" strokeWidth="40" strokeLinecap="round" strokeLinejoin="round" />

			<circle
				cx={109.2 * ratio}
				cy={110.5 * ratio}
				r={78 * ratio}
				stroke={valueColor}
				strokeDasharray={dasharray(value)}
				strokeDashoffset={239.4 * ratio}
				strokeWidth={21 * ratio}
				fill='none'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>

			<path d="M38.3237 183.354C38.7346 180.623 36.854 178.076 34.1233 177.666C31.3926 177.255 28.8459 179.135 28.435 181.866C27.1398 190.474 25.3991 205.226 25.2444 214.077C25.1961 216.838 27.3953 219.115 30.1563 219.163C32.9173 219.212 35.1946 217.013 35.2429 214.252C35.3898 205.844 37.0934 191.53 38.3237 183.354Z" fill={colorsPaletteArr[0]} />

			<path d="M58.835 129.488C60.0627 127.014 59.0527 124.014 56.5792 122.786C54.1057 121.559 51.1052 122.569 49.8776 125.042C45.9906 132.874 39.6038 146.978 36.7535 155.309C35.8596 157.922 37.253 160.765 39.8657 161.659C42.4785 162.553 45.3211 161.159 46.2151 158.547C48.923 150.632 55.1411 136.931 58.835 129.488Z" fill={colorsPaletteArr[1]} />

			<path d="M92.335 91.3937C94.4104 89.5722 94.6162 86.413 92.7947 84.3376C90.9731 82.2622 87.814 82.0564 85.7386 83.8779C79.1457 89.6643 70.9348 97.8752 63.1484 106.468C61.3269 108.543 61.5327 111.703 63.6081 113.524C65.6836 115.346 68.8427 115.14 70.6642 113.064C76.1644 106.798 86.0682 96.8939 92.335 91.3937Z" fill={colorsPaletteArr[2]} />

			<path d="M134.778 60.6557C137.253 59.4325 138.269 56.4339 137.046 53.9582C135.822 51.4824 132.824 50.4671 130.348 51.6903C122.454 55.5907 109.853 63.9939 102.588 68.8578C100.293 70.3941 99.6786 73.4996 101.215 75.7943C102.751 78.0889 105.857 78.7038 108.151 77.1675C115.056 72.5451 127.278 64.3613 134.778 60.6557Z" fill={colorsPaletteArr[3]} />

			<path d="M181.987 43.6344C184.696 43.0985 186.458 40.468 185.922 37.7591C185.386 35.0502 182.756 33.2886 180.047 33.8245C171.363 35.5424 156.88 40.8197 148.637 43.6173C146.022 44.5047 144.621 47.344 145.509 49.9589C146.396 52.5738 149.236 53.9742 151.85 53.0868C159.68 50.4296 173.738 45.2664 181.987 43.6344Z" fill={colorsPaletteArr[4]} />

			<path d="M221.831 28.999C217.413 28.999 209.027 29.1417 204.678 29.4227C201.922 29.6008 199.833 31.9791 200.011 34.7348C200.189 37.4904 202.567 39.58 205.323 39.4019C209.457 39.1347 217.628 38.999 221.831 38.999C226.035 38.999 234.206 39.1347 238.34 39.4019C241.096 39.58 243.474 37.4904 243.652 34.7348C243.83 31.9791 241.741 29.6008 238.985 29.4227C234.636 29.1417 226.25 28.999 221.831 28.999Z" fill={colorsPaletteArr[5]} />

			<path d="M262.054 43.6344C259.345 43.0985 257.583 40.468 258.119 37.7591C258.655 35.0502 261.285 33.2886 263.994 33.8245C272.678 35.5424 287.161 40.8197 295.404 43.6173C298.019 44.5047 299.42 47.344 298.532 49.9589C297.645 52.5738 294.806 53.9742 292.191 53.0868C284.361 50.4296 270.303 45.2664 262.054 43.6344Z" fill={colorsPaletteArr[6]} />

			<path d="M309.263 60.6557C306.788 59.4325 305.772 56.4339 306.995 53.9582C308.219 51.4824 311.217 50.4671 313.693 51.6903C321.587 55.5907 334.188 63.9939 341.453 68.8578C343.748 70.3941 344.362 73.4996 342.826 75.7943C341.29 78.0889 338.184 78.7038 335.89 77.1675C328.985 72.5451 316.763 64.3613 309.263 60.6557Z" fill={colorsPaletteArr[7]} />

			<path d="M351.706 91.3937C349.631 89.5722 349.425 86.413 351.246 84.3376C353.068 82.2622 356.227 82.0564 358.302 83.8779C364.895 89.6643 373.106 97.8752 380.893 106.468C382.714 108.543 382.508 111.703 380.433 113.524C378.357 115.346 375.198 115.14 373.377 113.064C367.877 106.798 357.973 96.8939 351.706 91.3937Z" fill={colorsPaletteArr[8]} />

			<path d="M385.206 129.488C383.978 127.014 384.988 124.014 387.462 122.786C389.935 121.559 392.936 122.569 394.163 125.042C398.05 132.874 404.437 146.978 407.288 155.309C408.181 157.922 406.788 160.765 404.175 161.659C401.563 162.553 398.72 161.159 397.826 158.547C395.118 150.632 388.9 136.931 385.206 129.488Z" fill={colorsPaletteArr[9]} />

			<path d="M405.717 183.354C405.306 180.623 407.187 178.076 409.918 177.666C412.648 177.255 415.195 179.135 415.606 181.866C416.901 190.474 418.642 205.226 418.797 214.077C418.845 216.838 416.646 219.115 413.885 219.163C411.124 219.212 408.846 217.013 408.798 214.252C408.651 205.844 406.948 191.53 405.717 183.354Z" fill={colorsPaletteArr[10]} />

			<path d="M10.4415 200.598V199.626H12.7203V192.746L10.7018 194.187V193.107L12.8155 191.654H13.8693V199.626H16.0465V200.598H10.4415Z" fill="#7D8C97" />

			<path d="M29.2848 137.999V137.193C29.5006 136.698 29.763 136.262 30.0719 135.885C30.385 135.504 30.713 135.162 31.0558 134.857C31.3985 134.548 31.7371 134.262 32.0714 134C32.4099 133.738 32.7146 133.475 32.9855 133.213C33.2563 132.951 33.4742 132.675 33.6393 132.388C33.8085 132.1 33.8932 131.774 33.8932 131.41C33.8932 130.919 33.7493 130.538 33.4615 130.268C33.1738 129.997 32.7739 129.861 32.2618 129.861C31.7752 129.861 31.3732 129.995 31.0558 130.261C30.7426 130.524 30.5585 130.894 30.5035 131.372L29.3356 131.264C29.4202 130.549 29.7228 129.98 30.2433 129.557C30.768 129.133 31.4409 128.922 32.2618 128.922C33.1632 128.922 33.8551 129.136 34.3375 129.563C34.8242 129.986 35.0675 130.589 35.0675 131.372C35.0675 131.719 34.9871 132.064 34.8263 132.407C34.6697 132.75 34.4348 133.092 34.1217 133.435C33.8085 133.778 33.2097 134.309 32.3253 135.028C31.8387 135.426 31.4514 135.786 31.1637 136.107C30.8759 136.425 30.6686 136.732 30.5416 137.028H35.2071V137.999H29.2848Z" fill="#7D8C97" />

			<path d="M69.6373 86.5298C69.6373 87.355 69.3749 87.994 68.8502 88.4468C68.3255 88.8996 67.5764 89.126 66.6031 89.126C65.6975 89.126 64.9739 88.9229 64.4322 88.5166C63.8948 88.1061 63.5753 87.501 63.4737 86.7012L64.6544 86.5933C64.8068 87.6512 65.4563 88.1802 66.6031 88.1802C67.1787 88.1802 67.6293 88.0384 67.9552 87.7549C68.2853 87.4714 68.4503 87.0503 68.4503 86.4917C68.4503 86.005 68.262 85.6263 67.8854 85.3555C67.513 85.0804 66.9734 84.9429 66.2667 84.9429H65.6193V83.9526H66.2413C66.8676 83.9526 67.3522 83.8172 67.6949 83.5464C68.0419 83.2713 68.2154 82.8926 68.2154 82.4102C68.2154 81.932 68.0737 81.5553 67.7901 81.2803C67.5109 81.001 67.094 80.8613 66.5397 80.8613C66.0361 80.8613 65.6277 80.9904 65.3146 81.2485C65.0056 81.5067 64.8258 81.8706 64.775 82.3403L63.6261 82.2515C63.7107 81.5194 64.0133 80.9481 64.5338 80.5376C65.0585 80.1271 65.7314 79.9219 66.5524 79.9219C67.4495 79.9219 68.1456 80.1313 68.6407 80.5503C69.1401 80.965 69.3898 81.5448 69.3898 82.2896C69.3898 82.8608 69.229 83.3263 68.9073 83.686C68.59 84.0415 68.1266 84.2827 67.5172 84.4097V84.4351C68.1858 84.507 68.7063 84.7313 69.0787 85.1079C69.4511 85.4845 69.6373 85.9585 69.6373 86.5298Z" fill="#7D8C97" />

			<path d="M114.057 50.9741V52.999H112.978V50.9741H108.763V50.0854L112.858 44.0552H114.057V50.0728H115.314V50.9741H114.057ZM112.978 45.3438C112.97 45.3691 112.91 45.4813 112.8 45.6802C112.69 45.8791 112.608 46.0187 112.553 46.0991L110.261 49.4761L109.919 49.9458L109.817 50.0728H112.978V45.3438Z" fill="#7D8C97" />

			<path d="M162.696 26.0854C162.696 27.0291 162.414 27.7718 161.852 28.3135C161.293 28.8551 160.517 29.126 159.522 29.126C158.688 29.126 158.016 28.944 157.504 28.5801C156.991 28.2161 156.668 27.6893 156.532 26.9995L157.688 26.8662C157.929 27.7507 158.549 28.1929 159.547 28.1929C160.161 28.1929 160.641 28.0088 160.988 27.6406C161.335 27.2682 161.509 26.7583 161.509 26.1108C161.509 25.548 161.333 25.0931 160.982 24.7461C160.635 24.3991 160.165 24.2256 159.573 24.2256C159.264 24.2256 158.976 24.2743 158.71 24.3716C158.443 24.4689 158.176 24.634 157.91 24.8667H156.793L157.091 20.0552H162.175V21.0264H158.132L157.961 23.8638C158.456 23.4829 159.071 23.2925 159.808 23.2925C160.688 23.2925 161.388 23.5506 161.909 24.0669C162.434 24.5832 162.696 25.256 162.696 26.0854Z" fill="#7D8C97" />

			<path d="M225.104 17.0728C225.104 18.0164 224.848 18.7612 224.336 19.3071C223.824 19.853 223.117 20.126 222.216 20.126C221.209 20.126 220.439 19.7515 219.905 19.0024C219.372 18.2534 219.106 17.1637 219.106 15.7334C219.106 14.1846 219.383 12.9954 219.937 12.166C220.491 11.3366 221.281 10.9219 222.305 10.9219C223.655 10.9219 224.505 11.5291 224.857 12.7437L223.765 12.9404C223.54 12.2126 223.05 11.8486 222.292 11.8486C221.64 11.8486 221.135 12.1533 220.775 12.7627C220.42 13.3678 220.242 14.2459 220.242 15.397C220.449 15.0119 220.741 14.7199 221.118 14.521C221.494 14.3179 221.926 14.2163 222.413 14.2163C223.238 14.2163 223.892 14.4744 224.374 14.9907C224.861 15.507 225.104 16.201 225.104 17.0728ZM223.942 17.1235C223.942 16.4761 223.784 15.9767 223.466 15.6255C223.149 15.2743 222.707 15.0986 222.14 15.0986C221.607 15.0986 221.175 15.2552 220.845 15.5684C220.519 15.8773 220.356 16.3047 220.356 16.8506C220.356 17.5404 220.525 18.1053 220.864 18.5454C221.207 18.9855 221.645 19.2056 222.178 19.2056C222.728 19.2056 223.16 19.0215 223.473 18.6533C223.786 18.2809 223.942 17.771 223.942 17.1235Z" fill="#7D8C97" />

			<path d="M287.149 20.9819C286.234 22.3784 285.589 23.4723 285.213 24.2637C284.836 25.055 284.552 25.8358 284.362 26.606C284.176 27.3761 284.083 28.1738 284.083 28.999H282.889C282.889 27.8564 283.13 26.6546 283.613 25.3936C284.1 24.1283 284.91 22.6725 286.044 21.0264H281.239V20.0552H287.149V20.9819Z" fill="#7D8C97" />

			<path d="M334.364 50.5044C334.364 51.3296 334.102 51.9728 333.577 52.4341C333.053 52.8953 332.299 53.126 331.317 53.126C330.361 53.126 329.612 52.8996 329.07 52.4468C328.533 51.994 328.264 51.3507 328.264 50.5171C328.264 49.9331 328.431 49.4422 328.766 49.0444C329.1 48.6466 329.527 48.4054 330.048 48.3208V48.2954C329.561 48.1812 329.176 47.9336 328.893 47.5527C328.613 47.1719 328.474 46.7254 328.474 46.2134C328.474 45.5321 328.728 44.9798 329.235 44.5566C329.748 44.1335 330.433 43.9219 331.292 43.9219C332.172 43.9219 332.866 44.1292 333.374 44.5439C333.886 44.9587 334.142 45.5194 334.142 46.2261C334.142 46.7381 334 47.1846 333.717 47.5654C333.433 47.9463 333.046 48.1854 332.555 48.2827V48.3081C333.127 48.4012 333.571 48.6445 333.888 49.0381C334.206 49.4274 334.364 49.9162 334.364 50.5044ZM332.955 46.2896C332.955 45.2782 332.401 44.7725 331.292 44.7725C330.755 44.7725 330.344 44.8994 330.061 45.1533C329.781 45.4072 329.642 45.786 329.642 46.2896C329.642 46.8016 329.786 47.193 330.073 47.4639C330.365 47.7305 330.776 47.8638 331.305 47.8638C331.842 47.8638 332.251 47.741 332.53 47.4956C332.813 47.2459 332.955 46.8439 332.955 46.2896ZM333.177 50.3965C333.177 49.8421 333.012 49.4253 332.682 49.146C332.352 48.8625 331.889 48.7207 331.292 48.7207C330.712 48.7207 330.26 48.873 329.934 49.1777C329.608 49.4782 329.445 49.8929 329.445 50.4219C329.445 51.6533 330.073 52.269 331.33 52.269C331.952 52.269 332.416 52.1209 332.72 51.8247C333.025 51.5243 333.177 51.0482 333.177 50.3965Z" fill="#7D8C97" />

			<path d="M380.06 84.3462C380.06 85.8823 379.778 87.063 379.215 87.8882C378.657 88.7134 377.859 89.126 376.822 89.126C376.124 89.126 375.563 88.98 375.14 88.688C374.721 88.3918 374.421 87.9157 374.239 87.2598L375.331 87.0884C375.559 87.8332 376.063 88.2056 376.841 88.2056C377.497 88.2056 378.005 87.9009 378.365 87.2915C378.725 86.6821 378.913 85.8125 378.93 84.6826C378.761 85.0635 378.471 85.3703 378.06 85.603C377.65 85.8315 377.199 85.9458 376.708 85.9458C375.904 85.9458 375.261 85.6707 374.778 85.1206C374.296 84.5705 374.055 83.8405 374.055 82.9307C374.055 81.9954 374.317 81.2612 374.842 80.728C375.367 80.1906 376.097 79.9219 377.032 79.9219C378.026 79.9219 378.78 80.29 379.292 81.0264C379.804 81.7627 380.06 82.8693 380.06 84.3462ZM378.816 83.2417C378.816 82.5223 378.651 81.9447 378.32 81.5088C377.99 81.0687 377.548 80.8486 376.994 80.8486C376.444 80.8486 376.01 81.0369 375.692 81.4136C375.375 81.786 375.216 82.2917 375.216 82.9307C375.216 83.5824 375.375 84.0986 375.692 84.4795C376.01 84.8561 376.439 85.0444 376.981 85.0444C377.311 85.0444 377.618 84.9704 377.901 84.8223C378.185 84.6699 378.407 84.4562 378.568 84.1812C378.733 83.9061 378.816 83.5929 378.816 83.2417Z" fill="#7D8C97" />

			<path d="M407.872 137.999V137.028H410.151V130.147L408.132 131.588V130.509L410.246 129.055H411.3V137.028H413.477V137.999H407.872ZM420.84 133.524C420.84 135.018 420.576 136.158 420.047 136.945C419.522 137.732 418.746 138.126 417.717 138.126C416.689 138.126 415.917 137.735 415.4 136.952C414.884 136.169 414.626 135.026 414.626 133.524C414.626 131.988 414.876 130.837 415.375 130.071C415.879 129.305 416.672 128.922 417.755 128.922C418.809 128.922 419.586 129.309 420.085 130.083C420.588 130.858 420.84 132.005 420.84 133.524ZM419.679 133.524C419.679 132.233 419.528 131.298 419.228 130.718C418.932 130.139 418.441 129.849 417.755 129.849C417.053 129.849 416.547 130.134 416.238 130.706C415.934 131.277 415.781 132.216 415.781 133.524C415.781 134.793 415.936 135.722 416.245 136.311C416.558 136.899 417.053 137.193 417.73 137.193C418.403 137.193 418.896 136.892 419.209 136.292C419.522 135.691 419.679 134.768 419.679 133.524Z" fill="#7D8C97" />

			<path d="M425.3 200.598V199.626H427.579V192.746L425.56 194.187V193.107L427.674 191.654H428.728V199.626H430.905V200.598H425.3ZM431.571 200.598V199.626H433.85V192.746L431.832 194.187V193.107L433.945 191.654H434.999V199.626H437.176V200.598H431.571Z" fill="#7D8C97" />

			<text
				x='219'
				y={valueVerticalPosition}
				fontSize={`${valueFontSize}px`}
				fill='#233544'
				textAnchor='middle'
			>
				{valueNum}
			</text>
			<text x='219' y='105' fontSize='13px' fill='#7D8C97' textAnchor='middle'>
				{text}
			</text>
			<defs>
				<linearGradient
					id='paint0_linear'
					x1='29'
					y1='111.5'
					x2='47'
					y2='57'
					gradientUnits='userSpaceOnUse'
				>
					<stop stopColor='#6AD400' />
					<stop offset='1' stopColor='#97D101' />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default RiskRatingChart;
