import React from 'react';
import cn from '../../../lib/classNames';

import styles from './SectionTitle.module.scss';

export default function SectionTitle(props) {
	const { className, title, subTitle } = props;

	return (
		<section className={cn(className, styles['section-title'])}>
			<h3>{title}</h3>
			{subTitle && <p className={styles['sub-title']}>{subTitle}</p>}
		</section>
	);
}
