
const styles = (theme) => ({
    icon: {
        width: 21,
        height: 21,
        stroke: '#7D8C97',
    }
});

export default styles;
