import {put, call} from 'redux-saga/effects';
import GeocodingActions, {GEOCODING_PENDING} from '../store/GeocodingRedux';
import NetworkActions from '../store/NetworkRedux';

export function* searchPlaces(api, action) {
    const pending = GEOCODING_PENDING.searchPlaces;
    const {query} = action;

    yield put(NetworkActions.networkAddPending(pending));
    const response = yield call(() => api.searchPlaces(query));

    if (response.ok) {
        yield put(GeocodingActions.searchPlacesSuccess(response.data, response.headers));
        yield put(NetworkActions.networkDelPending(pending));
    } else {
        yield put(GeocodingActions.searchPlacesFailure(response));
        yield put(NetworkActions.networkDelPending(pending));
    }

    return response;
}
