import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Form, Field } from "react-final-form";
import Joi from 'joi-browser';
import { connect } from 'react-redux';

import { Panel, TextInput, DarkButton, Autocomplete } from '../../../components';
import { getFinalFormErrors } from '../../../lib/form';
import { api } from '../../../sagas';
import {
    dictionaryAdaptGendersList, dictionaryAdaptRacesList,
} from '../../../store/selectors';
import { adaptSearchPlacesResponse } from '../../../store/GeocodingRedux';

import styles from './EditProperties.jss';

const errorMessages = {
    name: { 'default': 'Name is required' },
    age: { 'default': 'Age is required' },
    gender: { 'default': 'Gender is required' },
    race: { 'default': 'Race is required' },
    locationName: { 'default': 'Location is required' },
};

class EditProperties extends PureComponent {
    static propTypes = {
        user: PropTypes.object,
        genders: PropTypes.array,
        races: PropTypes.array,
        onClose: PropTypes.func,
        onSave: PropTypes.func,
        addEndpoint: PropTypes.func.isRequired,
        updateEndpoint: PropTypes.func.isRequired,
        onChangePlace: PropTypes.func,
    };

    constructor(props) {
        super(props);

        const { user: { locationName, latitude, longitude, locationMapboxId } } = props;
        this.state = {
            submitting: false,
            locationName,
            locationX: longitude,
            locationY: latitude,
            locationId: locationMapboxId,
        };
    }

    handleOnClose = () => {
        const { onClose } = this.props;
        if (onClose) onClose();
    }

    handleOnSubmit = (values) => {
        const { user, user: { id }, onSave, addEndpoint, updateEndpoint } = this.props;
        const { locationName, locationX, locationY, locationId } = this.state;
        const errorMessage = 'Change profile error';
        return new Promise((resolve, reject) => {
            this.setState({ submitting: true });
            const data = {
                name: values.name,
                age: values.age,
                gender: values.gender,
                // raceId: values.race,
                locationName,
                locationX,
                locationY,
                locationId,
            };

            const endpoint = (id) ? updateEndpoint : addEndpoint;
            endpoint(id, data).then((response) => {
                this.setState({ submitting: false });
                if (response.ok) {
                    resolve();
                    if (onSave) onSave(user);
                }
                else throw new Error(errorMessage);
            }).catch((e) => {
                this.setState({ submitting: false });
                resolve(errorMessage);
            });
        });
    }

    handleValidate = (values) => {
        let errors = {};

        const schema = Joi.object({
            name: Joi.string().required(),
            age: Joi.number().required(),
            gender: Joi.string().required(),
            // race: Joi.number().required(),
            locationName: Joi.string().required(),
        });
        const result = Joi.validate(values, schema, { abortEarly: false, allowUnknown: true }).error;
        if (result) errors = getFinalFormErrors(result.details, errorMessages);

        return errors;
    }

    handleLoadPlaces = (query) => {
        return (new Promise(resolve => {
            api.searchPlaces(query).then((response) => {
                const items = adaptSearchPlacesResponse(response.data);
                resolve(items.map((el) => ({
                    label: el.name,
                    value: el,
                })));
            });
        }));
    }

    handleOnChangePlace = (place) => {
        const { onChangePlace } = this.props;
        this.setState({
            locationName: place.value.name,
            locationY: place.value.latitude,
            locationX: place.value.longitude,
            locationId: place.value.id
        });
        if (onChangePlace) onChangePlace(place.value);
    }

    renderForm = ({
        handleSubmit,
        submitFailed,
        submitSucceeded,
    }) => {
        const { classes, genders } = this.props;
        return (
            <form onSubmit={handleSubmit} className="light">
                <Field name="name">
                    {({ input, meta }) => (
                        <div className={classes.row}>
                            <TextInput
                                placeholder="Name"
                                type="text"
                                dark
                                {...input}
                                error={(meta.error || meta.submitError) && meta.touched}
                                errorMessage={meta.error}
                            />
                        </div>
                    )}
                </Field>
                <Field name="age">
                    {({ input, meta }) => (
                        <div className={classes.row}>
                            <TextInput
                                placeholder="Age"
                                type="number"
                                dark
                                {...input}
                                error={(meta.error || meta.submitError) && meta.touched}
                                errorMessage={meta.error}
                            />
                        </div>
                    )}
                </Field>
                <Field
                    name="gender"
                    parse={(value) => {
                        return value.value;
                    }}
                >
                    {({ input, meta }) => {
                        const { value, ...rest } = input;
                        return (
                            <div className={classes.row}>
                                <Autocomplete
                                    dark
                                    placeholder="Choose Gender"
                                    items={genders}
                                    value={genders.filter((el) => (el.value === value))[0]}
                                    {...rest}
                                    error={(meta.error || meta.submitError) && meta.touched}
                                    errorMessage={meta.error}
                                />
                            </div>
                        );
                    }}
                </Field>
                <Field name="locationName">
                    {({ input, meta }) => {
                        const { value, ...rest } = input;
                        const onChangePlace = (e) => {
                            this.handleOnChangePlace(e);
                            input.onChange(e.value.name);
                        };
                        return (
                            <div className={classes.row}>
                                <Autocomplete
                                    dark
                                    async={true}
                                    placeholder="Choose City/Town"
                                    loadOptions={this.handleLoadPlaces}
                                    cacheOptions={true}
                                    defaultValue={value ? { label: value, value: value } : null}
                                    {...rest}
                                    onChange={onChangePlace}
                                    error={(meta.error || meta.submitError) && meta.touched}
                                    errorMessage={meta.error}
                                />
                            </div>
                        );
                    }}
                </Field>
                {submitFailed && <div className={classes.error}>Failed to save</div>}
                {submitSucceeded && <div className={`success ${classes.success}`}>Data has been saved</div>}
                <div className={classes.row}>
                    <DarkButton onClick={handleSubmit}>SAVE<input type="submit" style={{ display: 'none' }} /></DarkButton>
                </div>
            </form>
        );
    }

    get user() {
        const { user } = this.props;
        if (user) {
            return {
                id: user.id,
                age: user.age,
                name: user.name,
                gender: user.gender,
                race: user.race,
                locationName: user.locationName,
                locationX: user.locationX,
                locationY: user.locationY,
                locationMapboxId: user.locationMapboxId
            };
        }
        return {
            id: null,
            age: null,
            name: null,
            gender: null,
            race: null,
            locationName: null,
            locationX: null,
            locationY: null,
            locationMapboxId: null,
        };
    }

    render() {
        const { classes } = this.props;
        const { submitting } = this.state;
        const user = this.user;
        return (
            <div className={classes.container}>
                <div className={classes.header}>
                    <Typography variant="h4" color="textSecondary">{user.id ? 'Edit' : 'New'} Profile</Typography>
                </div>
                <Panel
                    className={classes.formPanel}
                    light
                    onClose={this.handleOnClose}
                    loading={submitting}
                >
                    <Form
                        onSubmit={this.handleOnSubmit}
                        validate={this.handleValidate}
                        render={this.renderForm}
                        initialValues={user}
                    />
                </Panel>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    genders: dictionaryAdaptGendersList(state),
    races: dictionaryAdaptRacesList(state),
});

export default connect(mapStateToProps)(withStyles(styles)(EditProperties));
