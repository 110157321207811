const styles = (theme) => ({
	container: {
		display: 'flex',
		flex: '1 1 auto',
		flexDirection: 'row',
		flexWrap: 'wrap',
		justifyContent: 'flex-start',
		alignItems: 'flex-start',
		paddingTop: 45,
		paddingLeft: 10,
	},

	'@media screen and (max-width: 1399px)': {
		container: {
			marginTop: 30,
		},
	},

	'@media screen and (max-width: 767px)': {
		container: {
			flexDirection: 'column',
			alignItems: 'center',
			paddingTop: 130,
			paddingLeft: 0,
			marginTop: 0,
		},

		content: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			width: '100%',
		},
	},
});

export default styles;
