import React from 'react';

// import logotype from '../../images/logotype.png';
import logotype from '../../images/safe_xplore_TM_white_no_border_1.svg';

const Logotype = (props) => {
    return (
        <img width="auto" height={60} src={logotype} alt="SE Logotype" />
    );
};

export default Logotype;
