import {createSelector} from 'reselect';

import {USER_GROUP} from '../UserRedux';

export const userSelector = state => (state.user);
export const userPropertiesSelector = state => (userSelector(state).properties);
export const userBillingSelector = state => (userPropertiesSelector(state).billing);
export const userErrorSelector = state => {
    const {error, errorType, status, errorMessage} = userSelector(state);
    return {error, errorType, status, errorMessage};
};

// TODO: remove later
// export const userNeedsAdditionInformation = createSelector([userSelector, userPropertiesSelector], (user, props) => {
//     if (user.authed && (
//         !props.name
//         || !props.gender
//         || !props.longitude
//         || !props.latitude
//     )) return true;
//     return false;
// });

export const userNeedsAdditionInformation = createSelector(
    [userSelector, userPropertiesSelector, userBillingSelector],
    (user, props, billing) => {
        const name = (billing.firstName && billing.lastName) || billing.businessName;
        if (user.authed && (
            !name
            || !billing.country
            || !billing.line1
            || !billing.postalCode
        )) return true;
        return false;
    }
);

export const userForgotPasswordSelector = state => (userSelector(state).forgotPassword);
export const userSubscribeDetails = state => userSelector(state).subscribe;

export const userIsAdmin = state => userPropertiesSelector(state).group === USER_GROUP.ADMIN;
export const userIsManager = state => userPropertiesSelector(state).group === USER_GROUP.MANAGER;
export const userIsModerator = state => userPropertiesSelector(state).group === USER_GROUP.MODERATOR;
