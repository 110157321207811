
export function getErrorObject(formErrors, path, errors) {
    const idx = formErrors.findIndex((value, index) => {
        if (value.path.findIndex((p) => (path === p)) >= 0) return true;
        return false;
    });
    let errorMessage = null;
    if (idx >= 0) {
        errors = (typeof errors === 'object') ? errors : {default: errors};
        const {type, context = {}} = formErrors[idx];
        if (type in errors) errorMessage = errors[type];
        else if ('default' in errors) errorMessage = errors.default;
        else errorMessage = errors[Object.keys(errors)[0]];

        Object.keys(context).forEach((k) => {
            errorMessage = errorMessage.replace(`{${k}}`, context[k]);
        });
    }
    return ({
        error: idx >= 0,
        errorMessage
    });
}

export function getFinalFormErrors(joiDetails, messages) {
    const errors = {};
    if (joiDetails && messages) {
        joiDetails.forEach((el) => {
            const path = el.path[0];
            const type = el.type;
            if (path in messages) {
                if (type in messages[path]) errors[path] = messages[path][type];
                else errors[path] = messages[path].default;
            } else {
                errors[path] = el.message;
            }
        });
    }
    return errors;
}
