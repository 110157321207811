import React, {PureComponent} from 'react';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import get from 'get-value';
import {connect} from 'react-redux';
import moment from 'moment';

import {api} from '../../../sagas';
import {adaptSubscriptionFromApi} from '../../../store/UserRedux';
import {userPropertiesSelector} from '../../../store/selectors';

import styles from './SubscriptionInfo.jss';

class SubscriptionInfo extends PureComponent {
    static propTypes = {
        classes: PropTypes.object,
        user: PropTypes.object,
    };

    state = {
        subscription: null,
    };

    componentDidMount() {
        this.loadSubscription();
    }

    get subscriptionExist() {
        const {subscription} = this.state;
        const id = get(subscription, 'id', {default: 0});
        return id > 0;
    }

    loadSubscription() {
        const {user} = this.props;
        if (user.billable) {
            api.getSubscription().then((response) => {
                if (response.ok) {
                    this.setState({
                        subscription: adaptSubscriptionFromApi(get(response, 'data')),
                    });
                }
            });
        }
    }

    renderInfo() {
        if (this.subscriptionExist) {
            const {subscription: s} = this.state;
            const productName = get(s, 'productName', {default: ''});
            const productDescription = get(s, 'productDescription', {default: ''});

            const productAdditionalDescription = get(s, 'productAdditionalDescription', {default: ''});
            const parts = productAdditionalDescription ? productAdditionalDescription.split(';') : [];

            const discountInfo = get(s, 'discountInfo', {default: ''});
            const currentPeriodDescription = get(s, 'currentPeriodDescription', {default: ''});

            const dateFormat = 'MMM DD, YYYY';
            const createdDate = get(s, 'createdDate');
            const createdDateFormatted = (createdDate) ? moment(createdDate).format(dateFormat) : null;

            const endDate = get(s, 'endDate');
            const endDateFormatted = (endDate) ? moment(endDate).format(dateFormat) : null;

            const freeReportsPerPeriod = get(s, 'freeReportsPerPeriod');
            const generatedCurrentReportsNumber = get(s, 'generatedCurrentReportsNumber');
            return (
                <div className="info">
                    <h2>Plan: {productName}</h2>
                    <div className="columns">
                        <div className="column">
                            <div>{productDescription}</div>
                            {parts && parts.length
                                ? (
                                    <ul>
                                        {parts.map((el, index) => <li key={index}>{el}</li>)}
                                    </ul>
                                )
                                : null
                            }
                        </div>
                        <div className="column">
                            <div>Start/End: {createdDateFormatted} - {endDateFormatted}</div>
                            <div>{discountInfo}</div>
                            <div>Current period: {currentPeriodDescription}</div>
                            <div>Free reports: {freeReportsPerPeriod}</div>
                            <div>Generated reports: {generatedCurrentReportsNumber}</div>
                        </div>
                    </div>
                </div>
            );
        }
        return (
            <div className="info">
                <h2 className="no-subscription">You have no subscription</h2>
            </div>
        );
    }

    render() {
        const {classes} = this.props;
        return (
            <div className={classes.container}>
                {this.renderInfo()}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: userPropertiesSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SubscriptionInfo));
