import React, {PureComponent} from 'react';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {Typography} from '@material-ui/core';
import {connect} from 'react-redux';
import {FORM_ERROR} from 'final-form';
import {Form, Field} from "react-final-form";
import Joi from 'joi-browser';

import {
    Panel, TextInput, DarkButton,
} from '../../../components';
import {
    userPropertiesSelector,
} from '../../../store/selectors';
import {getFinalFormErrors} from '../../../lib/form';
import {api} from '../../../sagas';

import styles from './EditPassword.jss';

const errorMessages = {
    oldPassword: {
        'default': 'Old password is required',
    },
    newPassword: {
        'default': 'New password is required',
    },
    repeatPassword: {
        'default': 'Repeat new password is required',
        'any.allowOnly': 'It should match new password field',
    },
};

class EditPassword extends PureComponent {
    static propTypes = {
        user: PropTypes.object.isRequired,
        onClose: PropTypes.func,
    };

    handleOnClose = () => {
        const {onClose} = this.props;
        if (onClose) onClose();
    }

    handleOnSubmit = (values) => {
        const errorMessage = 'Change password error';
        const {oldPassword, newPassword, repeatPassword} = values;
        return new Promise((resolve, reject) => {
            this.setState({submitting: true});
            api.changePassword(oldPassword, newPassword, repeatPassword).then((response) => {
                this.setState({submitting: false});
                if (response.ok) resolve();
                else if (response.status === 400) {
                    resolve({[FORM_ERROR]: response.data.message });
                }
                else throw new Error(errorMessage);
            }).catch((e) => {
                this.setState({submitting: false});
                resolve(errorMessage);
            });
        });
    }

    handleValidate = (values) => {
        let errors = {};

        const schema = Joi.object({
            oldPassword: Joi.string().required(),
            newPassword: Joi.string().required(),
            repeatPassword: Joi.any().valid(Joi.ref('newPassword')).required(),
        });
        const result = Joi.validate(values, schema, {abortEarly: false, allowUnknown: true}).error;
        if (result) errors = getFinalFormErrors(result.details, errorMessages);

        return errors;
    }

    renderForm = ({
        handleSubmit,
        submitFailed,
        submitSucceeded,
        submitError,
    }) => {
        const {classes} = this.props;
        return (
            <form onSubmit={handleSubmit} className="light">
                <Field name="oldPassword">
                    {({input, meta}) => (
                        <div className={classes.row}>
                            <TextInput
                                placeholder="Old Password"
                                type="password"
                                dark
                                {...input}
                                error={(meta.error || meta.submitError) && meta.touched}
                                errorMessage={meta.error}
                            />
                        </div>
                    )}
                </Field>
                <Field name="newPassword">
                    {({input, meta}) => (
                        <div className={classes.row}>
                            <TextInput
                                placeholder="New Password"
                                type="password"
                                dark
                                {...input}
                                error={(meta.error || meta.submitError) && meta.touched}
                                errorMessage={meta.error}
                            />
                        </div>
                    )}
                </Field>
                <Field name="repeatPassword">
                    {({input, meta}) => (
                        <div className={classes.row}>
                            <TextInput
                                placeholder="Repeat New Password"
                                type="password"
                                dark
                                {...input}
                                error={(meta.error || meta.submitError) && meta.touched}
                                errorMessage={meta.error}
                            />
                        </div>
                    )}
                </Field>
                {submitFailed && <div className={classes.error}>{submitError || 'Failed to save'}</div>}
                {submitSucceeded && <div className={`success ${classes.success}`}>Password has been changed</div>}
                <div className={classes.row}>
                    <DarkButton onClick={handleSubmit}>SAVE<input type="submit" style={{display:'none'}}/></DarkButton>
                </div>
            </form>
        );
    }

    render() {
        const {classes} = this.props;
        return (
            <div className={classes.container}>
                <div className={classes.header}>
                    <Typography variant="h4" color="textSecondary">Edit Password</Typography>
                </div>
                <Panel
                    className={classes.formPanel}
                    light
                    onClose={this.handleOnClose}
                >
                    <Form
                        onSubmit={this.handleOnSubmit}
                        validate={this.handleValidate}
                        render={this.renderForm}
                    />
                </Panel>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user: userPropertiesSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
    // userLogout: () => dispatch(UserActions.userLogout()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EditPassword));
