const styles = (theme) => ({
	panel: {
		zIndex: 100,
		minWidth: 676,
		width: 676,
		minHeight: 'auto',
		marginTop: -10,
		paddingBottom: 32,
		marginLeft: -676,
		position: 'relative',
		background: theme.palette.background.paper,
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
	},
	header: {
		background: theme.palette.primary.main,
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		minHeight: 70,
		padding: '0 20px',
		borderRadius: '12px 12px 0 0',
		color: theme.palette.text.secondary,
	},
	personName: {
		fontSize: '1.1428rem',
		lineHeight: 1.25,
		display: 'flex',
		alignItems: 'center',

		'& svg': {
			width: 20,
			height: 20,
			cursor: 'pointer',
			display: 'inline-block',
			marginRight: 10,
		},
	},
	buttonChange: {
		fontSize: '0.8571rem',
		lineHeight: 1,
		letterSpacing: '0.6px',
	},
	locationInfo: {
		padding: '24px 20px',

		'& .table-wrap': {
			background: 'rgba(222, 226, 229, 0.4)',
			color: theme.palette.primary.dark,
			width: '100%',
			borderRadius: 16,

			'& > tbody > tr': {
				height: 43,

				'&:nth-child(even) td': {
					background: 'rgba(222, 226, 229, .7)',
				},

				'&:last-child td:first-child': {
					borderBottomLeftRadius: 10,
				},

				'&:last-child td:last-child': {
					borderBottomRightRadius: 10,
				},
			},

			'& table': {
				borderRadius: 0,
				border: 'none',
				display: 'block',

				'& th': {
					border: 0,
					margin: 0,
					padding: 0,
					verticalAlign: 'middle',
					fontSize: 16,
					fontWeight: 'normal',

					'& svg': {
						marginRight: 10,
					},
				},

				'& td': {
					padding: '10px 0',

					'&:first-child': {
						paddingRight: 10,
					},

					'&:last-child': {
						width: '100%',
					},
				},
			},

			'& th': {
				height: 50,
				padding: '0 20px',
				textAlign: 'left',
			},
			'& td': {
				width: 43,
				padding: '0 20px',
				fontSize: 12,
				color: theme.palette.primary.light,
				verticalAlign: 'middle',
				'& span': {
					display: 'inline-block',
					minWidth: 90,
				},
				'& b': {
					color: theme.palette.primary.dark,
					fontSize: 16,
					fontWeight: 'normal',
				},
			},
		},

		'& .table-wrap.table-wrap-desktop, & .table-wrap.location-mobile-destinationIcon': {
			position: 'relative',
		},

		'& .table-wrap.table-wrap-desktop > tbody > tr > th:first-child, & .table-wrap > tbody > tr > td:first-child': {
			borderRight: '1px solid rgba(35, 53, 68, .15)',
		},

		'& .table-wrap.table-wrap-desktop > tbody > tr > th': {
			width: '50%',
		},

		'& .location-label': {
			minWidth: 75,
		},

		'& .location-mobile-destinationIcon': {
			marginTop: 20,
		},
	},

	dataLayerContainer: {
		position: 'absolute',
		top: 5,
		right: 10,

		'& .data-layer-tooltip': {
			padding: '3px 10px 6px',
		},

		'& .multi-line': {
			textAlign: 'left !important',
			fontSize: 11,
			lineHeight: 1.3
		}
	},

	modifiersContainer: {
		padding: '0 20px',
		marginBottom: 53,

		'& .header': {
			fontSize: 16,
			color: theme.palette.primary.dark,
			lineHeight: 1.25,
			marginBottom: 6,
		},

		'& .body': {
			fontSize: 12,
			color: theme.palette.primary.light,
			lineHeight: 1.33,
			letterSpacing: 0.44,
		},
	},

	buttonContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		padding: '0 32px',
	},

	reportIcon: {
		width: 22,
		height: 26,
		marginRight: 10,
	},

	residenceIcon: {
		width: 16,
		height: 16,
	},

	destinationIcon: {
		width: 17,
		height: 23,
	},

	footerButton: {
		maxWidth: 298,
	},

	'@media screen and (max-width: 767px)': {
		panel: {
			minWidth: 'auto',
			marginLeft: 0,
			width: 'calc(100% - 60px)',
		},

		modifiersContainer: {
			marginBottom: 44,
		},

		buttonContainer: {
			flexDirection: 'column-reverse',
			alignItems: 'center',

			'& .footerButtonChange': {
				marginTop: 10,
			},
		},
	},
});

export default styles;
