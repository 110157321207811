
const styles = (theme) => ({
    container: {
        padding: '56px 60px 50px',
    },
    row: {
        marginTop: 30,
    },
    forgotPassword: {
        textAlign: 'right',
        marginTop: 10,
        fontSize: 12,
    },
    terms: {
        marginTop: 30,
        textAlign: 'center',
        fontSize: 12,
    },
    copyr: {
        marginTop: 10,
        textAlign: 'center',
        fontSize: 10,
    },
});

export default styles;
