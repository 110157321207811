import apisauce from 'apisauce';

import Config from '../config';
import { localStore } from '../lib';
import { store } from '../App';
import UserActions from '../store/UserRedux';

export const STATUS = {
	OK: 200,
	UNAUTHORIZED: 401,
	AGREEMENT_ERROR: 406,
};

export const MODIFIER_CODE = {
	Traveling: 'TRAVEL',
};

const v1 = '/api/v1';
const limited1 = '/api/limited/v1';

const create = () => {
	const config = {
		baseURL: Config.apiBaseURL,
		headers: {
			'Cache-Control': 'no-cache',
		},
		timeout: 120000,
	};
	const auth = localStore.getToken();
	if (auth) {
		config.headers.Authorization = auth;
	}
	const api = apisauce.create(config);

	const wrapRefreshFunctionality = (call) => {
		return function () {
			const params = arguments;
			return new Promise((resolve) => {
				call.apply(null, params).then((response) => {
					const status = response.status;
					if (response.ok) {
						resolve(response);
					} else {
						if (status) {
							const {
								data: { type, message },
							} = response;
							store.dispatch(
								UserActions.userReceivedErrorMessage({ status, type, message })
							);
							if (status === 401) {
								const refreshToken = localStore.getRefreshToken();
								if (refreshToken) {
									// TODO: use refresh token and receive the new token
								} else {
									resolve(response);
								}
							} else resolve(response);
						} else {
							localStore.setRefreshToken('');
							localStore.setToken('');
							resolve(response);
						}
					}
				});
			});
		};
	};

	const login = (name, password, action = 'init') =>
		api.post(`/auth/token?action=${action}`, { name, password });
	const changePassword = wrapRefreshFunctionality(
		(oldPassword, password, confirmation) =>
			api.post(`${limited1}/person/password`, {
				oldPassword,
				password,
				confirmation,
			})
	);
	const changeEmail = wrapRefreshFunctionality((email) =>
		api.post(`${limited1}/person/email`, { email })
	);
	const forgotPassword = wrapRefreshFunctionality((email) =>
		api.post(`/common/password/email`, { email })
	);
	const changeProperties = wrapRefreshFunctionality((data) =>
		api.post(`${limited1}/person`, data)
	);
	const changeBilling = wrapRefreshFunctionality((data) =>
		api.post(`${limited1}/billingInfo`, data)
	);

	const getPerson = wrapRefreshFunctionality(() =>
		api.get(`${limited1}/person`)
	);
	const getPersonMapboxHomeData = wrapRefreshFunctionality(() =>
		api.get(`${limited1}/person/homeTurf`)
	);

	const getPersons = wrapRefreshFunctionality(() =>
		api.get(`${limited1}/persons`)
	);
	const getPersonById = wrapRefreshFunctionality((id) =>
		api.get(`${limited1}/persons/${id}`)
	);
	const searchPersons = wrapRefreshFunctionality((name) =>
		api.post(`${limited1}/persons/search`, { name })
	);
	const addPerson = wrapRefreshFunctionality((data) =>
		api.post(`${limited1}/persons`, data)
	);
	const updatePerson = wrapRefreshFunctionality((id, data) =>
		api.post(`${limited1}/persons/${id}`, data)
	);
	const deletePerson = wrapRefreshFunctionality((id) =>
		api.post(`${limited1}/persons/${id}/delete`)
	);

	const getCountries = wrapRefreshFunctionality(() =>
		api.get(`${limited1}/dictionary/countries`)
	);
	const getRaces = wrapRefreshFunctionality(() =>
		api.get(`${limited1}/dictionary/races`)
	);
	const getGenders = wrapRefreshFunctionality(() =>
		api.get(`${limited1}/dictionary/genders`)
	);
	const getModifiers = wrapRefreshFunctionality((codes = '') =>
		api.get(`${limited1}/dictionary/modifierGroups`, { codes })
	);

	const applyPromoCode = wrapRefreshFunctionality((promoCode) =>
		api.post(`${v1}/subscription/discount`, { promoCode })
	);

	const getSubscriptions = wrapRefreshFunctionality(() =>
		api.get(`${limited1}/subscriptions`)
	);
	const getSubscription = wrapRefreshFunctionality(() =>
		api.get(`${limited1}/subscription`)
	);
	const getBankCard = wrapRefreshFunctionality(() =>
		api.get(`${limited1}/bankCard`)
	);
	const initBankCard = wrapRefreshFunctionality(() =>
		api.get(`${limited1}/createBankCardInit`)
	);
	const deleteBankCard = wrapRefreshFunctionality((id) =>
		api.post(`${limited1}/bankCard/${id}/delete`)
	);

	// data: {planId, promoCode}
	const subscribe = wrapRefreshFunctionality((data) =>
		api.post(`${limited1}/subscribe`, data)
	);

	const getRiskTravelReport = wrapRefreshFunctionality((data) =>
		api.post(`${v1}/riskTravelReport`, data, {
			responseType: 'arraybuffer',
			headers: { Accept: 'application/pdf' },
		})
	);
	const getRiskTravelReportV2 = wrapRefreshFunctionality((data) =>
		api.post(`${v1}/travelReportV2`, data)
	);
	const reportShare = wrapRefreshFunctionality((id) =>
		api.post(`${v1}/travelReportV2/${id}/share`)
	);
	const getSharedReport = wrapRefreshFunctionality((uuid, password) =>
		api.post('/common/shared/report', { uuid, password })
	);
	const getTestReport = wrapRefreshFunctionality((uuid) =>
		api.get(`/common/test/report/${uuid}`)
	);
	const getReportCount = wrapRefreshFunctionality(() =>
		api.get(`${limited1}/travelReportV2/count`)
	);
	const getReport = wrapRefreshFunctionality((id) =>
		api.get(`${v1}/travelReportV2/${id}`)
	);
	const getReports = wrapRefreshFunctionality((search, props = {}) =>
		api.get(`${limited1}/travelReportV2`, { search, ...props })
	);

	const getBoundary = wrapRefreshFunctionality((coordinates) =>
		api.post(`${limited1}/boundary`, coordinates)
	); // {lng, lat}
	const getBoundaryById = wrapRefreshFunctionality((id) =>
		api.get(`${v1}/boundary/${id}`)
	);
	const getBoundaries = wrapRefreshFunctionality(
		(lngLatBounds, crimeSeDataFlag = false) =>
			api.post(
				`${limited1}/boundaries?crimeSeDataFlag=${crimeSeDataFlag.toString()}`,
				lngLatBounds
			)
	); // {_ne, _sw}
	const getBoundaryScore = wrapRefreshFunctionality((boundaryId, data) =>
		api.post(`${limited1}/boundaries/scoring/${boundaryId}`, data)
	);

	const geocoding = apisauce.create({
		baseURL: Config.mapbox.geocoding,
		headers: {
			'Cache-Control': 'no-cache',
		},
		timeout: 10000,
	});

	const getOrttoApisauce = (orttoKey) => {
		const orttoApisauce = apisauce.create({
			baseURL: Config.apiBaseURL,
			headers: {
				'Cache-Control': 'no-cache',
				'X-Api-Key': orttoKey,
			},
			timeout: 120000,
		});
		return orttoApisauce;
	};

	const customOrttoActivity = (data, key) => {
		const ortto = getOrttoApisauce(key);
		return ortto.post('common/ortto/activities/create', data);
	};

	// const typesPlaces = 'country,region,district,place';
	const typesPlaces = 'district,place';

	const searchPlaces = (query) =>
		geocoding.get(
			`/mapbox.places/${query}.json?types=${typesPlaces}&access_token=${Config.mapbox.accessToken}`
		);

	return {
		apiInstance: api,
		login,
		changePassword,
		changeEmail,
		forgotPassword,
		changeProperties,
		changeBilling,

		getPerson,
		getPersonMapboxHomeData,

		getPersons,
		getPersonById,
		searchPersons,
		addPerson,
		updatePerson,
		deletePerson,

		searchPlaces,

		getCountries,
		getRaces,
		getGenders,
		getModifiers,

		applyPromoCode,
		getSubscriptions,
		getSubscription,
		subscribe,
		getBankCard,
		initBankCard,
		deleteBankCard,

		getRiskTravelReport,
		getRiskTravelReportV2,
		reportShare,
		getSharedReport,
		getTestReport,
		getReportCount,
		getReport,
		getReports,

		getBoundary,
		getBoundaryById,
		getBoundaries,
		getBoundaryScore,
		customOrttoActivity,
	};
};

export default { create };
