import React from 'react';

import cn from '../../../../lib/classNames';

import styles from './TabInfo.module.scss';

function Row({ label, value }) {
	if (!value) return null;
	return (
		<div className={styles['row']}>
			<p className={styles['label']}>{label}</p>
			<p className={styles['value']}>{value}</p>
		</div>
	);
}

export default function TabInfo(props) {
	const { className, data, icon } = props;

	if (data?.length) {
		return data.map((e) => {
			return (
				<div className={cn(styles['item-wrap'], className)} key={e.name}>
					<div className={styles['name-wrap']}>
						<span
							className={cn(
								styles['name-icon'],
								styles[icon],
								'material-symbols-rounded'
							)}
						>
							{icon}
						</span>
						<p className={styles['name']}>{e.name}</p>
					</div>
					<div className={styles['content-wrap']}>
						<Row label='Address:' value={e.address} />
						<Row label='Phone Number:' value={e.number} />
						<Row label='Note:' value={e.notes} />
						<Row label='Confidence Level:' value={e.confidence} />
					</div>
				</div>
			);
		});
	}

	return null;
}
