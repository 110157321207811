import React from 'react';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';

import cn from '../lib/classNames';

import './AccordionCustom.scss';

export default function AccordionCustom(props) {
	const { headerText, expanded, panel, setExpanded, panelContent, children } =
		props;

	return (
		<div
			className={cn('accordion-custom', {
				'accordion-custom-active': expanded === panel,
			})}
		>
			<ExpansionPanel
				className='accordion-container'
				expanded={expanded === panel}
				onChange={(event, isExpanded) => {
					setExpanded(isExpanded ? panel : '');
				}}
			>
				<ExpansionPanelSummary
					className='accordion-header'
					aria-controls={panelContent}
					id={panel}
					classes={{
						content: 'accordion-summary-content-wrap',
					}}
				>
					<div className='accordion-header-content'>
						<h4 className='overflow-row-1'>{headerText}</h4>
						<span className='opened-closed-icon opened-icon material-icons-outlined'>
							add_circle_outline
						</span>
						<span className='opened-closed-icon closed-icon material-icons-outlined'>
							do_disturb_on
						</span>
					</div>
				</ExpansionPanelSummary>
				<ExpansionPanelDetails className='accordion-details'>
					{children}
				</ExpansionPanelDetails>
			</ExpansionPanel>
		</div>
	);
}
