import React, { useRef } from 'react';

import noData from '../../../images/nodata_img.svg';

import './ViolentCrimeRiskNoData.scss';

const ViolentCrimeRiskNoData = (props) => {

	const { header = '', description = '', destinationLocation = '' } = props;

	const elementToShare = useRef();

	return (
		<div ref={elementToShare} className='violent-crimer-risk-nodata-container'>
			{
				header
					?
					(
						<div className='header'>
							<div className='block-text'>
								<h3>{header}</h3>
							</div>
						</div>
					)
					:
					''
			}

			<div className='body'>
				<img className='nodata-img' src={noData} alt="" />
				<div className="nodata-destination-location overflow-row-1" title={destinationLocation}>
					{destinationLocation}
				</div>
				{/* <h2 className='title'>Active Conflict Area</h2> */}
				<p className='nodata-description'>{description}</p>
			</div>
		</div >
	);
};

export default ViolentCrimeRiskNoData;
