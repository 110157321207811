const styles = (theme) => ({
    container: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        // paddingTop: 45,
        // paddingLeft: 10,
        background: '#F2F2F2',
    },
    overlay: {
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        width: '100%',
        backgroundColor: 'rgba(0,0,0,0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& > *': {
            width: '50%',
        },
    },
    content: {
        maxWidth: 1020,
        width: '100%',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    form: {
        marginTop: 30,
    },
    row: {
        marginTop: 30,
    },
    error: {
        marginTop: 20,
        textAlign: 'center',
        color: theme.palette.error.main,
    },

    '@media screen and (max-width: 1079px)': {
        container: {
            paddingLeft: 20,
            paddingRight: 20,
        },
    },
});

export default styles;
