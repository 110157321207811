import React, { useState, useEffect } from 'react';

import { Color } from '../../theme';

const RiskRatingChart = (props) => {

	const {
		value,
		valueFontSize = 35,
		valueHorizontalPosition = '210',
		valueVerticalPosition = '85',
		text = 'Counties Ranking',
		colors = '',
	} = props;

	const [dataValue, setDataValue] = useState(value);

	const NA = 'N.A.';

	const valueNum = dataValue > 0 ? dataValue : NA;

	const colorsArr = [
		Color.heatmap1,
		Color.heatmap2,
		Color.heatmap3,
		Color.heatmap4,
		Color.heatmap5,
		Color.heatmap6,
		Color.heatmap7,
		Color.heatmap8,
		Color.heatmap9,
		Color.heatmap10,
		Color.heatmap11,
	];

	const colorsBlueArr = [
		Color.blueHeatmap1,
		Color.blueHeatmap2,
		Color.blueHeatmap3,
		Color.blueHeatmap4,
		Color.blueHeatmap5,
		Color.blueHeatmap6,
		Color.blueHeatmap7,
		Color.blueHeatmap8,
		Color.blueHeatmap9,
		Color.blueHeatmap10,
	];


	const colorsPaletteArr = colors === 'blue' ? { ...colorsBlueArr } : { ...colorsArr };

	const valueColor = colorsPaletteArr[dataValue - 1];

	const ratio = 204.545 / 100;

	const padding = (v) => -18 * (0.07 * v - 1);

	const dasharray = (v) => {
		const l = Math.round((232 * v) / 11) - padding(v);
		const ll = 493 - l;
		return `${l * ratio} ${ll * ratio}`;
	};

	const setScaleValue = () => {

		const valueScale = document.querySelectorAll('.value-scale');

		if (valueScale.length > 0) {
			valueScale.forEach(e => {
				e.classList.remove('active');
			});

			if (dataValue > 0 && dataValue <= 11) valueScale[dataValue - 1].classList.add('active');
		}
	};

	useEffect(() => {
		setScaleValue();
	}, [dataValue]);


	return (
		<svg className='graph-circle' viewBox="0 0 428 231" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_7821_55329)">
				<g>
					<circle cx="212.296" cy="224.755" r="179.835" fill="#DEE2E5" />
					{/* <path className='graph-value-scale' style={{ transform: 'rotate(' + scaleRotate + 'deg)' }} d="M392.131 224.753C392.131 272.448 373.184 318.19 339.458 351.915C305.733 385.641 259.991 404.588 212.296 404.588C164.601 404.588 118.859 385.641 85.1334 351.915C51.4078 318.19 32.4609 272.448 32.4609 224.753L212.296 224.753H392.131Z" fill={valueColor} /> */}
				</g>
				<path fillRule="evenodd" clipRule="evenodd" d="M428 0H0V428H428V0ZM388.759 195.245C374.415 109.726 299.69 46 212.393 46C125.097 46 50.3715 109.726 36.0277 195.245C35.842 196.352 36.6105 197.399 37.7204 197.566L69.522 202.336C70.6049 202.498 71.6074 201.76 71.7927 200.681C83.4622 132.734 142.939 82.1679 212.393 82.1679C281.847 82.1679 341.324 132.734 352.994 200.681C353.179 201.76 354.182 202.498 355.264 202.336L387.066 197.566C388.176 197.399 388.944 196.352 388.759 195.245Z" fill="white" />
				<path d="M5.26562 175.721V174.525H8.07031V166.057L5.58594 167.83V166.502L8.1875 164.713H9.48438V174.525H12.1641V175.721H5.26562Z" fill="#7D8C97" />
				<path d="M410.586 175.721V174.525H413.391V166.057L410.906 167.83V166.502L413.508 164.713H414.805V174.525H417.484V175.721H410.586Z" fill="#7D8C97" />
				<path d="M418.305 175.721V174.525H421.109V166.057L418.625 167.83V166.502L421.227 164.713H422.523V174.525H425.203V175.721H418.305Z" fill="#7D8C97" />
				<path d="M26.9608 125.485V124.492C27.2264 123.883 27.5493 123.347 27.9295 122.883C28.3149 122.414 28.7186 121.992 29.1405 121.617C29.5623 121.237 29.979 120.886 30.3905 120.563C30.8071 120.24 31.1821 119.917 31.5155 119.594C31.8488 119.271 32.117 118.932 32.3201 118.578C32.5285 118.224 32.6326 117.823 32.6326 117.375C32.6326 116.771 32.4556 116.302 32.1014 115.969C31.7472 115.636 31.255 115.469 30.6248 115.469C30.0259 115.469 29.5311 115.633 29.1405 115.961C28.755 116.284 28.5285 116.74 28.4608 117.328L27.0233 117.195C27.1274 116.315 27.4998 115.615 28.1405 115.094C28.7863 114.573 29.6144 114.313 30.6248 114.313C31.7342 114.313 32.5858 114.576 33.1795 115.102C33.7785 115.623 34.078 116.365 34.078 117.328C34.078 117.755 33.979 118.18 33.7811 118.602C33.5884 119.024 33.2993 119.445 32.9139 119.867C32.5285 120.289 31.7915 120.943 30.703 121.828C30.104 122.318 29.6274 122.761 29.2733 123.156C28.9191 123.547 28.6639 123.925 28.5076 124.289H34.2498V125.485H26.9608Z" fill="#7D8C97" />
				<path d="M67.5547 78.248C67.5547 79.2637 67.2318 80.0501 66.5859 80.6074C65.9401 81.1647 65.0182 81.4434 63.8203 81.4434C62.7057 81.4434 61.8151 81.1934 61.1484 80.6934C60.487 80.1881 60.0938 79.4434 59.9688 78.459L61.4219 78.3262C61.6094 79.6283 62.4089 80.2793 63.8203 80.2793C64.5286 80.2793 65.0833 80.1048 65.4844 79.7559C65.8906 79.4069 66.0938 78.8887 66.0938 78.2012C66.0938 77.6022 65.862 77.1361 65.3984 76.8027C64.9401 76.4642 64.276 76.2949 63.4062 76.2949H62.6094V75.0762H63.375C64.1458 75.0762 64.7422 74.9095 65.1641 74.5762C65.5911 74.2376 65.8047 73.7715 65.8047 73.1777C65.8047 72.5892 65.6302 72.1257 65.2812 71.7871C64.9375 71.4434 64.4245 71.2715 63.7422 71.2715C63.1224 71.2715 62.6198 71.4303 62.2344 71.748C61.8542 72.0658 61.6328 72.5137 61.5703 73.0918L60.1562 72.9824C60.2604 72.0814 60.6328 71.3783 61.2734 70.873C61.9193 70.3678 62.7474 70.1152 63.7578 70.1152C64.862 70.1152 65.7188 70.373 66.3281 70.8887C66.9427 71.3991 67.25 72.1126 67.25 73.0293C67.25 73.7324 67.0521 74.3053 66.6562 74.748C66.2656 75.1855 65.6953 75.4824 64.9453 75.6387V75.6699C65.7682 75.7585 66.4089 76.0345 66.8672 76.498C67.3255 76.9616 67.5547 77.5449 67.5547 78.248Z" fill="#7D8C97" />
				<path d="M110.539 42.3477V44.8398H109.211V42.3477H104.023V41.2539L109.062 33.832H110.539V41.2383H112.086V42.3477H110.539ZM109.211 35.418C109.201 35.4492 109.128 35.5872 108.992 35.832C108.857 36.0768 108.755 36.2487 108.688 36.3477L105.867 40.5039L105.445 41.082L105.32 41.2383H109.211V35.418Z" fill="#7D8C97" />
				<path d="M163.234 23.4453C163.234 24.6068 162.888 25.5208 162.195 26.1875C161.508 26.8542 160.552 27.1875 159.328 27.1875C158.302 27.1875 157.474 26.9635 156.844 26.5156C156.214 26.0677 155.815 25.4193 155.648 24.5703L157.07 24.4062C157.367 25.4948 158.13 26.0391 159.359 26.0391C160.115 26.0391 160.706 25.8125 161.133 25.3594C161.56 24.901 161.773 24.2734 161.773 23.4766C161.773 22.7839 161.557 22.224 161.125 21.7969C160.698 21.3698 160.12 21.1562 159.391 21.1562C159.01 21.1562 158.656 21.2161 158.328 21.3359C158 21.4557 157.672 21.6589 157.344 21.9453H155.969L156.336 16.0234H162.594V17.2188H157.617L157.406 20.7109C158.016 20.2422 158.773 20.0078 159.68 20.0078C160.763 20.0078 161.625 20.3255 162.266 20.9609C162.911 21.5964 163.234 22.4245 163.234 23.4453Z" fill="#7D8C97" />
				<path d="M217.352 16.3984C217.352 17.5599 217.036 18.4766 216.406 19.1484C215.776 19.8203 214.906 20.1562 213.797 20.1562C212.557 20.1562 211.609 19.6953 210.953 18.7734C210.297 17.8516 209.969 16.5104 209.969 14.75C209.969 12.8438 210.31 11.3802 210.992 10.3594C211.674 9.33854 212.646 8.82812 213.906 8.82812C215.568 8.82812 216.615 9.57552 217.047 11.0703L215.703 11.3125C215.427 10.4167 214.823 9.96875 213.891 9.96875C213.089 9.96875 212.466 10.3438 212.023 11.0938C211.586 11.8385 211.367 12.9193 211.367 14.3359C211.622 13.862 211.982 13.5026 212.445 13.2578C212.909 13.0078 213.44 12.8828 214.039 12.8828C215.055 12.8828 215.859 13.2005 216.453 13.8359C217.052 14.4714 217.352 15.3255 217.352 16.3984ZM215.922 16.4609C215.922 15.6641 215.727 15.0495 215.336 14.6172C214.945 14.1849 214.401 13.9688 213.703 13.9688C213.047 13.9688 212.516 14.1615 212.109 14.5469C211.708 14.9271 211.508 15.4531 211.508 16.125C211.508 16.974 211.716 17.6693 212.133 18.2109C212.555 18.7526 213.094 19.0234 213.75 19.0234C214.427 19.0234 214.958 18.7969 215.344 18.3438C215.729 17.8854 215.922 17.2578 215.922 16.4609Z" fill="#7D8C97" />
				<path d="M272.062 17.1641C270.938 18.8828 270.143 20.2292 269.68 21.2031C269.216 22.1771 268.867 23.138 268.633 24.0859C268.404 25.0339 268.289 26.0156 268.289 27.0312H266.82C266.82 25.625 267.117 24.1458 267.711 22.5938C268.31 21.0365 269.307 19.2448 270.703 17.2188H264.789V16.0234H272.062V17.1641Z" fill="#7D8C97" />
				<path d="M321.117 44.7656C321.117 45.7812 320.794 46.5729 320.148 47.1406C319.503 47.7083 318.576 47.9922 317.367 47.9922C316.19 47.9922 315.268 47.7135 314.602 47.1562C313.94 46.599 313.609 45.8073 313.609 44.7812C313.609 44.0625 313.815 43.4583 314.227 42.9688C314.638 42.4792 315.164 42.1823 315.805 42.0781V42.0469C315.206 41.9062 314.732 41.6016 314.383 41.1328C314.039 40.6641 313.867 40.1146 313.867 39.4844C313.867 38.6458 314.18 37.9661 314.805 37.4453C315.435 36.9245 316.279 36.6641 317.336 36.6641C318.419 36.6641 319.273 36.9193 319.898 37.4297C320.529 37.9401 320.844 38.6302 320.844 39.5C320.844 40.1302 320.669 40.6797 320.32 41.1484C319.971 41.6172 319.495 41.9115 318.891 42.0312V42.0625C319.594 42.1771 320.141 42.4766 320.531 42.9609C320.922 43.4401 321.117 44.0417 321.117 44.7656ZM319.383 39.5781C319.383 38.3333 318.701 37.7109 317.336 37.7109C316.674 37.7109 316.169 37.8672 315.82 38.1797C315.477 38.4922 315.305 38.9583 315.305 39.5781C315.305 40.2083 315.482 40.6901 315.836 41.0234C316.195 41.3516 316.701 41.5156 317.352 41.5156C318.013 41.5156 318.516 41.3646 318.859 41.0625C319.208 40.7552 319.383 40.2604 319.383 39.5781ZM319.656 44.6328C319.656 43.9505 319.453 43.4375 319.047 43.0938C318.641 42.7448 318.07 42.5703 317.336 42.5703C316.622 42.5703 316.065 42.7578 315.664 43.1328C315.263 43.5026 315.062 44.013 315.062 44.6641C315.062 46.1797 315.836 46.9375 317.383 46.9375C318.148 46.9375 318.719 46.7552 319.094 46.3906C319.469 46.0208 319.656 45.4349 319.656 44.6328Z" fill="#7D8C97" />
				<path d="M366.594 75.5605C366.594 77.4512 366.247 78.9043 365.555 79.9199C364.867 80.9355 363.885 81.4434 362.609 81.4434C361.75 81.4434 361.06 81.2637 360.539 80.9043C360.023 80.5397 359.654 79.9538 359.43 79.1465L360.773 78.9355C361.055 79.8522 361.674 80.3105 362.633 80.3105C363.44 80.3105 364.065 79.9355 364.508 79.1855C364.951 78.4355 365.182 77.3652 365.203 75.9746C364.995 76.4434 364.638 76.821 364.133 77.1074C363.628 77.3887 363.073 77.5293 362.469 77.5293C361.479 77.5293 360.688 77.1908 360.094 76.5137C359.5 75.8366 359.203 74.9381 359.203 73.8184C359.203 72.6673 359.526 71.7637 360.172 71.1074C360.818 70.446 361.716 70.1152 362.867 70.1152C364.091 70.1152 365.018 70.5684 365.648 71.4746C366.279 72.3809 366.594 73.7428 366.594 75.5605ZM365.062 74.2012C365.062 73.3158 364.859 72.6048 364.453 72.0684C364.047 71.5267 363.503 71.2559 362.82 71.2559C362.143 71.2559 361.609 71.4876 361.219 71.9512C360.828 72.4095 360.633 73.0319 360.633 73.8184C360.633 74.6204 360.828 75.2559 361.219 75.7246C361.609 76.1881 362.138 76.4199 362.805 76.4199C363.211 76.4199 363.589 76.3288 363.938 76.1465C364.286 75.959 364.56 75.696 364.758 75.3574C364.961 75.0189 365.062 74.6335 365.062 74.2012Z" fill="#7D8C97" />
				<path d="M389.602 125.484V124.289H392.406V115.82L389.922 117.594V116.266L392.523 114.477H393.82V124.289H396.5V125.484H389.602Z" fill="#7D8C97" />
				<path d="M405.562 119.977C405.562 121.815 405.237 123.219 404.586 124.188C403.94 125.156 402.984 125.641 401.719 125.641C400.453 125.641 399.503 125.159 398.867 124.195C398.232 123.232 397.914 121.826 397.914 119.977C397.914 118.086 398.221 116.669 398.836 115.727C399.456 114.784 400.432 114.312 401.766 114.312C403.062 114.312 404.018 114.789 404.633 115.742C405.253 116.695 405.562 118.107 405.562 119.977ZM404.133 119.977C404.133 118.388 403.948 117.237 403.578 116.523C403.214 115.81 402.609 115.453 401.766 115.453C400.901 115.453 400.279 115.805 399.898 116.508C399.523 117.211 399.336 118.367 399.336 119.977C399.336 121.539 399.526 122.682 399.906 123.406C400.292 124.13 400.901 124.492 401.734 124.492C402.562 124.492 403.169 124.122 403.555 123.383C403.94 122.643 404.133 121.508 404.133 119.977Z" fill="#7D8C97" />
				<path d="M33.6693 145.976L40.1677 148.669C41.1696 149.084 42.3121 148.625 42.7606 147.637C47.8636 136.402 54.0493 125.761 61.1866 115.846C61.8215 114.963 61.6489 113.742 60.7868 113.08L55.2089 108.798C54.311 108.109 53.0136 108.292 52.3512 109.209C44.6878 119.829 38.0565 131.238 32.5996 143.289C32.1318 144.322 32.6215 145.542 33.6693 145.976H33.6693Z" fill="#97D101" />
				<path d="M369.555 108.798L363.977 113.08C363.115 113.742 362.942 114.963 363.577 115.846C370.714 125.76 376.9 136.402 382.003 147.637C382.452 148.624 383.594 149.083 384.596 148.668L391.094 145.976C392.142 145.541 392.632 144.322 392.164 143.289C386.707 131.238 380.076 119.829 372.413 109.209C371.75 108.292 370.453 108.109 369.555 108.798V108.798Z" fill="#FF0000" />
				<path d="M280.628 49.0504L283.32 42.5484C283.753 41.5025 283.241 40.2967 282.183 39.8949C270.026 35.2797 257.301 31.8316 244.141 29.6921C243.023 29.5103 241.973 30.2988 241.826 31.4218L240.908 38.3952C240.766 39.472 241.508 40.4575 242.58 40.6331C254.849 42.6433 266.716 45.8617 278.061 50.1533C279.077 50.5378 280.212 50.0544 280.628 49.0504Z" fill="#FF8E01" />
				<path d="M323.632 72.7466L327.911 67.1674C328.6 66.2693 328.417 64.972 327.499 64.3098C316.882 56.653 305.476 50.0276 293.429 44.576C292.395 44.1084 291.176 44.5984 290.742 45.6463L288.051 52.1453C287.636 53.1473 288.096 54.2897 289.083 54.738C300.315 59.8361 310.953 66.0163 320.866 73.1473C321.748 73.7819 322.97 73.6089 323.632 72.7466V72.7466Z" fill="#FF6401" />
				<path d="M183.855 38.3952L182.937 31.4218C182.789 30.2988 181.74 29.5103 180.622 29.6921C167.462 31.8317 154.736 35.2797 142.58 39.8949C141.522 40.2967 141.01 41.5025 141.443 42.5484L144.135 49.0504C144.551 50.0544 145.686 50.5378 146.702 50.1533C158.047 45.8618 169.913 42.6433 182.182 40.6331C183.254 40.4575 183.996 39.472 183.855 38.3952L183.855 38.3952Z" fill="#FFCB00" />
				<path d="M334.278 72.0681L329.999 77.6466C329.339 78.5075 329.487 79.7303 330.325 80.4187C339.81 88.2035 348.513 96.9043 356.301 106.386C356.99 107.225 358.213 107.373 359.073 106.712L364.651 102.431C365.55 101.741 365.708 100.437 364.989 99.5604C356.654 89.3874 347.324 80.061 337.149 71.7286C336.271 71.0103 334.968 71.1685 334.278 72.0681Z" fill="#FF3C01" />
				<path d="M394.189 153.389L387.687 156.083C386.683 156.499 386.2 157.633 386.585 158.65C390.883 170.002 394.105 181.877 396.118 194.155C396.294 195.227 397.28 195.969 398.356 195.827L405.33 194.909C406.453 194.761 407.241 193.712 407.059 192.594C404.917 179.424 401.464 166.689 396.843 154.525C396.441 153.467 395.235 152.955 394.189 153.389Z" fill="#C10000" />
				<path d="M191.818 37.3626C191.96 38.4387 192.928 39.1994 194.008 39.0927C200.053 38.4961 206.179 38.1822 212.378 38.1822C218.576 38.1822 224.702 38.4961 230.746 39.0927C231.826 39.1993 232.795 38.4387 232.937 37.3626L233.854 30.3883C234.002 29.2647 233.195 28.2321 232.067 28.1194C225.589 27.4722 219.023 27.1309 212.378 27.1309C205.732 27.1309 199.166 27.4722 192.688 28.1194C191.56 28.2321 190.752 29.2647 190.9 30.3883L191.818 37.3626L191.818 37.3626Z" fill="#FFB601" />
				<path d="M103.892 73.1475C113.805 66.0165 124.444 59.8362 135.675 54.738C136.663 54.2897 137.122 53.1473 136.707 52.1453L134.016 45.6463C133.582 44.5984 132.363 44.1084 131.33 44.576C119.282 50.0277 107.876 56.6532 97.2588 64.3101C96.3407 64.9723 96.1579 66.2696 96.8469 67.1677L101.127 72.7469C101.788 73.6092 103.01 73.7822 103.892 73.1475H103.892Z" fill="#D3CE01" />
				<path d="M37.0617 156.084L30.5602 153.391C29.5144 152.957 28.3085 153.469 27.9065 154.527C23.285 166.691 19.8325 179.426 17.6902 192.595C17.5083 193.713 18.2967 194.763 19.4197 194.911L26.393 195.829C27.4698 195.971 28.4554 195.229 28.6311 194.157C30.6438 181.879 33.8666 170.003 38.164 158.652C38.5487 157.635 38.0656 156.5 37.0617 156.084Z" fill="#6AD400" />
				<path d="M94.7619 77.6466L90.4828 72.0681C89.7927 71.1685 88.4894 71.0103 87.6122 71.7286C77.4367 80.061 68.1074 89.3874 59.7719 99.5603C59.0534 100.437 59.2112 101.741 60.1105 102.431L65.6876 106.712C66.5483 107.372 67.7712 107.225 68.4598 106.386C76.2476 96.9042 84.9509 88.2036 94.4355 80.4187C95.2742 79.7303 95.4223 78.5075 94.7619 77.6466Z" fill="#B5CF01" />

				<path className='value-scale value-scale-1' d="M79.5086 172.981L45.8133 159.844C41.3986 171.111 38.0894 182.953 36.0277 195.245C35.842 196.352 36.6105 197.399 37.7204 197.565L69.522 202.336C70.6049 202.498 71.6074 201.76 71.7927 200.681C73.4436 191.068 76.0512 181.804 79.5086 172.981Z" fill="#6AD400" />
				<path className='value-scale value-scale-2' d="M97.9448 139.83L68.927 118.279C52.3921 140.447 40.8282 166.627 36.0277 195.249C35.842 196.356 36.6105 197.402 37.7204 197.569L69.522 202.339C70.6049 202.502 71.6074 201.764 71.7927 200.684C75.6774 178.065 84.8599 157.372 97.9448 139.83Z" fill="#97D101" />
				<path className='value-scale value-scale-3' d="M123.067 113.764L100.424 85.6016C67.159 112.382 43.4686 150.881 36.0277 195.245C35.842 196.352 36.6105 197.399 37.7204 197.565L69.522 202.336C70.6049 202.498 71.6074 201.76 71.7927 200.681C77.831 165.522 96.6695 135.017 123.067 113.764Z" fill="#B5CF01" />
				<path className='value-scale value-scale-4' d="M154.349 94.586L139.645 61.5605C86.2633 85.4948 46.14 134.954 36.0277 195.245C35.842 196.352 36.6105 197.399 37.7204 197.565L69.522 202.335C70.6049 202.498 71.6074 201.76 71.7927 200.681C80.0097 152.836 111.931 113.609 154.349 94.586Z" fill="#D3CE01" />
				<path className='value-scale value-scale-5' d="M190.491 83.8642L184.958 48.123C109.849 59.8371 48.8421 118.844 36.0277 195.246C35.842 196.353 36.6105 197.4 37.7204 197.566L69.522 202.337C70.6049 202.499 71.6074 201.761 71.7927 200.682C82.2137 140.004 130.76 93.1871 190.491 83.8642Z" fill="#FFCB00" />
				<path className='value-scale value-scale-6' d="M227.703 82.9928C222.665 82.4474 217.557 82.1679 212.393 82.1679C142.939 82.1679 83.4622 132.734 71.7927 200.681C71.6074 201.76 70.6049 202.498 69.522 202.336L37.7204 197.566C36.6105 197.399 35.842 196.352 36.0277 195.245C50.3715 109.726 125.097 46 212.393 46C218.874 46 225.286 46.3512 231.609 47.0366L227.703 82.9928Z" fill="#FFB601" />
				<path className='value-scale value-scale-7' d="M264.546 92.1014C248.317 85.6817 230.702 82.1679 212.393 82.1679C142.939 82.1679 83.4622 132.734 71.7927 200.681C71.6074 201.76 70.6049 202.498 69.522 202.336L37.7204 197.566C36.6105 197.399 35.842 196.352 36.0277 195.245C50.3715 109.726 125.097 46 212.393 46C235.354 46 257.445 50.4086 277.797 58.4627L264.546 92.1014Z" fill="#FF8E01" />
				<path className='value-scale value-scale-8' d="M318.307 80.9345L296.864 110.022C273.059 92.4699 243.742 82.1679 212.393 82.1679C142.939 82.1679 83.4622 132.734 71.7927 200.681C71.6074 201.76 70.6049 202.498 69.522 202.336L37.7204 197.566C36.6105 197.399 35.842 196.352 36.0277 195.245C50.3715 109.726 125.097 46 212.393 46C251.702 46 288.461 58.9209 318.307 80.9345Z" fill="#FF6401" />
				<path className='value-scale value-scale-9' d="M351.976 113.256L323.727 135.802C297.37 102.963 256.933 82.1679 212.393 82.1679C142.939 82.1679 83.4622 132.734 71.7927 200.681C71.6074 201.76 70.6049 202.498 69.522 202.336L37.7204 197.566C36.6105 197.399 35.842 196.352 36.0277 195.245C50.3715 109.726 125.097 46 212.393 46C268.237 46 318.936 72.0777 351.976 113.256Z" fill="#FF3C01" />
				<path className='value-scale value-scale-10' d="M376.182 153.129L343.043 167.607C320.753 116.961 270.005 82.1679 212.393 82.1679C142.939 82.1679 83.4622 132.734 71.7927 200.681C71.6074 201.76 70.6049 202.498 69.522 202.336L37.7204 197.566C36.6105 197.399 35.842 196.352 36.0277 195.245C50.3715 109.726 125.097 46 212.393 46C284.623 46 348.247 89.6271 376.182 153.129Z" fill="#FF0000" />
				<path className='value-scale value-scale-11' d="M212.393 46C299.69 46 374.415 109.726 388.759 195.245C388.944 196.352 388.176 197.399 387.066 197.566L355.264 202.336C354.182 202.498 353.179 201.76 352.994 200.681C341.324 132.734 281.847 82.1679 212.393 82.1679C142.939 82.1679 83.4622 132.734 71.7927 200.681C71.6074 201.76 70.6049 202.498 69.522 202.336L37.7204 197.566C36.6105 197.399 35.842 196.352 36.0277 195.245C50.3715 109.726 125.097 46 212.393 46Z" fill="#C10000" />
			</g>

			<text
				x={valueNum === NA ? 215 : valueHorizontalPosition}
				y={valueVerticalPosition}
				fontSize={`${valueFontSize}px`}
				fill='#233544'
				textAnchor='middle'
			>
				{valueNum}
			</text>
			<defs>
				<clipPath id="clip0_7821_55329">
					<rect width="428" height="428" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default RiskRatingChart;
