import React from 'react';
import Markdown from 'react-markdown';
import cn from '../../../../lib/classNames';
import Accordion from '../Accordion';

import styles from './Emergency.module.scss';

export default function Emergency(props) {
	const { className, data = 'N/A' } = props;

	return (
		<section className={cn(styles.emergency, className)}>
			<Accordion
				description={<Markdown components={{ h2: 'h4' }}>{data}</Markdown>}
			/>
		</section>
	);
}
