export const adaptDataForDestinationRiskApi = (data) => {
    const {ageGroup: ageBucket, homeLocation, destinationLocation, email} = data;
    return {
        ageBucket,
        homeLat: homeLocation.value.latitude,
        homeLng: homeLocation.value.longitude,
        destLat: destinationLocation.value.latitude,
        destLng: destinationLocation.value.longitude,
        email,
    };
};

export const adaptDataFromHomeCovidTrendHistoryApi = (data) => {
    const newData = data.map((el) => ({
        date: el.date,
        'case': el.avg_cases,
        death: el.avg_deaths,
    }));

    return newData;
};