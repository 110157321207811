import { createReducer, createActions } from 'reduxsauce';

const { Types, Creators } = createActions({
	reportRiskTravelRequest: ['data'],
	reportRiskTravelSuccess: ['data'],
	reportRiskTravelFailure: ['data'],

	reportSetLastReportData: ['data'],
	reportSetTravelingScoring: ['data'],
});

export const ReportTypes = Types;
export default Creators;

export const REPORT_PENDING = {
	reportRiskTravel: 'reportRiskTravel',
};

export const INITIAL_STATE = {
	reportRiskTravel: {
		error: false,
		data: null,
	},
	lastReportData: {
		person: {
			id: null,
			name: null,
		},
		modifiers: [],
		residence: {
			locationName: null,
			boundaryId: null,
		},
		destination: {
			locationId: null,
			locationName: null,
			boundaryId: null,
		},
	},
	travelingScoring: null,
};

export const reportRiskTravelRequest = (state, action) => {
	return {
		...state,
		reportRiskTravel: {
			error: false,
			data: null,
		},
	};
};
export const reportRiskTravelSuccess = (state, action) => {
	const { data } = action;
	return {
		...state,
		reportRiskTravel: {
			error: false,
			data,
		},
	};
};
export const reportRiskTravelFailure = (state, action) => {
	return {
		...state,
		reportRiskTravel: {
			error: true,
		},
	};
};

export const reportSetLastReportData = (state, action) => {
	const { data } = action;
	return {
		...state,
		lastReportData: data,
	};
};

export const reportSetTravelingScoring = (state, action) => {
	const { data } = action;
	return {
		...state,
		travelingScoring: data,
	};
};

export const reducer = createReducer(INITIAL_STATE, {
	[Types.REPORT_RISK_TRAVEL_REQUEST]: reportRiskTravelRequest,
	[Types.REPORT_RISK_TRAVEL_SUCCESS]: reportRiskTravelSuccess,
	[Types.REPORT_RISK_TRAVEL_FAILURE]: reportRiskTravelFailure,
	[Types.REPORT_SET_LAST_REPORT_DATA]: reportSetLastReportData,
	[Types.REPORT_SET_TRAVELING_SCORING]: reportSetTravelingScoring,
});
