import React from 'react';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import {withStyles} from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Icon from 'react-svg-icon';

import styles from './TextInput.jss';

const TextInput = (props) => {
    const {
        classes, placeholder, type = 'text', error = false, errorMessage = '',
        dark = false, clearable = false, endAdornment, onClear, ...rest
    } = props;

    let endAdornmentAdjusted = endAdornment;
    if (!endAdornment && clearable) {
        const onClickClear = (e) => {
            if (onClear) onClear();
        };
        endAdornmentAdjusted = (
            <InputAdornment position="end">
                <IconButton className={classes.clearButton} onClick={onClickClear}>
                    <Icon name="svg-i-close" className={classes.iconClose} />
                </IconButton>
            </InputAdornment>
        );
    }

    return (
        <FormControl
            fullWidth
            error={!!error}
        >
            <Input
                placeholder={placeholder}
                classes={{
                    root: classes.root,
                    underline: (error) ? classes.underlineError : classes.underline,
                    input: (dark) ? classes.inputDark : classes.input,
                    focused: classes.focused,
                }}
                fullWidth
                type={type}
                endAdornment={endAdornmentAdjusted}
                {...rest}
            />
            {error && <FormHelperText>{errorMessage}</FormHelperText>}
            
      </FormControl>
    );
};

export default withStyles(styles)(TextInput);
