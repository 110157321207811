
const styles = (theme) => ({
    container: {
        minHeight: 70,
        backgroundColor: '#233544',
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
        '& .info': {
            overflow: 'hidden',
            padding: '16px 20px',
            color: theme.palette.primary.light,
            fontWeight: 'normal',
            lineHeight: '17px',
            '& h2': {
                margin: 0,
                color: theme.palette.primary.contrastText,
                fontSize: '1.142857142857143rem',
                fontWeight: 'normal',
                lineHeight: '20px',
                marginBottom: 7,
            },
            '& h2.no-subscription': {
                paddingTop: 10,
            },
            '& ul': {
                marginLeft: 14,
            },
            '& .columns': {
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
            },
            '& .column': {
                width: '46%',
            },
        }
    },
});

export default styles;
