
const styles = (theme) => ({
    row: {
        position: 'relative',
        height: '45px',
        color: theme.palette.text.secondary,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
        '& > button': {
            display: 'none',
        },
        '&:hover > button': {
            display: 'inline-block',
        },
        '& > span': {
            paddingLeft: 22,
            textOverflow: 'ellipsis',
            width: '80%',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            display: 'inline-block',
            lineHeight: '42px',
        },
    },
    editAction: {
        position: 'absolute',
        right: 40,
        top: 2,
        padding: 4,
    },
    deleteAction: {
        position: 'absolute',
        right: 6,
        top: 2,
        padding: 4,
    },
    icon: {
        width: 31,
        height: 31,
        cursor: 'pointer',
        stroke: theme.palette.primary.light,
        '&:hover': {
            stroke: theme.palette.secondary.main,
        }
    },
});

export default styles;
