
const styles = (theme) => ({
    container: {
        '& .submit': {
            marginTop: 20,
        },
        '& .bank-card span': {
            paddingRight: 30,
            color: '#fff',
        },
        '& .bank-card span:last-child': {
            paddingRight: 0,
        },
        '& .card': {
            padding: '14px 20px 20px',
        },
    },
    containerLight: {
        '& .submit': {
            marginTop: 20,
        },
        '& .bank-card span': {
            paddingRight: 30,
            color: theme.palette.primary.main,
            fontSize: '1.285714285714286rem',
        },
        '& .bank-card span:last-child': {
            paddingRight: 0,
        },
        '& .card': {
            padding: '14px 20px 20px',
        },
    }
});

export default styles;
