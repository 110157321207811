import React from 'react';

import homeIcon from '../../../images/home_icon.svg';
import locationDestinationIcon from '../../../images/Location_solid.svg';

import './SimilarCities.scss';

const SimilarCities = (props) => {
	const { location, locationTitle, riskValue } = props;

	const classRiskColor = riskValue < 10 ? `heatmap-0${riskValue}` : `heatmap-${riskValue}`;

	return (
		<div className={`similar-cities ${classRiskColor}`}>
			<div className="content-wrap">
				<div className='left-block'>
					<img
						src={locationTitle === 'Home' ? homeIcon : locationDestinationIcon}
						alt=''
					/>
				</div>
				<div className='right-block'>
					<div className='risk-rating'>
						<div className='risk-rating-data'>
							{riskValue > 0 ? riskValue : 'N.A.'}{riskValue > 0 ? (<span>/11</span>) : ''}
						</div>
						<div className='risk-rating-title'>Risk Rating</div>
					</div>
					{/* <div className='percentile-rank'>
					<div className='percentile-rank-data'>49</div>
					<div className='percentile-rank-title'>Percentile Rank</div>
				</div> */}
				</div>
			</div>
			<div className="footer">
				<div className='location' title={location}>
					{location}
				</div>
				<div className='location-title'>{locationTitle}</div>
			</div>
		</div>
	);
};

export default SimilarCities;
