// @flow
import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import styles from './RiskHighlightsIndicator.jss';

const RiskHighlightsIndicator = ({ name, value, description, classes }) => {
    const colors = ['#6AD400', '#97D101', '#D3CE01', '#FFB601', '#FF0000'];

    return (
        <div className={classes.riskScoreChart}>
            <div className='ratingData' style={{background: colors[value - 1]}}>
                <div className='ratingVal'>
                    {value}
                    <span className='ratingTotal'> /5</span>
                </div>
            </div>
            <div className='ratingTextInfo'>
                <div className='ratingName'>{name}</div>
                <div className='ratingDescription'>{description}</div>
            </div>
        </div>
    );
};

export default withStyles(styles)(RiskHighlightsIndicator);
