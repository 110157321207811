import React from 'react';

import './GraphColor.scss';

const GraphColor = (props) => {
    const { className, ...rest } = props;
    return <div className={`graph-color-icon ${className}`} {...rest} />;
};

export default GraphColor;
