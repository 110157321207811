const config = {
  useFixtures: false,
  debug: false,
  log: true,
  apiBaseURL:
    process.env.REACT_APP_API_ENDPOINT || "https://xplore.safe-esteem.com:8443",
  mapbox: {
    accessToken:
      "pk.eyJ1IjoiZmlsaXBwbzMwNSIsImEiOiJjanRlbTVwNjMxam02NDNzenBxc3ByZzA4In0.P9lseqfFwQzcRzdAOgYi_A",
    geocoding: "https://api.mapbox.com/geocoding/v5",
    style: "mapbox://styles/filippo305/cju42q7t9074a1ft605hlegpa",
    accessTokenReport:
      "pk.eyJ1IjoibWFydGluc2Vhbmh1bnQiLCJhIjoiY2xvb21pa2prMDIzcjJrbHEzdHl5ajE5NSJ9.zf_yj5vtlxim_cXq6T0HxA",
    styleReport: "mapbox://styles/mapbox/streets-v11",
    // ViCRI Report map
    accessTokenRatingMap:
      'pk.eyJ1IjoiZmlsaXBwbzMwNSIsImEiOiJjanN1aXVjd20xMm4zNDNvMTY1b2QyZ3dlIn0.yBNNjVibr4MmuddYcGtD0A',
    styleRatingMap: 'mapbox://styles/filippo305/clv4zq96c00i201oc83c15gt0',
    tilesetCountryLevel: 'mapbox://filippo305.7887f2r3',
    layerCountryLevel: 'se_level0_tolerance0-5knzy8',
    tilesetRegionLevel: 'mapbox://filippo305.d9l995yf',
    layerRegionLevel: 'se_level1_2_tolerance_0_01-05pvr2',
  },
  stripe: {
    apiKey:
      process.env.REACT_APP_STRIPE_API_KEY ||
      "pk_test_8FNzCw0l2Th8mVD9F8HOrUBA00XMEsUW25",
  },
  ortto: {
    generalKey: "ZJwvjdMG84ljhJTcc2FmZWVzdGVlbQ",
    createReport: {
      id: "act:cm:createdreport",
      key: "PRV-safeesteem-_07e7lc6HE8g7dk_DVDPneRtHps3R8WOygZJVuSVgNg",
    },
  },
};

export default config;
