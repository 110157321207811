import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Color } from '../../theme';
import riskStatic from '../../../images/icons/risk_static.svg';
import './PersonalRiskStaticInfo.scss';

const styles = {
    root: {
        width: '100%',
        marginTop: '15px !important',
        borderTop: '1px solid rgba(35, 53, 68, .1)',
        boxShadow: 'none',
        borderRadius: '0 !important',

        '&:before': {
            content: 'none',
        },
    },

    summaryRoot: {
        minHeight: 'auto !important',
        padding: 0,
        margin: '10px 0',
    },

    summaryContent: {
        margin: '0 !important',
    },

    summaryExpandIcon: {
        paddingTop: 0,
        paddingBottom: 0,

        '& svg': {
            fontSize: '2.5rem',
        },
    },

    heading: {
        fontSize: '13px',
        color: Color.blueDark,
        marginBottom: 0,
    },

    details: {
        padding: 0,
        marginBottom: 12,
    },

    detailsBody: {
        fontSize: '12px',
        color: Color.gray,
    },
};

const PersonalRiskStaticInfo = (props) => {
    const { classes } = props;

    return (
        <div className='personal-risk-static-info-container'>
            <div className='block-img'>
                <img src={riskStatic} alt='' />
            </div>
            <div className='block-text'>
                <p>
                    Your mortality risk increase based on local reports and your
                    demographic group could be less than 2%. This, however,
                    should NOT be interpreted as an indication that you are risk
                    free...
                </p>
                <div>
                    <ExpansionPanel
                        classes={{
                            root: classes.root,
                            rounded: classes.rounded,
                        }}
                    >
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls='panel1a-content'
                            id='panel1a-header'
                            classes={{
                                root: classes.summaryRoot,
                                content: classes.summaryContent,
                            }}
                        >
                            <Typography className={classes.heading}>
                                Your behavior can act as a risk multiplier...
                            </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                            classes={{ root: classes.details }}
                        >
                            <Typography className={classes.detailsBody}>
                                By not wearing a mask, attending large
                                gatherings or not practicing social distancing,
                                you could easily increase your risk of infection
                                exponentially - this tool is not taking behavior
                                into account, which means it could be severely
                                underestimating your risk of dying.
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel
                        classes={{
                            root: classes.root,
                            rounded: classes.rounded,
                        }}
                    >
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls='panel1a-content'
                            id='panel1a-header'
                            classes={{
                                root: classes.summaryRoot,
                                content: classes.summaryContent,
                            }}
                        >
                            <Typography className={classes.heading}>
                                Mortality risk may be low for you now, but
                                morbidity risks are still unclear and can be
                                very severe...
                            </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                            classes={{ root: classes.details }}
                        >
                            <Typography className={classes.detailsBody}>
                                The risk for severe illness and death from
                                COVID-19 increases with age. Yet COVID-19 has
                                proven to carry health consequences for people
                                of all ages. These range from mild flu-like
                                symptoms to critical conditions requiring
                                advanced life support by intensive care units.
                                There is not enough data yet to accurately
                                measure and predict the morbidity and long-term
                                health effects, but early results indicate these
                                may impact the lungs, as well as circulatory and
                                central nervous systems.
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel
                        classes={{
                            root: classes.root,
                            rounded: classes.rounded,
                        }}
                    >
                        <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls='panel1a-content'
                            id='panel1a-header'
                            classes={{
                                root: classes.summaryRoot,
                                content: classes.summaryContent,
                            }}
                        >
                            <Typography className={classes.heading}>
                                You could easily spread the disease...
                            </Typography>
                        </ExpansionPanelSummary>
                        <ExpansionPanelDetails
                            classes={{ root: classes.details }}
                        >
                            <Typography className={classes.detailsBody}>
                                Many if not most infected people are
                                asymptomatic. You could be infected right now
                                and actively spreading the disease, endangering
                                your family, friends, coworkers and fellow
                                citizens. You can actively reduce your personal
                                risk and that of your loved ones and your
                                community by taking basic precautions that are
                                proven to save lives and minimize the COVID-19
                                economic impact.
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                </div>
            </div>
        </div>
    );
};

export default withStyles(styles)(PersonalRiskStaticInfo);
