import React, {PureComponent} from 'react';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@material-ui/core/MenuItem';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import styles from './Select.jss';

class CSelect extends PureComponent {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        placeholder: PropTypes.string,
        items: PropTypes.array.isRequired, // [{title, value}]
        onChange: PropTypes.func,
        name: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired,
    };

    handleChange = (event) => {
        const {onChange} = this.props;
        if (onChange) onChange(event.target.name, event.target.value);
    }

    renderItem(item) {
        const {title, value} = item;
        return (
            <MenuItem value={value}>{title}</MenuItem>
        );
    }

    render() {
        const {classes, placeholder, value, name = '', items = [], error = ''} = this.props;
        return (
            <FormControl
                fullWidth
                error={!!error}
            >
                <Select
                    value={value}
                    onChange={this.handleChange}
                    name={name}
                    displayEmpty
                    classes={{
                        root: classes.root,
                        select: (error) ? classes.underlineError : classes.underline,
                        icon: classes.icon,
                        selectMenu: classes.selectMenu,
                    }}
                >
                    {placeholder && <MenuItem value="" disabled>{placeholder}</MenuItem>}
                    {items.map((el) => (
                        this.renderItem(el)
                    ))}
                </Select>
                {error && <FormHelperText>{error}</FormHelperText>}    
            </FormControl>
        );
    }
};

export default withStyles(styles)(CSelect);
