import React from 'react';
import { useEffect, useState } from 'react';

import cn from '../../../../lib/classNames';

import styles from './ComparisonInfo.module.scss';

export default function ComparisonInfo(props) {
	const { type, percent, min, max, destinationValue } = props;
	const [show, setShow] = useState(false);

	useEffect(() => {
		setShow(true);
	}, []);

	const title = destinationValue > 0 ? `${type} ${min}-${max}` : `${type}`;
	let body = `Destination country ${type} is higher than ${percent}% of indexed nations`;

	if (destinationValue === 0) {
		body = (
			<>
				The destination is designated as a <b>poor data</b> location.
				HR/VCR estimates are unreliable.
			</>
		);
	}

	if (destinationValue === -1) {
		body = (
			<>
				The destination is designated as a <b>conflict zone</b>. HR/VCR
				estimates are unreliable.
			</>
		);
	}

	return (
		<div className={cn(styles['comparison-info'], { [styles.visible]: show })}>
			<h5 className={styles.title}>
				<div className={cn(styles.image, 'material-icons-round')}>
					location_on
				</div>
				<div className={styles.text}>{title}</div>
			</h5>
			<p className={styles.body}>{body}</p>
		</div>
	);
}
