import Color from 'color';

const styles = (theme) => ({
    root: {
        height: 50,
        borderRadius: 25,
    },
    rootSmall: {
        height: 30,
        minHeight: 30,
        borderRadius: 15,
        boxShadow: 'none',
        lineHeight: 'unset',
    },
    rootTiny: {
        height: 26,
        minHeight: 26,
        borderRadius: 13,
        boxShadow: 'none',
        lineHeight: 'unset',
    },
    label: {
        fontSize: '1.143rem',
        letterSpacing: 0.6,
        color: theme.palette.primary.dark,
    },
    labelSmall: {
        fontSize: '0.8571rem',
        fontWeight: 500,
        color: theme.palette.primary.dark,
        textTransform: 'none',
    },
    labelTiny: {
        fontSize: '0.8571rem',
        fontWeight: 500,
        color: theme.palette.primary.dark,
        textTransform: 'none',
    },
    default: {
        backgroundColor: theme.palette.primary.main,
        border: '1px solid ' + theme.palette.common.grey,
        '&:hover': {
            backgroundColor: Color(theme.palette.primary.main).darken(0.2).string(),
        },
    },
    primary: {
        backgroundColor: theme.palette.common.blue,
        boxShadow: theme.shadows[24],
        borderColor: theme.palette.common.blue,
        '&:hover': {
            backgroundColor: Color(theme.palette.common.blue).darken(0.2).string(),
            borderColor: Color(theme.palette.common.blue).darken(0.2).string(),
        }
    },
    secondary: {
        '&:hover': {
            backgroundColor: Color(theme.palette.common.white).darken(0.1).string(),
        }
    },
    defaultLabelColor: {
        color: theme.palette.common.white,
    },
    primaryLabelColor: {
        color: theme.palette.common.white,
    },
    secondaryLabelColor: {
        color: theme.palette.primary.dark,
    },
});

export default styles;
