
const styles = (theme) => ({
    container: {
        display: 'flex',
        flex: '1 1 auto',
        justifyContent: 'center',
        alignItems: 'flex-start',
        paddingTop: 45,
        paddingLeft: 10,
        alignSelf: 'flex-start',
    },
    content: {
        padding: 0,
    },
    panel: {
        zIndex: 100,
        maxWidth: 1092,
        width: '100%',
        minHeight: 204,
        marginTop: -10,
        marginLeft: 10,
        position: 'relative',
    },
    header: {
        // borderBottom: `1px solid ${theme.palette.primary.dark}`,
        padding: '13px 22px',
    },
    headerLine: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    overlay: {
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        width: '100%',
        backgroundColor: 'rgba(0,0,0,0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: theme.shape.borderRadius,
        zIndex: 500,
    },
    list: {
        margin: '0 8px 8px',
        backgroundColor: 'white',
        borderRadius: 12,
        padding: '40px 40px 20px 40px',
        display: 'flex',
        flex: 0,
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        '& .item': {
            display: 'flex',
            flexDirection: 'column',
            width: 200,
            padding: '20px 20px 0',
            borderRadius: 8,
            '&:hover': {
                background: 'linear-gradient(180deg, rgba(229, 234, 237, 0) 7.29%, #DBE2E7 100%)',
            },
            '&:hover .button': {
                visibility: 'visible !important',
            },
            '& h5': {
                fontSize: '1.57142rem',
                fontWeight: 'bold',
                lineHeight: '30px',
                color: theme.palette.primary.main,
            },
            '& p': {
                fontSize: '1.285714285714286rem',
                lineHeight: '26px',
                color: theme.palette.primary.main,
            },
            '& ul': {
                listStyle: 'none',
                color: theme.palette.primary.light,
                fontSize: '1.285714285714286rem',
                lineHeight: '26px',
            },
            '& .button': {
                width: 200,
                alignItems: 'center',
                alignSelf: 'flex-end',
                marginTop: 'auto',
            }
        }
    },
    confirmPlan: {
        backgroundColor: '#ccc',
        margin: 0,
        padding: '10px',
        borderRadius: '5px',
        marginTop: '15px',
    },
    promoCodeContainer: {
        paddingTop: 20,
        paddingBottom: 10,
    },
});

export default styles;
