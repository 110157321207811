// @flow
import RiskScoreDecrease from './RiskScoreDecrease';

class RiskScoreIncrease extends RiskScoreDecrease {
    getArrowPath(ax1, ah) {
        return `M${ax1},0 v${ah} M0,24 L20,0 L42,24`;
    }
    getArrowPath2() {
        return `M6.20826 23.125L8.7916 23.125L8.7916 5.33658L12.9067 9.45169L14.7334 7.625L7.42663 0.318229L5.59994 2.14492L0.119858 7.62501L1.94655 9.4517L6.20826 5.18999L6.20826 23.125Z`;
    }
}

RiskScoreIncrease.defaultProps = {
    width: 210,
    height: 150,
    radius: 16,
    lineWidth: 2,
    color: {
        line: '#FF6401',
        text: '#233544',
    },
};

export default RiskScoreIncrease;
