import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Icon from 'react-svg-icon';
import MediaQuery from 'react-responsive';
import { connect } from 'react-redux';

import { Panel, OutlinedButton, DarkButton } from '../../../components';
import EditAccount from '../../Settings/components/EditAccount';
import { reportTravelingScoring } from '../../../store/selectors';
import DataLayer from '../../Report/components/DataLayer';

import styles from './ReportPanel.jss';

class ReportPanel extends PureComponent {
	static propTypes = {
		data: PropTypes.object,
		onClickChangeData: PropTypes.func,
		onClickGenerateReport: PropTypes.func,
		loading: PropTypes.bool,
		travelingScoring: PropTypes.object,
	};

	state = {
		editBillingDetails: false,
	};

	handleOnClickCancel = () => {
		const { onClickChangeData } = this.props;
		if (onClickChangeData) onClickChangeData();
	};

	handleOnClickGenerateReport = () => {
		const { onClickGenerateReport } = this.props;
		if (onClickGenerateReport) onClickGenerateReport();
	};

	toggleEditBillingInfo = () => {
		const { editBillingDetails } = this.state;
		this.setState({ editBillingDetails: !editBillingDetails });
	};

	render() {
		const { classes, data, loading, travelingScoring } = this.props;
		const { person, residence, destination } = data;
		const { editBillingDetails } = this.state;

		console.log('=== ReportPanel::render', { travelingScoring });

		const filterDataNotNull = (location) => {
			let locationData = location.levelNames;
			locationData = locationData.filter((e) => {
				return e.trim() !== '';
			});
			return locationData;
		};

		const locationLabel = ['Country', 'State/Region', 'County', 'City'];
		const locationResidence = filterDataNotNull(residence);
		const locationDestination = filterDataNotNull(destination);

		const renderLocationData = () => {
			const locationMaxLength = Math.max(
				locationResidence.length,
				locationDestination.length
			);
			const renderTableTrDesktop = [];

			for (let i = 0; i < locationMaxLength; i++) {
				renderTableTrDesktop.push(
					<tr key={i}>
						<td>
							<table cellPadding='0' cellSpacing='0' border='0'>
								<tbody>
									<tr>
										<td className='location-label'>{locationResidence[i] ? locationLabel[i] : ''}</td>
										<td className='location-data'>
											<b>{locationResidence[i]}</b>
										</td>
									</tr>
								</tbody>
							</table>
						</td>
						<td>
							<table cellPadding='0' cellSpacing='0' border='0'>
								<tbody>
									<tr>
										<td className='location-label'>{locationDestination[i] ? locationLabel[i] : ''}</td>
										<td className='location-data'>
											<b>{locationDestination[i]}</b>
										</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
				);
			}
			return renderTableTrDesktop;
		};

		const renderLocationDataMob = (location) => {
			const renderTableTrMob = [];

			for (let i = 0; i < location.length; i++) {
				renderTableTrMob.push(
					<tr key={i}>
						<td>
							<table cellPadding='0' cellSpacing='0' border='0'>
								<tbody>
									<tr>
										<td className='location-label'>{locationLabel[i]}</td>
										<td className='location-data'>
											<b>{location[i]}</b>
										</td>
									</tr>
								</tbody>
							</table>
						</td>
					</tr>
				);
			}
			return renderTableTrMob;
		};

		return [
			<Panel className={classes.panel} key='panel' loading={loading}>
				<div className={classes.container}>
					<div className={classes.header}>
						<div className={classes.personName}>
							<Icon name='svg-i-persona-light' />
							{person.name}
						</div>
						<div className={classes.buttonChange}>
							<DarkButton
								fullWidth={false}
								small={true}
								secondary
								onClick={this.toggleEditBillingInfo}
							>
								View/Edit Billing Info
							</DarkButton>
						</div>
					</div>
					<div className={classes.locationInfo}>
						<MediaQuery minDeviceWidth={768}>
							<div className='location-desktop'>
								<table
									className='table-wrap table-wrap-desktop'
									cellPadding='0'
									cellSpacing='0'
								>
									<tbody>
										<tr>
											<th>
												<table cellPadding='0' cellSpacing='0' border='0'>
													<tbody>
														<tr>
															<th>
																<Icon
																	name='svg-i-residence'
																	className={classes.residenceIcon}
																/>
															</th>
															<th>Residence</th>
														</tr>
													</tbody>
												</table>
											</th>
											<th>
												<table cellPadding='0' cellSpacing='0' border='0'>
													<tbody>
														<tr>
															<th>
																<Icon
																	name='svg-i-destination'
																	className={classes.destinationIcon}
																/>
															</th>
															<th>Travel Destination</th>
															<th>
																<div className={classes.dataLayerContainer}>
																	<DataLayer numData={travelingScoring.maxCrimeLevel} />
																</div>
															</th>
														</tr>
													</tbody>
												</table>
											</th>
										</tr>
										{renderLocationData()}
									</tbody>
								</table>
							</div>
						</MediaQuery>
						<MediaQuery maxDeviceWidth={767}>
							<div className='location-mobile'>
								<table
									className='table-wrap location-mobile-residence'
									cellPadding='0'
									cellSpacing='0'
								>
									<tbody>
										<tr>
											<th>
												<table cellPadding='0' cellSpacing='0' border='0'>
													<tbody>
														<tr>
															<th>
																<Icon
																	name='svg-i-residence'
																	className={classes.residenceIcon}
																/>
															</th>
															<th>Residence</th>
														</tr>
													</tbody>
												</table>
											</th>
										</tr>
										{renderLocationDataMob(locationResidence)}
									</tbody>
								</table>
								<table
									className='table-wrap location-mobile-destinationIcon'
									cellPadding='0'
									cellSpacing='0'
								>
									<tbody>
										<tr>
											<th>
												<table cellPadding='0' cellSpacing='0' border='0'>
													<tbody>
														<tr>
															<th>
																<Icon
																	name='svg-i-destination'
																	className={classes.destinationIcon}
																/>
															</th>
															<th>Travel Destination</th>
															<th>
																<div className={classes.dataLayerContainer}>
																	<DataLayer numData={travelingScoring.maxCrimeLevel} />
																</div>
															</th>
														</tr>
													</tbody>
												</table>
											</th>
										</tr>
										{renderLocationDataMob(locationDestination)}
									</tbody>
								</table>
							</div>
						</MediaQuery>
					</div>
					<div className={classes.modifiersContainer}>
						<div className='header'>Modifiers:</div>
						<div className='body'>
							Traveler will be accompanied and in group activities for 90% or
							more of the activities at destination (vs. solo or 1-on-1
							activities/meetings). Traveler is a publicly known personality at
							destination. Traveler has an established local social/business
							network (family, friends, business associates, etc.). Travel for
							business, pleasure, both. Local automobile transfers are via a
							local, trained driver
						</div>
					</div>
					<div className={classes.buttonContainer}>
						<OutlinedButton
							className={`${classes.footerButton} footerButtonChange`}
							secondary
							onClick={this.handleOnClickCancel}
						>
							Change data
						</OutlinedButton>
						<OutlinedButton
							className={`${classes.footerButton} footerButtonGenerate`}
							primary
							onClick={this.handleOnClickGenerateReport}
						>
							<Icon name='svg-i-report' className={classes.reportIcon} />
							Generate Report
						</OutlinedButton>
					</div>
				</div>
			</Panel>,
			editBillingDetails ? (
				<EditAccount key='edit-account' onClose={this.toggleEditBillingInfo} />
			) : null,
		];
	}
}

ReportPanel.defaultProps = {
	loading: false,
};

const mapStateToProps = (state) => ({
	travelingScoring: reportTravelingScoring(state),
});

export default connect(mapStateToProps)(withStyles(styles)(ReportPanel));
