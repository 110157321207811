import React, {PureComponent} from 'react';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';

import styles from './Paper.jss';

class CPaper extends PureComponent {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        children: PropTypes.node,
    };

    render() {
        const {classes, children} = this.props;
        return (
            <Paper className={classes.root} elevation={undefined}>
                {children}
            </Paper>
        );
    }
}

export default withStyles(styles)(CPaper);
