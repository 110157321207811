
const styles = (theme) => ({
    root: {
        minWidth: 412,
        minHeight: 436,
        backgroundColor: theme.palette.primary.main,
        boxShadow: theme.shadows[23],
        position: 'relative',
    },
    rootLight: {
        minWidth: 282,
        minHeight: 190,
        backgroundColor: theme.palette.secondary.main,
        boxShadow: 'none',
        position: 'relative',
    },
    overlay: {
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        width: '100%',
        backgroundColor: 'rgba(0,0,0,0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: theme.shape.borderRadius,
        '& > *': {
            width: '50%',
        }
    },
    closeButton: {
        position: 'absolute',
        right: 1,
        top: 1,
        color: theme.palette.grey[500],
    },
});

export default styles;
