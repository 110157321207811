import {createReducer, createActions} from 'reduxsauce';
import Immutable from 'seamless-immutable';

const {Types, Creators} = createActions({
    networkAddPending: ['name'],
    networkDelPending: ['name'],
});

export const NetworkTypes = Types;
export default Creators;

export const INITIAL_STATE = Immutable({
    pending: {
        searchPlaces: 0,
        userLogin: 0,
        userDetails: 0,
        userMapboxHodeData: 0,
        getRaces: 0,
        getGenders: 0,
        getModifiers: 0,
        boundariesList: 0,
        getPersons: 0,
        reportRiskTravel: 0,
    }
});

// pending
export const networkAddPending = (state, action) => {
    const {name} = action;
    return state.merge({
        pending: {
            [name]: state.pending[name] + 1,
        }
    }, {deep: true});
};
export const networkDelPending = (state, action) => {
    const {name} = action;
    return state.merge({
        pending: {
            [name]: (state.pending[name] > 0) ? state.pending[name] - 1 : 0,
        }
    }, {deep: true});
};

export const reducer = createReducer(INITIAL_STATE, {
    [Types.NETWORK_ADD_PENDING]: networkAddPending,
    [Types.NETWORK_DEL_PENDING]: networkDelPending,
});
