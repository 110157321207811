// @flow
import React, {Component} from 'react';
import * as d3 from 'd3';

type PropsType = {
    width: number,
    height: number,
    value: number,
    valueWidth: number,
    color: string,
    labelHeight: number,
    label: string,
    centerIcon: React.ReactNode,
    iconSize: number,
    onComplete: Function,
    colors: Array<string>,
};

class RiskScoreLine extends Component<PropsType> {
    chart = null;
    chartInited = false;
    svg = null;

    initChart() {
        if (this.chart && !this.chartInited) {
            this.chartInited = true;
            const {width, height, labelHeight, onComplete} = this.props;

            const svg = d3.select(this.chart);
            svg.attr("width", width).attr("height", height + labelHeight);
            this.svg = svg;

            this.initDefs();
            this.initLine();
            this.addCenterPoint();
            this.addLabel();
            this.addValue();

            if (onComplete) onComplete({
                svg: svg.html(),
                width,
                height: height + labelHeight,
            });
        }
    }

    initDefs() {
        const svg = this.svg;
        if (svg) {
            const defs = svg.append("defs");
            const maskDestination = defs.append('mask')
                .attr('id', 'mask-destination')
            maskDestination.append('rect')
                    .attr('x', -50)
                    .attr('y', -50)
                    .attr('width', 100)
                    .attr('height', 200)
                    .attr('fill', 'white')
            maskDestination.append('circle')
                    .attr('cx', 7)
                    .attr('cy', 5.5)
                    .attr('r', 3.6)
                    .attr('fill', 'black');
        }
    }

    addCenterPoint() {
        const svg = this.svg;
        if (svg) {
            const {colors, centerIcon, iconSize} = this.props;

            const g = svg.append('g')
                .attr('class', 'center-icon')
                .attr('transform', `translate(${iconSize / 2},${iconSize / 2})`);

            g.append('circle')
                .attr('cx', 0)
                .attr('cy', 0)
                .attr('r', iconSize / 2)
                .attr('fill', '#EBEEEF');

            g.append('g')
                    // .attr('opacity', 0.5)
                    .attr('transform', `translate(-5.625, -6.375) scale(0.75)`)
                .append(() => (
                    centerIcon({fill: colors.centerIcon})
                ));
        }
    }

    initLine() {
        const svg = this.svg;
        if (svg) {
            const {value, color, width, height, iconSize, valueWidth} = this.props;
            const g = svg.append('g')
                .attr('class', 'contributor-line')
                .attr('transform', `translate(0,${iconSize / 2})`);

            const r = height / 2;
            const x1 = iconSize / 2;
            const x2 = width - x1 + r / 2 - valueWidth;
            const y = 0;
            const valueX = x1 + (x2 - x1) * value / 100;
            g.append('line')
                .attr('x1', x1)
                .attr('y1', y)
                .attr('x2', x2)
                .attr('y2', y)
                .attr('stroke', 'rgba(210, 210, 210, 0.4)')
                .attr('stroke-width', height)
                .attr('stroke-linecap', 'round');
            g.append('line')
                .attr('x1', x1)
                .attr('y1', y)
                .attr('x2', valueX)
                .attr('y2', y)
                .attr('stroke', color)
                .attr('stroke-width', height)
                .attr('stroke-linecap', 'round');
        }
    }

    addValue() {
        const svg = this.svg;
        if (svg) {
            const {value, width, colors, iconSize} = this.props;

            const fs = 16;
            svg.append('text')
                .attr('x', width)
                .attr('y', iconSize / 2 + 6)
                .text(value)
                .attr('font-size', `${fs}px`)
                .attr('text-anchor', 'end')
                .attr('fill', colors.value);
        }
    }

    addLabel() {
        const svg = this.svg;
        if (svg) {
            const {label, colors, height, labelHeight} = this.props;

            const fs = 12;
            const x = 0;
            svg.append('text')
                .attr('x', x)
                .attr('y', 43)
                .text(label)
                .attr('font-size', `${fs}px`)
                .attr('fill', colors.label);
        }
    }

    render(): React.ReactNode {
        return (
            <svg className="risk-score-line" ref={(ref) => { this.chart = ref; this.initChart(); }} />
        );
    }
}

RiskScoreLine.defaultProps = {
    width: 280,
    height: 20,
    valueWidth: 40,
    labelHeight: 30,
    iconSize: 32,
    colors: {
        centerIcon: '#233544',
        value: 'rgba(0, 0, 0, 0.7)',
        label: '#7D8C97',
    },
};

export default RiskScoreLine;
