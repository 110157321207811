import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import Icon from 'react-svg-icon';

import {
	Modifiers,
	ScaleWithLabels,
	ScaleColor,
	Panel,
	IconButton,
	Autocomplete,
	OutlinedButton,
	DarkButton,
} from '../../../components';
import { api } from '../../../sagas';
import { adaptScoring } from '../../../store/selectors/boundary';
import { adaptSearchPlacesResponse } from '../../../store/GeocodingRedux';
import HomeActions from '../../../store/HomeRedux';
import PersonsActions from '../../../store/PersonRedux';
import ReportActions from '../../../store/ReportRedux';
import {
	mapClickSelector,
	personAdaptListItems,
	boundaryListDataSelector,
	personGetById,
	pendingGetPersons,
} from '../../../store/selectors';
import { MODIFIER_CODE } from '../../../api';

import EditProperties from '../../Settings/components/EditProperties';

import DropDown from './DropDown';

import styles from './PersonPanel.jss';

class PersonPanel extends PureComponent {
	static propTypes = {
		watchMapEvents: PropTypes.bool.isRequired,
		changablePerson: PropTypes.bool,
		changableLocation: PropTypes.bool,
		closable: PropTypes.bool,
		name: PropTypes.string.isRequired,
		user: PropTypes.object.isRequired, // user properties INITIAL_USER
		showCompareWith: PropTypes.bool,
		showFullPanel: PropTypes.bool,
		panelClassName: PropTypes.string,

		mapClick: PropTypes.object,
		persons: PropTypes.array,
		boundaries: PropTypes.array,

		onChangePerson: PropTypes.func,
		onChangeLocation: PropTypes.func,
		onClickCompareWith: PropTypes.func,
		onClickClose: PropTypes.func,
		onClickGenerateReport: PropTypes.func,
		setPersonPanel: PropTypes.func,
		getPersonById: PropTypes.func,
		onCancel: PropTypes.func,
		getPersons: PropTypes.func.isRequired,
		setLastReportData: PropTypes.func.isRequired,
		forwardRef: PropTypes.func,
	};

	static defaultProps = {
		panelClassName: '',
		watchMapEvents: true,
		closable: true,
		showCompareWith: false,
		changablePerson: true,
		changableLocation: true,
		onClickGenerateReport: () => {},
	};

	constructor(props) {
		super(props);

		this.initialState = {
			boundaryId: null,
			levelNames: [],
			compact: false,
			selectedModifiers: null,
			loading: false,
			person: '',
			scoring: null,
			travelingScoring: null,
			travelingBoundaryId: null,
			travelingLevelNames: [],
			modifiers: null,
			place: null,
			placeData: null,
			editProperties: false,
		};

		this.state = this.initialState;

		this.scoringInitialyzed = false;

		if (props.forwardRef) props.forwardRef(this);
	}

	componentDidMount() {
		this.getModifiers();
	}

	componentDidUpdate(prevProps, prevState) {
		const {
			watchMapEvents,
			mapClick: { featureId, featureName },
		} = this.props;
		if (
			watchMapEvents &&
			featureId &&
			featureId !== prevProps.mapClick.featureId
		) {
			this.setState({
				travelingBoundaryId: featureId,
				place: {
					label: featureName,
					value: featureName,
				},
			});
			const { person } = this.state;
			this.getScoring(person, featureId, this.getSelectedModifiers())
				.then(({ scoring: travelingScoring }) => {
					this.setState({ travelingScoring });
				})
				.catch(() => {
					this.setState({ travelingScoring: null });
				});
		}
	}

	reset() {
		this.setState(this.initialState, () => {
			this.getModifiers();
		});
	}

	handleOnClose = () => {
		const { onClickClose, name } = this.props;
		if (onClickClose) onClickClose({ name });
	};

	handleOnChangeModifier = (props) => {
		const { selectedModifiers } = this.state;
		const m = { ...selectedModifiers };
		m[props.name] = props.list;
		this.setState({
			selectedModifiers: m,
		});
	};

	handleOnChangePlace = (place) => {
		const { onChangeLocation } = this.props;
		const { travelingScoring } = this.state;
		if (place && place.value) {
			this.getBoundary(place.value).then((data) => {
				if (data && data.features && data.features[0] && data.features[0].id) {
					this.setState(
						{
							travelingBoundaryId: data.features[0].id,
							travelingLevelNames: data.features[0].levelNames,
						},
						() => {
							if (travelingScoring) this.handleOnClickCompare();
						}
					);
				}
				if (onChangeLocation)
					onChangeLocation({
						place: place.value,
						data,
					});
				this.setState({ placeData: data });
			});
			this.setState({ place });
		}
	};

	handleLoadPlaces = (query) => {
		return new Promise((resolve) => {
			api.searchPlaces(query).then((response) => {
				const items = adaptSearchPlacesResponse(response.data);
				const list = items.map((el) => ({
					label: el.name,
					value: el,
				}));
				resolve([{ label: 'Mapbox', options: list }]);
			});
		});
	};

	handleClickCompareWith = () => {
		const { onClickCompareWith, name } = this.props;
		if (onClickCompareWith) onClickCompareWith({ name });
	};

	handleOnChangePerson = (event) => {
		const { target: person } = event;
		if (person.value && person.value === 'footer') {
			this.setState({ editProperties: true });
		} else if (person.value) {
			const { onChangePerson, getPersonById } = this.props;
			this.setState({ person: person.value }, () => {
				this.getBoundary(getPersonById(person.value)).then((data) => {
					if (
						data &&
						data.features &&
						data.features[0] &&
						data.features[0].id
					) {
						const boundaryId = data.features[0].id;
						this.setState(
							{ boundaryId, levelNames: data.features[0].levelNames },
							() => {
								const selected = this.getSelectedModifiers();
								this.getScoring(person.value, boundaryId, selected)
									.then(({ scoring }) => {
										this.setState({ scoring });
									})
									.catch((e) => {
										console.log('=== getScoring', e);
										this.setState({ scoring: null });
									});
							}
						);
					}
				});
			});
			if (onChangePerson) onChangePerson(person.value);
		}
	};

	handleOnClickCancel = () => {
		const { onCancel } = this.props;
		if (onCancel) onCancel();
		this.setState({
			place: null,
			placeData: null,
			travelingBoundaryId: null,
			travelingScoring: null,
		});
	};

	handleToggleEditProperties = () => {
		const { editProperties } = this.state;
		this.setState({ editProperties: !editProperties });
	};

	handleOnSave = (person) => {
		this.props.getPersons();
		this.setState({ editProperties: false });
	};

	handleOnClickCompare = () => {
		const { person, travelingBoundaryId } = this.state;
		const { setTravelingScoring } = this.props;
		const selected = this.getSelectedModifiers();
		this.getScoring(person, travelingBoundaryId, selected)
			.then(({ scoring: travelingScoring }) => {
				this.setState({ travelingScoring });
				setTravelingScoring(travelingScoring);
			})
			.catch(() => {
				this.setState({ travelingScoring: null });
			});
	};

	handleOnClickChangeModifiers = () => {
		this.setState({ travelingScoring: null });
	};

	handleOnClickGenerateReport = () => {
		const { onClickGenerateReport, setLastReportData, getPersonById } =
			this.props;
		const {
			person,
			modifiers,
			place,
			boundaryId,
			travelingBoundaryId,
			levelNames,
			travelingLevelNames,
		} = this.state;
		const foundPerson = getPersonById(person);

		if (foundPerson) {
			const data = {
				person: {
					id: foundPerson.id,
					name: foundPerson.name,
				},
				modifiers,
				residence: {
					locationName: foundPerson.locationName,
					boundaryId: boundaryId,
					levelNames,
				},
				destination: {
					locationId: place.value.id,
					locationName: place.label,
					boundaryId: travelingBoundaryId,
					levelNames: travelingLevelNames,
				},
			};
			onClickGenerateReport(data);
			setLastReportData(data);
		}
	};

	getPersonById(id) {
		const { persons } = this.props;
		return persons.find((el) => el.value === id);
	}

	get name() {
		const { user } = this.props;
		return user.name;
	}

	get LocationName() {
		const { user } = this.props;
		return user.locationName;
	}

	getBoundary({ longitude, latitude, locationMapboxId }) {
		this.setState({ loading: true });
		return new Promise((resolve, reject) => {
			api
				.getBoundary({
					lng: longitude,
					lat: latitude,
					mapboxId: locationMapboxId,
				})
				.then((response) => {
					resolve(response.data);
					this.setState({
						boundaries: response.data,
						loading: false,
					});
				})
				.catch((e) => {
					// TODO show error
					reject(e);
					this.setState({
						boundaries: null,
						loading: false,
					});
				});
		});
	}

	getSelectedModifiers(filterGroup) {
		return [];
		// const {selectedModifiers} = this.state;
		// const list = new Set();
		// Object.keys(selectedModifiers).forEach((group) => {
		//     selectedModifiers[group].forEach((m) => {
		//         if (filterGroup) {
		//             if (filterGroup === group) list.add(m);
		//         } else list.add(m);
		//     });
		// });

		// return Array.from(list);
	}

	getModifiers(codes = MODIFIER_CODE.Traveling) {
		this.setState({ loading: true });
		api
			.getModifiers(codes)
			.then((response) => {
				this.setState({
					modifiers: response.data[0].modifiers,
					loading: false,
				});
			})
			.catch(() => {
				this.setState({
					modifiers: null,
					loading: false,
				});
			});
	}

	getScoring(id, boundaryId, modifiers = []) {
		return new Promise((resolve, reject) => {
			if (id && boundaryId) {
				this.setState({ loading: true });
				api
					.getBoundaryScore(boundaryId, {
						userId: parseInt(id, 10),
						modifiers: modifiers,
					})
					.then((response) => {
						resolve({ scoring: adaptScoring(response.data) });
						this.setState({ loading: false });
					})
					.catch((e) => {
						reject(e);
						this.setState({ loading: false });
					});
			} else
				reject(
					new Error(
						'Parameter error: ' + JSON.stringify({ id, boundaryId, modifiers })
					)
				);
		});
	}

	calculateScaleEnd(groups) {
		let end = 30;
		if (groups) {
			const maxScores = Object.keys(groups).map((group) =>
				typeof groups[group] === 'object' ? groups[group].maxScore : 0
			);
			end = Math.max(...maxScores) * 1.2;
			end = Math.round(end);
		}
		return end;
	}

	renderScale(scoring) {
		const { classes } = this.props;

		const scoringEmpty = {
			criminal: {
				minScore: 0,
				maxScore: 0,
			},
			accident: {
				minScore: 0,
				maxScore: 0,
			},
			health: {
				minScore: 0,
				maxScore: 0,
			},
		};

		const { criminal, accident, health } = scoring || scoringEmpty;
		const end = this.calculateScaleEnd(scoring);
		return [
			<div key='1' className={classes.scaleContainer}>
				<ScaleWithLabels
					start={0}
					end={end}
					min={criminal.minScore}
					max={criminal.maxScore}
					color={ScaleColor.red}
					title='Crime Risk'
				/>
			</div>,
			<div key='2' className={classes.scaleContainer}>
				<ScaleWithLabels
					start={0}
					end={end}
					min={accident.minScore}
					max={accident.maxScore}
					color={ScaleColor.blue}
					title='Accidents Risk'
				/>
			</div>,
			<div key='3' className={classes.scaleContainer}>
				<ScaleWithLabels
					start={0}
					end={end}
					min={health.minScore}
					max={health.maxScore}
					color={ScaleColor.yellow}
					title='Health Risk'
				/>
			</div>,
		];
	}

	renderScaleCompact(scoring) {
		const { classes } = this.props;

		const scoringEmpty = {
			criminal: {
				minScore: 0,
				maxScore: 0,
			},
			accident: {
				minScore: 0,
				maxScore: 0,
			},
			health: {
				minScore: 0,
				maxScore: 0,
			},
		};

		const { criminal, accident, health } = scoring || scoringEmpty;
		const end = this.calculateScaleEnd(scoring);
		return (
			<div className={classes.scaleCompactContainer}>
				<div className={classes.scaleCompact}>
					<ScaleWithLabels
						start={0}
						end={end}
						min={criminal.minScore}
						value={criminal.score}
						max={criminal.maxScore}
						color={ScaleColor.red}
						title='Crime'
						compact
					/>
				</div>
				<div className={classes.scaleCompact}>
					<ScaleWithLabels
						start={0}
						end={end}
						min={accident.minScore}
						value={accident.score}
						max={accident.maxScore}
						color={ScaleColor.blue}
						title='Accidents'
						compact
					/>
				</div>
				<div className={classes.scaleCompact}>
					<ScaleWithLabels
						start={0}
						end={end}
						min={health.minScore}
						value={health.score}
						max={health.maxScore}
						color={ScaleColor.yellow}
						title='Health'
						compact
					/>
				</div>
			</div>
		);
	}

	renderModifiers() {
		const { classes } = this.props;
		const { modifiers, compact } = this.state;
		if (compact || !modifiers) return null;
		const items = modifiers.map((el) => {
			return {
				label: el.description,
				value: el.code,
			};
		});
		return [
			<div key='modifiers' className={classes.modifiersTitleH2}>
				<Typography className={classes.titleH2} variant='h2'>
					Select Applicable Modifiers (Coming Soon)
				</Typography>
				<Modifiers
					name={MODIFIER_CODE.Traveling}
					items={items}
					multivalues={false}
					className={`${classes.modifiers} scrollbar1`}
					preselect={this.getSelectedModifiers()}
					onChange={this.handleOnChangeModifier}
				/>
			</div>,
			<div key='button' className={classes.containerOutlinedButton}>
				<OutlinedButton primary onClick={this.handleOnClickCompare}>
					Compare
				</OutlinedButton>
				<div className='btn-cancel btn-cancel-mobile'>
					<OutlinedButton onClick={this.handleOnClickCancel}>
						Cancel
					</OutlinedButton>
				</div>
			</div>,
		];
	}

	renderTravelingScoring() {
		const { classes } = this.props;
		const { compact, travelingScoring } = this.state;
		return (
			<div className={classes.containerOutlinedButton}>
				<div className={`${classes.scrollable} scrollbar1`}>
					{compact
						? this.renderScaleCompact(travelingScoring)
						: this.renderScale(travelingScoring)}
				</div>
				<OutlinedButton primary onClick={this.handleOnClickGenerateReport}>
					<Icon name='svg-i-report' className={classes.reportIcon} />
					Generate Report
				</OutlinedButton>
				<div className='btn-cancel btn-cancel-mobile'>
					<OutlinedButton onClick={this.handleOnClickCancel}>
						Cancel
					</OutlinedButton>
				</div>
			</div>
		);
	}

	renderPerson() {
		const { classes, persons, changablePerson, getPersonById } = this.props;
		if (changablePerson) {
			const { person } = this.state;
			const adaptedPersons = persons.map((el) => {
				const p = getPersonById(el.value);
				return {
					title: el.label,
					value: el.value,
					start: (
						<Icon name='svg-i-persona-light' className={classes.personIcon} />
					),
					subtitle: p.locationName,
					subtitlePrefix: 'from',
					longitude: p.longitude,
					latitude: p.latitude,
				};
			});
			const placeholder = (
				<div className={classes.personSelect}>
					<Icon name='svg-i-persona-light' />
					Select a profile
				</div>
			);
			const noPersons = adaptedPersons.length ? null : (
				<div key='no-persons'>
					<p>
						<center>No persons</center>
					</p>
				</div>
			);
			return (
				<div className={classes.autocomplete}>
					<DropDown
						value={person}
						items={adaptedPersons}
						onChange={this.handleOnChangePerson}
						placeholder={placeholder}
						footer={[
							noPersons,
							<div key='footer' value='footer'>
								<DarkButton>Add</DarkButton>
							</div>,
						]}
					/>
				</div>
			);
		} else {
			return (
				<Typography variant='h6' color='textSecondary'>
					{this.name}
				</Typography>
			);
		}
	}

	renderLocation() {
		const { classes, changableLocation } = this.props;
		const { place } = this.state;
		if (changableLocation) {
			return (
				<div className={classes.autocomplete}>
					<Autocomplete
						async={true}
						defaultValue={place}
						value={place}
						placeholder='Choose City/Town'
						loadOptions={this.handleLoadPlaces}
						cacheOptions={true}
						onChange={this.handleOnChangePlace}
						large
					/>
				</div>
			);
		} else {
			return (
				<Typography variant='h6' color='textSecondary'>
					{this.LocationName}
				</Typography>
			);
		}
	}

	renderPersonLeftPanel() {
		const { classes, closable, showCompareWith, showFullPanel } = this.props;
		const { scoring, compact } = this.state;

		return (
			<div className={classes.container1}>
				<div className={classes.personRowShadows}>
					<div className={classes.personShadow1} />
					<div className={classes.personShadow2} />
					<div className={classes.person}>{this.renderPerson()}</div>
				</div>
				<div className={`${classes.scrollable} scrollbar1`}>
					{compact
						? this.renderScaleCompact(scoring)
						: this.renderScale(scoring)}
				</div>
				{showFullPanel ? (
					<div className={classes.containerOutlinedButton}>
						<div className='btn-cancel btn-cancel-desktop'>
							<OutlinedButton onClick={this.handleOnClickCancel}>
								Cancel
							</OutlinedButton>
						</div>
					</div>
				) : null}
				<ul className={classes.actionsLeft}>
					{showCompareWith && (
						<IconButton
							aria-label='Compare with'
							onClick={this.handleClickCompareWith}
						>
							<li className={classes.withPerson}>
								compare with
								<Icon name='svg-i-persona' className={classes.withPersonIcon} />
							</li>
						</IconButton>
					)}
				</ul>
				<ul className={classes.actions}>
					{compact ? (
						<IconButton
							aria-label='Expand'
							onClick={() => {
								this.setState({ compact: false });
							}}
						>
							<li className={classes.expand}>
								<Icon name='svg-i-expand' className={classes.expand} />
							</li>
						</IconButton>
					) : (
						<IconButton
							aria-label='Collapse'
							onClick={() => {
								this.setState({ compact: true });
							}}
						>
							<li className={classes.collapse}>
								<Icon name='svg-i-collapse' className={classes.collapse} />
							</li>
						</IconButton>
					)}
					{closable && (
						<IconButton aria-label='Close' onClick={this.handleOnClose}>
							<li className={classes.close}>
								<Icon name='svg-i-close-circle' className={classes.close} />
							</li>
						</IconButton>
					)}
				</ul>
			</div>
		);
	}

	renderPersonEditor() {
		return (
			<EditProperties
				user={{}}
				onClose={this.handleToggleEditProperties}
				onSave={this.handleOnSave}
				addEndpoint={(id, data) => api.addPerson(data)}
				updateEndpoint={(id, data) => api.updatePerson(id, data)}
			/>
		);
	}

	render() {
		const { classes, showFullPanel, panelClassName } = this.props;
		const { loading, place, editProperties, travelingScoring } = this.state;

		const className = `${panelClassName} ${
			showFullPanel ? classes.panelFull : classes.panel
		}`;
		return (
			<Panel className={className} loading={loading}>
				<div className={classes.container}>
					{editProperties
						? this.renderPersonEditor()
						: this.renderPersonLeftPanel()}
					{showFullPanel ? (
						<div className={classes.container2}>
							<div className={classes.container2Header}>
								<Typography className={classes.titleH2} variant='h2'>
									Traveling to...{' '}
								</Typography>
								{travelingScoring ? (
									<DarkButton
										fullWidth={false}
										small={true}
										secondary
										onClick={this.handleOnClickChangeModifiers}
									>
										Change modifiers
									</DarkButton>
								) : null}
							</div>
							<div
								className={`${classes.location} ${
									place ? classes.locationEntered : classes.locationEmpty
								}`}
							>
								<Icon name='svg-i-pin-grey' className={classes.pin} />
								{this.renderLocation()}
							</div>
							{travelingScoring
								? this.renderTravelingScoring()
								: this.renderModifiers()}
						</div>
					) : null}
				</div>
			</Panel>
		);
	}
}

const mapStateToProps = (state) => ({
	mapClick: mapClickSelector(state),
	pending: pendingGetPersons(state),
	persons: personAdaptListItems(state),
	boundaries: boundaryListDataSelector(state),
	getPersonById: (id) => personGetById(state, { id }),
});

const mapDispatchToProps = (dispatch) => ({
	getPersons: () => dispatch(PersonsActions.getPersonsRequest()),
	setPersonPanel: (data) => dispatch(HomeActions.setPersonPanel(data)),
	setLastReportData: (data) =>
		dispatch(ReportActions.reportSetLastReportData(data)),
	setTravelingScoring: (data) =>
		dispatch(ReportActions.reportSetTravelingScoring(data)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(withStyles(styles)(PersonPanel));
