
const styles = (theme) => ({
    container: {
        position: 'relative',
        height: 100,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    containerCompact: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    value: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    valueCompact: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        marginBottom: 5,
    },
    title: {
        fontSize: '0.8571428571428571rem'
    },
    titleCompact: {
        fontSize: '0.8571428571428571rem',
        margin: 0,
        padding: 0,
    },
    minMax: {
        width: '100%',
        height: 14,
    },
    min: {
        fontSize: '0.8571428rem',
        position: 'absolute',
        transform: 'translateX(-100%)',
    },
    max: {
        fontSize: '0.8571428rem',
        position: 'absolute',
    },
});

export default styles;
