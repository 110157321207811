
const styles = (theme) => ({
    root: {
    },
    input: {
        color: '#fff',
    },
    inputDark: {
        color: theme.palette.primary.dark,
    },
    underline: {
        borderBottom: '2px solid #7D8C97',
        '&:after': {
            display: 'none',
        },
        '&:before': {
            display: 'none',
        },
    },
    underlineError: {
        borderBottom: `2px solid red`,
        '&:after': {
            display: 'none',
        },
        '&:before': {
            display: 'none',
        },
    },
    focused: {
    },
    iconClose: {
        stroke: theme.palette.primary.light,
        width: 30,
        height: 30,
    },
    clearButton: {
        padding: 3,
    },
});

export default styles;
