import {put, call} from 'redux-saga/effects';
import BoundaryActions, {BOUNDARY_PENDING} from '../store/BoundaryRedux';
import NetworkActions from '../store/NetworkRedux';

export function* boundariesList(api, action) {
    const pending = BOUNDARY_PENDING.boundariesList;
    const {bounds} = action;

    yield put(NetworkActions.networkAddPending(pending));
    const response = yield call(() => api.getBoundaries(bounds));

    if (response.ok) {
        yield put(BoundaryActions.boundaryListSuccess(response.data, response.headers, response.status));
        yield put(NetworkActions.networkDelPending(pending));
    } else {
        yield put(BoundaryActions.boundaryListFailure(response.headers, response.status));
        yield put(NetworkActions.networkDelPending(pending));
    }

    return response;
}
