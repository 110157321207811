
const styles = (theme) => ({
    container: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        paddingTop: 45,
        paddingLeft: 10,
    },
    panel: {
        position: 'relative',
        zIndex: 100,
        minWidth: 298,
        minHeight: 204,
        marginTop: -10,
        marginLeft: 10,
        marginRight: 10,
    },
    travelingTo: {
        position: 'relative',
        zIndex: 100,
        minWidth: 180,
        height: 56,
        backgroundColor: 'rgba(51,75,93,0.65)',
        borderRadius: 28,
        fontSize: '1.142857142857143rem',
        listStyle: 'none',
        lineHeight: '56px',
        marginTop: -10,
        marginLeft: 10,
    },
    panelHidden: {
        visibility: 'hidden',
    },
    report: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 110,
        backgroundColor: '#fff',
    },
    overlay: {
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        width: '100%',
        backgroundColor: 'rgba(0,0,0,0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 500,
    },
    addCard: {
        position: 'absolute',
        left: 97,
        top: 45,
    },
    editAccount: {
        position: 'absolute',
        left: 97,
        top: 45,
    },

    '@media screen and (max-width: 767px)': {
        container: {
            flexDirection: 'column',
            alignItems: 'center',
            paddingLeft: 0,
            paddingTop: 140,
        },

        panelHidden: {
            display: 'none',
        },
    },
});

export default styles;
