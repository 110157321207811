import {createSelector} from 'reselect';

export const dictionarySelector = state => (state.dictionary);

export const countriesSelector = state => (dictionarySelector(state).countries);
export const countriesListSelector = state => (countriesSelector(state).items);

export const racesSelector = state => (dictionarySelector(state).races);
export const racesListSelector = state => (racesSelector(state).items);

export const gendersSelector = state => (dictionarySelector(state).genders);
export const gendersListSelector = state => (gendersSelector(state).items);

const getGenderLabel = (name) => {
    switch(name) {
        case 'M':
            return 'Male';

        case 'F':
            return 'Female';

        default:
            return 'N/A';
    }
};
export const dictionaryAdaptGendersList = createSelector([gendersListSelector], (items) => {
    return items.filter((el) => el.id !== 'NA').map((el) => ({
        label: getGenderLabel(el.id),
        value: el.id,
    }));
});

export const dictionaryAdaptRacesList = createSelector([racesListSelector], (items) => {
    return items.map((el) => ({
        label: el.name,
        value: el.id,
    }));
});

export const dictionaryAdaptCountriesList = createSelector([countriesListSelector], (items) => {
    return items.map((el) => ({
        label: el.name,
        value: el.id,
    }));
});

export const modifiersSelector = state => (dictionarySelector(state).modifiers);
export const modifiersListSelector = state => (modifiersSelector(state).items);
