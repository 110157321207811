
const styles = (theme) => ({
    ul: {
        marginLeft: 40,
        '& li': {
            color: '#7D8C97'
        }
    },
});

export default styles;
