
const styles = (theme) => ({
    panel: {
        width: 412,
        minHeight: 366,
    },
    container: {
        padding: '56px 57px 50px',
    },
    row: {
        marginTop: 32,
    },
    back: {
        marginTop: 30,
        textAlign: 'center',
        fontSize: 12,
    },
});

export default styles;
