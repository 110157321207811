import {put, call} from 'redux-saga/effects';
import DictionaryActions, {DICTIONARY_PENDING} from '../store/DictionaryRedux';
import NetworkActions from '../store/NetworkRedux';

export function* getCountries(api, action) {
    const pending = DICTIONARY_PENDING.getCountries;

    yield put(NetworkActions.networkAddPending(pending));
    const response = yield call(() => api.getCountries());

    if (response.ok) {
        yield put(DictionaryActions.getCountriesSuccess(response.data, response.headers));
        yield put(NetworkActions.networkDelPending(pending));
    } else {
        yield put(DictionaryActions.getCountriesFailure(response));
        yield put(NetworkActions.networkDelPending(pending));
    }

    return response;
}

export function* getRaces(api, action) {
    const pending = DICTIONARY_PENDING.getRaces;

    yield put(NetworkActions.networkAddPending(pending));
    const response = yield call(() => api.getRaces());

    if (response.ok) {
        yield put(DictionaryActions.getRacesSuccess(response.data, response.headers));
        yield put(NetworkActions.networkDelPending(pending));
    } else {
        yield put(DictionaryActions.getRacesFailure(response));
        yield put(NetworkActions.networkDelPending(pending));
    }

    return response;
}

export function* getGenders(api, action) {
    const pending = DICTIONARY_PENDING.getGenders;

    yield put(NetworkActions.networkAddPending(pending));
    const response = yield call(() => api.getGenders());

    if (response.ok) {
        yield put(DictionaryActions.getGendersSuccess(response.data, response.headers));
        yield put(NetworkActions.networkDelPending(pending));
    } else {
        yield put(DictionaryActions.getGendersFailure(response));
        yield put(NetworkActions.networkDelPending(pending));
    }

    return response;
}

export function* getModifiers(api, action) {
    const {codes} = action;
    const pending = DICTIONARY_PENDING.getModifiers;

    yield put(NetworkActions.networkAddPending(pending));
    const response = yield call(() => api.getModifiers(codes));

    if (response.ok) {
        yield put(DictionaryActions.getModifiersSuccess(response.data, response.headers));
        yield put(NetworkActions.networkDelPending(pending));
    } else {
        yield put(DictionaryActions.getModifiersFailure(response));
        yield put(NetworkActions.networkDelPending(pending));
    }

    return response;
}
