import {put, call} from 'redux-saga/effects';

import UserActions, {USER_PENDING} from '../store/UserRedux';
import {localStore} from '../lib';
import NetworkActions from '../store/NetworkRedux';
import GeocodingActions from '../store/GeocodingRedux';

export function* userLogin(api, action) {
    const {email, password, action: apiAction = 'init'} = action;
    const pending = USER_PENDING.userLogin;

    yield put(NetworkActions.networkAddPending(pending));
    const response = yield call(() => api.login(email, password, apiAction));

    if (response.ok) {
        const auth = response.headers['authorization'];
        yield put(UserActions.userLoginSuccess(response.data, response.headers, response.status));
        yield localStore.setToken(auth);
        yield api.apiInstance.setHeader('Authorization', auth);
        yield put(UserActions.userDetailsRequest());
        yield put(NetworkActions.networkDelPending(pending));
    } else {
        yield put(UserActions.userLoginFailure(response.headers, response.status));
        yield put(NetworkActions.networkDelPending(pending));
    }

    return response;
}

export function* userForgotPassword(api, action) {
    const {email} = action;
    const pending = USER_PENDING.userForgotPassword;

    yield put(NetworkActions.networkAddPending(pending));
    const response = yield call(() => api.forgotPassword(email));

    if (response.ok) {
        yield put(UserActions.userForgotPasswordSuccess(response.data, response.headers, response.status));
    } else {
        yield put(UserActions.userForgotPasswordFailure(response.data, response.headers, response.status));
    }

    return response;
}

export function* userLogout(action) {
    console.log('=== userLogout');
    yield localStore.setToken('');
    yield localStore.setRefreshToken('');
    yield put(GeocodingActions.geocodingLogout());
}

export function* userDetails(api, action) {
    const pending = USER_PENDING.userDetails;

    yield put(NetworkActions.networkAddPending(pending));
    const response = yield call(() => api.getPerson());

    if (response.ok) {
        yield put(UserActions.userDetailsSuccess(response.data, response.status));
        yield put(NetworkActions.networkDelPending(pending));
    } else {
        yield put(UserActions.userDetailsFailure(response.headers, response.status));
        yield put(NetworkActions.networkDelPending(pending));
    }
}
