import React, {PureComponent} from 'react';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Link, Redirect} from 'react-router-dom';
import Joi from 'joi-browser';
import Typography from '@material-ui/core/Typography';

import {
    Background, Panel, Logotype,
    TextInput, LightButton, Checkbox,
} from '../../../components';
import {getErrorObject} from '../../../lib/form';
import {ROUTE} from '../../../App';
import UserActions from '../../../store/UserRedux';
import {STATUS} from '../../../api';

import styles from './Login.jss';

class Login extends PureComponent {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        user: PropTypes.object.isRequired,
        userLogin: PropTypes.func.isRequired,
        userResetErrorMessage: PropTypes.func.isRequired,
        userLoginAgreement: PropTypes.func.isRequired,
    };

    state = {
        form: {
            email: null,
            emailTouched: false,
            password: null,
            passwordTouched: false,
            agreements: false,
            agreementsTouched: false,
        },
        formErrors: [],
        showAgreements: false,
    };

    handleLogin = () => {
        this.setTouched().then(() => {
            if (this.validate() === null) {
                const {form, showAgreements} = this.state;
                const {userLogin, userLoginAgreement, userResetErrorMessage} = this.props;
                userResetErrorMessage();
                if (showAgreements) userLoginAgreement(form.email, form.password);
                else userLogin(form.email, form.password);
            }
        });
    };

    handleChangeEmail = (event) => {
        const {form} = this.state;
        this.setState({
            form: {
                ...form,
                email: event.target.value,
                emailTouched: true,
            }
        }, () => {
            this.validate();
        });
    };

    handleChangePassword = (event) => {
        const {form} = this.state;
        this.setState({
            form: {
                ...form,
                password: event.target.value,
                passwordTouched: true,
            }
        }, () => {
            this.validate();
        });
    };

    handleChangeAgreement = (event) => {
        const {form} = this.state;
        this.setState({
            form: {
                ...form,
                agreements: event.target.checked,
                agreementsTouched: true,
            }
        }, () => {
            this.validate();
        });
    };

    validate() {
        const {form, showAgreements} = this.state;

        const keys = {};
        if (form.emailTouched) keys.email = Joi.string().email().required();
        if (form.passwordTouched) keys.password = Joi.string().required();
        if (showAgreements && form.agreementsTouched) keys.agreements = Joi.any().valid(true).required();
        const schema = Joi.object(keys);

        const result = Joi.validate(form, schema, {abortEarly: false, allowUnknown: true}).error;

        let formErrors = [];
        if (result) {
            formErrors = result.details.map((item: Object): {} => ({
                path: item.path,
                type: item.type,
            }));
        }
        this.setState({formErrors});

        return result;
    }

    setTouched() {
        return new Promise((resolve, reject) => {
            const {form} = this.state;
            this.setState({
                form: {
                    ...form,
                    emailTouched: true,
                    passwordTouched: true,
                    agreementsTouched: true,
                }
            }, () => { resolve(); });
        });
    }

    componentDidUpdate() {
        const {user: {status}} = this.props;
        if (status === STATUS.AGREEMENT_ERROR) {
            this.setState({
                showAgreements: true,
            });
        }
    }

    render() {
        const {classes, user} = this.props;
        const {formErrors, form, showAgreements} = this.state;
        if (user.authed) {
            return (
                <Redirect to={ROUTE.default} />
            );
        }
        return (
            <Background>
                <Panel>
                    <div className={classes.container}>
                        <div><Logotype /></div>
                        <div className={classes.row}>
                            <TextInput
                                placeholder="Email"
                                type="email"
                                onChange={this.handleChangeEmail}
                                {...getErrorObject(formErrors, 'email', {
                                    "default": "Please enter Email",
                                    "string.email": "Please enter valid Email",
                                })}
                            />
                        </div>
                        <div className={classes.row}>
                            <TextInput
                                placeholder="Password"
                                type="password"
                                onChange={this.handleChangePassword}
                                {...getErrorObject(formErrors, 'password', {
                                    "default": "Please enter Password",
                                })}
                            />
                        </div>
                        <div className={classes.forgotPassword}>
                            <Link to={ROUTE.forgotPassword}>forgot password?</Link>
                        </div>
                        {showAgreements && (
                            <div>
                                <Checkbox
                                    label="I agree to Safe-esteem’s Terms & Conditions"
                                    value="1"
                                    onChange={this.handleChangeAgreement}
                                    checked={form.agreements}
                                    {...getErrorObject(formErrors, 'agreements', {
                                        "default": "Please confirm",
                                    })}
                                />
                            </div>
                        )}
                        {user.error && user.status === STATUS.UNAUTHORIZED && (
                            <Typography variant="body1" color="error">Wrong name or password.</Typography>
                        )}
                        {user.error && user.status === STATUS.AGREEMENT_ERROR && (
                            <Typography variant="body1" color="error">Please confirm you read and agreed to the Terms & Conditions</Typography>
                        )}
                        <div className={classes.row}>
                            <LightButton onClick={this.handleLogin} type="submit">LOG IN</LightButton>
                        </div>
                        <div className={classes.terms}>
                            <Link to={ROUTE.terms}>Terms and Conditions</Link>
                        </div>
                        <div className={classes.copyr}>
                            <Typography variant="caption" color="textPrimary">© {(new Date()).getFullYear()} Safe-esteem, Inc. All Rights Reserved</Typography>
                        </div>
                    </div>
                </Panel>
            </Background>
        );
    }
}

const mapStateToProps = (state) => ({
    user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
    userLogin: (email, password) => dispatch(UserActions.userLoginRequest(email, password)),
    userResetErrorMessage: () => dispatch(UserActions.userResetErrorMessage()),
    userLoginAgreement: (email, password) => dispatch(UserActions.userLoginRequest(email, password, 'agreement')),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Login));
