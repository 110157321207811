import React from 'react';

import homeIcon from '../../../images/location_home_icon_dark.svg';
import locationDestinationIcon from '../../../images/location_destination_icon_dark.svg';
import nodataIcon from '../../../images/nodata_icon_v1.svg';

import './SimilarCitiesNoData.scss';

const SimilarCitiesNoData = (props) => {
	const { location, locationTitle } = props;

	return (
		<div className='similar-cities-nodata'>
			<div className="content-wrap">
				<div className='left-block'>
					<img
						src={locationTitle === 'Home' ? homeIcon : locationDestinationIcon}
						alt=''
					/>
					<div className="text-wrap">
						<div className='location' title={location}>
							{location}
						</div>
						<div className='location-title'>{locationTitle}</div>
					</div>
				</div>
				<div className='right-block'>
					<div className='nodata-icon'>
						<img className='nodata-icon' src={nodataIcon} alt="" />
					</div>
					{/* <div className='text-block'>
						Conflict
					</div> */}
				</div>
			</div>
		</div>
	);
};

export default SimilarCitiesNoData;
