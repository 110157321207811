import React from 'react';
import Typography from '@material-ui/core/Typography';
import { fileFlagUrl } from '../../lib/countries';

import linkIcon from '../../../images/Link.svg';

import './TravelAdvice.scss';

const TravelAdvice = (props) => {
	const { travelSupportInfo } = props;

	const countryCode = travelSupportInfo?.code2;
	const countryName = travelSupportInfo?.name;

	const links = travelSupportInfo?.links || {};

	const flagUrl = countryCode ? fileFlagUrl(countryCode.toLowerCase()) : null;

	const renderBtnLink = (url = '#', text) => {
		return (
			<a
				key={url}
				className='btn-link'
				href={url}
				target='_blank'
				rel='noopener noreferrer'
			>
				<span className='link-icon'>
					<img src={linkIcon} alt='link' />
				</span>
				<span className='link-text'>{text}</span>
			</a>
		);
	};

	return (
		<div className='travel-advice'>
			<div className='text-wrap'>
				<div className='block-text titleSection'>
					<div className='title-text-wrap'>
						<Typography variant='h4' align='left'>
							{countryName} Travel Advice from Key Sources
							<img className='flag-icon' src={flagUrl} alt='' />
						</Typography>
						<Typography variant='h5' align='left'>
							Crime, safety, health, and other critical information for
							international travelers from selected government sources
						</Typography>
					</div>
				</div>
				<div className='btn-wrap'>
					{Object.keys(links).map((key) => renderBtnLink(links[key], key))}
				</div>
			</div>
		</div>
	);
};

export default TravelAdvice;
