import { createMuiTheme } from '@material-ui/core/styles';
import styles from './theme.json';

export const Color = {
    white: '#fff',
    blueDark: '#233544',
    blueMediumDark: '#334B5D',
    gray: '#7D8C97',
    grayLight: '#DEE2E5',
    violet: '#9356F8',
    cyanLight: '#7EF2F3',
    green: '#6AD400',
    crime: '#FF0000',

    heatmap0: '#dee2e5',
    heatmap1: '#6AD400',
    heatmap2: '#97D101',
    heatmap3: '#B5CF01',
    heatmap4: '#D3CE01',
    heatmap5: '#FFCB00',
    heatmap6: '#FFB601',
    heatmap7: '#FF8E01',
    heatmap8: '#FF6401',
    heatmap9: '#FF3C01',
    heatmap10: '#FF0000',
    heatmap11: '#C10000',
    heatmap12: '#390505',

    /*Heatmap colors (blue - violet)*/
    blueHeatmap1: '#1FF1F2',
    /* 0 - 0.01*/
    blueHeatmap2: '#2EDDF3',
    /* 0.01 - 1*/
    blueHeatmap3: '#40C5F4',
    /* 1 - 10*/
    blueHeatmap4: '#4BB6F5',
    /* 10 - 50*/
    blueHeatmap5: '#54AAF5',
    /* 50 - 100*/
    blueHeatmap6: '#6298F6',
    /* 100 - 200*/
    blueHeatmap7: '#6E87F6',
    /* 200 - 400*/
    blueHeatmap8: '#7C75F7',
    /* 400 - 600*/
    blueHeatmap9: '#826DF7',
    /* 600 - 800*/
    blueHeatmap10: '#9356F8',
    /* 800 - 1000*/
};

export const HeatmapColors = [
    Color.heatmap1,
    Color.heatmap2,
    Color.heatmap3,
    Color.heatmap4,
    Color.heatmap5,
    Color.heatmap6,
    Color.heatmap7,
    Color.heatmap8,
    Color.heatmap9,
    Color.heatmap10,
    Color.heatmap11,
];

export const BtnStyleDefault = {
    height: 50,
    borderRadius: 25,
    padding: '0 50px',
    fontSize: '1.4rem',
    fontWeight: 'bold',
    lineHeight: '114%',
    letterSpacing: '0.05em',
    textTransform: 'uppercase',
};

export const BtnStyleDefaultSmall = {
    height: 35,
    borderRadius: 30,
    padding: '0 15px',
    fontSize: '1.4rem',
    lineHeight: '114%',
    letterSpacing: '0.6px',
    textTransform: 'uppercase',
};

export const Shadow = {
    boxShadow: '0 2rem 2.5rem -1.5rem rgba(0, 26, 33, .5)'
};

const theme = createMuiTheme(styles);

export default theme;
