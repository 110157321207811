
const styles = (theme) => ({
    root: {
        height: 50,
        borderRadius: 25,
        boxShadow: theme.shadows[24],
    },
    rootSmall: {
        height: 30,
        minHeight: 30,
        borderRadius: 15,
        boxShadow: 'none',
        lineHeight: 'unset',
    },
    rootTiny: {
        height: 26,
        minHeight: 26,
        borderRadius: 13,
        boxShadow: 'none',
        lineHeight: 'unset',
    },
    label: {
        fontSize: '1rem',
        fontWeight: 'bold',
        color: theme.palette.primary.dark,
    },
    labelSmall: {
        fontSize: '0.8571rem',
        fontWeight: 500,
        color: theme.palette.primary.dark,
        textTransform: 'none',
    },
    labelTiny: {
        fontSize: '0.8571rem',
        fontWeight: 500,
        color: theme.palette.primary.dark,
        textTransform: 'none',
    }
});

export default styles;
