import {put, call} from 'redux-saga/effects';
import ReportActions, {REPORT_PENDING} from '../store/ReportRedux';
import NetworkActions from '../store/NetworkRedux';

export function* reportRiskTravel(api, action) {
    const pending = REPORT_PENDING.reportRiskTravel;
    const {data} = action;

    yield put(NetworkActions.networkAddPending(pending));
    const response = yield call(() => api.getRiskTravelReportV2(data));

    if (response.ok) {
        yield put(ReportActions.reportRiskTravelSuccess(response.data, response.headers));
        yield put(NetworkActions.networkDelPending(pending));
    } else {
        yield put(ReportActions.reportRiskTravelFailure(response));
        yield put(NetworkActions.networkDelPending(pending));
    }

    return response;
}
