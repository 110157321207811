import React from 'react';
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';

import styles from './OutlinedButton.jss';

const OutlinedButton = (props) => {
    const {
        classes, children, onClick, small = false, tiny = false,
        primary, secondary, ...rest
    } = props;

    const getRootStyle = () => {
        if (small) return classes.rootSmall;
        else if (tiny) return classes.rootTiny;
        return classes.root;
    };

    const getLabelStyle = () => {
        if (small) return classes.labelSmall;
        else if (tiny) return classes.labelTiny;
        return classes.label;
    };

    const getColorStyle = () => {
        if (primary) return classes.primary;
        else if (secondary) return classes.secondary;
        return classes.default;
    };

    const getLabelColorStyle = () => {
        if (primary) return classes.defaultLabelColor;
        else if (secondary) return classes.secondaryLabelColor;
        return classes.defaultLabelColor;
    };

    return (
        <Button
            variant="outlined"
            classes={{
                root: `${getRootStyle()} ${getColorStyle()}`,
                label: `${getLabelStyle()} ${getLabelColorStyle()}`
            }}
            fullWidth
            onClick={onClick}
            {...rest}
        >
            {children}
        </Button>
    );
};

export default withStyles(styles)(OutlinedButton);
