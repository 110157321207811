import React from 'react';

import logotype from '../../images/logo.png';

const Logotype = (props) => {
    const {id = "logo"} = props;
    return (
        <img width={90} height={90} src={logotype} alt="SE" id={id} />
    );
};

export default Logotype;
