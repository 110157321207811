import React, {PureComponent} from 'react';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import styles from './Panel.jss';

class CPaper extends PureComponent {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        children: PropTypes.node,
        className: PropTypes.string,
        light: PropTypes.bool,
        onClose: PropTypes.func,
    };

    getRootClass() {
        const {classes, light} = this.props;
        return (light) ? classes.rootLight : classes.root; 
    }

    render() {
        const {
            classes, children, className = '', loading = false,
            onClose,
        } = this.props;
        return (
            <Paper className={`${this.getRootClass()} ${className}`} elevation={undefined}>
                {onClose ? (
                    <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
                {children}
                {loading && (
                    <div className={classes.overlay}>
                        <LinearProgress color="primary" />
                    </div>
                )}
            </Paper>
        );
    }
}

export default withStyles(styles)(CPaper);
