
import bg from '../../images/bg.jpg';

const styles = (theme) => ({
    bg: {
        display: 'flex',
        flex: '1 1 auto',
        alignItems: 'center',
        justifyContent: 'center',
        background: `url(${bg}) no-repeat`,
        backgroundSize: 'cover',
        minHeight: '100%',
        position: 'relative',

        '& #bg-video': {
            position: 'fixed',
            right: 0,
            bottom: 0,
            minWidth: '100%',
            minHeight: '100%',
        }

    },
});

export default styles;
