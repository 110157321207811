
const styles = (theme) => ({
    container: {
        padding: 0,
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '13px 22px',
    },
    formPanel: {
        minWidth: 282,
        width: 282,
        minHeight: 204,
        position: 'relative',
        margin: 8,
        padding: 20,
        boxSizing: 'border-box',
    },
    row: {
        marginTop: 30,
    },
    error: {
        marginTop: 20,
        textAlign: 'center',
        color: theme.palette.error.main,
    },
    success: {
        marginTop: 20,
        textAlign: 'center',
    },
});

export default styles;
