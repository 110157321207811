
const styles = (theme) => ({
    container: {
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        paddingTop: 45,
        paddingLeft: 10,
    },
    content: {
        padding: 0,
    },
    panel: {
        zIndex: 100,
        minWidth: 298,
        width: 298,
        minHeight: 204,
        marginTop: -10,
        marginLeft: 10,
        position: 'relative',
    },
    header: {
        borderBottom: `1px solid ${theme.palette.primary.dark}`,
        padding: '13px 22px',
    },
    headerLine: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    list: {
        listStyleType: 'none',
        maxHeight: 210,
        overflowY: 'auto',
        marginBottom: 13,
        marginRight: 1,
        position: 'relative',
    },
    overlay: {
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        width: '100%',
        backgroundColor: 'rgba(0,0,0,0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: theme.shape.borderRadius,
    },

    '@media screen and (max-width: 767px)': {
        container: {
            flexDirection: 'column',
            alignItems: 'center',
            paddingLeft: 0,
            paddingTop: 130,
        },

        panel: {
            marginLeft: 0,
            width: 'calc(100% - 60px)',
            minWidth: 'auto',
        },
    },
});

export default styles;
