import React, {PureComponent} from 'react';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import styles from './Dialog.jss';

class CDialog extends PureComponent {
    static propTypes = {
        classes: PropTypes.object.isRequired,
        children: PropTypes.node,
        onClose: PropTypes.func,
        open: PropTypes.bool.isRequired,
    };

    render() {
        const {classes, children, onClose, title = '', open = false} = this.props;
        return (
            <Dialog
                onClose={this.handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                className={classes.root}
                classes={{
                    paper: classes.paper,
                }}
            >
                <MuiDialogTitle disableTypography className={classes.title}>
                    <Typography variant="h5">{title}</Typography>
                    {onClose ? (
                        <IconButton aria-label="Close" className={classes.closeButton} onClick={onClose}>
                            <CloseIcon />
                        </IconButton>
                    ) : null}
                </MuiDialogTitle>
                <MuiDialogContent className="report-help-popap">
                    {children}
                </MuiDialogContent>
            </Dialog>
        );
    }
}

export default withStyles(styles)(CDialog);
