import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { withStyles } from '@material-ui/core/styles';

import styles from './DropDown.jss';

const DropDown = (props) => {
    const {items, placeholder, classes, footer, ...rest} = props;

    const Arrow = () => {
        return (
            <span className={classes.iconArrow}>keyboard_arrow_down</span>
        );
    };

    const renderItems = () => {
        if (items && items.length) {
            return ([
                items.map((item, idx) => (renderItem(item, idx))),
                footer
            ]);
        } else if (footer) return footer;
        return null;
    };

    const renderItem = (item, idx) => {
        const {
            value, start, end, title, subtitle, subtitlePrefix
        } = item;
        return (
            <MenuItem value={value} key={`item${idx}`}>
                {start}
                <div className={classes.selectContainerOpened}>
                    <div>{title}</div>
                    <div>{subtitlePrefix} {subtitle}</div>
                </div>
                {end}
            </MenuItem>
        );
    };

    const renderValue = (value) => {
        if (value) {
            const item = items.find((el) => (el.value === value));
            if (item) {
            const {
                start, end, title, subtitle, subtitlePrefix
            } = item;
                return (
                    <div className={classes.selectContainer}>
                        {start}
                        <div>
                            <div className={classes.selectTitle}>{title}</div>
                            <div className={classes.selectSubTitlePrefix}>{subtitlePrefix} {subtitle}</div>
                        </div>
                        {end}
                    </div>
                );
            } else {
                console.log('=== DropDown::renderValue error', value);
            }
        }
        return (placeholder || <div />);
    };

    return (
        <FormControl fullWidth className={classes.formControl}>
            <Select
                native={false}
                displayEmpty
                renderValue={renderValue}
                classes={{
                    select: classes.select
                }}
                IconComponent={Arrow}
                {...rest}>
                {renderItems()}
            </Select>
        </FormControl>
    );
};

export default withStyles(styles)(DropDown);
