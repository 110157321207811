import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import 'unorm';
import { loadStripe } from '@stripe/stripe-js';

import Config from './config';
import createStore from './store';
import theme from './theme';
import { api } from './sagas';

import './App.scss';

import { Logo } from './components';

import { Layout } from './features/Layout';
import { Login, Terms, Eula, ForgotPassword, Privacy } from './features/Login';
import { ReportShared } from './features/Report';
import userActions from './store/UserRedux';
import ReportsPDF from './features/Report/ReportsPDF/ReportsPDF';
import OrttoProvider from './context/ortto';

const ROUTE = {
	default: '/',
	login: '/login',
	terms: '/terms',
	eula: '/eula',
	forgotPassword: '/forgot-password',
	report: '/report',
	reportShared: '/report/:uuid',
	reportPDF: '/test/report/:uuid',
	persons: '/persons',
	settings: '/settings',
	privacy: '/privacy',
	noSubscriptionException: '/no-subscription',
	textMessageException: '/message',
	addCreditCard: '/add-credit-card',
};
export { ROUTE };

const ROUTE_LEFT_MENU = {
	logo: ROUTE.default,
	report: ROUTE.report,
	persons: ROUTE.persons,
	settings: ROUTE.settings,
};
export { ROUTE_LEFT_MENU };

const store = createStore();
export { store };

export { theme };

export const stripePromise = loadStripe(Config.stripe.apiKey);

class App extends Component {
	state = {
		checked: false,
	};

	componentDidMount() {
		this.checkAuthorization();
	}

	checkAuthorization() {
		api
			.getPerson()
			.then((response) => {
				if (response.ok && response.data.id > 0) {
					store.dispatch(
						userActions.userDetailsSuccess(response.data, response.status)
					);
					store.dispatch(
						userActions.userLoginSuccess({ token: null }, response.status)
					);
				} else {
					store.dispatch(userActions.userLogout());
				}
				this.setState({ checked: true });
			})
			.catch(() => {
				this.setState({ checked: true });
			});
	}

	render() {
		const { checked } = this.state;

		if (!checked)
			return (
				<div className='preloading'>
					<Logo />
				</div>
			);

		return (
			<Provider store={store}>
				<MuiThemeProvider theme={theme}>
					<OrttoProvider>
						<Router>
							<Switch>
								<Route path={ROUTE.login} exact component={Login} />
								<Route path={ROUTE.terms} exact component={Terms} />
								<Route path={ROUTE.eula} exact component={Eula} />
								<Route path={ROUTE.privacy} exact component={Privacy} />
								<Route
									path={ROUTE.forgotPassword}
									exact
									component={ForgotPassword}
								/>
								<Route
									path={ROUTE.reportShared}
									exact
									component={ReportShared}
								/>
								<Route path={ROUTE.reportPDF} exact component={ReportsPDF} />
								<Route path={ROUTE.default} component={Layout} />
								<Route component={() => <div>404</div>} />
							</Switch>
						</Router>
					</OrttoProvider>
				</MuiThemeProvider>
			</Provider>
		);
	}
}

export default App;
