import React, { Component } from 'react';
import * as d3 from 'd3';

import { truncate } from '../../../lib';
import { HeatmapColors } from '../../../theme';

import {
    appendColorLinearGradient, getLinePoint, appendGrid, getHomePoint, getDestinationPoint, arrowSmall,
} from './SvgFactory';

type PropsType = {
    width: number,
    height: number,
    hMargin: number,
    vMargin: number,
    axisStroke: number,
    pointColor: {
        color0: string,
        color1: string,
        color2: string,
        value: string,
        label: string,
    },
    pointData: Array<{}>,
    homePointColor: {
        color0: string,
        color1: string,
        color2: string,
        icon: string,
    },
    homePointTooltipColor: {
        border: string,
        background: string,
        text: string,
        type: string,
    },
    homePointData: {
        value: number,
        label: string
    },
    destinationPointData: {
        value: number,
        label: string
    },
    onComplete: Function
};

class RiskChart extends Component<PropsType> {
    riskChart = null;
    riskChartInited = false;
    svg = null;

    constructor(props: PropsType) {
        super(props);

        const {
            width, height, hMargin, vMargin, pointData, homePointData, destinationPointData,
        } = props;

        const points = pointData.concat(homePointData, destinationPointData);
        const maxPointScore = points.reduce(
            (score, p) => {
                return Math.max(score, p.value);
            },
            0
        );

        const getValueColor = d3.scaleSequential().domain([0, maxPointScore + 15]).interpolator((t) => {
            const point = d3.scaleLinear().domain([0, 1]).range([0, 9]);
            const value = point(t);
            const left = Math.floor(value);
            const right = Math.ceil(value);
            const color = d3.scaleLinear([left, right], [HeatmapColors[left], HeatmapColors[right]])
            return color(value);
        });

        const lineData = d3.range(0, 1000, 5);
        const lineXDomain = [d3.min(lineData), d3.max(lineData)];
        const lineYDomain = [0, 2.57];

        const xScale = d3.scaleLinear().domain(lineXDomain).range([0, width]);
        const xScale2 = d3.scaleLinear().domain(lineXDomain).range([0, 20]);
        const yScale = d3.scaleLinear().domain(lineYDomain).range([height, 0]);
        const yScale2 = d3.scaleLinear().domain(lineYDomain).range([0, maxPointScore + 5]);

        const getAxisY = (x) => (
            yScale(Math.log(1 + 0.6 * xScale2(x)))
        );

        const getAxisXFromRiskValue = (value) => (
            xScale(
                xScale2.invert(
                    (Math.pow(Math.E, yScale2.invert(value)) - 1) / 0.6
                )
            )
        );
        const getAxisYFromRiskValue = (value) => (
            yScale(yScale2.invert(value))
        );

        this.state = {
            width,
            height,
            hMargin,
            vMargin,
            lineData,
            lineXDomain,
            lineYDomain,
            xScale,
            xScale2,
            yScale,
            yScale2,
            getAxisY,
            getAxisXFromRiskValue,
            getAxisYFromRiskValue,
            getValueColor,
        };
    }

    initChart() {
        if (this.riskChart && !this.riskChartInited) {
            const {
                width, height, hMargin, vMargin,
            } = this.state;
            const { onComplete } = this.props;
            this.riskChartInited = true;

            const svg = d3.select(this.riskChart);
            svg
                .attr("width", width + hMargin)
                .attr("height", height + vMargin)
            // .attr("viewBox", '0 0 820 430');
            this.svg = svg;

            this.initChartDefs();
            this.initAxises();
            this.initLine();
            this.initPoints();
            this.initHomePoint();
            this.initDestinationPoint();

            if (onComplete) onComplete({
                svg: svg.html(),
                width: width + hMargin,
                height: height + vMargin,
            });
        }
    }

    initChartDefs() {
        const svg = this.svg;
        if (svg) {
            const defs = svg.append("defs");
            appendColorLinearGradient({
                defs, id: 'linear-gradient-line',
                x1: '0%', y1: '100%',
                x2: '100%', y2: '0%'
            });

            const maskDestination = defs.append('mask')
                .attr('id', 'mask-destination')
            maskDestination.append('rect')
                .attr('x', -50)
                .attr('y', -50)
                .attr('width', 100)
                .attr('height', 200)
                .attr('fill', 'white')
            maskDestination.append('circle')
                .attr('cx', 7)
                .attr('cy', 5.5)
                .attr('r', 3.6)
                .attr('fill', 'black');

            const shadow = defs.append('filter')
                .attr('id', 'dropshadow')
                .attr('height', '130%');
            shadow.append('feGaussianBlur').attr('in', 'SourceAlpha').attr('stdDeviation', '4');
            shadow.append('feOffset').attr('dx', '0').attr('dy', '2').attr('result', 'offsetblur');
            shadow.append('feComponentTransfer')
                .append('feFuncA').attr('type', 'linear').attr('slope', '0.15');
            const shadowMerge = shadow.append('feMerge');
            shadowMerge.append('feMergeNode');
            shadowMerge.append('feMergeNode').attr('in', 'SourceGraphic');
        }
    }

    initAxises() {
        const svg = this.svg;
        if (svg) {
            const {
                width, height, hMargin, xScale, yScale2, yScale,
            } = this.state;
            const { axisStroke } = this.props;
            appendGrid({
                parent: svg.append('g').attr('transform', `translate(${hMargin - axisStroke}, 0)`),
                height: height + axisStroke, width: width + axisStroke,
                xScale, yScale: (value) => (yScale2(yScale.invert(value))), axisStroke,
            });
        }
    }

    initLine() {
        const svg = this.svg;
        if (svg) {
            const {
                hMargin, xScale, getAxisY, lineData,
            } = this.state;

            const line = d3.line()
                .x((d) => (xScale(d)))
                .y((d) => (getAxisY(d)));
            svg.append('g').attr('transform', `translate(${hMargin}, 0)`)
                .append('path')
                .attr('stroke', 'url(#linear-gradient-line)')
                .attr('stroke-width', '3')
                .attr('fill', 'none')
                .attr('d', line(lineData));
        }
    }

    initPoints() {
        const svg = this.svg;
        if (svg) {
            const {
                getAxisXFromRiskValue, hMargin, getAxisYFromRiskValue, getValueColor,
            } = this.state;
            const {
                pointColor, pointData,
            } = this.props;

            const point = getLinePoint({});
            pointData.forEach((d, idx) => {
                const isFirst = idx === 0;
                const isLast = idx === pointData.length - 1;

                const pointG = svg.append('g')
                    .attr('transform', `translate(${getAxisXFromRiskValue(d.value) + hMargin}, ${getAxisYFromRiskValue(d.value)})`);
                pointG.append('path')
                    .attr('d', point[0])
                    .attr('fill', getValueColor(d.value));
                pointG.append('path')
                    .attr('d', point[1])
                    .attr('fill', pointColor.color1);
                pointG.append('text')
                    .text(d.value)
                    .style('text-anchor', 'middle')
                    .attr('font-size', '12px')
                    .attr('y', '4')
                    .attr('fill', pointColor.value);

                let arrow = pointG.append('g').attr('transform', `translate(11,13) rotate(135,7,3)`);
                if (isFirst || isLast) {
                    arrow = pointG.append('g').attr('transform', `translate(-7,18) rotate(180,7,3)`);
                }
                arrow.append('path')
                    .attr('d', arrowSmall)
                    .attr('fill', getValueColor(d.value));

                let labelX = '25';
                let labelY = '28';
                if (isFirst) {
                    labelX = '-15';
                    labelY = '38';
                } else if (isLast) {
                    labelX = '-20';
                    labelY = '38';
                }
                pointG.append('text')
                    .text(d.label)
                    .attr('font-size', '11px')
                    .attr('x', labelX)
                    .attr('y', labelY)
                    .attr('fill', pointColor.label);
            });
        }
    }

    initHomePoint() {
        const {
            homePointColor: color, homePointData: data, homePointTooltipColor: tooltipColor,
        } = this.props;

        const point = getHomePoint({ fill: color.icon });

        this.drawBigPoint({
            data, color, tooltipColor, point, label: 'Home', index: 1,
        });
    }

    initDestinationPoint() {
        const {
            destinationPointColor: color, destinationPointData: data, destinationPointTooltipColor: tooltipColor,
        } = this.props;
        const point = getDestinationPoint({});

        this.drawBigPoint({
            data, color, tooltipColor, point, label: 'Destination', index: 0,
        });
    }

    drawBigPoint(props) {
        const {
            data, color, tooltipColor, point, label, index,
        } = props;
        const svg = this.svg;
        if (svg) {
            const {
                getAxisXFromRiskValue, hMargin, getAxisYFromRiskValue,
            } = this.state;

            const dataLabel = truncate(data.label, 24);

            const pointGTx = getAxisXFromRiskValue(data.value) + hMargin;
            const pointGTy = getAxisYFromRiskValue(data.value);

            if (data.value !== -1) {
                const pointG = svg.append('g')
                    .attr('class', 'point-icon point-icon-' + index)
                    .attr('transform', `translate(${pointGTx}, ${pointGTy})`);

                pointG.append('path')
                    .attr('d', point[0])
                    .attr('fill', color.color0)
                    .attr('fill-opacity', '0.8')
                    .attr('filter', 'url(#dropshadow)');
                pointG.append('path')
                    .attr('d', point[1])
                    .attr('fill', color.color1);
                pointG.append('g')
                    .attr('transform', 'translate(-7.5, -8.5)')
                    .append(() => (point[2]({ fill: color.icon })));
            }
            const pointTooltip = svg.append('g')
                .attr('class', 'home-point home-point-' + index);
            const pointText = pointTooltip.append('text')
                // .text(dataLabel)
                .text(dataLabel)
                .attr('class', 'home-point-text')
                .attr('font-size', '13px')
                .style('text-anchor', 'middle')
                .attr('fill', 'transparent');

            const textBox = pointText.node().getBBox();
            if (textBox) { // width, height, x, y,
                pointText.attr('fill', tooltipColor.text);
                console.log('=== textBox', textBox);

                const topMargin = 13;
                const bottomMargin = 28;
                const panelW = 65;
                const rX = textBox.x - panelW;
                const rY = textBox.y - topMargin;
                const rW = panelW * 2 + Math.max(textBox.width, 60);
                const rH = textBox.height + topMargin + bottomMargin;
                pointTooltip.insert('rect', '.home-point-text')
                    .attr('x', rX)
                    .attr('y', rY)
                    .attr('width', rW)
                    .attr('height', rH)
                    .attr('rx', 15)
                    .attr('stroke', tooltipColor.text)
                    .attr('stroke-width', 2)
                    .attr('fill', tooltipColor.background)
                    .attr('fill-opacity', 0.9);

                pointTooltip
                    .append('g')
                    .attr('transform', `translate(${rX + panelW * 0.36}, ${rY + rH * 0.32})`)
                    .append(() => (point[2]({ fill: tooltipColor.text })));

                const vX = rX + rW - panelW + 16;
                const vY = rY + rH / 2 + 6;
                const valueFontSize = data.value !== -1 ? '26px' : '20px';

                pointTooltip.append('text')
                    .text(data.value !== -1 ? data.value : 'N.A.')
                    .attr('x', vX)
                    .attr('y', vY)
                    .attr('font-size', valueFontSize)
                    .attr('fill', tooltipColor.text);

                pointTooltip.append('text')
                    .text(label)
                    .attr('x', textBox.x)
                    .attr('y', textBox.y + 28)
                    .attr('font-size', '11px')
                    .attr('fill', tooltipColor.type);

                const pointTooltipBox = pointTooltip.node().getBBox();
                if (pointTooltipBox) {
                    const { width, height, hMargin } = this.state;
                    const tooltipMarginY = 5;

                    pointTooltip.attr('transform', `translate(${width + hMargin - pointTooltipBox.width - pointTooltipBox.x}, ${height - (tooltipMarginY * (index + 1)) - (pointTooltipBox.height * (index + 1)) - pointTooltipBox.y})`);
                }

                // const pointGBox = pointG.node().getBBox();
                return { pointGTx, pointGTy };
            }
        }

        return null;
    }

    render() {
        return (
            <svg className="risk-chart" ref={(ref) => { this.riskChart = ref; this.initChart(); }} />
        );
    }
}

RiskChart.defaultProps = {
    width: 648,
    height: 270,
    hMargin: 50,
    vMargin: 50,
    axisStroke: 1,
    axisColor: '#e9ebec',
    pointColor: {
        color0: null,
        color1: 'white',
        value: '#233544',
        label: '#899',
    },
    pointData: [
        { value: 11, label: 'Paris, France' },
        { value: 26, label: 'Tbilisi, Georgia' },
        { value: 39, label: 'Kiev, Ukraine' },
        { value: 50, label: 'Puerto Rico, Colombia' },
        { value: 66, label: 'Aleppo, Syria' },
    ],
    homePointColor: {
        color0: 'white',
        color1: '#FFCB00',
        icon: '#233544',
    },
    homePointTooltipColor: {
        border: 'white',
        background: '#FFCB00',
        text: 'white',
        type: 'white',
    },
    homePointData: {
        value: null,
        label: 'N.A.',
    },
    destinationPointColor: {
        color0: 'white',
        color1: '#FF8E01',
        icon: '#233544',
    },
    destinationPointData: {
        value: null,
        label: 'N.A.',
    },
    destinationPointTooltipColor: {
        border: 'white',
        background: '#FF8E01',
        text: 'white',
        type: 'white',
    },
};

export default RiskChart;
