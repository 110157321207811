
const styles = (theme) => ({
    root: {
        '& .report-help-popap': {
            marginBottom: 24,

            '& .risk-type': {
                '&:before': {
                    content: `''`,
                    display: 'block',
                    width: 17,
                    height: 6,
                    borderRadius: 7,
                },
                '&.risk-type-1:before': {
                    background: '#387A2C',
                },
                '&.risk-type-2:before': {
                    background: '#6AAD5A',
                },
                '&.risk-type-3:before': {
                    background: '#F9CE38',
                },
                '&.risk-type-4:before': {
                    background: '#E99597',
                },
                '&.risk-type-5:before': {
                    background: '#EB3223',
                },
            },
        },
    },
    paper: {
        minWidth: 480,
    },
    title: {
        margin: 0,
        padding: theme.spacing.unit * 2,
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing.unit,
        top: theme.spacing.unit,
        color: theme.palette.grey[500],
    },

    '@media screen and (max-width: 767px)': {
        paper: {
            minWidth: '100%'
        },
    },
});

export default styles;
